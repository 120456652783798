// import React, { useEffect, useRef } from 'react';
// import './refund.css';

// const RefundModel = ({ show, handleClose, children, refundloading, position }) => {
//   const modalRef = useRef(null);

//   useEffect(() => {
//     if (show && modalRef.current) {
//       const { top, left } = position;
//       modalRef.current.style.top = `${top + 30}px`; // Adjust as needed
//       modalRef.current.style.left = `${left}+677px`;
//     }
//   }, [show, position]);

//   return (
//     <div className={`modal ${show ? 'show' : ''}`} ref={modalRef}>
//       <div className="" style={{ padding: '30px' }}>
//         <span className="close-button" onClick={handleClose}>&times;</span>
//         {refundloading ? (
//           <div style={{ padding: '70px' }}>
//             <div className="loader"></div>
//           </div>
//         ) : (
//           children
//         )}
//       </div>
//     </div>
//   );
// };

// export default RefundModel;


import React, { useEffect, useRef } from 'react';
import './refund.css';

const RefundModel = ({ show, handleClose, children, refundloading, position }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (show && modalRef.current) {
      const { top, left } = position;
      const modalHeight = modalRef.current.offsetHeight;
      const windowHeight = window.innerHeight;
      
      let newTop = top + 30; // Default positioning
      
      // Check if the modal would go off the bottom of the screen
      if (newTop + modalHeight > windowHeight) {
        // Position the modal above the click position instead
        newTop = Math.max(top - modalHeight - 10, 0);
      }
      
      modalRef.current.style.top = `${newTop}px`;
      modalRef.current.style.left = `${Math.min(left, window.innerWidth - modalRef.current.offsetWidth)}px`;
    }
  }, [show, position]);

  return (
    <div className={`modal ${show ? 'show' : ''}`} ref={modalRef}>
      <div className="" style={{ padding: '30px' }}>
        <span className="close-button" onClick={handleClose}>&times;</span>
        {refundloading ? (
          <div style={{ padding: '70px' }}>
            <div className="loader"></div>
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default RefundModel;