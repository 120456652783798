import Navbar from "./main/Navbar";
import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import "../components/main/dashboard.css";
import "./dashborad.css";
import useLanguage from "../translation/TranslationEngToChinese";
import Swal from "sweetalert2";
import { removeLocalStorageItems } from "../utils/encrypt/encryptUtils";
import { CheckedAccessTokenValid } from "../utils/LogAuth";

export default function Dashboard() {
  const [navVisible, showNavbar] = useState(true);
  const { language, setLanguage, content } = useLanguage();

  // function isAccessTokenValid() {
  //   const accessToken = localStorage.getItem("accessToken");
  //   return accessToken !== null;
  // }

  // useEffect(() => {
  //   if (!isAccessTokenValid()) {
  //     window.location.href = "#";
  //   }
  // }, []);

  useEffect(() => {
    CheckedAccessTokenValid();
  }, []);

  return (
    <>
      <div class="wrapper">
        <div className="navDiv">
          <Navbar visible={navVisible} show={showNavbar} content={content} />
        </div>

        <div className="outletDiv">
          <Outlet context={{ language, setLanguage, content }} />
        </div>
      </div>
    </>
  );
}
