import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./course.css";
import logout from "../../../img/logout_copy.png";
import logo from "../../../img/wf-c.png";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { Switch, Space } from "antd";
import { ImCross } from "react-icons/im";
import { apiBaseUrls, apiKeys } from "../../../config";
import ReactPaginate from "react-paginate";
import NavBar from "../other/navBar";
import { encryptedaes } from "../../../utils/encrypt/encryptUtils";
import {
  removeAllLocalStorageItems,
  AccessTokenValid,
  CheckedAccessTokenValid,
} from "../../../utils/LogAuth";

const API_ENDPOINT = apiBaseUrls.development;


function getTitle(titleJson) {
  let title = titleJson; // Initialize with the default title
  try {
    const titleObject = JSON.parse(titleJson);
    title = titleObject["en-US"] || title; // Use the localized title if available
  } catch (error) {
    // Parsing error, use default title
  }
  return title;
}

function Course() {
  const { language, setLanguage, content } = useOutletContext();
  const [courses, setCourses] = useState([]);
  const [blackListedCourses, setBlackListedCourses] = useState([]); // Initialize as an empty array
  const [searchQuery, setSearchQuery] = useState("");
  const [courseStatus, setCourseStatus] = useState({}); // Initialize an empty object
  const [loading, setLoading] = useState(true); // Initialize as true
  const loginUser = localStorage.getItem("accessToken");
  const dataArray = loginUser ? loginUser.split("#") : []; // Add a check for loginUser
  const Currentemail = dataArray[0] || null; // Set Currentemail to null if dataArray is empty
  const apiurl = API_ENDPOINT + "course/getCourses";
  const blackListedApiUrl = API_ENDPOINT + "course/blackListedCourses";
  const toggleApiUrl = API_ENDPOINT + "course/blackList";
  const [videoIds, setVideoIds] = useState([]);

  const [filterOption, setFilterOption] = useState("all"); // Default to "all" as an initial filter option
  const [selectedTypeFilter, setSelectedTypeFilter] = useState("all"); // Default to "all"

  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };
  
  // Extract unique course types
  const uniqueCourseTypes = [
    ...new Set(courses.map((course) => course.class_type.name)),
  ];

  const filterOptions = [
    { label: "All", value: "all" },
    { label: "Enabled", value: "enabled" },
    { label: "Disabled", value: "disabled" },
  ];

  const getPosterToDisplay = axios.get(
    API_ENDPOINT + "poster/posterToDisplay",
    config
  );

  // useEffect(() => {
  //   setLoading(true);
  //   axios
  //     .get(apiurl, config)
  //     .then((response) => {
  //       const data = response.data;
  //       if (data.videos && data.videos.data) {
  //         setCourses(data.videos.data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching course data:", error);
  //     })
  //     .finally(() => {
  //       setLoading(false); // Set loading to false after API call completion
  //     });

  //   axios
  //     .get(blackListedApiUrl, config)
  //     .then((response) => {
  //       const data = response.data;
  //       if (data.blackList) {
  //         // Create an object with courseId as key and blacklisted status as value
  //         const statusMap = {};
  //         data.blackList.forEach((blackListedCourse) => {
  //           statusMap[blackListedCourse.course.id] =
  //             blackListedCourse.blackList;
  //         });
  //         setCourseStatus(statusMap);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching blacklisted courses:", error);
  //     });
  // }, []);

  const storedCourses = localStorage.getItem("getCourses");
  useEffect(() => {
    setLoading(true);
    if (storedCourses) {
      const coursesData = JSON.parse(storedCourses);
      if (coursesData.videos && coursesData.videos.data) {
        setCourses(coursesData.videos.data);
      }
      setLoading(false);
    } else {
      if (AccessTokenValid()) {
        axios
          .get(apiurl, config)
          .then((response) => {
            const data = response.data;
            if (data.videos && data.videos.data) {
              setCourses(data.videos.data);
              // Store the fetched data in local storage
              localStorage.setItem("getCourses", JSON.stringify(data));
            }
          })
          .catch((error) => {
            console.error("Error fetching course data:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        CheckedAccessTokenValid();
      }
    }

    if (AccessTokenValid()) {
      axios
        .get(blackListedApiUrl, config)
        .then((response) => {
          const data = response.data;
          if (data.blackList) {
            const statusMap = {};
            data.blackList.forEach((blackListedCourse) => {
              statusMap[blackListedCourse.course.id] =
                blackListedCourse.blackList;
            });
            setCourseStatus(statusMap);
          }
        })
        .catch((error) => {
          console.error("Error fetching blacklisted courses:", error);
        });
    } else {
      CheckedAccessTokenValid();
    }

    // Make the API call for poster data
    if (AccessTokenValid()) {
      getPosterToDisplay
        .then((response) => {
          const posterImages = response.data.images;
          const videoIdsArray = posterImages.map((image) => image.video.id);
          setVideoIds(videoIdsArray);
        })
        .catch((error) => {
          console.error("Error fetching poster data:", error);
        });
    } else {
      CheckedAccessTokenValid();
    }
  }, []);

  console.log("video_id:--", videoIds);
  console.log("Video:--", courses);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(0);
  };

  const isBlackListed = (courseId) =>
    blackListedCourses.some(
      (blackListedCourse) => blackListedCourse.course.id === courseId
    );
  const handleToggle = async (course) => {
    // Check if the course's video_id is in the videoIds array
    if (videoIds.includes(course.id)) {
      // Display an alert message here
      Swal.fire({
        icon: "error",
        title: content["Oops"],
        text: content["Videos linked to banners cannot be disabled!"],
      });
      return; // Exit the function, do not toggle the course
    }

    const newCourseStatus = { ...courseStatus }; // Create a copy of the current status
    newCourseStatus[course.id] = !courseStatus[course.id]; // Toggle the status

    try {
      const requestBody = {
        course: {
          id: course.id,
          title: course.title,
          duration: course.duration,
          language: course.language,
          with_weights: course.with_weights,
          description: course.description,
          video_url: course.video_url,
          snapshot_url: course.snapshot_url,
          class_type_id: course.class_type_id,
          scheduled_time: course.scheduled_time,
          start_time: course.start_time,
          end_time: course.end_time,
          featured: course.featured,
          video_id: course.video_id,
          status: course.status,
          source_class_id: course.source_class_id,
          channel_ids: course.channel_ids,
          updated_at: course.updated_at,
          active: course.active,
          instructor: {
            id: course.instructor.id,
            first_name: course.instructor.first_name,
            last_name: course.instructor.last_name,
            bio: course.instructor.bio,
            banner_url: course.instructor.banner_url,
            avatar_url: course.instructor.avatar_url,
          },
          class_type: {
            id: course.class_type.id,
            name: course.class_type.name,
            live_compatible: course.class_type.live_compatible,
            floor_type: course.class_type.floor_type,
          },
        },
        status: newCourseStatus[course.id], // Send the updated status
      };

      const { encryptedData, SessionToken } = encryptedaes(requestBody);
      config.headers["Session-Token"] = SessionToken;

      const encryptbody = { data: encryptedData };

      const response = await axios.post(toggleApiUrl, encryptbody, config);

      
      // if (response.status === 200) {
      //   setCourseStatus(newCourseStatus); // Update the local state
      // }
      if (response.status === 200) {
        // Check response.data.result for specific values
        if (response.data.result === 420) {
            // Prevent toggling and show an error using Swal
            newCourseStatus[course.id] = !newCourseStatus[course.id]; // Restore the previous status
            Swal.fire({
                icon: "error",
                // title: "Unauthorized",
                // text: "You are not authorized to perform this action.",
                title: content["Unauthorized"],
                text: content["You are not authorized to perform this action!"],
                confirmButtonText: content["OK"],
            });
        } else if (response.data.result === 100) {
            // Trigger a success action using Swal alert
            Swal.fire({
                icon: "success",
                title: "Success",
                text: "Course toggled successfully!",
            });
            setCourseStatus(newCourseStatus); // Update the local state
        }
    }
    } catch (error) {
      console.error("Error toggling course:", error);
    }
  };

  // Filter based on both searchQuery and selectedTypeFilter
  // const filteredCourses = courses.filter((course) => {
  //   const isTitleMatch = getTitle(course.title)
  //     .toLowerCase()
  //     .includes(searchQuery.toLowerCase());

  //   const isTypeMatch =
  //     selectedTypeFilter === "all" ||
  //     course.class_type.name === selectedTypeFilter;

  //   if (filterOption === "all") {
  //     // Show all courses
  //     return isTitleMatch && isTypeMatch;
  //   } else if (filterOption === "enabled") {
  //     // Show only enabled courses
  //     return isTitleMatch && isTypeMatch && !courseStatus[course.id];
  //   } else if (filterOption === "disabled") {
  //     // Show only disabled courses
  //     return isTitleMatch && isTypeMatch && courseStatus[course.id];
  //   }
  // });

  useEffect(() => {
    setCurrentPage(0); // Reset currentPage whenever filterOption changes
  }, [filterOption]);

  const filteredCourses = courses.filter((course) => {
    const isTitleMatch = getTitle(course.title)
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    const isTypeMatch =
      selectedTypeFilter === "all" ||
      course.class_type.name === selectedTypeFilter;

    const hasDuration = course.duration !== null; // Add this condition

    if (filterOption === "all") {
      // Show all courses
      return isTitleMatch && isTypeMatch && hasDuration;
    } else if (filterOption === "enabled") {
      // Show only enabled courses
      return (
        isTitleMatch && isTypeMatch && !courseStatus[course.id] && hasDuration
      );
    } else if (filterOption === "disabled") {
      // Show only disabled courses
      return (
        isTitleMatch && isTypeMatch && courseStatus[course.id] && hasDuration
      );
    }
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(25); // Number of items to display per page

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    // Access the scrollable container element using the ref
    const scrollContainer = scrollContainerRef.current;

    // Reset the scroll position to the top
    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
    }
  }, [currentPage]);

  // Handle type filter change
  const handleTypeFilterChange = (newTypeFilter) => {
    setSelectedTypeFilter(newTypeFilter);
    setCurrentPage(0); // Reset the current page to 0 when the type filter changes

    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
    }
  };

  // Calculate the number of pages
  const pageCount = Math.ceil(filteredCourses.length / perPage);

  // Calculate the current page data based on currentPage and perPage
  const offset = currentPage * perPage;
  const currentPageData = filteredCourses.slice(offset, offset + perPage);

  // Handle page change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    const accessToken = localStorage.getItem("accessToken");
    const userId = accessToken.split("#")[0];
    console.log("Test User_id", userId);
    const requestBody = { accessToken, userId };
    //encryption data
    // const encryptbody = {data:encryption(requestBody)}
    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;

    const encryptbody = { data: encryptedData };
    axios
      .post(API_ENDPOINT + "user/logout", encryptbody, config)
      .then(() => {
        // localStorage.removeItem("accessToken");
        removeAllLocalStorageItems();
        document.cookie =
          "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("apiurl", apiurl + "user/logout");

  function convertMillisecondsToMinutesAndSeconds(milliseconds) {
    // Calculate total seconds
    var totalSeconds = Math.floor(milliseconds / 1000);

    // Calculate minutes and remaining seconds
    var minutes = Math.floor(totalSeconds / 60);
    var seconds = totalSeconds % 60;

    return {
      minutes: minutes,
      seconds: seconds,
    };
  }

  return (
    <>
      <NavBar />
      <br />

      <div
        className="mainpage"
        style={{
          textAlign: "right",
          marginBottom: "10px",
          marginLeft: "10px",
          marginRight: "-6px",
        }}
      >
        <div>
          <div style={{ fontSize: "3.3vh", fontWeight: 500 }}>
            {content["Courses"]}({courses.length})
          </div>
        </div>

        <div style={{ display: "flex" }}>
          {/* <select
  value={filterOption}
  onChange={(e) => setFilterOption(e.target.value)}
>
  {filterOptions.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ))}
</select>




<select
    value={selectedTypeFilter}
    onChange={(e) => setSelectedTypeFilter(e.target.value)}
  >
    <option value="all">All Types</option>
    {uniqueCourseTypes.map((type, index) => (
      <option key={index} value={type}>
        {type}
      </option>
    ))}
  </select> */}
          {/* <select
          value={filterOption}
          onChange={(e) => handleFilterChange(e.target.value)}
        >
          {filterOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        
        <select
          value={selectedTypeFilter}
          onChange={(e) => handleTypeFilterChange(e.target.value)} 
        >
          <option value="all">All Types</option>
          {uniqueCourseTypes.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select> */}
          <select
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value)}
            style={{ height: "29px" }}
          >
            {filterOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          &nbsp;&nbsp;
          <select
            value={selectedTypeFilter}
            onChange={(e) => handleTypeFilterChange(e.target.value)}
            style={{ height: "29px" }}
          >
            <option value="all">All Types</option>
            {uniqueCourseTypes.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
          <ReactPaginate
            // previousLabel={"previous"}
            // nextLabel={"next"}
            previousLabel={content["previous"]}
            nextLabel={content["next"]}
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            forcePage={currentPage}
          />
          <div className="search-container">
            <form>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder={content["Enter Title/Keyword"]}
                style={{ padding: "6px", marginRight: "10px" }}
                name="search"
              />
              <button type="submit">
                <i class="fa fa-search"></i>
              </button>
            </form>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="homeloader"></div> // Replace this with your actual loader component
      ) : (
        <div
          style={{ overflow: "auto", height: "73vh" }}
          ref={scrollContainerRef}
        >
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <br />
            <thead>
              <tr>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["No"]}
                  {/* No */}
                </th>

                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                ></th>

                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Title"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Type"]}
                </th>

                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["State"]}
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.map((course, index) => (
                <tr key={index.id}>
                  <td className="tdvaluecoursenoid">
                    <h2>{index + currentPage * perPage + 1}</h2>
                  </td>

                  <td className="tdimage1">
                    <div className="imagecontainer">
                      {course.channel_ids && course.channel_ids.includes(9) && (
                        <div
                          style={{
                            position: "absolute",
                            top: "5px",
                            marginLeft: "245px",
                            color: "black",
                            padding: "7px 10px 4px 10px",
                            borderRadius: "4px",
                            backgroundImage:
                              "linear-gradient(to right top, #ffffff, #f2e8f2, #efceda, #eab7b6, #daa48b)",
                          }}
                        >
                          VIP
                        </div>
                      )}

                      <img
                        src={getTitle(course.snapshot_url)}
                        alt="IMG"
                        className="imagesnap"
                      />
                      <div className="imagetext">
                        {`${
                          convertMillisecondsToMinutesAndSeconds(
                            course.duration
                          ).minutes
                        }:${
                          convertMillisecondsToMinutesAndSeconds(
                            course.duration
                          ).seconds
                        }`}
                      </div>
                    </div>
                  </td>

                  <td className="tdvaluecourses">
                    <h2>{getTitle(course.title)}</h2>
                  </td>

                  <td className="coursetype">
                    <span>{course.class_type.name}</span>
                  </td>

                  <td className="tdvaluethree">
                    <Switch
                      checkedChildren={content["On the shelves"]}
                      unCheckedChildren={content["Off the shelves"]}
                      checked={!courseStatus[course.id]}
                      onChange={() => handleToggle(course)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
const tableHeaderStyle = {
  backgroundColor: "rgb(234 235 244)",
  fontWeight: "bold",
  padding: "8px",
};

export default Course;
