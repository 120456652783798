import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { encryptedaes } from "../../../utils/encrypt/encryptUtils";
import {removeAllLocalStorageItems} from "../../../utils/LogAuth"
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import "./navBar.css";
import logout from "../../../img/logout_copy.png";
import logo from "../../../img/wf-c.png";
import { apiBaseUrls, apiKeys } from "../../../config";
const API_ENDPOINT = apiBaseUrls.development;


export default function NavBar() {
  const { language, setLanguage, content } = useOutletContext();
  const loginUser = localStorage.getItem("accessToken");
  const dataArray = loginUser ? loginUser.split("#") : []; // Add a check for loginUser
  const Currentemail = dataArray[0] || null; // Set Currentemail to null if dataArray is empty

  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };

  
  const navigate = useNavigate();
  const handleLogout = () => {
    const accessToken = localStorage.getItem("accessToken");
    const userId = accessToken.split("#")[0];
    console.log("Test User_id", userId);
    const requestBody = { accessToken, userId }
    //encryption data
    // const encryptbody = {data:encryption(requestBody)}
    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;
     
    const encryptbody = {data:encryptedData}
    axios
      .post(API_ENDPOINT + "user/logout", encryptbody, config)
      .then(() => {
        // localStorage.removeItem("accessToken");
        // localStorage.removeItem("position");
          removeAllLocalStorageItems()
          document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="headerNav">
        <div className="NavBarDef">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <img
                style={{ width: "40px" }}
                src={logo}
                className="logoimage"
                alt=""
              />
              &nbsp;
            </div>
            <div style={{ fontSize: "large", fontWeight: 700 }}>
              {content["WhaleFit Content Management Platform"]}
            </div>
          </div>
        </div>

        <nav>
          <ul>
            <li>{Currentemail}</li>
            <li>
              <select
                value={language}
                onChange={(e) => {
                  setLanguage(e.target.value);
                }}
              >
                <option>English</option>
                <option>中国人</option>
              </select>
            </li>
            <li>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleLogout}
              >
                <div>
                  <img
                    style={{ width: "31px" }}
                    src={logout}
                    className="logoimage"
                    alt=""
                  />
                  &nbsp;
                </div>
                <div style={{ fontSize: "large", fontWeight: 700 }}>
                  {content["Logout"]}
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
