
import React, { useState } from "react";
import Swal from "sweetalert2";
import { useOutletContext } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faTrashCan,
  faUpDownLeftRight,
} from "@fortawesome/free-solid-svg-icons";
import "./wsp-gallery.css";
import { ImCross } from "react-icons/im";

const WSPGallery = ({
  galleryImages,
  selectedImages,
  onSelectImage,
  onPreviewImage,
  onDeleteImage,
  checkedValues,
  setValue,
  posterToDisplay
}) => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const { language, setLanguage, content } = useOutletContext();
  const handleDeleteImage = (imageId) => {
    onDeleteImage(imageId);
    console.log("ujjwal0000");
  };

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setOpenModal(true);
    setIsImageLoaded(false); // Reset isImageLoaded when opening the modal
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const prevSlide = () => {
    if (slideNumber > 0) {
      setSlideNumber(slideNumber - 1);
      setIsImageLoaded(false); // Reset isImageLoaded when changing the slide
    }
  };

  const nextSlide = () => {
    if (slideNumber + 1 < galleryImages.length) {
      setSlideNumber(slideNumber + 1);
      setIsImageLoaded(false); // Reset isImageLoaded when changing the slide
    }
  };

  const handleImageLoaded = () => {
    setIsImageLoaded(true); // Set isImageLoaded to true when the image has finished loading
  };

  const handleImageError = () => {
    setIsImageLoaded(false); // Set isImageLoaded to false if the image fails to load
  };

  function handleChange(event) {
    const { value, checked } = event.target;

    if (checked) {
      setValue((pre) => [...pre, value]);
    } else {
      setValue((pre) => {
        return [...pre.filter((imageid) => imageid !== value)];
      });
    }
  }

  return (
    <>
      {openModal && (
        <div className="sliderWrap">
          <ImCross className="btnClose" width={100} onClick={handleCloseModal} />
          {slideNumber > 0 && (
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="btnPrev"
              onClick={prevSlide}
              // disabled={!isImageLoaded}
            />
          )}
          {slideNumber < galleryImages.length - 1 && (
            <FontAwesomeIcon
              icon={faChevronRight}
              className="btnNext"
              onClick={nextSlide}
              // disabled={!isImageLoaded}
            />
          )}
          <div className="fullScreenImage">
            {!isImageLoaded && ( // Show the loader if the image is not loaded
              <div className="loader"></div>
            )}
            <img
              src={galleryImages[slideNumber].posterUrl}
              alt=""
              onLoad={handleImageLoaded}
              onError={handleImageError}
              style={{ display: isImageLoaded ? "block" : "none" }} // Show the image when it has finished loading
            />
          </div>
        </div>
      )}

      <div className="card-container">
        {galleryImages.map((image, index) => (
          <div className="container" key={index}>
            <div
              className={`btn button ${
                selectedImages.includes(index) ? "visible" : ""
              }`}
            >
              <div>
                <div>
                  {/* <input
                    type="checkbox"
                    id={`myCheckbox-${index}`}
                    name={`myCheckbox-${index}`}
                    value={image.id}
                    className="styled-checkbox"
                    checked={selectedImages.includes(index)}
                    onChange={(event) => {
                      onSelectImage(index);
                      handleChange(event);
                    }}
                  /> */}
                  <input
                  type="checkbox"
                  id={`myCheckbox-${index}`}
                  name={`myCheckbox-${index}`}
                  value={image.id}
                  className="styled-checkbox"
                  checked={selectedImages.includes(index)}
                  onChange={(event) => {
                    const imageToDelete = galleryImages.find((img) => img.id === image.id);
                    const Activities = localStorage.getItem("Activities");
                    const ActivitiesData = JSON.parse(Activities);
                    const posterToActivity = ActivitiesData
                    const isUsedInBanners = imageToDelete && posterToDisplay.includes(imageToDelete.posterUrl);
                    const isUsedInActivities = imageToDelete && posterToActivity.some(activity => activity.image === imageToDelete.posterUrl);
                  
                    console.log("8r758478",imageToDelete)
                    if (
                      // imageToDelete &&
                      // posterToDisplay.includes(imageToDelete.posterUrl
                      isUsedInBanners || isUsedInActivities
                        
                    ) {
                      // Prevent deletion and show an alert
                      Swal.fire({
                        text: content["Poster is being used in Banners or Activities."],
                        icon: "error",
                        confirmButtonText:content["OK"]
                      });
                    } else {
                      // Allow selecting/de-selecting the image
                      onSelectImage(index);
                      handleChange(event);
                    }
                  }}
                />

                </div>
                <div>
                  {!selectedImages.includes(index) && (
                    <>
                      <span
                        style={{
                          backgroundColor: "rgb(255, 255, 255)",
                          padding: "4px",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faUpDownLeftRight}
                          onClick={() => handleOpenModal(index)}
                          style={{ color: "#000000" }}
                        />
                      </span>
                      &nbsp;&nbsp;
                      <span
                        style={{
                          backgroundColor: "rgb(255, 255, 255)",
                          padding: "4px",
                          borderRadius: "4px",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          onClick={() => handleDeleteImage(image.id)}
                          style={{ color: "#000000" }}
                        />
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <img
              src={image.thumbnailUrl}
              className="cover-image"
              alt={`Image ${index + 1}`}
              onClick={() => onPreviewImage(image.posterUrl)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default WSPGallery;
