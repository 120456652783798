import useLanguage from "../../../translation/TranslationEngToChinese";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import NavBar from "../other/navBar";
import logo from "../../../img/wf-c.png";
import logo_old from "../../../img/wf-c.png";
import logout from "../../../img/logout_copy.png";
import "./home.css";
import MyCarousel from "./MyCarousel/MyCarousel";
import React, { useEffect, useState, useRef } from "react";
import DragImage from "./MyCarousel/DragImage";
import LoadingSpinner from "../poster/LoadingSpinner";
import { apiBaseUrls, apiKeys } from "../../../config";
import { getAllposterToDisplay } from "../../../api/FileManagementApi";
import axios from "axios";
import { encryptedaes } from "../../../utils/encrypt/encryptUtils";
import {
  removeAllLocalStorageItems,
  AccessTokenValid,
  CheckedAccessTokenValid,
} from "../../../utils/LogAuth";
import blank from "../../../img/blank.png";

//new update
const defaultSlide = {
  id: "010",
  posterUrl: blank,
  targetType: "video",
  video: {},
};

const API_ENDPOINT = apiBaseUrls.development;

export default function Home() {
  const { language, setLanguage, content } = useOutletContext();
  const [slides, setSlides] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [galleryVideos, setGalleryVideos] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [carouselKey, setCarouselKey] = useState(0);

  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };

  const dialogControl = useRef();

  const openModal = () => {
    if (dialogControl.current) {
      dialogControl.current.showModal();
      // setIsDialogOpen(true);
      setCarouselKey((prevKey) => prevKey + 1);
    }
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleDialogClose = () => {
    dialogControl.current.close();
    // setIsDialogOpen(false);
  };

  const loginUser = localStorage.getItem("accessToken");
  const dataArray = loginUser ? loginUser.split("#") : [];
  const Currentemail = dataArray[0] || null;

  const fetchDataFromAPI = async () => {
    try {
      const storedPosterData = JSON.parse(
        localStorage.getItem("getPosterToDisplay")
      );
      const storedCoursesData = JSON.parse(localStorage.getItem("getCourses"));
      const storedActivitiesData = JSON.parse(
        localStorage.getItem("Activities")
      );

      if (!storedPosterData) {
        if (AccessTokenValid()) {
          const posterResponse = await axios.get(
            API_ENDPOINT + "poster/posterToDisplay",
            config
          );
          console.log("callingmultiple posterToDisplay");
          const posterData = posterResponse.data;
          setSlides(posterData.images);
          localStorage.setItem(
            "getPosterToDisplay",
            JSON.stringify(posterData)
          );
        } else {
          CheckedAccessTokenValid();
        }
      } else {
        setSlides(storedPosterData.images);
      }

      if (!storedCoursesData) {
        if (AccessTokenValid()) {
          const coursesResponse = await axios.get(
            API_ENDPOINT + "course/getCourses",
            config
          );
          const coursesData = coursesResponse.data;
          setGalleryVideos(coursesData.videos.data);
          localStorage.setItem("getCourses", JSON.stringify(coursesData));
        } else {
          CheckedAccessTokenValid();
        }
      } else {
        setGalleryVideos(storedCoursesData.videos.data);
      }

      if (!storedActivitiesData) {
        if (AccessTokenValid()) {
          const activitiesResponse = await axios.get(
            API_ENDPOINT + "activity/getActivities",
            config
          );
          const activitiesData = activitiesResponse.data.activities;
          localStorage.setItem("Activities", JSON.stringify(activitiesData));
        } else {
          CheckedAccessTokenValid();
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchDataFromAPI();
  }, []);

  const navigate = useNavigate();

  const handleLogout = () => {
    const accessToken = localStorage.getItem("accessToken");
    const userId = accessToken.split("#")[0];
    console.log("Test User_id", userId);
    const requestBody = { accessToken, userId };
    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;

    const encryptbody = { data: encryptedData };
    axios
      .post(API_ENDPOINT + "user/logout", encryptbody, config)
      .then(() => {
        removeAllLocalStorageItems();
        document.cookie =
          "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (isLoading) {
    return (
      <div>
        {/* <LoadingSpinner /> */}
        <div className="homeloader"></div>
      </div>
    );
  }

  if (!isLoading) {
    return (
      <>
        <div class="container">
          <dialog
            ref={dialogControl}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              background: "#0c1842",
              zIndex: 9999,
              border: "none",
              border: "2px solid gray",
            }}
          >
            <div className="loginform">
              <MyCarousel
                key={carouselKey}
                slides={slides}
                setSlides={setSlides}
                setGalleryVideos={setGalleryVideos}
                galleryVideos={galleryVideos}
                setGalleryImages={setGalleryImages}
                galleryImages={galleryImages}
                isFullscreen={true}
                onClose={() => {
                  dialogControl.current.close();
                  // setCarouselKey((prevKey) => prevKey + 1);
                }}
              />
            </div>
          </dialog>

          <div className="headerNavHome" style={{ color: "black" }}>
            <div className="NavBarDef">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <img
                    style={{ width: "40px" }}
                    src={logo}
                    className="logoimage"
                    alt=""
                  />
                  &nbsp;
                </div>
                <div style={{ fontSize: "large", fontWeight: 700 }}>
                  {content["WhaleFit Content Management Platform"]}
                </div>
              </div>
            </div>

            <nav>
              <ul>
                <li>{Currentemail}</li>
                <li>
                  <select
                    value={language}
                    onChange={(e) => {
                      setLanguage(e.target.value);
                    }}
                  >
                    <option>English</option>
                    <option>中国人</option>
                  </select>
                </li>
                <li>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleLogout}
                  >
                    <div>
                      <img
                        style={{ width: "31px" }}
                        src={logout}
                        className="logoimage"
                        alt=""
                      />
                      &nbsp;
                    </div>
                    <div style={{ fontSize: "large", fontWeight: 700 }}>
                      {content["Logout"]}
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>

          <section
            class="content-area-wrapper clearfix"
            style={{ width: "176vh" }}
          >
            <main class="content-area" style={{ color: "black" }}>
              <div style={{ textAlign: "left" }}>
                <h2 style={{ color: "black" }}>
                  {content["Manage Recommendation"]}
                </h2>
              </div>
              <MyCarousel
                slides={slides}
                setSlides={setSlides}
                setGalleryVideos={setGalleryVideos}
                galleryVideos={galleryVideos}
                setGalleryImages={setGalleryImages}
                galleryImages={galleryImages}
                isFullscreen={false}
                openModal={openModal}
              />
            </main>
          </section>

          <section class="sponsors-wrapper clearfix"></section>

          <footer class="footer">
            <p></p>
          </footer>
        </div>
      </>
    );
  }
}
