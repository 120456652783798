import React, { useState, useRef, useEffect } from "react";
import NavBar from "../other/navBar";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import countriesJson from "../other/JsonData/countries.json";
import subscriptionJson from "../other/JsonData/subscription.json";
import currenciesJson from "../other/JsonData/currencies.json";
import { apiBaseUrls, apiKeys } from "../../../config";
import { encryptedaes } from "../../../utils/encrypt/encryptUtils";
import {
  CheckedAccessTokenValid,
  AccessTokenValid,
} from "../../../utils/LogAuth";
import Swal from "sweetalert2";
import "./subscription.css";
const API_ENDPOINT = apiBaseUrls.development;

export default function EditSubs() {
  const navigate = useNavigate();
  const { content } = useOutletContext();
  const [matchedsubscriptions, setMatchedsubscriptions] = useState(null);
  const [selectedMembership, setSelectedMembership] = useState("monthly");
  const [selectedCountries, setSelectedCountries] = useState("GB");
  const [selectecurrency, setSelectedcurrency] = useState("EUR");
  const [selectesymbol, setSelectedsymbol] = useState("");
  const [Defaultprice, setDefaultprice] = useState("");
  const [Currentprice, setCurrentprice] = useState("");
  const [Delete, setDelete] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [isModified, setIsModified] = useState(false);

  // const [Latestprice, setLatestprice] = useState("");

  const countries = countriesJson.countries;
  const subscriptions = subscriptionJson.subscriptions;
  const currencies = currenciesJson.currencies;

  console.log("Defaultprice", Defaultprice);
  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };

  
  const handleCountryChange = (event) => {
    setSelectedCountries(event.target.value);
  };

  const handleFormValidation = () => {
    // Check if Defaultprice and Currentprice are not empty
    const isDefaultPriceValid = Defaultprice.trim() !== "";
    const isCurrentPriceValid = Currentprice.trim() !== "";

    // Update the formValid state based on validation conditions
    setFormValid(isDefaultPriceValid && isCurrentPriceValid);
  };

  useEffect(() => {
    handleFormValidation(); // Call the form validation function whenever Defaultprice or Currentprice changes
  }, [Defaultprice, Currentprice]);

  const handleMembershipChange = (event) => {
    setSelectedMembership(event.target.value);
    setIsModified(true);
    setDefaultprice("");
    setCurrentprice("");
    console.log("event.target.value", event.target.value);
  };

  useEffect(() => {
    const selectedCountry = countries.find(
      (country) => country.code === selectedCountries
    );
    if (selectedCountry) {
      const currencyCode = selectedCountry.code;
      const selectedCurrency = currencies.find(
        (currency) => currency.code === currencyCode
      );
      console.log("selectedCurrency", selectedCurrency);
      if (currencyCode === "GB") {
        setSelectedsymbol("£");
        setSelectedcurrency("GBP");
      } else if (currencyCode == "DE") {
        setSelectedsymbol("€");
        setSelectedcurrency("EUR");
      }
    }
  }, [selectedCountries, countries, currencies]);

  useEffect(() => {
    // Check if any field has been modified to enable the Save button
    const isFormModified =
      selectedMembership !== matchedsubscriptions?.name ||
      selectedCountries !== matchedsubscriptions?.country ||
      Defaultprice !== matchedsubscriptions?.defaultPrice ||
      Currentprice !== matchedsubscriptions?.currentPrice;

    setIsModified(isFormModified);
  }, [
    selectedMembership,
    selectedCountries,
    Defaultprice,
    Currentprice,
    matchedsubscriptions,
  ]);

  useEffect(() => {
    const currentActivityId = localStorage.getItem("currentsubscriptionId");
    const responseDataString = localStorage.getItem("Getsubscriptions");

    if (responseDataString) {
      try {
        const responseData = JSON.parse(responseDataString);
        const idToMatch = currentActivityId;
        const matchedsubscriptions = responseData.find(
          (activity) => activity._id === idToMatch
        );
        setMatchedsubscriptions(matchedsubscriptions);

        console.log("matchedsubscriptions", matchedsubscriptions);

        if (matchedsubscriptions) {
          setSelectedMembership(matchedsubscriptions.name);
          setSelectedCountries(matchedsubscriptions.country);
          setSelectedcurrency(matchedsubscriptions.currency);
          setSelectedsymbol(matchedsubscriptions.symbol);
          setDefaultprice(matchedsubscriptions.defaultPrice);
          setCurrentprice(matchedsubscriptions.currentPrice);
          setDelete(matchedsubscriptions.delete);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.error("Data with key 'Activities' not found in local storage.");
    }
  }, []);

  const handlesetDefaultpriceChange = (event) => {
    const enteredValue = event.target.value;
    // const regex = /^(0|\d{1,8}(\.\d{0,2})?)$/; // Allow zero or numbers up to 8 digits with up to 2 decimal places
    const regex = /^(0(\.\d{0,2})?|[1-9]\d{0,7}(\.\d{0,2})?)$/;

    if (
      enteredValue === "" || // Allow an empty value
      regex.test(enteredValue) // Allow numbers within the specified regex pattern
    ) {
      setDefaultprice(enteredValue);
    }
  };

  const handlesetCurrentpriceChange = (event) => {
    const enteredValue = event.target.value;
    const regex = /^(0(\.\d{0,2})?|[1-9]\d{0,7}(\.\d{0,2})?)$/; // Allow zero or numbers up to 8 digits with up to 2 decimal places

    if (
      enteredValue === "" || // Allow an empty value
      regex.test(enteredValue) // Allow numbers within the specified regex pattern
    ) {
      setCurrentprice(enteredValue);
    }
  };

  const handlesetLatestpriceChange = (event) => {
    // const inputValue = event.target.value;
    const enteredValue = event.target.value;
    const regex = /^\d+(\.\d{0,2})?$/;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 10000000 && regex.test(enteredValue))
    ) {
      // setLatestprice(enteredValue);
    }
  };

  const handleSubmit = async () => {
    const currentsubscriptionId = localStorage.getItem("currentsubscriptionId");
    try {
      const currentSubscriptions = JSON.parse(
        localStorage.getItem("Getsubscriptions")
      );
      const isExistingSubscription = currentSubscriptions.some(
        (subscription) =>
          subscription._id !== matchedsubscriptions._id &&
          subscription.name === selectedMembership &&
          subscription.country === selectedCountries
      );

      if (isExistingSubscription) {
        // If a matching subscription exists, show a warning message using Swal
        Swal.fire({
          icon: "warning",
          title: content["Cannot create subscription"],
          text: content[
            "A subscription with the same name and country already exists."
          ],
          confirmButtonText: content["OK"],
        });
        return; // Prevent further execution of creating a new subscription
      }
      const deleteValue = selectedMembership === "monthly" ? false : true;
      const requestBody = {
        subscription: {
          _id: currentsubscriptionId,
          name: selectedMembership,
          country: selectedCountries,
          currency: selectecurrency,
          symbol: selectesymbol,
          defaultPrice: Defaultprice,
          currentPrice: Currentprice,
          delete: deleteValue,
        },
        status: true,
      };

      console.log("requestBodyrequestBody", requestBody);

      const { encryptedData, SessionToken } = encryptedaes(requestBody);

      config.headers["Session-Token"] = SessionToken;
      const encryptbody = { data: encryptedData };

      const response = await axios.post(
        API_ENDPOINT + "subscription/editSubscription",
        encryptbody,
        config
      );
  
      const result = response.data.result;
  
      if (result === 100) {
        // Subscription updated successfully, show SweetAlert2 success message and navigate
        Swal.fire({
          icon: "success",
          title: content["Success"],
          text: content["Updated subscription successfully"],
          confirmButtonText: content["OK"],
        }).then(() => {
          navigate("/dashboard/subscription"); // Redirect to /dashboard/subscription
        });
      } else if (result === 420) {
        // Show Swal alert for unauthorized error
        Swal.fire({
          icon: 'error',
          title: content["Unauthorized"],
          text: content["You are not authorized to perform this action!"],
          confirmButtonText: content["OK"],
        });
      } else {
        // Handle any error from the API, show SweetAlert2 error message
        Swal.fire({
          icon: "error",
          title: content["Error"],
          text: content["Failed to updated subscription"],
          confirmButtonText: content["OK"],
        });
        console.error(response.data.activities);
      }
    } catch (error) {
      // Handle any network or other errors
      console.error("Error creating activity", error);
    }
  };
  return (
    <>
      <NavBar />
      <div>
        <span>
          <FontAwesomeIcon icon={faChevronLeft} size="xl" />
        </span>
        &nbsp;
        <span
          style={{ fontSize: "20px", cursor: "pointer" }}
          onClick={() => navigate("/dashboard/subscription")}
        >
          {content["Back to previous"]}
        </span>
      </div>
      <br />
      <div className="SubscriptionContainer">
        <div className="subs1">
          <span style={{ color: "red" }}>*</span>&nbsp; {content["Title"]}
        </div>
        <div className="subs2">
          <select
            className="dropdownforCountry"
            value={selectedMembership}
            onChange={handleMembershipChange}
          >
            {subscriptions.map((subscription) => (
              <option key={subscription._id} value={subscription.id}>
                {subscription.name}
              </option>
            ))}
          </select>
        </div>

        <div className="subs3">
          <span style={{ color: "red" }}>*</span>&nbsp; {content["Country"]}
        </div>
        <div className="subs4">
          <select
            className="dropdownforCountry"
            value={selectedCountries}
            onChange={handleCountryChange}
          >
            {countries.map((country) => (
              <option key={country._id} value={country.code}>
                {country.country}
              </option>
            ))}
          </select>
        </div>

        <div className="subs5">
          <span style={{ color: "red" }}>*</span>&nbsp;
          {content["Default price"]}
        </div>
        <div className="subs6">
          <div className="cardsubs">{selectecurrency}</div>
        </div>
        <div className="subs7">
          <input
            name="name"
            type="text"
            placeholder={content["Default price"]}
            className="sub_input1"
            value={Defaultprice}
            onChange={handlesetDefaultpriceChange}
          />
        </div>
        <div className="subs8">
          <span style={{ color: "red" }}>*</span>&nbsp;
          {content["Current price"]}
        </div>
        <div className="subs9">
          <div className="cardsubs">{selectecurrency}</div>
        </div>
        <div className="subs10">
          <input
            name="name"
            type="text"
            placeholder={content["Current price"]}
            className="sub_input1"
            value={Currentprice}
            onChange={handlesetCurrentpriceChange}
          />
        </div>
      </div>

      <div className="footerBtn14">
        <button
          className="btnCoupon"
          onClick={handleSubmit}
          disabled={!isModified || !formValid} // Disable the button if no changes or form not valid
        >
          {content["Save"]}
        </button>
      </div>
    </>
  );
}
