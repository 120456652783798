import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Event.css";
import logout from "../../../img/logout_copy.png";
import logo from "../../../img/wf-c.png";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { Switch, Space } from "antd";
import { ImCross } from "react-icons/im";
import { apiBaseUrls, apiKeys } from "../../../config";
import AddActivity from "./AddActivity";
import NavBar from "../other/navBar";
import { encryptedaes  } from "../../../utils/encrypt/encryptUtils";
import { CheckedAccessTokenValid , AccessTokenValid } from "../../../utils/LogAuth";
import { getAllposterToDisplay , getAllActivities } from "../../../api/FileManagementApi"
const API_ENDPOINT = apiBaseUrls.development;


function getTitle(titleJson) {
  let title = titleJson; // Initialize with the default title
  try {
    const titleObject = JSON.parse(titleJson);
    title = titleObject["en-US"] || title; // Use the localized title if available
  } catch (error) {
    // Parsing error, use default title
  }
  return title;
}

function Event() {
  const { content } = useOutletContext();
  const [courses, setCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [courseStatus, setCourseStatus] = useState({}); // Initialize an empty object
  const [loading, setLoading] = useState(true); // Initialize as true



  // const loginUser = localStorage.getItem("accessToken");
  // const dataArray = loginUser ? loginUser.split("#") : []; // Add a check for loginUser
  // const Currentemail = dataArray[0] || null; // Set Currentemail to null if dataArray is empty

  const [posterToDisplay, setPosterToDisplay] = useState([]);

  const [activities, setActivities] = useState([]);

  const apiurl = API_ENDPOINT + "activity/getActivities";

  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };

  
  console.log("_____activitiesapi__", activities);

  // useEffect(() => {
  //   axios
  //     .get(API_ENDPOINT + "/activity/getActivities", config)
  //     .then((response) => {
  //       setActivities(response.data.activities);
  //       let resparsed = response.data.activities;
  //       localStorage.setItem("Activities", JSON.stringify(resparsed));

  //       const storedCourses = localStorage.getItem("getPosterToDisplay");
  //       const coursesData = JSON.parse(storedCourses);
  //       setPosterToDisplay(coursesData.images);

  //     })
  //     .catch((error) => {
  //       console.error("Error fetching activities data:", error);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, []);



  useEffect(() => {
    if(AccessTokenValid()){
      axios
      .get(API_ENDPOINT + "activity/getActivities", config)
      .then((response) => {
        setActivities(response.data.activities);
        let resparsed = response.data.activities;
        localStorage.setItem("Activities", JSON.stringify(resparsed));
      })
      .catch((error) => {
        console.error("Error fetching activities data:", error);
      })
      .finally(() => {
        axios
          .get(API_ENDPOINT + "poster/posterToDisplay", config)
          .then((response) => {
            setPosterToDisplay(response.data.images);
          })
          .catch((error) => {
            console.error("Error fetching posterToDisplay data:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }else{
      CheckedAccessTokenValid()
    }


  }, []);


  // const storedCourses = localStorage.getItem("Activities");
  // useEffect(() => {
  //   setLoading(true);
  //   if (storedCourses) {
  //     const coursesData = JSON.parse(storedCourses);
  //     console.log(coursesData)
  //     if (coursesData) {
  //       setActivities(coursesData);
  //     }
  //     setLoading(false);
  //   } else {
  //     // If it's not in local storage, make the API request to fetch it data.activities
  //     axios
  //       .get(API_ENDPOINT + "activity/getActivities" , config)
  //       .then((response) => {
  //         const data = response.data;
  //         const activitiesData = response.data.activities;
  //         if (activitiesData) {
  //           setActivities(data.videos.data);
  //           localStorage.setItem("Activities", JSON.stringify(activitiesData));
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching course data:", error);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, []);


  console.log("New LogsDIS--", posterToDisplay);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const removeActivity = (activityId) => {
    setActivities((prevActivities) =>
      prevActivities.filter((activity) => activity._id !== activityId)
      
    );
  };


  function getCurrencySymbol(currencyCode) {
    // Define a map of currency codes to symbols
    const currencySymbols = {
      EUR: "€",
      GBP: "£",
      // Add more currency codes and symbols as needed
    };

    // Return the symbol if it exists in the map, or return the currency code itself
    return currencySymbols[currencyCode] || currencyCode;
  }

  function formatDate(milliseconds) {
    const date = new Date(milliseconds);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  
  // const handleDelete = (activityId) => {
  //   Swal.fire({
  //     title: content["Are you sure?"],
  //     text: "",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: content["Delete"] ,
  //     cancelButtonText: content["Cancel"] ,
  //   }).then((result) => {
  //     if (result.isConfirmed) {

  //       const requestBody = { _id: activityId }
  //       const { encryptedData, SessionToken } = encryptedaes(requestBody);
  //       config.headers["Session-Token"] = SessionToken;
  //       const encryptbody = {data:encryptedData}

  //       axios
  //         .post(
  //           `${API_ENDPOINT}/activity/deleteActivity`,
  //           // { _id: activityId },
  //           encryptbody,
  //           config
  //         )
  //         .then((response) => {
  //           if (response.data.result === 100) {
  //             Swal.fire(
  //               "Deleted!",
  //               "The activity has been deleted.",
  //               "success"
  //             )
              
  //             .then(() => {
  //               removeActivity(activityId); // Remove the activity from the list
  //             });
  //           } else {
  //             Swal.fire("Error!", "Failed to delete the activity.", "error");
  //           }
  //         })
  //         .catch((error) => {
  //           console.error("Error deleting activity", error);
  //           Swal.fire(
  //             "Error!",
  //             "An error occurred while deleting the activity.",
  //             "error"
  //           );
  //         });
  //     }
  //   });
  // };

  const handleDelete = (activityId) => {
    Swal.fire({
      title: content["Are you sure?"],
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: content["Delete"],
      cancelButtonText: content["Cancel"],
    }).then((result) => {
      if (result.isConfirmed) {
        if(AccessTokenValid()){
          const requestBody = { _id: activityId };
          const { encryptedData, SessionToken } = encryptedaes(requestBody);
          config.headers["Session-Token"] = SessionToken;
          const encryptbody = { data: encryptedData };
    
          axios
            .post(`${API_ENDPOINT}activity/deleteActivity`, encryptbody, config)
            .then((response) => {
              if (response.data.result === 100) {
                // Optionally provide feedback here if needed
                removeActivity(activityId); // Remove the activity from the list
              } 
              else if (response.data.result === 420) {
                // Unauthorized, show SweetAlert2 unauthorized error message
                Swal.fire({
                  icon: "error",
                  title: content["Unauthorized"],
                  text: content["You are not authorized to perform this action!"],
                  confirmButtonText: content["OK"],
                });
              }
              else {
                Swal.fire("Error!", "Failed to delete the activity.", "error");
              }
            })
            .catch((error) => {
              console.error("Error deleting activity", error);
              Swal.fire(
                "Error!",
                "An error occurred while deleting the activity.",
                "error"
              );
            });
        }else{
          CheckedAccessTokenValid()
        }
      }
    });
  };
  

  // const filteredActivities = activities.filter((activity) =>
  //   getTitle(activity.name.en).toLowerCase().includes(searchQuery.toLowerCase())
  // );

  const filteredActivities = activities.filter((activity) => {
    const activityName = activity.name.find(nameObj => nameObj.lang === 'en')?.text || '';
  
    return activityName.toLowerCase().includes(searchQuery.toLowerCase());
  });
  
  


  // Use filteredActivities in your rendering logic

  const navigate = useNavigate();

  // const handleLogout = () => {
  //   const accessToken = localStorage.getItem("accessToken");
  //   const userId = accessToken.split("#")[0];
  //   console.log("Test User_id", userId);

  //   axios
  //     .post(API_ENDPOINT + "/user/logout", { accessToken, userId }, config)
  //     .then(() => {
  //       localStorage.removeItem("accessToken");
  //       navigate("/");
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  function handleEditClick(activityId) {
    navigate("/dashboard/event/editactivity");
    localStorage.setItem("currentActivityId", activityId);
  }

  return (
    <>
      {/* <span
        style={{ position: "fixed", top: "4%", right: "297px", color: "gray" }}
      >
        {Currentemail}
      </span>
      <div
        className="mainpage"
        style={{
          textAlign: "right",
          marginBottom: "10px",
          marginLeft: "-3px",
          marginRight: "6px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <img
              style={{ width: "40px" }}
              src={logo}
              className="logoimage"
              alt=""
            />
            &nbsp;
          </div>
          <div style={{ fontSize: "large", fontWeight: 700 }}>
            {content["WhaleFit Content Management Platform"]}
          </div>
        </div>

        <div className="lang">
          <select
            value={language}
            onChange={(e) => {
              setLanguage(e.target.value);
            }}
          >
            <option>English</option>
            <option>中国人</option>
          </select>
        </div>

        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={handleLogout}
        >
          <div>
            <img
              style={{ width: "31px" }}
              src={logout}
              className="logoimage"
              alt=""
            />
            &nbsp;
          </div>
          <div style={{ fontSize: "large", fontWeight: 700 }}>
            {content["Logout"]}
          </div>
        </div>
      </div>

      <br /> */}
      <NavBar />
      <div
        className="mainpage"
        style={{
          textAlign: "right",
          marginBottom: "10px",
          marginLeft: "10px",
          marginRight: "-6px",
        }}
      >
        <div>
          <div style={{ fontSize: "3.3vh", fontWeight: 500 }}>
            {content["Activities"]}({activities.length})
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <button
            onClick={() => navigate("/dashboard/event/addactivity")}
            className="btn_add"
          >
            {content["Add new activity"]}
          </button>
          &nbsp;&nbsp;
          <div className="search-container">
            <form>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder={content["Enter Title/Keyword"]}
                style={{ padding: "8px", marginRight: "10px" }}
                name="search"
              />
              <button type="submit">
                <i class="fa fa-search"></i>
              </button>
            </form>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="homeloader"></div>
      ) : (
        <div style={{ overflow: "auto", height: "82vh" }}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <br />
            <thead>
              <tr>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Cover"]}
                </th>

                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Activity"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["State"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Coupon"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Operate"]}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredActivities.map((activity) => (
                <tr key={activity._id}>
                  <td className="tdimage">
                    <img alt="IMG" className="imagesnap" src={activity.image} />
                  </td>
                  <td className="tdvaluecoursetitle">
                    <h2>{activity.name?.[0]?.text}</h2>
                  </td>
                  <td className="tdvaluestate">
                    {posterToDisplay &&
                    posterToDisplay.some(
                      (poster) =>
                        poster.activity && poster.activity._id === activity._id
                    )
                      ? content["published"]
                      : content["Not published"]}
                  </td>

                  <td className="tdvaluecourse">
                    {activity.coupon && activity.coupon.expiryDate && (
                      <div className="mainBoxCoupan">
                        <h3 style={{ color: "gray" }}>
                          {activity.coupon.type}
                        </h3>
                        {activity.coupon.type === "cash" && (
                          <div className="mainBoxCoupanSelect">
                            <div>
                              <ul>
                                <li
                                  style={{ backgroundColor: "rgb(214 183 71)" }}
                                >
                                  <h1
                                    style={{ fontSize: "37px", color: "#fff" }}
                                  >
                                    {/* ¥
                                    {activity.coupon.promotionMethod &&
                                    activity.coupon.promotionMethod.fixedAmount
                                      ? activity.coupon.promotionMethod
                                          .fixedAmount
                                      : "N/A"} */}

                                      {activity.coupon.promotionMethod &&
                                    activity.coupon.promotionMethod.fixedDiscount !==
                                      undefined
                                      ? `%${activity.coupon.promotionMethod.fixedDiscount}`
                                      : activity.coupon.promotionMethod &&
                                        activity.coupon.promotionMethod.fixedAmount !==
                                          undefined
                                      ? `${getCurrencySymbol(activity.coupon.currency)}${
                                          activity.coupon.promotionMethod.fixedAmount
                                        }`
                                      : activity.coupon.promotionMethod &&
                                        activity.coupon.promotionMethod
                                          .randomDiscount !== undefined
                                      ? `${getCurrencySymbol(activity.coupon.currency)}${
                                          activity.coupon.promotionMethod.randomDiscount
                                            .low
                                        }-${getCurrencySymbol(
                                          activity.coupon.currency
                                        )}${
                                          activity.coupon.promotionMethod.randomDiscount
                                            .high
                                        }`
                                      : "N/A"}
                                  </h1>
                                </li>
                                <li>
                                  <div>
                                    <div>
                                      {/* <h3 style={{ fontSize: "18px" }}>
                                        {activity.coupon.scopeOfUse
                                          .anualMembership === "true"
                                          ? content[
                                              "Annual membership recharge"
                                            ]
                                          : activity.coupon.scopeOfUse
                                              .allAvailable === "true"
                                          ? content["All Available"]
                                          : activity.coupon.scopeOfUse
                                              .semiAnnualMembership === "true"
                                          ? content[
                                              "Semi-Annual membership recharge"
                                            ]
                                          : activity.coupon.scopeOfUse
                                              .notAnnualMembership === "true"
                                          ? content["Not Annual membership"]
                                          : activity.coupon.scopeOfUse
                                              .notSemiAnnualMembership ===
                                            "true"
                                          ? content[
                                              "Not Semi-Annual membership"
                                            ]
                                          : activity.coupon.scopeOfUse
                                              .notsingleMonthMembership ===
                                            "true"
                                          ? content[
                                              "Not Single-Month membership"
                                            ]
                                            : activity.coupon.scopeOfUse.singleMonthMembership === "true"
                                            ? content["Single-Month membership available"]
                                          : content["N/A"]}
                                      </h3> */}

                                      <h3 style={{ fontSize: "18px" }}>
                                        {activity.coupon.scopeOfUse
                                          .anualMembership === "true"
                                          ? content[
                                              "Annual membership recharge"
                                            ]
                                          : activity.coupon.scopeOfUse
                                              .allAvailable === "true"
                                          ? content["All Available"]
                                          : activity.coupon.scopeOfUse
                                              .semiAnnualMembership === "true"
                                          ? content[
                                              "Semi-Annual membership recharge"
                                            ]
                                          : activity.coupon.scopeOfUse
                                              .notAnnualMembership === "true"
                                          ? content["Not Annual membership"]
                                          : activity.coupon.scopeOfUse
                                              .notSemiAnnualMembership ===
                                            "true"
                                          ? content[
                                              "Not Semi-Annual membership"
                                            ]
                                          : activity.coupon.scopeOfUse
                                              .notsingleMonthMembership ===
                                            "true"
                                          ? content[
                                              "Not Single-Month membership"
                                            ]
                                            : activity.coupon.scopeOfUse.singleMonthMembership === "true"
                                            ? content["Single-Month membership available"]
                                          : content["N/A"]}
                                      </h3>

                                    </div>
                                    <div>
                                      <p
                                        style={{
                                          fontSize: "larger",
                                          fontWeight: "bold",
                                          color: "gray",
                                        }}
                                      >
                                        {/* {activity.coupon.expiryDate.startDate} -{" "}
                                        {activity.coupon.expiryDate.endDate} */}

                                      {activity.coupon.expiryDate.permanent
                                          ? content[
                                            "Permanent"
                                          ]
                                          : activity.coupon.expiryDate.startDate &&
                                          activity.coupon.expiryDate.endDate
                                          ? `${formatDate(
                                            activity.coupon.expiryDate.startDate
                                            )} - ${formatDate(
                                              activity.coupon.expiryDate.endDate
                                            )}`
                                          : activity.coupon.expiryDate.daysFromActivation
                                          ? `${content["Expires in"]} ${activity.coupon.expiryDate.daysFromActivation} ${content["Days"]}`
                                          : content["N/A"]}


                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                        {activity.coupon.type === "voucher" && (
                          <div className="mainBoxGiftSelect">
                            <div>
                              <ul>
                                <li
                                  style={{
                                    backgroundColor: "rgb(117 165 231)",
                                    display: "block",
                                  }}
                                >
                                  <div>
                                    <h2
                                      style={{
                                        color: "#fff",
                                        marginLeft: "33px",
                                      }}
                                    >
                                      {activity.coupon.type}
                                    </h2>
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        fontSize: "larger",
                                        fontWeight: "bold",
                                        color: "rgb(255, 255, 255)",
                                        marginLeft: "33px",
                                      }}
                                    >
                                      {/* {activity.coupon.expiryDate.startDate} -{" "}
                                      {activity.coupon.expiryDate.endDate} */}
                                                                            {activity.coupon.expiryDate.permanent
                                          ? content[
                                            "Permanent"
                                          ]
                                          : activity.coupon.expiryDate.startDate &&
                                          activity.coupon.expiryDate.endDate
                                          ? `${formatDate(
                                            activity.coupon.expiryDate.startDate
                                            )} - ${formatDate(
                                              activity.coupon.expiryDate.endDate
                                            )}`
                                          : activity.coupon.expiryDate.daysFromActivation
                                          ? `${content["Expires in"]} ${activity.coupon.expiryDate.daysFromActivation} ${content["Days"]}`
                                          : content["N/A"]}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                        {activity.coupon.type === "discount" && (
                          <div className="mainBoxCoupanSelect">
                            <div>
                              <ul>
                                <li
                                  style={{ backgroundColor: "rgb(255 87 120)" }}
                                >
                                  <h1
                                    style={{ fontSize: "37px", color: "#fff" }}
                                  >
                                    {/* {activity.coupon.promotionMethod &&
                                    activity.coupon.promotionMethod.fixedAmount
                                      ? activity.coupon.promotionMethod
                                          .fixedAmount
                                      : content["N/A"]}
                                    % */}
                                                                         {activity.coupon.promotionMethod &&
                                    activity.coupon.promotionMethod.fixedDiscount !==
                                      undefined
                                      ? `${activity.coupon.promotionMethod.fixedDiscount}%`
                                      : activity.coupon.promotionMethod &&
                                        activity.coupon.promotionMethod.fixedAmount !==
                                          undefined
                                      ? `${
                                          activity.coupon.promotionMethod.fixedAmount
                                        }%`
                                      : activity.coupon.promotionMethod &&
                                        activity.coupon.promotionMethod
                                          .randomDiscount !== undefined
                                      ? `${
                                          activity.coupon.promotionMethod.randomDiscount
                                            .low
                                        }%-${
                                          activity.coupon.promotionMethod.randomDiscount
                                            .high
                                        }%`
                                      : "N/A"}
                                  </h1>
                                </li>
                                <li>
                                  <div>
                                    <div>
                                      <h3 style={{ fontSize: "18px" }}>
                                      {activity.coupon.scopeOfUse
                                          .anualMembership === "true"
                                          ? content[
                                              "Annual membership recharge"
                                            ]
                                          : activity.coupon.scopeOfUse
                                              .allAvailable === "true"
                                          ? content["All Available"]
                                          : activity.coupon.scopeOfUse
                                              .semiAnnualMembership === "true"
                                          ? content[
                                              "Semi-Annual membership recharge"
                                            ]
                                          : activity.coupon.scopeOfUse
                                              .notAnnualMembership === "true"
                                          ? content["Not Annual membership"]
                                          : activity.coupon.scopeOfUse
                                              .notSemiAnnualMembership ===
                                            "true"
                                          ? content[
                                              "Not Semi-Annual membership"
                                            ]
                                          : activity.coupon.scopeOfUse
                                              .notsingleMonthMembership ===
                                            "true"
                                          ? content[
                                              "Not Single-Month membership"
                                            ]
                                            : activity.coupon.scopeOfUse.singleMonthMembership === "true"
                                            ? content["Single-Month membership available"]
                                          : content["N/A"]}
                                      </h3>
                                    </div>
                                    <div>
                                      <p
                                        style={{
                                          fontSize: "larger",
                                          fontWeight: "bold",
                                          color: "gray",
                                        }}
                                      >
                                        {/* {activity.coupon.expiryDate.startDate} -{" "}
                                        {activity.coupon.expiryDate.endDate} */}

                                  {activity.coupon.expiryDate.permanent
                                          ? content[
                                            "Permanent"
                                          ]
                                          : activity.coupon.expiryDate.startDate &&
                                          activity.coupon.expiryDate.endDate
                                          ? `${formatDate(
                                            activity.coupon.expiryDate.startDate
                                            )} - ${formatDate(
                                              activity.coupon.expiryDate.endDate
                                            )}`
                                          : activity.coupon.expiryDate.daysFromActivation
                                          ? `${content["Expires in"]} ${activity.coupon.expiryDate.daysFromActivation} ${content["Days"]}`
                                          : content["N/A"]}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </td>

                  <td className="tdvalueOperate">
                    {posterToDisplay && (
                      <>
                        <a
                          className={`EditAct ${
                            posterToDisplay.some(
                              (poster) =>
                                poster.activity &&
                                poster.activity._id === activity._id
                            )
                              ? "disabled-button"
                              : ""
                          }`}
                          onClick={() => handleEditClick(activity._id)}
                        >
                          {content["Edit"]}
                        </a>
                        <a
                          className={`DeleteAct ${
                            posterToDisplay.some(
                              (poster) =>
                                poster.activity &&
                                poster.activity._id === activity._id
                            )
                              ? "disabled-button"
                              : ""
                          }`}
                          onClick={() => handleDelete(activity._id)}
                        >
                          {content["Delete"]}
                        </a>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
const tableHeaderStyle = {
  backgroundColor: "rgb(234 235 244)",
  fontWeight: "bold",
  padding: "8px",
};

export default Event;
