import React, { useEffect, useState } from "react";
import NavBar from "../other/navBar";

import axios from "axios";
import Swal from "sweetalert2";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { apiBaseUrls, apiKeys } from "../../../config";
import { encryptedaes } from "../../../utils/encrypt/encryptUtils";
import { CheckedAccessTokenValid , AccessTokenValid } from "../../../utils/LogAuth";
const API_ENDPOINT = apiBaseUrls.development;

const tableHeaderStyle = {
  backgroundColor: "rgb(234 235 244)",
  fontWeight: "bold",
  padding: "8px",
};

export default function Subscription() {
  const { content } = useOutletContext();
  const [allCoupan, setAllCoupan] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [allsubscriptions, setAllsubscriptions] = useState([]);

  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };

  const navigate = useNavigate();

  useEffect(() => {
    if(AccessTokenValid()){
      axios
      .get(API_ENDPOINT + "subscription/getSubscriptions", config)
      .then((response) => {
        const subscriptions = response.data.subscriptions;
          console.log("cosssupons",subscriptions)
          setAllsubscriptions(subscriptions); // Update the galleryImages state
          localStorage.setItem("Getsubscriptions", JSON.stringify(subscriptions));
      })
      .catch((error) => {
        console.error("Failed to fetch Coupons:", error);
      })
      .finally(() => {
        setLoading(false);
      });
    }else{
      CheckedAccessTokenValid()
    }




      // .finally(() => {
      //   // After the first API call completes, make the second API call
      //   axios
      //     .get(API_ENDPOINT + "/activity/getActivities", config)
      //     .then((response) => {
      //       setActivities(response.data.activities);
      //     })
      //     .catch((error) => {
      //       console.error("Error fetching activities data:", error);
      //     })
      //     .finally(() => {
      //       setLoading(false);
      //     });
      // });
  }, []);


  // const handleEdit = (userId) => {
  //   Swal.fire({
  //     title: "",
  //     html: `
  //       <input type="password" id="password" class="swal2-input" style="width: 82%;" >
  //     `,
  //     showCancelButton: true,
  //     cancelButtonText: content["Cancel"],
  //     // cancelButtonColor: "rgb(242 242 242)",
  //     cancelButtonColor: "rgb(184 184 184)",
  //     confirmButtonText: content["Sure"],
  //     backdrop: false,
  //     preConfirm: () => {
  //       const password = Swal.getPopup().querySelector("#password").value;

  //       if (password.length < 6) {
  //         Swal.showValidationMessage(
  //           content["Password should be at least 6 characters"]
  //         );
  //         return false;
  //       }

  //       // Request body
  //       const requestBody = {
  //         userId,
  //         password,
  //       };

  //       //encryption data
  //       // const encryptbody = {data:encryption(requestBody)}
  //       const { encryptedData, SessionToken } = encryptedaes(requestBody);
  //       config.headers["Session-Token"] = SessionToken;

  //       const encryptbody = { data: encryptedData };

  //       // Call your API to update the password using the provided `userId` and `password`
  //       return axios
  //         .post(
  //           API_ENDPOINT + "/user/password/update",
  //           // {
  //           //   userId,
  //           //   password,
  //           // }
  //           encryptbody,
  //           config
  //         )
  //         .then((response) => {
  //           // Handle the API response
  //           Swal.fire(
  //             content["Success"],
  //             content["Password updated successfully"],
  //             "success"
  //           );
  //         })
  //         .catch((error) => {
  //           // Handle API error
  //           Swal.fire(
  //             content["Error"],
  //             content["Failed to update password"],
  //             "error"
  //           );
  //         });
  //     },
  //     didOpen: () => {
  //       const passwordInput = Swal.getPopup().querySelector("#password");
  //       const confirmButton = Swal.getConfirmButton();

  //       // Disable the button initially
  //       confirmButton.disabled = true;

  //       // Add an event listener to the password input
  //       passwordInput.addEventListener("input", () => {
  //         // Enable the button if the password length is at least 6 characters
  //         confirmButton.disabled = passwordInput.value.length < 6;
  //       });
  //     },
  //   });
  // };

  
  const filteredActivities = allsubscriptions.filter(
    (subscriptions) =>
    subscriptions.name.toLowerCase().includes(searchQuery.toLowerCase()) 
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  
  function handleEditSubscription(subscriptionId) {
    navigate("/dashboard/subscription/editsubscription"); 
    localStorage.setItem("currentsubscriptionId", subscriptionId);
  }

  const subscriptionNameMapper = {
    monthly: content["Monthly Membership"],
    annual: content["Annual Membership"],
    'half-yearly': content["Half-yearly Membership"]
  };


  const subscriptionCountryMapper = {
    GB: content["United Kingdom"],
    DE: content["Germany"],
  };


  const handleDelete = (subscriptionId) => {
    Swal.fire({
      // title: content["Are you sure want to delete this subscription"] + "?",
      text: content["Are you sure want to delete this subscription?"],
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#5d649c",
      cancelButtonColor: "#CCCCCC",
      cancelButtonText: content["Cancel"],
      confirmButtonText: content["Delete"],
    }).then((result) => {
      if (result.isConfirmed) {
        // Send a POST request to your API route to delete the coupon
        const requestBody = { _id: subscriptionId };
        const { encryptedData, SessionToken } = encryptedaes(requestBody);
        config.headers["Session-Token"] = SessionToken;

        const encryptbody = { data: encryptedData };

        if(AccessTokenValid()){
          axios
          .post(`${API_ENDPOINT}subscription/deleteSubscription`, encryptbody, config)
          .then((response) => {
            const result = response.data.result;
      
            if (result === 100) {
              // Subscription deleted successfully, update state and handle accordingly
              const updatedSubscriptions = allsubscriptions.filter(
                (subscription) => subscription._id !== subscriptionId
              );
              setAllsubscriptions(updatedSubscriptions);
      
              // Optionally show success message using Swal
              Swal.fire("Deleted!", "The subscription has been deleted.", "success");
            } else if (result === 420) {
              // Show Swal alert for unauthorized error
              Swal.fire({
                icon: 'error',
                title: content["Unauthorized"],
                text: content["You are not authorized to perform this action!"],
                confirmButtonText: content["OK"],
              });
              // Optionally, you can handle other logic for unauthorized case here
            } else {
              // Handle any other error from the API, show Swal error message
              console.error("Error deleting subscription:", response.data.error);
              Swal.fire("Error", "Failed to delete the subscription.", "error");
            }
          })
          .catch((error) => {
            console.error("Error deleting subscription:", error);
            Swal.fire("Error", "Failed to delete the subscription.", "error");
          });
        }else{
          CheckedAccessTokenValid()
        }



      }
    });
  };





  return (
    <>
      <NavBar />
      <div
        className="mainpage"
        style={{
          textAlign: "right",
          marginBottom: "10px",
          marginLeft: "10px",
          marginRight: "-6px",
        }}
      >
        <div>
          <div style={{ fontSize: "3.3vh", fontWeight: 500 }}>
            {content["Subscriptions"]}({allsubscriptions.length})
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <button
            onClick={() => navigate("/dashboard/subscription/addsubscription")
          }
            className="btn_add"
          >
            {content["Add Subscription"]}
          </button>
          &nbsp;&nbsp;
          <div className="search-container">
            <form>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder={content["Enter Title/Keyword"]}
                style={{ padding: "8px", marginRight: "10px" }}
                name="search"
              />
              <button type="submit">
                <i class="fa fa-search"></i>
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* //list */}
      {loading ? (
        <div className="homeloader"></div>
      ) : (
        <div style={{ overflow: "auto", height: "82vh" }}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <br />
            <thead>
              <tr>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Subscription"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Country"]}
                </th>

                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Default price"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Current price"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Operate"]}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredActivities.map((subscription) => (
                <tr key={subscription._id}>
                  {/* <td className="tdvaluecoursetitle_new">{subscription.name}</td> */}
                  <td className="tdvaluesubtitle">
                  {subscriptionNameMapper[subscription.name] || subscription.name}
                </td>
                  <td
                    className="tdimage"
                    style={{ textAlign: "center", color: "darkgray" }}
                  >
                  {subscriptionCountryMapper[subscription.country] || subscription.country}
                   {/* {subscription.country} */}
                    
                  </td>

                  <td className="tdvaluecourse" style={{ color: "darkgray" }}>
                  {subscription.defaultPrice}
                  </td>

                  <td className="tdvaluecourse" style={{ color: "darkgray" }} >
                    {/* {coupan.usageThreshold.minimumOrderValue} */}
                    {subscription.currentPrice}
                  </td>
                  <td className="tdvalueOperate" >
                        <a
                        className="EditAct"
                          onClick={() => handleEditSubscription(subscription._id)}
                        >
                          {content["Edit"]}
                        </a>
                        <a
                          className={`DeleteAct ${
                            subscription.delete === false ? "disabled-button" : ""
                          }`}
                          onClick={() => {
                            if (subscription.delete !== false) {
                              handleDelete(subscription._id);
                            }
                          }}
                        >
                          {content["Delete"]}
                        </a>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
