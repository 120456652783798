import React, { useEffect, useState } from "react";
import NavBar from "../other/navBar";
import axios from "axios";
import Swal from "sweetalert2";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import pointsJson from "../other/JsonData/point.json";
import countriesJson from "../other/JsonData/countries.json";
import { apiBaseUrls, apiKeys } from "../../../config";
import { encryptedaes } from "../../../utils/encrypt/encryptUtils";
import {
  CheckedAccessTokenValid,
  AccessTokenValid,
} from "../../../utils/LogAuth";
const API_ENDPOINT = apiBaseUrls.development;
const config = {
  headers: {
    "X-API-KEY": apiKeys.development,
    "User-Id": localStorage.getItem("UserId"),
    "Session-Token": "",
  },
};

const tableHeaderStyle = {
  backgroundColor: "rgb(234 235 244)",
  fontWeight: "bold",
  padding: "8px",
};

const point_constraint = pointsJson.Constraint;
const point_benefit = pointsJson.Benefit_types;
const countries = countriesJson.countries;

const getConstraintName = (ruleId) => {
  const constraint = point_constraint.find((constraint) => constraint[ruleId]);
  return constraint ? constraint[ruleId].name : "Unknown";
};

const getCountryName = (code) => {
  const country = countries.find((c) => c.code === code);
  return country ? country.country : "Unknown country";
};

function getBenefitTypeName(id) {
  const benefitType = point_benefit.find((type) => type.id === id);
  return benefitType ? benefitType.name : "Unknown";
}

function Point() {
  const { content } = useOutletContext();
  const [loading, setLoading] = useState(true);
  const [allpoints, setAllpoints] = useState([]);
  const [selectedOption, setSelectedOption] = useState("redemption");



  // const [filteredRedemptionpoints, setFilteredRedemptionpoints] = useState([]);
  const [redemptionsearchQuery, redemptionsetSearchQuery] = useState("");

console.log("redemptionsearchQuery",redemptionsearchQuery)
  // const [filteredAccumulationpoints, setFilteredAccumulationpoints] = useState([]);
  const [accumulationsearchQuery, accumulationsetSearchQuery] = useState("");

  
  const navigate = useNavigate();

console.log("selectedOption->",selectedOption)


  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };



  //Redemption
  const handleSearchInputRedemptionChange = (event) => {
    redemptionsetSearchQuery(event.target.value);
  };

    //accumulation
    const handleSearchInputAccumulationChange = (event) => {
      accumulationsetSearchQuery(event.target.value);
    };
  
  const handleChooseRedemption = () => {
    setSelectedOption("redemption");
    localStorage.setItem("PointOption", "redemption");
  };

  const handleChooseAccumulation = () => {
    setSelectedOption("accumulation");
    localStorage.setItem("PointOption", "accumulation");
  };

  const redemptionTextColor =
    selectedOption === "redemption" ? "black" : "blue";
  const accumulationTextColor =
    selectedOption === "accumulation" ? "black" : "blue";
  const redemptionCursorStyle =
    selectedOption === "redemption" ? "default" : "pointer";
  const accumulationCursorStyle =
    selectedOption === "accumulation" ? "default" : "pointer";

  useEffect(() => {

    const pointoption = localStorage.getItem("PointOption")
    setSelectedOption(pointoption);

    if (AccessTokenValid()) {
      axios
        .get(API_ENDPOINT + "redemption/getPoints", config)
        .then((response) => {
          const points = response.data.pointsRedemptions;
          console.log("cosssupons", points);
          setAllpoints(points); // Update the galleryImages state
          localStorage.setItem("Redemptionpoints", JSON.stringify(points));
        })
        .catch((error) => {
          console.error("Failed to fetch Points:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      CheckedAccessTokenValid();
    }
    
  }, []);

  useEffect(()=>{
    localStorage.setItem("Redemptionpoints", JSON.stringify(allpoints));
  },[allpoints])

  return (
    <>
      <NavBar />
      <div
        className="mainpage"
        style={{
          textAlign: "right",
          marginBottom: "10px",
          marginLeft: "10px",
          marginRight: "-6px",
        }}
      >
        <div>
          <div className="header-app-points">
            <span
              style={{
                color: redemptionTextColor,
                cursor: redemptionCursorStyle,
              }}
              onClick={handleChooseRedemption}
            >
              {content["Redemption"]}
            </span>
            &nbsp;|&nbsp;
            <span
              style={{
                color: accumulationTextColor,
                cursor: accumulationCursorStyle,
              }}
              onClick={handleChooseAccumulation}
            >
              {content["Accumulation"]}
            </span>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          {selectedOption === "redemption" ? (
            <button
              onClick={() => navigate("/dashboard/point/addredemption")}
              className="btn_add"
            >
               {content["Add Rule"]}
            </button>
          ) : (
            <button
              onClick={() => navigate("/dashboard/point/addaccumulation")}
              className="btn_add"
            >
              {content["Add Rule"]}
            </button>
          )}
          &nbsp;&nbsp;
          <div className="search-container">
            {/* <form>
              <input
                type="text"
                placeholder={content["Enter Title/Keyword"]}
                style={{ padding: "8px", marginRight: "10px" }}
                value={redemptionsearchQuery}
                onChange={handleSearchInputRedemptionChange}
                name="search"
              />
              <button type="submit">
                <i class="fa fa-search"></i>
              </button>
            </form> */}

          {selectedOption === "redemption" ? (
            <form>
              <input
                type="text"
                placeholder={content["Enter Title/Keyword"]}
                style={{ padding: "8px", marginRight: "10px" }}
                value={redemptionsearchQuery}
                onChange={handleSearchInputRedemptionChange}
                name="search"
                maxlength="10"
              />
              <button type="submit">
                <i class="fa fa-search"></i>
              </button>
            </form>
          ) : (
            <form>
              <input
                type="text"
                placeholder={content["Enter Title/Keyword"]}
                style={{ padding: "8px", marginRight: "10px" }}
                value={accumulationsearchQuery}
                onChange={handleSearchInputAccumulationChange}
                name="search"
                maxlength="10"
              />
              <button type="submit">
                <i class="fa fa-search"></i>
              </button>
            </form>
          )}
          </div>
        </div>
      </div>

      <div style={{ overflow: "auto", height: "82vh" }}>
        {selectedOption === "redemption" ? (
          <DisplayRedemptionPoints points={allpoints} content={content} setAllpoints={setAllpoints} redemptionsearchQuery={redemptionsearchQuery} navigate={navigate} />
        ) : (
          <DisplayAccumulationPoints accumulationsearchQuery={accumulationsearchQuery} content={content} />
        )}
      </div>
    </>
  );
}

// Display components
function DisplayRedemptionPoints({content,points,setAllpoints,redemptionsearchQuery,navigate}) {

  const [filteredRedemptionpoints, setFilteredRedemptionpoints] = useState([]);


  const handleEditredemption = (redemptionId) => {
    navigate(`/dashboard/point/updateredemption/${redemptionId}`);
  };


  useEffect(() => {
    const filtered = points.filter(point =>
      point.country.toLowerCase().includes(redemptionsearchQuery.toLowerCase()) ||
      point.Points.toLowerCase().includes(redemptionsearchQuery.toLowerCase()) ||
      point.Benefit_type.toLowerCase().includes(redemptionsearchQuery.toLowerCase()) ||
      point.Benefit_Value.toLowerCase().includes(redemptionsearchQuery.toLowerCase()) ||
      point.Constraint.some(constraint =>
        constraint.rule.toLowerCase().includes(redemptionsearchQuery.toLowerCase()) ||
        constraint.value.toLowerCase().includes(redemptionsearchQuery.toLowerCase())
      )
    );
    setFilteredRedemptionpoints(filtered);
  }, [redemptionsearchQuery, points]);


  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };

  const handleDelete = (redemptionId) => {
    Swal.fire({
      title: content["Are you sure you want to delete this redemption?"],
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: content["Yes"],
    }).then((result) => {
      if (result.isConfirmed) {
        // Send a POST request to your API route to delete the redemption
        const requestBody = { _id: redemptionId }
        const { encryptedData, SessionToken } = encryptedaes(requestBody);
        config.headers["Session-Token"] = SessionToken;
        const encryptbody = {data:encryptedData}
        if(AccessTokenValid()){
          axios
          .post(`${API_ENDPOINT}redemption/deletePoint`, encryptbody, config)
          .then((response) => {
            const result = response.data.result;
            if (result === 420) {
              Swal.fire({
                icon: 'error',
                title: content["Unauthorized"],
                text: content["You are not authorized to perform this action!"],
                confirmButtonText: content["OK"],
              });
              // Optionally, you can handle other logic for unauthorized case here
            } else {
              // Remove the deleted redemption from the state
              const updatedredemptions = points.filter(
                (redemption) => redemption._id !== redemptionId
              );
              
              setAllpoints(updatedredemptions);
              // Swal.fire("Deleted!", "The redemption has been deleted.", "success");
            }
          })
          .catch((error) => {
            console.error("Error deleting redemption:", error);
            Swal.fire("Error", "Failed to delete the redemption.", "error");
          });
        }else{
          CheckedAccessTokenValid()
        }

  
      }
    });
  };

  return (
    <table style={{ borderCollapse: "collapse", width: "100%" }}>
      <br />
      <thead>
        <tr>
          <th
            style={{
              ...tableHeaderStyle,
              position: "sticky",
              top: "0",
              color: "#a9afc3",
              textAlign: "center",
              zIndex: "1",
            }}
          >
            {content["Country"]}
           {/* {content["Points"]}  */}
          </th>
          <th
            style={{
              ...tableHeaderStyle,
              position: "sticky",
              top: "0",
              color: "#a9afc3",
              textAlign: "center",
              zIndex: "1",
            }}
          >
            {/* {content["Country"]} */}
            {content["Points"]} 
          </th>

          <th
            style={{
              ...tableHeaderStyle,
              position: "sticky",
              top: "0",
              color: "#a9afc3",
              textAlign: "center",
              zIndex: "1",
            }}
          >
            {content["Constraint"]}
            {/* {content["Benefit"]} */}
          </th>
          <th
            style={{
              ...tableHeaderStyle,
              position: "sticky",
              top: "0",
              color: "#a9afc3",
              textAlign: "center",
              zIndex: "1",
            }}
          >
            {/* {content["Constraint"]} */}
            {content["Benefit"]}
          </th>
          <th
            style={{
              ...tableHeaderStyle,
              position: "sticky",
              top: "0",
              color: "#a9afc3",
              textAlign: "center",
              zIndex: "1",
            }}
          >
            {content["Operate"]}
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredRedemptionpoints.map((redemptions) => (
          <tr>
           
            <td
              className="tdimage"
              style={{ textAlign: "center", color: "darkgray" }}
            >
              {getCountryName(redemptions.country)}
            </td>

            <td className="tdvaluesubtitle">{redemptions.Points}</td>



            {/* <td className="tdvaluecourse" style={{ color: "darkgray" }}>
            {redemptions.Benefit_Value}
          </td>
          <td className="tdvaluecourse" style={{ color: "darkgray" }}>
            {redemptions.Benefit_unit} 
          </td> */}
            <td className="tdvaluecourse" style={{ color: "darkgray" }}>
              {redemptions.Constraint.length === 1 &&
              redemptions.Constraint[0].value === "false" ? (
                <span style={{ textAlign: "-webkit-center" }}>
                  <div className="constraintcard">Empty</div>
                </span>
              ) : (
                redemptions.Constraint.map((constraint, index) => (
                  <span key={index} style={{ textAlign: "-webkit-center" }}>
                    {constraint.value === "true" ? (
                      <div className="constraintcard">
                        {getConstraintName(constraint.rule)}
                      </div>
                    ) : constraint.rule === "count_limit_per_month" ? (
                      <div className="constraintcard">{`${getConstraintName(
                        constraint.rule
                      )} - ${constraint.value}`}</div>
                    ) : null}
                  </span>
                ))
              )}
            </td>

            <td className="tdvaluecourse" style={{ color: "darkgray" }}>
              {getBenefitTypeName(redemptions.Benefit_type)}&nbsp;:&nbsp;
              {redemptions.Benefit_Value}&nbsp;{redemptions.Benefit_unit}
            </td>

            <td className="tdvalueOperate">
              <a className="EditAct" onClick={() => handleEditredemption(redemptions._id)}>{content["Edit"]}</a>
              <a className="DeleteAct" 
              onClick={() => handleDelete(redemptions._id)}
              >
                {content["Delete"]}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

// function DisplayAccumulationPoints({ content, points }) {
//   return (
//     <table style={{ borderCollapse: "collapse", width: "100%" }}>
//       <br />
//       <thead>
//         <tr>
//           <th
//             style={{
//               ...tableHeaderStyle,
//               position: "sticky",
//               top: "0",
//               color: "#a9afc3",
//               textAlign: "center",
//               zIndex: "1",
//             }}
//           >
//             {content["Accumilation Rules"]}
//           </th>
//           <th
//             style={{
//               ...tableHeaderStyle,
//               position: "sticky",
//               top: "0",
//               color: "#a9afc3",
//               textAlign: "center",
//               zIndex: "1",
//             }}
//           >
//             {content["Points"]}
//           </th>

//           <th
//             style={{
//               ...tableHeaderStyle,
//               position: "sticky",
//               top: "0",
//               color: "#a9afc3",
//               textAlign: "center",
//               zIndex: "1",
//             }}
//           >
//             {content["Operate"]}
//           </th>
//         </tr>
//       </thead>
//       <tbody>
//         {/* {points.map((redemptions) => ( */}
//         <tr>
//           <td className="tdvaluesubtitle">Login</td>
//           <td
//             className="tdimage-point"
//             style={{ textAlign: "center", color: "darkgray" }}
//           >
//             10
//           </td>

//           <td className="tdvalueOperate">
//             <a className="EditAct">{content["Edit"]}</a>
//             <a className="DeleteAct" onClick={() => {}}>
//               {content["Delete"]}
//             </a>
//           </td>
//         </tr>
//         {/*))} */}
//       </tbody>
//     </table>
//   );
// }


// function DisplayAccumulationPoints({ content }) {
//   const [points, setPoints] = useState([]);

//   console.log(points)

//   useEffect(() => {
//     if (AccessTokenValid()) {
//       axios
//         .get(API_ENDPOINT + "accumulation/getPoints", config)
//         .then((response) => {
//           const points = response.data.pointsAccumulations;
//           setPoints(points); 
//           localStorage.setItem("Accumulationpoints", JSON.stringify(points));
//         })
//         .catch((error) => {
//           console.error("Failed to fetch Points:", error);
//         })
//         .finally(() => {
//         });
//     } else {
//       CheckedAccessTokenValid();
//     }
//   }, []);

//   return (
//     <table style={{ borderCollapse: "collapse", width: "100%" }}>
//       <br />
//       <thead>
//         <tr>
//           <th
//             style={{
//               ...tableHeaderStyle,
//               position: "sticky",
//               top: "0",
//               color: "#a9afc3",
//               textAlign: "center",
//               zIndex: "1",
//             }}
//           >
//             {content["Accumulation Rules"]}
//           </th>
//           <th
//             style={{
//               ...tableHeaderStyle,
//               position: "sticky",
//               top: "0",
//               color: "#a9afc3",
//               textAlign: "center",
//               zIndex: "1",
//             }}
//           >
//             {content["Points"]}
//           </th>

//           <th
//             style={{
//               ...tableHeaderStyle,
//               position: "sticky",
//               top: "0",
//               color: "#a9afc3",
//               textAlign: "center",
//               zIndex: "1",
//             }}
//           >
//             {content["Operate"]}
//           </th>
//         </tr>
//       </thead>
//       <tbody>
//         {points.map((point) => (
//           <tr key={point._id}>
//             <td className="tdvaluesubtitle">{point.accumilation_rule}</td>
//             <td
//               className="tdimage-point"
//               style={{ textAlign: "center", color: "darkgray" }}
//             >
//               {point.Points}
//             </td>

//             <td className="tdvalueOperate">
//               <a className="DeleteAct" onClick={() => {}}>
//                 {content["Save"]}
//               </a>
//             </td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   );
// }

function DisplayAccumulationPoints({ content , accumulationsearchQuery }) {
  const [points, setPoints] = useState([]);
  const [filteredAccumulationpoints, setFilteredAccumulationpoints] = useState([]);

  useEffect(() => {
    // Filter points based on search query
    const filtered = points.filter(point =>
      point.Points.toLowerCase().includes(accumulationsearchQuery.toLowerCase()) ||
      point.accumilation_rule.toLowerCase().includes(accumulationsearchQuery.toLowerCase())
    );
    setFilteredAccumulationpoints(filtered);
  }, [accumulationsearchQuery, points]);
  

  useEffect(() => {
    if (AccessTokenValid()) {
      axios
        .get(API_ENDPOINT + "accumulation/getPoints", config)
        .then((response) => {
          const points = response.data.pointsAccumulations;
          console.log("response",response)
          setPoints(points); 
          localStorage.setItem("Accumulationpoints", JSON.stringify(points));
        })
        .catch((error) => {
          console.error("Failed to fetch Points:", error);
        })
        .finally(() => {
        });
    } else {
      CheckedAccessTokenValid();
    }
  }, []);


 const handlePointChange = (index, newValue) => {
    const updatedPoints = [...points];
    console.log("updatedPoints",newValue)
    updatedPoints[index].Points = newValue;
    const regex = /^[1-9]\d{0,3}$/;
    if (newValue === "" || regex.test(newValue)) {
    setPoints(updatedPoints);
    }
  };
  


  const handleDelete = (accumulationId) => {
    Swal.fire({
      title: content["Are you sure you want to delete this accumulation?"],
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: content["Yes"],
    }).then((result) => {
      if (result.isConfirmed) {
        // Send a POST request to your API route to delete the redemption
        const requestBody = { _id: accumulationId }
        const { encryptedData, SessionToken } = encryptedaes(requestBody);
        config.headers["Session-Token"] = SessionToken;
        const encryptbody = {data:encryptedData}
        if(AccessTokenValid()){
          axios
          .post(`${API_ENDPOINT}accumulation/deletePoint`, encryptbody, config)
          .then((response) => {
            const result = response.data.result;
            if (result === 420) {
              Swal.fire({
                icon: 'error',
                title: content["Unauthorized"],
                text: content["You are not authorized to perform this action!"],
                confirmButtonText: content["OK"],
              });
            } else {
              const updatedaccumulation = points.filter(
                (accumulation) => accumulation._id !== accumulationId
              );
              
              setPoints(updatedaccumulation);
              // Swal.fire("Deleted!", "The accumulation has been deleted.", "success");
            }
          })
          .catch((error) => {
            console.error("Error deleting accumulation:", error);
            Swal.fire("Error", "Failed to delete the accumulation.", "error");
          });
        }else{
          CheckedAccessTokenValid()
        }

  
      }
    });
  };



  const handleSave = (index) => {
    const updatedPoint = points[index];
    const currentId = updatedPoint._id
    const currentPoint = updatedPoint.Points
    
    const requestBody = {
      accumulation: {
        _id: currentId,
        Points:currentPoint
      }
    };

    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;
    const encryptbody = {data:encryptedData}

    if(AccessTokenValid()){
      axios
      .post(`${API_ENDPOINT}accumulation/editPoint`, encryptbody, config)
      .then((response) => {
        const result = response.data.result;
        
        
        if (result === 100) {
          // Subscription updated successfully, show SweetAlert2 success message and navigate
          Swal.fire({
            icon: "success",
            title: content["Success"],
            text: content["Updated subscription successfully"],
            confirmButtonText: content["OK"],
          }).then(() => {
            // navigate("/dashboard/subscription"); // Redirect to /dashboard/subscription
          });
        } else if (result === 420) {
          Swal.fire({
            icon: 'error',
            title: content["Unauthorized"],
            text: content["You are not authorized to perform this action!"],
            confirmButtonText: content["OK"],
          });
        } else {
          // const updatedaccumulation = points.filter(
          //   (accumulation) => accumulation._id !== currentId
          // );
          
          // console.log("updatedaccumulation",updatedaccumulation)
          // setPoints(updatedaccumulation);
          // Swal.fire("Deleted!", "The accumulation has been deleted.", "success");
        }
      })
      .catch((error) => {
        console.error("Error deleting accumulation:", error);
        Swal.fire("Error", "Failed to delete the accumulation.", "error");
      });
    }else{
      CheckedAccessTokenValid()
    }



    // const updatedPoint = points[index];
    // Assuming you have an API endpoint to update accumulation points
    // axios.post(`YOUR_API_ENDPOINT/${updatedPoint._id}`, updatedPoint)
    //   .then(response => {
    //     console.log('Point updated successfully:', response.data);
    //   })
    //   .catch(error => {
    //     console.error('Error updating point:', error);
    //   });
  };


  return (
    <table style={{ borderCollapse: "collapse", width: "100%" }}>
      <br />
      <thead>
        <tr>
          <th
            style={{
              ...tableHeaderStyle,
              position: "sticky",
              top: "0",
              color: "#a9afc3",
              textAlign: "center",
              zIndex: "1",
            }}
          >
            {content["Accumulation Rules"]}
          </th>
          <th
            style={{
              ...tableHeaderStyle,
              position: "sticky",
              top: "0",
              color: "#a9afc3",
              textAlign: "center",
              zIndex: "1",
            }}
          >
            {content["Points"]}
          </th>

          <th
            style={{
              ...tableHeaderStyle,
              position: "sticky",
              top: "0",
              color: "#a9afc3",
              textAlign: "center",
              zIndex: "1",
            }}
          >
            {content["Operate"]}
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredAccumulationpoints.map((point, index) => (
          <tr key={point._id}>
            <td className="tdvaluesubtitle">{point.accumilation_rule}</td>
            <td className="tdimage-point" style={{ textAlign: "center", color: "darkgray" }}>
              <input
                type="text"
                value={point.Points}
                onChange={(e) => handlePointChange(index, e.target.value)}
              />
            </td>

            <td className="tdvalueOperate">
              <a className="EditAct" 
              onClick={() => handleSave(index)}
              >
                {content["Save"]}
              </a>
              <a className="DeleteAct" 
              onClick={() => handleDelete(point._id)}
              >
                {content["Delete"]}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}




export default Point;
