import React, { useState, useEffect, useRef } from "react";
import NavBar from "../other/navBar";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./coupon.css";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImCross } from "react-icons/im";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import currenciesJson from "../other/JsonData/currencies.json";
import { encryptedaes } from "../../../utils/encrypt/encryptUtils";
import {
  CheckedAccessTokenValid,
  AccessTokenValid,
} from "../../../utils/LogAuth";

import { apiBaseUrls, apiKeys } from "../../../config";
const API_ENDPOINT = apiBaseUrls.development;



export default function Editcoupon() {
  const [matchedActivityData, setMatchedActivityData] = useState(null);
  console.log("Check the___", matchedActivityData);

  const [formData, setFormData] = useState({
    name: "",
    expiryDate: "",
    quantity: "",
    type: "",
    UsageThreshold: "",
    promotionMethod: "",
    limitPerPerson: "",
    gift: "",
    recievedCondition: "",
    scopeOfUse: "",
    currency: "",
  });

  console.log("Initial formData:", formData);

  let conn = formData.currency;
  let conn1 = "GBP";
  console.log("conn----", conn);
  console.log("conn1----", conn1);

  console.log("conn type----", typeof conn);
  console.log("conn1 type----", typeof conn1);

  const [formChanged, setFormChanged] = useState(false);

  const { content } = useOutletContext();
  const [selectedOption, setSelectedOption] = useState(); //here i want used {formData.type}
  const [selectedUsageThreshold, setSelectedUsageThreshold] = useState(""); //here i want used {formData.UsageThreshold}
  const [selectedPromotionMethod, setSelectedPromotionMethod] = useState(""); //here i want used {formData.promotionMethod}
  const [selectedvalidityPeriod, setSelectedvalidityPeriod] = useState(""); //here i want used {formData.expiryDate}
  // const [selectedRecevingcondition, setSelectedRecevingcondition] =
  //   useState(""); //here i want used {formData.recievedCondition}
  const [selectedRecevingcondition, setSelectedRecevingcondition] =
    useState("");
  const [selectedLimited, setSelectedLimited] = useState("");
  const [selectedScope, setSelectedScope] = useState("");
  const [minimumOrderValue, setMinimumOrderValue] = useState("");
  const [lowestDiscount, setLowestDiscount] = useState("");
  const [low, setLow] = useState("");
  const [high, setHigh] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [daysFromActivation, setDaysFromActivation] = useState("");

  const [redeemPoints, setRedeemPoints] = useState("");
  const [cash, setCash] = useState("");
  const [quantity, setQuantity] = useState("");
  const [name, setName] = useState("");
  const [redemptionsLimit, setRedemptionsLimit] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState();
  const currencies = currenciesJson.currencies;
  const [allGift, setAllGift] = useState([]);


  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };


  const GetGiftAPI = () => {
    if (AccessTokenValid()) {
      axios
        .get(API_ENDPOINT + "gift/getGifts", config)
        .then((response) => {
          const gifts = response.data.gifts;
          console.log("Response", gifts);
          console.log("_____1____getGifts", gifts);
          setAllGift(gifts); // Update the galleryImages state
          localStorage.setItem("GetGift", JSON.stringify(gifts));
        })
        .catch((error) => {
          console.error("Failed to fetch Coupons:", error);
        });
    } else {
      CheckedAccessTokenValid();
    }
  };

  const [selectedGiftIndex, setselectedGiftIndex] = useState(null);
  const [selectedGiftId, setselectedGiftId] = useState(null);
  const [selectedGiftData, setSelectedGiftData] = useState(null);

  const dialogControlGift = useRef();

  const handleInputChange = (e) => {
    setFormChanged(true);
    // Other logic for handling input change
  };

  // coupon?
  const matchingObjectsarraygift = allGift.find(
    (gift) => gift._id === selectedGiftId
  );

  const matchingObjectsgift = {
    ...matchingObjectsarraygift,
  };
  // coupon?

  console.log("MatchingObjectGift________", matchingObjectsgift);

  const openModalGift = () => {
    if (dialogControlGift.current) {
      dialogControlGift.current.showModal();
    }
  };

  const handleDialogGiftClose = () => {
    dialogControlGift.current.close();
  };

  function handleClickCoupon() {
    GetGiftAPI();
    openModalGift();
  }
  // gift
  const currentCouponId = localStorage.getItem("currentCouponId");
  const responseDataString = localStorage.getItem("GetCoupon");

  console.log("Updated List Data______1", formData);

  function getCurrencySymbol(currencyCode) {
    // Define a map of currency codes to symbols
    const currencySymbols = {
      EUR: "€",
      GBP: "£",
      // Add more currency codes and symbols as needed
    };

    // Return the symbol if it exists in the map, or return the currency code itself
    return currencySymbols[currencyCode] || currencyCode;
  }

  function formatDate(milliseconds) {
    const date = new Date(milliseconds);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    if (responseDataString) {
      try {
        const responseData = JSON.parse(responseDataString);
        console.log("Response Data:", responseData);

        // Define the id you want to match
        const idToMatch = currentCouponId; // Replace this with the id you want to match

        const matchedActivity = responseData.find(
          (coupon) => coupon._id === idToMatch
        );

        // Set the matched activity in state
        setMatchedActivityData(matchedActivity);

        setSelectedRecevingcondition(
          Object.keys(matchedActivity.receivedCondition)[0]
        );
        setRedeemPoints(matchedActivity.receivedCondition.redeemPoints || "");
        setCash(matchedActivity.receivedCondition.cash || "");

        setSelectedLimited(Object.keys(matchedActivity.limitPerPerson)[0]);
        setRedemptionsLimit(matchedActivity.limitPerPerson.limit || "");

        // // Handle expiryDate based on its structure
        // const expiryDate = matchedActivity.expiryDate;
        // // setSelectedvalidityPeriod()
        // if (expiryDate.startDate && expiryDate.endDate) {
        //   // Case: dateSelect
        //   setSelectedvalidityPeriod('dateSelect');
        //   setStartDate(expiryDate.startDate);
        //   setEndDate(expiryDate.endDate);
        // } else if (expiryDate.permanent === true) {
        //   // Case: permanent
        //   setSelectedvalidityPeriod('permanent');
        // } else if (expiryDate.daysFromActivation) {
        //   // Case: daysFromActivation
        //   setSelectedvalidityPeriod('daysFromActivation');
        //   setDaysFromActivation(expiryDate.daysFromActivation);
        // }

        const expiryDate = matchedActivity.expiryDate;

        if (expiryDate.startDate && expiryDate.endDate) {
          // Case: dateSelect
          setSelectedvalidityPeriod("dateSelect");

          // Convert milliseconds to Date and format as "YYYY-MM-DD"
          const startDate = new Date(expiryDate.startDate);
          const endDate = new Date(expiryDate.endDate);

          setStartDate(startDate);
          setEndDate(endDate);



        } else if (expiryDate.permanent === "true") {
          // Case: permanent
          setSelectedvalidityPeriod("permanent");
        } else if (expiryDate.daysFromActivation) {
          // Case: daysFromActivation
          setSelectedvalidityPeriod("daysFromActivation");
          setDaysFromActivation(expiryDate.daysFromActivation);
        }

        // Populate formData with matchedActivityData
        if (matchedActivity) {
          setFormData({
            name: matchedActivity.name || "",
            expiryDate: matchedActivity.expiryDate || "",
            quantity: matchedActivity.quantity || "",
            // UsageThreshold:matchedActivity.usageThreshold || "",
            // promotionMethod:matchedActivity.promotionMethod || "",
            limitPerPerson: matchedActivity.limitPerPerson || "",
            gift: matchedActivity.gift || "",
            recievedCondition: matchedActivity.receivedCondition || "",
            scopeOfUse: matchedActivity.scopeOfUse || "",
          });

          // const scopeOfUseValue = matchedActivity.scopeOfUse;
          // console.log("Updated 2.0 Scope-",scopeOfUseValue);
          const scopeOfUseValue = matchedActivity.scopeOfUse;
          const firstKey = Object.keys(scopeOfUseValue)[0];
          console.log("Updated 2.0 Scope -", firstKey);

          console.log("Inside Gift -", matchedActivity.gift);

          setSelectedGiftData(matchedActivity.gift || "");
          setSelectedCurrency(matchedActivity.currency || "");
          setSelectedOption(matchedActivity.type || "");
          setSelectedScope(Object.keys(matchedActivity.scopeOfUse)[0] || "");
          setSelectedUsageThreshold(
            Object.keys(matchedActivity.usageThreshold)[0] || ""
          );
          setMinimumOrderValue(
            matchedActivity.usageThreshold.minimumOrderValue || ""
          );
          setSelectedPromotionMethod(
            Object.keys(matchedActivity.promotionMethod)[0] || ""
          );
          // setLowestDiscount(matchedActivity.promotionMethod.fixedAmount || "");
          // Handle both fixedAmount and fixedDiscount here
          const promotionMethod = matchedActivity.promotionMethod;
          if (promotionMethod.fixedAmount) {
            setLowestDiscount(promotionMethod.fixedAmount);
          } else if (promotionMethod.fixedDiscount) {
            setLowestDiscount(promotionMethod.fixedDiscount);
          }

          setLow(matchedActivity.promotionMethod.randomDiscount.low || "");
          setHigh(matchedActivity.promotionMethod.randomDiscount.high || "");

          // setRedeemPoints(matchedActivity.receivedCondition.redeemPoints || "")
          // setCash(matchedActivity.receivedCondition.cash || "")

          // setSelectedvalidityPeriod(Object.keys(matchedActivity.expiryDate)[0] || ""); //some issue is there issue
        }

        console.log("Matched Activity Data:", matchedActivity);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.error("Data with key 'Activities' not found in local storage.");
    }
  }, []);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const dateInput = document.getElementById("startDateInput");
    if (dateInput) {
      dateInput.min = today;
    }
  }, []);

  // Calculate today's date
  const today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    if (selectedOption === "voucher") {
      clearVoucherStates();
    } else if (selectedOption === "discount" || selectedOption === "cash") {
      clearAllGiftStates();
    }
  }, [selectedOption]);

  const clearVoucherStates = () => {
    setSelectedUsageThreshold("");
    setSelectedPromotionMethod("");
    setMinimumOrderValue("");
    setLowestDiscount("");
    setLow("");
    setHigh("");
  };

  const clearAllGiftStates = () => {
    setAllGift([]);
  };

  // const clearVoucherStates = () => {
  //   console.log("clear RUn")
  //   setSelectedUsageThreshold("");
  //   setSelectedPromotionMethod("");
  //   setMinimumOrderValue("");
  //   setLowestDiscount("");
  //   setLow("");
  //   setHigh("");
  // };

  // const clearDiscountCashStates = () => {
  //   setAllGift([]); // Clearing allGift state
  // };

  // useEffect(() => {
  //   if (selectedOption === "voucher") {
  //     clearVoucherStates();
  //   } else if (selectedOption === "discount" || selectedOption === "cash") {
  //     clearDiscountCashStates();
  //   }
  // }, [selectedOption]);

  // Ensure the functions clearVoucherStates, clearDiscountCashStates, and clearAllGiftStates are defined correctly.

  // console.log("New Updated RecevingCondition---->", selectedRecevingcondition);
  // console.log("cashInt:-->", cash);
  // console.log("redeemPoints:-->", redeemPoints);
  // console.log("validityPeriod-->", selectedvalidityPeriod);
  // console.log("___Response", matchedActivityData);
  // console.log("lowestDiscount1(((((__)))))-->", lowestDiscount);

  console.log("Gift-----Edit>", allGift);

  function dateToMilliseconds(date) {
    return date.getTime();
  }

  const areAllFieldsFilled = () => {
    // List of required fields
    let requiredFields = [
      selectedOption,
      formData.name,
      formData.quantity,
      selectedScope,
      selectedvalidityPeriod !== "dateSelect" || !!startDate,
      selectedvalidityPeriod !== "dateSelect" || !!endDate,
      selectedvalidityPeriod !== "daysFromActivation" || !!daysFromActivation,
      selectedRecevingcondition !== "redeemPoints" || !!redeemPoints,
      selectedRecevingcondition !== "cash" || !!cash,
      selectedLimited !== "limit" || !!redemptionsLimit,
    ];

    // Check Promotion Method and Usage threshold only if selectedOption is not "voucher"

    if (selectedOption !== "voucher") {
      requiredFields = [
        ...requiredFields,
        selectedUsageThreshold === "noThreshold" || !!minimumOrderValue,
        (selectedPromotionMethod === "fixedAmount" && !!lowestDiscount) ||
          (selectedPromotionMethod === "fixedDiscount" && !!lowestDiscount) ||
          (selectedPromotionMethod === "randomDiscount" && !!low && !!high),
      ];
    }

    // Check if all required fields have a value
    const allFieldsFilled = requiredFields.every((field) => !!field);

    return allFieldsFilled;
  };

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
    handleInputChange();
  };

  // Find the selected currency object
  const selectedCurrencyObj = currencies.find(
    (currency) => currency.code === selectedCurrency
  );

  console.log("Type:--", selectedOption);
  console.log("Scope:--", selectedScope);
  // Function to handle radio button for selection type
  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };
  console.log("minimumOrderValue:->", minimumOrderValue);
  console.log("SelectedUsageThreshold", selectedUsageThreshold);

  console.log("lowestDiscount:-", lowestDiscount);

  // Function to handle radio button for selection Usage threshold
  const handleRadioChangeThreshold = (e) => {
    setSelectedUsageThreshold(e.target.value);
    handleInputChange();
  };

  const handleMinimumOrderValueChange = (value) => {
    // setMinimumOrderValue(value);
    const enteredValue = value;
    const regex = /^\d+(\.\d{0,2})?$/;

    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 1000000 && regex.test(enteredValue))
    ) {
      setMinimumOrderValue(value);
    }
    handleInputChange();
  };

  // Function to handle radio button for selection Usage Promotion Method
  const handleRadioChangePromotionMethod = (e) => {
    setSelectedPromotionMethod(e.target.value);
    handleInputChange();
  };

  // Define your input change handlers for "fixedAmount" and "randomDiscount" values
  const handleLowestDiscountChange = (event) => {
    // setLowestDiscount(event.target.value);
    const enteredValue = event.target.value;
    // if (!enteredValue || (enteredValue >= 0 && enteredValue <= 1000000)) {
    //   setLowestDiscount(event.target.value);
    // }
    const regex = /^\d+(\.\d{0,2})?$/;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 1000000 && regex.test(enteredValue))
    ) {
      setLowestDiscount(event.target.value);
    }
    handleInputChange();
  };

  // const handleLowChange = (event) => {
  //   setLow(event.target.value);
  // };

  // const handleHighChange = (event) => {
  //   setHigh(event.target.value);
  // };

  const handleLowChange = (e) => {
    // const newLow = parseInt(e.target.value, 10);
    // setLow(newLow);
    const newLow = e.target.value.toString();
    const enteredValue = newLow;
    const regex = /^\d+(\.\d{0,2})?$/;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 10000000 && regex.test(enteredValue))
    ) {
      setLow(newLow);
    }
    handleInputChange();
  };

  const handleHighChange = (e) => {
    // const newHigh = parseInt(e.target.value, 10);
    // setHigh(newHigh);
    const newHigh = e.target.value.toString();
    const regex = /^\d+(\.\d{0,2})?$/;
    // setHigh(newHigh);
    const enteredValue = newHigh;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 10000000 && regex.test(enteredValue))
    ) {
      setHigh(newHigh);
    }
    handleInputChange();
  };

  // Function to handle radio button for selection Usage validity Period
  const handleRadioChangevalidityPeriod = (e) => {
    setSelectedvalidityPeriod(e.target.value);
    handleInputChange();
  };

  // Define your input change handlers for start date and end date
  // const handleStartDateChange = (event) => {
  //   setStartDate(event.target.value);
  //   setEndDate("")
  //   handleInputChange()
  // };
  const handleStartDateChange = (date) => {
    setStartDate(date);
    setEndDate("");
  };

  // const handleStartDateChange = (event) => {
  //   const selectedDate = event.target.value;
  //   const today = new Date().toISOString().split("T")[0];

  //   // Check if the selectedDate is before today
  //   if (selectedDate < today) {
  //     // If the selected date is in the past, set the start date to today
  //     setStartDate(today);
  //     setEndDate("");
  //     handleInputChange()
  //     // You can also show a message or perform any other action to notify the user
  //     console.log("Please select a date starting from today.");
  //   } else {
  //     // If the selected date is valid, update the start date
  //     setStartDate(selectedDate);
  //     setEndDate("");
  //     handleInputChange()
  //   }
  // };
  // const handleEndDateChange = (event) => {
  //   setEndDate(event.target.value);
  //   handleInputChange()
  // };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  // const handleEndDateChange = (event) => {
  //   const selectedDate = event.target.value;
  //   // const today = startDate

  //   // Check if the selectedDate is before today
  //   if (selectedDate < startDate) {
  //     // If the selected date is in the past, set the start date to today
  //     setEndDate(startDate);
  //     handleInputChange()
  //     // You can also show a message or perform any other action to notify the user
  //     console.log("Please select a date starting from today.");
  //   } else {
  //     // If the selected date is valid, update the start date
  //     // setStartDate(selectedDate);
  //     setEndDate(selectedDate);
  //     handleInputChange()
  //     // setEndDate("");
  //   }
  // };

  const handleDaysFromActivationChange = (event) => {
    // setDaysFromActivation(event.target.value);
    const enteredValue = event.target.value;

    // if (enteredValue === '' || enteredValue === 0 || (enteredValue > 0 && enteredValue <= 1000000)) {
    //   setDaysFromActivation(event.target.value);
    // }
    const regex = /^\d+(\\d{0,2})?$/;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 1000000 && regex.test(enteredValue))
    ) {
      setDaysFromActivation(event.target.value);
    }
    handleInputChange();
  };

  // Function to handle radio button for selection Usage Receving condition
  const handleRadioChangeRecevingcondition = (e) => {
    setSelectedRecevingcondition(e.target.value);
    handleInputChange();
  };

  // Define your input change handler for redeem points and cash
  const handleRedeemPointsChange = (event) => {
    // setRedeemPoints(event.target.value);\
    const regex = /^\d+(\.\d{0,2})?$/;
    const enteredValue = event.target.value;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 10000000 && regex.test(enteredValue))
    ) {
      setRedeemPoints(enteredValue);
    }
    handleInputChange();
  };

  const handleCashChange = (event) => {
    // setCash(event.target.value);
    const regex = /^\d+(\.\d{0,2})?$/;
    const enteredValue = event.target.value;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 10000000 && regex.test(enteredValue))
    ) {
      setCash(enteredValue);
    }
    handleInputChange();
  };

  // Function to handle radio button for selection Usage Limited
  const handleRadioLimited = (e) => {
    setSelectedLimited(e.target.value);
    handleInputChange();
  };

  // Define your input change handler for redemptions limit
  const handleRedemptionsLimitChange = (event) => {
    // setRedemptionsLimit(event.target.value);
    const enteredValue = event.target.value;
    // const regex = /^\d+(\.\d{0,2})?$/;
    // if (enteredValue === '' || enteredValue === 0 || (enteredValue > 0 && enteredValue <= 1000000) && regex.test(enteredValue)) {
    //   setRedemptionsLimit(enteredValue);
    // }
    const regex = /^\d+(\\d{0,2})?$/;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 1000000 && regex.test(enteredValue))
    ) {
      setRedemptionsLimit(enteredValue);
    }
    handleInputChange();
  };

  // Define your input change handler for quantity
  const handleQuantityChange = (event) => {
    // setQuantity(event.target.value);
    const enteredValue = event.target.value;
    // if (!enteredValue || (enteredValue >= 0 && enteredValue <= 1000000)) {
    //   setQuantity(enteredValue);
    // }
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 1000000)
    ) {
      setQuantity(enteredValue);
    }
    handleInputChange();
  };

  // Define your input change handler for name
  // const handleNameChange = (event) => {
  //   setName(event.target.value);
  // };

  // const MAX_WORD_LIMIT = 20; // Change this to your desired maximum word limit
  // const MAX_CHARACTER_LIMIT = 200; // Change this to your desired maximum character limit

  const MAX_WORD_LIMIT = 10; // Change this to your desired maximum word limit
  const MAX_CHARACTER_LIMIT = 50; // Change this to your desired maximum character limit

  const handleNameChange = (event) => {
    const inputValue = event.target.value;

    // Count words and characters of the input value
    const words = inputValue.trim().split(/\s+/);
    const characters = inputValue.trim().length;

    if (words.length <= MAX_WORD_LIMIT && characters <= MAX_CHARACTER_LIMIT) {
      setName(inputValue);
    } else {
      // Display an error message for exceeding limits
      console.error("Maximum 200 words and 2000 characters allowed.");
      // You might want to handle the exceeding limit case as per your requirements
      // For instance, prevent the input or take other appropriate actions
    }
  };

  // Function to handle radio button for selection Usage Limited
  const handleRadioScope = (e) => {
    setSelectedScope(e.target.value);
    handleInputChange();
  };

  const handleSubmit = () => {
    if (selectedPromotionMethod === "randomDiscount") {
      // Ensure that low and high are parsed as numbers
      const parsedLow = parseFloat(low);
      const parsedHigh = parseFloat(high);

      // Perform the validation with parsed values
      if (parsedLow > parsedHigh) {
        // Show an error message using Swal
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Maximum amount cannot be less than Minimum amount",
        });
        return; // Exit the function to prevent form submission
      }
    }

    const promotionMethod = {};
    if (selectedPromotionMethod === "fixedAmount") {
      promotionMethod.fixedAmount = lowestDiscount;
    } else if (selectedPromotionMethod == "fixedDiscount") {
      promotionMethod.fixedDiscount = lowestDiscount;
    } else if (selectedPromotionMethod === "randomDiscount") {
      promotionMethod.randomDiscount = { low, high };
    }

    function dateToMilliseconds(date) {
      return date.getTime();
    }

    // Convert startDate and endDate to milliseconds
    let startDateMillis, endDateMillis;
    if (selectedvalidityPeriod === "dateSelect") {
      startDateMillis = dateToMilliseconds(new Date(startDate));
      endDateMillis = dateToMilliseconds(new Date(endDate));
    }

    // Prepare the request body based on selections
    const expiryDate = {};
    if (selectedvalidityPeriod === "permanent") {
      expiryDate.permanent = "true";
    } else if (selectedvalidityPeriod === "dateSelect") {
      expiryDate.startDate = startDateMillis;
      expiryDate.endDate = endDateMillis;
    } else if (selectedvalidityPeriod === "daysFromActivation") {
      expiryDate.daysFromActivation = parseInt(daysFromActivation, 10);
    }

    // Prepare the request body based on selections
    const receivingCondition = {};
    if (selectedRecevingcondition === "free") {
      receivingCondition.free = "true";
    } else if (selectedRecevingcondition === "redeemPoints") {
      receivingCondition.redeemPoints = parseInt(redeemPoints, 10);
    } else if (selectedRecevingcondition === "cash") {
      receivingCondition.cash = cash;
    }

    // Prepare the request body based on selections
    const limitPerPerson = {};
    if (selectedLimited === "unlimited") {
      limitPerPerson.unlimited = "true";
    } else if (selectedLimited === "limit") {
      limitPerPerson.limit = parseInt(redemptionsLimit, 10);
    }

    // Prepare the scopeOfUse based on selectedOption
    // let scopeOfUse = {};
    // if (
    //   selectedOption === "cash" ||
    //   selectedOption === "discount"
    //   // &&
    //   // selectedPromotionMethod
    // ) {
    //   // If selectedOption is "cash" or "discount" and there's a discount
    //   scopeOfUse = {
    //     [selectedScope]: "true",
    //   };
    // }

    let scopeOfUse = {};
    if (
      (selectedOption === "cash" ||
        selectedOption === "discount" ||
        selectedOption === "voucher") &&
      selectedPromotionMethod
    ) {
      // If selectedOption is "cash" or "discount" and there's a discount
      scopeOfUse = {
        [selectedScope]: "true",
      };
    }

    // Prepare the request body based on selections
    const requestBody = {
      coupon: {
        _id: currentCouponId,
        name: formData.name, // Add the selected coupon name here
        type: selectedOption,
        quantity: formData.quantity, // Add the selected quantity here
        expiryDate,
        // gift: {},
        gift: selectedGiftData,
        promotionMethod,
        usageThreshold: {
          [selectedUsageThreshold]:
            selectedUsageThreshold === "minimumOrderValue"
              ? minimumOrderValue
              : "true",
        },
        receivedCondition: receivingCondition,
        limitPerPerson: limitPerPerson,
        // scopeOfUse: scopeOfUse,
        scopeOfUse: (scopeOfUse = {
          [selectedScope]: "true",
        }),
        currency: selectedCurrency,
      },
    };

    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;

    const encryptbody = { data: encryptedData };

    if (AccessTokenValid()) {
      axios
      .post(API_ENDPOINT + "coupon/editCoupon", encryptbody, config)
      .then((response) => {
        const result = response.data.result;

        if (result === 420) {
          // Show Swal alert for unauthorized error
          Swal.fire({
            icon: 'error',
            title: content["Unauthorized"],
            text: content["You are not authorized to perform this action!"],
            confirmButtonText: content["OK"],
          });
          // Optionally, you can handle other logic for unauthorized case here
        } else {
          // Handle the API response here
          console.log(response.data); // You can access response data using response.data

          // Show a success message using Swal
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Coupon updated successfully!",
          }).then((result) => {
            if (result.isConfirmed) {
              // Redirect to the desired location after the alert is closed
              // Make sure to import the necessary function for navigation
              navigate("/dashboard/coupon");
            }
          });
        }
      })
        .catch((error) => {
          // Handle errors here
          console.error(error);

          // Show an error message using Swal
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while updated the coupon.",
          });
        });
    } else {
      CheckedAccessTokenValid();
    }
  };

  const navigate = useNavigate();
  return (
    <>
      <NavBar />

      <br />
      <div>
        <span>
          <FontAwesomeIcon icon={faChevronLeft} size="xl" />
        </span>
        &nbsp;
        <span
          style={{ fontSize: "20px", cursor: "pointer" }}
          onClick={() => navigate("/dashboard/coupon")}
        >
          {content["Back to previous"]}
        </span>
      </div>
      <br />
      <div class="CouponContainer">
      <div style={{ fontSize: "15px" }}>
      {content["Coupan Edit Information"]}
        </div>
        {/* <div class="div1">{content["Coupan Edit Information"]}</div> */}
        <div class="div2">
          {selectedOption === "cash" && (
            /* UI for Cash selected */
            <div className="mainBoxCoupanSelect ">
              <div>
                <ul>
                  <li style={{ backgroundColor: "rgb(214 183 71)" }}>
                    <h1 style={{ fontSize: "37px", color: "#fff" }}>
                      {/* {lowestDiscount} */}
                      {matchedActivityData.promotionMethod &&
                      matchedActivityData.promotionMethod.fixedDiscount !==
                        undefined
                        ? `${matchedActivityData.promotionMethod.fixedDiscount}%`
                        : matchedActivityData.promotionMethod &&
                          matchedActivityData.promotionMethod.fixedAmount !==
                            undefined
                        ? `${getCurrencySymbol(matchedActivityData.currency)}${
                            matchedActivityData.promotionMethod.fixedAmount
                          }`
                        : matchedActivityData.promotionMethod &&
                          matchedActivityData.promotionMethod.randomDiscount !==
                            undefined
                        ? `${getCurrencySymbol(matchedActivityData.currency)}${
                            matchedActivityData.promotionMethod.randomDiscount
                              .low
                          }-${getCurrencySymbol(matchedActivityData.currency)}${
                            matchedActivityData.promotionMethod.randomDiscount
                              .high
                          }`
                        : "N/A"}
                    </h1>
                  </li>
                  <li>
                    <div>
                      <div>
                        <h3>{formData.name}</h3>
                        {/* <div class="break-word">{formData.name}</div> */}
                      </div>
                      <div>
                        <p
                          style={{
                            fontSize: "larger",
                            fontWeight: "bold",
                            color: "gray",
                          }}
                        >
                          {/* Valid period of use */}
                          {matchedActivityData.expiryDate.permanent
                            ? "Permanent"
                            : matchedActivityData.expiryDate.startDate &&
                              matchedActivityData.expiryDate.endDate
                            ? `${formatDate(
                                matchedActivityData.expiryDate.startDate
                              )} - ${formatDate(
                                matchedActivityData.expiryDate.endDate
                              )}`
                            : matchedActivityData.expiryDate.daysFromActivation
                            ? `Expires in ${matchedActivityData.expiryDate.daysFromActivation} days`
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}

          {selectedOption === "discount" && (
            /* UI for Discount selected */
            <div className="mainBoxCoupanSelect">
              <div>
                <ul>
                  <li style={{ backgroundColor: "rgb(255 87 120)" }}>
                    <h1 style={{ fontSize: "37px", color: "#fff" }}>
                      {matchedActivityData.promotionMethod &&
                      matchedActivityData.promotionMethod.fixedDiscount !==
                        undefined
                        ? `${matchedActivityData.promotionMethod.fixedDiscount}%`
                        : matchedActivityData.promotionMethod &&
                          matchedActivityData.promotionMethod.fixedAmount !==
                            undefined
                        ? `${getCurrencySymbol(matchedActivityData.currency)}${
                            matchedActivityData.promotionMethod.fixedAmount
                          }`
                        : matchedActivityData.promotionMethod &&
                          matchedActivityData.promotionMethod.randomDiscount !==
                            undefined
                        ? `${getCurrencySymbol(matchedActivityData.currency)}${
                            matchedActivityData.promotionMethod.randomDiscount
                              .low
                          }-${getCurrencySymbol(matchedActivityData.currency)}${
                            matchedActivityData.promotionMethod.randomDiscount
                              .high
                          }`
                        : "N/A"}
                    </h1>
                  </li>
                  <li>
                    <div>
                      <div>
                        <h3 > {formData.name}</h3>
                      </div>
                      <div>
                        <p
                          style={{
                            fontSize: "larger",
                            fontWeight: "bold",
                            color: "gray",
                          }}
                        >
                          {/* Valid period of use */}
                          {matchedActivityData.expiryDate.permanent
                            ? "Permanent"
                            : matchedActivityData.expiryDate.startDate &&
                              matchedActivityData.expiryDate.endDate
                            ? `${formatDate(
                                matchedActivityData.expiryDate.startDate
                              )} - ${formatDate(
                                matchedActivityData.expiryDate.endDate
                              )}`
                            : matchedActivityData.expiryDate.daysFromActivation
                            ? `Expires in ${matchedActivityData.expiryDate.daysFromActivation} days`
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}

          {selectedOption === "voucher" && (
            /* UI for voucher selected */
            <div className="mainBoxGiftSelect ">
              <div>
                <ul>
                  <li
                    style={{
                      backgroundColor: "rgb(117 165 231)",
                      display: "block",
                    }}
                  >
                    <div>
                      <h2
                        style={{
                          color: "#fff",
                          marginLeft: "33px",
                        }}
                      >
                        {formData.name}
                      </h2>
                    </div>
                    <div>
                      <p
                        style={{
                          fontSize: "larger",
                          fontWeight: "bold",
                          color: "rgb(255, 255, 255)",
                          marginLeft: "33px",
                        }}
                      >
                        {matchedActivityData.expiryDate.permanent
                          ? "Permanent"
                          : matchedActivityData.expiryDate.startDate &&
                            matchedActivityData.expiryDate.endDate
                          ? `${formatDate(
                              matchedActivityData.expiryDate.startDate
                            )} - ${formatDate(
                              matchedActivityData.expiryDate.endDate
                            )}`
                          : matchedActivityData.expiryDate.daysFromActivation
                          ? `Expires in ${matchedActivityData.expiryDate.daysFromActivation} days`
                          : "N/A"}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div class="div3">
          <span style={{ color: "black" }}> &#x2022; </span>&nbsp;
          {content["Type"]}
        </div>
        <div class="div4">
          <span style={{ color: "black" }}> &#x2022; </span>&nbsp;
          {content["Coupon name"]}
        </div>

        <div class="div5">
          <span>
            <input
              type="radio"
              value="cash"
              checked={selectedOption === "cash"}
              onChange={handleRadioChange}
            />
            {content["Cash"]}
          </span>
        </div>
        <div class="div6">
          <span>
            <input
              type="radio"
              value="discount"
              checked={selectedOption === "discount"}
              onChange={handleRadioChange}
            />
            {content["Discount"]}
          </span>
        </div>
        <div class="div7">
          <span>
            <input
              type="radio"
              value="voucher"
              checked={selectedOption === "voucher"}
              onChange={handleRadioChange}
            />
            {content["Voucher"]}
          </span>
        </div>

        <div class="div8">
          <input
            type="text"
            className="coupon_input_name"
            // value={name}
            value={formData.name}
            // onChange={handleNameChange}

            onChange={(e) => {
              setFormData({ ...formData, name: e.target.value });
              handleInputChange();
            }}
            maxlength="35"
          />
        </div>

        <div class="div9">
          <span style={{ color: "black" }}> &#x2022; </span>
          {content["Quantity"]}
        </div>
        <div class="div10">
          <input
            type="text"
            className="coupon_input_name"
            // value={quantity}
            value={formData.quantity}
            onChange={(e) => {
              const inputValue = e.target.value;
              const regex = /^\d+(\.\d{0,2})?$/;
              if (
                inputValue === "" ||
                inputValue === 0 ||
                (inputValue > 0 &&
                  inputValue <= 10000000 &&
                  regex.test(inputValue))
              ) {
                setFormData({ ...formData, quantity: inputValue });
                handleInputChange();
              }
            }}
            placeholder={content["Quantity"]}
          />
        </div>

        <div class="div101">
          {content["Currency"]}&nbsp;&nbsp;
          <select
            id="currencyDropdown"
            onChange={handleCurrencyChange}
            value={selectedCurrency}
          >
            <option value="">Select...</option>
            {currencies.map((currency) => (
              <option key={currency.code} value={currency.code}>
                {currency.code}
              </option>
            ))}
          </select>
        </div>

        {selectedOption === "cash" && (
          <>
            <div class="div11">
              <span style={{ color: "black" }}> &#x2022; </span>
              {content["Usage threshold"]}
            </div>
            <div class="div12">
              <span>
                <input
                  type="radio"
                  value="noThreshold"
                  checked={selectedUsageThreshold === "noThreshold"}
                  onChange={handleRadioChangeThreshold}
                />
                {content["No threshold"]}
              </span>
            </div>

            <div class="div13">
              <span>
                <input
                  type="radio"
                  value="minimumOrderValue"
                  checked={selectedUsageThreshold === "minimumOrderValue"}
                  onChange={handleRadioChangeThreshold}
                />
                {content["Minimum amount"]}&nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  placeholder={content["Minimum amount"]}
                  value={
                    selectedUsageThreshold === "minimumOrderValue"
                      ? minimumOrderValue
                      : ""
                  }
                  disabled={selectedUsageThreshold !== "minimumOrderValue"}
                  onChange={(e) =>
                    handleMinimumOrderValueChange(e.target.value)
                  }
                />
                &nbsp;
                {selectedCurrency}
              </span>
            </div>

            <div class="div14">
              <span style={{ color: "black" }}> &#x2022; </span>
              {content["Promotion method"]}
            </div>

            <div class="div15">
              <span>
                <input
                  type="radio"
                  value="fixedAmount"
                  checked={selectedPromotionMethod === "fixedAmount"}
                  onChange={handleRadioChangePromotionMethod}
                />
                {content["Fixed amount"]}&nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  placeholder={content["Fixed amount"]}
                  value={lowestDiscount}
                  onChange={handleLowestDiscountChange}
                  disabled={selectedPromotionMethod !== "fixedAmount"}
                />
                &nbsp;
                {selectedCurrency}
              </span>
            </div>

            <div class="div16">
              <span>
                <input
                  type="radio"
                  value="randomDiscount"
                  checked={selectedPromotionMethod === "randomDiscount"}
                  onChange={handleRadioChangePromotionMethod}
                />
                {content["Random amount"]}&nbsp;
                <input
                  type="text"
                  placeholder={content["Minimum amount"]}
                  className="coupon_input_quantity"
                  value={low}
                  onChange={handleLowChange}
                  disabled={selectedPromotionMethod !== "randomDiscount"}
                />
                &nbsp;
                {selectedCurrency}
              </span>
            </div>
            <div class="div17">
              <span>
                <input
                  type="text"
                  placeholder={content["Maximum amount"]}
                  className="coupon_input_quantity"
                  value={high}
                  onChange={handleHighChange}
                  disabled={selectedPromotionMethod !== "randomDiscount"}
                />
                &nbsp;
                {selectedCurrency}
              </span>
            </div>
            <div class="div18">
              <span style={{ color: "black" }}> &#x2022; </span>{" "}
              {content["Validity period"]}
            </div>
            <div class="div19">
              <span>
                <input
                  type="radio"
                  value="permanent"
                  checked={selectedvalidityPeriod === "permanent"}
                  onChange={handleRadioChangevalidityPeriod}
                />
                {content["Effective permanently"]}
              </span>
            </div>
            <div class="div20">
              <span>
                <input
                  type="radio"
                  value="dateSelect"
                  checked={selectedvalidityPeriod === "dateSelect"}
                  onChange={handleRadioChangevalidityPeriod}
                />
              </span>
              {/* <input
                type="date"
                id="startDateInput"
                className="coupon_input_quantity"
                placeholder="start date"
                value={startDate}
                onChange={handleStartDateChange}
                disabled={selectedvalidityPeriod !== "dateSelect"}
              /> */}
              <DatePicker
                className="coupon_input_start"
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                minDate={new Date()} // Restrict to future dates
                maxDate={new Date("2080-12-1")} // Set maximum date
                dateFormat="dd-MM-yyyy"
                disabled={selectedvalidityPeriod !== "dateSelect"}
                placeholderText={content["Start Date"]}
              />
              &nbsp; - &nbsp;
              {/* <input
                type="date"
                className="coupon_input_quantity"
                placeholder="end date"
                value={endDate}
                onChange={handleEndDateChange}
                // disabled={selectedvalidityPeriod !== "dateSelect"}
                disabled={!startDate}
                min={startDate}
              /> */}
              <DatePicker
                className="coupon_input_end"
                selected={endDate}
                onChange={handleEndDateChange}
                selectsStart
                minDate={startDate} // Restrict to future dates
                maxDate={new Date("2080-12-1")} // Set maximum date
                dateFormat="dd-MM-yyyy"
                disabled={!startDate || selectedvalidityPeriod !== "dateSelect"}
                placeholderText={content["End Date"]}
              />
            </div>
            <div class="div21">
              <span>
                <input
                  type="radio"
                  value="daysFromActivation"
                  checked={selectedvalidityPeriod === "daysFromActivation"}
                  onChange={handleRadioChangevalidityPeriod}
                />
                {content["From the day you receive the coupon"]} &nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  value={daysFromActivation}
                  onChange={handleDaysFromActivationChange}
                  disabled={selectedvalidityPeriod !== "daysFromActivation"}
                />
                &nbsp;{content["Available within days"]}
              </span>
            </div>

            <div class="div22">
              <span style={{ color: "black" }}> &#x2022; </span>
              {content["Receving condition"]}
            </div>
            <div class="div23">
              <span>
                <input
                  type="radio"
                  value="free"
                  checked={selectedRecevingcondition === "free"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Get it for free"]}
              </span>
            </div>

            <div class="div24">
              <span>
                <input
                  type="radio"
                  value="redeemPoints"
                  checked={selectedRecevingcondition === "redeemPoints"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Redeem points"]}&nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  value={redeemPoints}
                  onChange={handleRedeemPointsChange}
                  disabled={selectedRecevingcondition !== "redeemPoints"}
                />
                &nbsp; {content["Integral"]}
              </span>
            </div>
            <div class="div25">
              <span>
                <input
                  type="radio"
                  value="cash"
                  checked={selectedRecevingcondition === "cash"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Cash"]}&nbsp;
                <input
                  type="text"
                  value={cash}
                  onChange={handleCashChange}
                  className="coupon_input_quantity"
                  disabled={selectedRecevingcondition !== "cash"}
                />
                &nbsp;
                {selectedCurrency}
              </span>
            </div>
          </>
        )}

        {selectedOption === "discount" && (
          <>
            <div class="div11">
              <span style={{ color: "black" }}> &#x2022; </span>
              {content["Usage threshold"]}
            </div>
            <div class="div12">
              <span>
                <input
                  type="radio"
                  value="noThreshold"
                  checked={selectedUsageThreshold === "noThreshold"}
                  onChange={handleRadioChangeThreshold}
                />
                {content["No threshold"]}
              </span>
            </div>

            <div class="div13">
              <span>
                <input
                  type="radio"
                  value="minimumOrderValue"
                  checked={selectedUsageThreshold === "minimumOrderValue"}
                  onChange={handleRadioChangeThreshold}
                />
                {content["Minimum amount"]}&nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  placeholder={content["Minimum amount"]}
                  value={
                    selectedUsageThreshold === "minimumOrderValue"
                      ? minimumOrderValue
                      : ""
                  }
                  disabled={selectedUsageThreshold !== "minimumOrderValue"}
                  onChange={(e) =>
                    handleMinimumOrderValueChange(e.target.value)
                  }
                />
                &nbsp; {selectedCurrency}
              </span>
            </div>

            <div class="div14">
              <span style={{ color: "black" }}> &#x2022; </span>
              {content["Promotion method"]}
            </div>

            <div class="div15">
              <span>
                <input
                  type="radio"
                  value="fixedDiscount"
                  checked={selectedPromotionMethod === "fixedDiscount"}
                  onChange={handleRadioChangePromotionMethod}
                />
                {content["Fixed discount"]}&nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  placeholder={content["Fixed discount"]}
                  value={lowestDiscount}
                  onChange={handleLowestDiscountChange}
                  disabled={selectedPromotionMethod !== "fixedDiscount"}
                />
                &nbsp; %
              </span>
            </div>

            <div class="div16">
              <span>
                <input
                  type="radio"
                  value="randomDiscount"
                  checked={selectedPromotionMethod === "randomDiscount"}
                  onChange={handleRadioChangePromotionMethod}
                />
                {content["Random discount"]}&nbsp;
                <input
                  type="text"
                  placeholder={content["Minimum discount"]}
                  className="coupon_input_quantity"
                  value={low}
                  onChange={handleLowChange}
                  disabled={selectedPromotionMethod !== "randomDiscount"}
                />
                &nbsp; %
              </span>
            </div>
            <div class="div17">
              <span>
                <input
                  type="text"
                  placeholder={content["Maximum discount"]}
                  className="coupon_input_quantity"
                  value={high}
                  onChange={handleHighChange}
                  disabled={selectedPromotionMethod !== "randomDiscount"}
                />
                &nbsp; %
              </span>
            </div>
            <div class="div18">
              <span style={{ color: "black" }}> &#x2022; </span>{" "}
              {content["Validity period"]}
            </div>
            <div class="div19">
              <span>
                <input
                  type="radio"
                  value="permanent"
                  checked={selectedvalidityPeriod === "permanent"}
                  onChange={handleRadioChangevalidityPeriod}
                />
                {content["Effective permanently"]}
              </span>
            </div>
            <div class="div20">
              <span>
                <input
                  type="radio"
                  value="dateSelect"
                  checked={selectedvalidityPeriod === "dateSelect"}
                  onChange={handleRadioChangevalidityPeriod}
                />
              </span>
              {/* <input
                type="date"
                id="startDateInput"
                className="coupon_input_quantity"
                placeholder="start date"
                value={startDate}
                onChange={handleStartDateChange}
                disabled={selectedvalidityPeriod !== "dateSelect"}
                min={today}
              /> */}
              <DatePicker
                className="coupon_input_start"
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                minDate={new Date()} // Restrict to future dates
                maxDate={new Date("2080-12-1")} // Set maximum date
                dateFormat="dd-MM-yyyy"
                disabled={selectedvalidityPeriod !== "dateSelect"}
                placeholderText={content["Start Date"]}
              />
              &nbsp; - &nbsp;
              {/* <input
                type="date"
                className="coupon_input_quantity"
                placeholder="end date"
                value={endDate}
                onChange={handleEndDateChange}
                disabled={selectedvalidityPeriod !== "dateSelect"}
                min={startDate}
              /> */}
              <DatePicker
                className="coupon_input_end"
                selected={endDate}
                onChange={handleEndDateChange}
                selectsStart
                minDate={startDate} // Restrict to future dates
                maxDate={new Date("2080-12-1")} // Set maximum date
                dateFormat="dd-MM-yyyy"
                disabled={!startDate || selectedvalidityPeriod !== "dateSelect"}
                placeholderText={content["End Date"]}
              />
            </div>
            <div class="div21">
              <span>
                <input
                  type="radio"
                  value="daysFromActivation"
                  checked={selectedvalidityPeriod === "daysFromActivation"}
                  onChange={handleRadioChangevalidityPeriod}
                />
                {content["From the day you receive the coupon"]} &nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  value={daysFromActivation}
                  onChange={handleDaysFromActivationChange}
                  disabled={selectedvalidityPeriod !== "daysFromActivation"}
                />
                &nbsp;{content["Available within days"]}
              </span>
            </div>

            <div class="div22">
              <span style={{ color: "black" }}> &#x2022; </span>
              {content["Receving condition"]}
            </div>
            <div class="div23">
              <span>
                <input
                  type="radio"
                  value="free"
                  checked={selectedRecevingcondition === "free"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Get it for free"]}
              </span>
            </div>

            <div class="div24">
              <span>
                <input
                  type="radio"
                  value="redeemPoints"
                  checked={selectedRecevingcondition === "redeemPoints"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Redeem points"]}&nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  value={redeemPoints}
                  onChange={handleRedeemPointsChange}
                  disabled={selectedRecevingcondition !== "redeemPoints"}
                />
                &nbsp; {content["Integral"]}
              </span>
            </div>
            <div class="div25">
              <span>
                <input
                  type="radio"
                  value="cash"
                  checked={selectedRecevingcondition === "cash"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Cash"]}&nbsp;
                <input
                  type="text"
                  value={cash}
                  onChange={handleCashChange}
                  className="coupon_input_quantity"
                  disabled={selectedRecevingcondition !== "cash"}
                />
                &nbsp; {selectedCurrency}
              </span>
            </div>
          </>
        )}

        {selectedOption === "voucher" && (
          <>
            <div class="div11">
              <span style={{ color: "black" }}> &#x2022; </span>{" "}
              {content["Redeem items"]}
            </div>
            {/* <div class="div12">
              <div style={{ textDecoration: "underline", color: "#4da5fe" }}>
              {content["Select Gift"]}
              </div>
            </div> */}

            <div class="div12">
              {/* <div
                style={{ textDecoration: "underline", color: "#4da5fe" }}
                onClick={() => handleClickCoupon()}
              >
                {content["Select Gift"]}
              </div> */}

              <div style={{ display: "flex" }}>
                {/* //preview */}

                {selectedGiftData ? (
                  <div className="mainBoxCoupanSelect">
                    <div className="mainBoxCoupanSelect">
                      <div>
                        <div className="Featured">
                          <div className="GiftImage">
                            <img
                              src={selectedGiftData.image}
                              alt="Featured img"
                              class="img-fluid"
                            />
                          </div>

                          <div className="Featured_text">
                            {/* <h3>{selectedGiftData.name.en}</h3> */}
                            {selectedOption === "voucher" &&
                            selectedGiftData &&
                            selectedGiftData.name &&
                            selectedGiftData.name.en ? (
                              <h3>{selectedGiftData.name.en}</h3>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ margin: "14px" }}>
                      ({content["Stock"]}:{selectedGiftData.count})
                    </div>
                  </div>
                ) : null}

                <div>
                  {/* <button
                className="actbtn1"
                style={{ margin: "0px 0px 0px 21px" }}
                onClick={() => handleClickCoupon()}
              >
                + Gift
              </button> */}
                  <div
                    style={{
                      textDecoration: "underline",
                      color: "#4da5fe",
                      cursor: "pointer",
                    }}
                    onClick={() => handleClickCoupon()}
                    className="disabled-button"
                    
                  >
                    {content["Select Gift"]}
                  </div>
                </div>
              </div>
            </div>

            <div class="div18">
              <span style={{ color: "black" }}> &#x2022; </span>{" "}
              {content["Validity period"]}
            </div>
            <div class="div19">
              <span>
                <input
                  type="radio"
                  value="permanent"
                  checked={selectedvalidityPeriod === "permanent"}
                  onChange={handleRadioChangevalidityPeriod}
                />
                {content["Effective permanently"]}
              </span>
            </div>
            <div class="div20">
              <span>
                <input
                  type="radio"
                  value="dateSelect"
                  checked={selectedvalidityPeriod === "dateSelect"}
                  onChange={handleRadioChangevalidityPeriod}
                />
              </span>
              {/* <input
                type="date"
                className="coupon_input_quantity"
                placeholder="start date"
                value={startDate}
                onChange={handleStartDateChange}
                disabled={selectedvalidityPeriod !== "dateSelect"}
                min={today}
              /> */}
              <DatePicker
                className="coupon_input_start"
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                minDate={new Date()} // Restrict to future dates
                maxDate={new Date("2080-12-1")} // Set maximum date
                dateFormat="dd-MM-yyyy"
                disabled={selectedvalidityPeriod !== "dateSelect"}
                placeholderText={content["Start Date"]}
              />
              - &nbsp;
              {/* <input
                type="date"
                className="coupon_input_quantity"
                placeholder="end date"
                value={endDate}
                onChange={handleEndDateChange}
                disabled={selectedvalidityPeriod !== "dateSelect"}
                min={startDate}
              /> */}
              <DatePicker
                className="coupon_input_end"
                selected={endDate}
                onChange={handleEndDateChange}
                selectsStart
                minDate={startDate} // Restrict to future dates
                maxDate={new Date("2080-12-1")} // Set maximum date
                dateFormat="dd-MM-yyyy"
                disabled={!startDate || selectedvalidityPeriod !== "dateSelect"}
                placeholderText={content["End Date"]}
              />
            </div>
            <div class="div21">
              <span>
                <input
                  type="radio"
                  value="daysFromActivation"
                  checked={selectedvalidityPeriod === "daysFromActivation"}
                  onChange={handleRadioChangevalidityPeriod}
                />
                {content["From the day you receive the coupon"]} &nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  value={daysFromActivation}
                  onChange={handleDaysFromActivationChange}
                  disabled={selectedvalidityPeriod !== "daysFromActivation"}
                />
                &nbsp;{content["Available within days"]}
              </span>
            </div>

            <div class="div22">
              <span style={{ color: "black" }}> &#x2022; </span>
              {content["Receving condition"]}
            </div>
            <div class="div23">
              <span>
                <input
                  type="radio"
                  value="free"
                  checked={selectedRecevingcondition === "free"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Get it for free"]}
              </span>
            </div>

            <div class="div24">
              <span>
                <input
                  type="radio"
                  value="redeemPoints"
                  checked={selectedRecevingcondition === "redeemPoints"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Redeem points"]}&nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  value={redeemPoints}
                  onChange={handleRedeemPointsChange}
                  disabled={selectedRecevingcondition !== "redeemPoints"}
                />
                &nbsp;
                {content["Integral"]}
                {/* {selectedCurrency} */}
              </span>
            </div>
            <div class="div25">
              <span>
                <input
                  type="radio"
                  value="cash"
                  checked={selectedRecevingcondition === "cash"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Cash"]}&nbsp;
                <input
                  type="text"
                  value={cash}
                  onChange={handleCashChange}
                  className="coupon_input_quantity"
                  disabled={selectedRecevingcondition !== "cash"}
                />
                &nbsp;
                {selectedCurrency}
              </span>
            </div>
          </>
        )}

        <div class="div26">
          <span style={{ color: "black" }}> &#x2022; </span>
          {content["Limited to per person"]}
        </div>
        <div class="div27">
          <span>
            <input
              type="radio"
              value="unlimited"
              checked={selectedLimited === "unlimited"}
              onChange={handleRadioLimited}
            />
            {content["Unlimited"]}
          </span>
        </div>
        <div class="div28">
          <span>
            <input
              type="radio"
              value="limit"
              checked={selectedLimited === "limit"}
              onChange={handleRadioLimited}
            />
            {content["Limited"]}&nbsp;
            <input
              type="text"
              value={redemptionsLimit}
              onChange={handleRedemptionsLimitChange}
              disabled={selectedLimited !== "limit"}
              className="coupon_input_quantity"
            />
          </span>
        </div>
        <div class="div29">
          <span style={{ color: "black" }}> &#x2022; </span>
          {content["Scope of use"]}
        </div>
        <div class="div30">
          <span>
            <input
              type="radio"
              value="allAvailable"
              checked={selectedScope === "allAvailable"}
              onChange={handleRadioScope}
            />
            {content["All Available"]}
          </span>
        </div>
        <div class="div31">
          <span>
            <input
              type="radio"
              value="anualMembership"
              checked={selectedScope === "anualMembership"}
              onChange={handleRadioScope}
            />
            {content["Annual membership recharge"]}
          </span>
        </div>
        <div class="div32">
          <span>
            <input
              type="radio"
              value="semiAnnualMembership"
              checked={selectedScope === "semiAnnualMembership"}
              onChange={handleRadioScope}
            />
            {content["Semi-Annual membership recharge"]}
          </span>
        </div>
        <div class="div33">
          <span>
            <input
              type="radio"
              value="singleMonthMembership"
              checked={selectedScope === "singleMonthMembership"}
              onChange={handleRadioScope}
            />
            {content["Single-Month membership available"]}
          </span>
        </div>
        <div class="div34">
          <span>
            <input
              type="radio"
              value="notAnnualMembership"
              checked={selectedScope === "notAnnualMembership"}
              onChange={handleRadioScope}
            />
            {content["Not Annual membership"]}
          </span>
        </div>

        <div class="div35">
          <span>
            <input
              type="radio"
              value="notSemiAnnualMembership"
              checked={selectedScope === "notSemiAnnualMembership"}
              onChange={handleRadioScope}
            />
            {content["Not Semi-Annual membership"]}
          </span>
        </div>

        <div class="div36">
          <span>
            <input
              type="radio"
              value="notsingleMonthMembership"
              checked={selectedScope === "notsingleMonthMembership"}
              onChange={handleRadioScope}
            />
            {content["Not Single-Month membership"]}
          </span>
        </div>
        <div class="div37">
          {/* <button
            class="btnCoupon"
            onClick={handleSubmit}
            disabled={selectedOption === "voucher" ? !areAllFieldsFilled() || selectedGiftData === null : !areAllFieldsFilled()}
          >
            {content["Save"]}
          </button> */}

          <button
            className="btnCoupon"
            onClick={handleSubmit}
            disabled={
              selectedOption === "voucher"
                ? !areAllFieldsFilled() ||
                  !formChanged
                : !areAllFieldsFilled() || !formChanged
            }
          >
            {content["Save"]}
          </button>
        </div>
      </div>

      {/* //Gift */}

      <dialog
        ref={dialogControlGift}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "73%",
          background: "#fff",
          zIndex: 9999,
          border: "none",
        }}
      >
        <div>
          <div className="header-app">
            <div>{content["Select Gift"]}</div>
            <div>
              <ImCross
                style={{ cursor: "pointer" }}
                className="btnClose"
                width={100}
                onClick={handleDialogGiftClose}
              />
            </div>
          </div>

          <div className="body-app">
            <div className="mainBox-card-container-app">
              {allGift.map((coupon, index) => (
                <div key={coupon._id}>
                  <label
                    htmlFor={`coupon${coupon._id}`}
                    className="label-container-act"
                  >
                    {/* <div>
                      test
                    </div> */}
                    <div className="Featured">
                      <div className="Featured_img">
                        <img
                          src={coupon.image}
                          alt="Featured img"
                          class="img-fluid"
                        />
                      </div>

                      <div className="Featured_text">
                        <h3>{coupon.name.en}</h3>
                        <div style={{ color: "darkgray" }}>
                          {content["Stock"]}:{coupon.count}
                        </div>
                      </div>
                    </div>

                    <input
                      type="checkbox"
                      checked={index === selectedGiftIndex}
                      onChange={() => {
                        setselectedGiftIndex(index);
                        setselectedGiftId(coupon._id);
                      }}
                      name="coupons"
                      id={`coupon${coupon._id}`}
                      className="hidden-checkbox-act"
                    />
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="footer-app" style={{ bottom: "8px" }}>
            <div
              className="btn-save"
              onClick={() => {
                handleDialogGiftClose();
                setSelectedGiftData(matchingObjectsgift);
                console.log("Selected Coupon ID:", selectedGiftId);
              }}
            >
              {content["OK"]}
            </div>
            <button className="btn-cancel" onClick={handleDialogGiftClose}>
              {content["Cancel"]}
            </button>
          </div>
        </div>
      </dialog>
    </>
  );
}
