import Swal from "sweetalert2";
import { removeLocalStorageItems } from "./encrypt/encryptUtils";

function removeAllLocalStorageItems() {
  // removeLocalStorageItems(
  //   "accessToken",
  //   "position",
  //   "countries",
  //   "GetGift",
  //   "GetCoupon",
  //   "currentImageId",
  //   "getPosterToDisplay",
  //   "currentActivityId",
  //   "Activities",
  //   "currentCouponId",
  //   "Languges",
  //   "slides",
  //   "getCourses",
  //   "Getsubscriptions",
  //   "currentsubscriptionId",
  //   "UserId"
  // );
  localStorage.clear();
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

function isAccessTokenValid() {
  const accessToken = getCookie("accessToken");
  return (
    accessToken !== undefined && accessToken !== null && accessToken !== ""
  );
}
console.log("isAccessTokenValid", isAccessTokenValid);
function CheckedAccessTokenValid() {
  if (!isAccessTokenValid()) {
    Swal.fire({
      icon: "error",
      title: "Access Token Expired",
      text: "Your access token has expired. Logging out...",
      showConfirmButton: false,
      timer: 1000,
      willClose: () => {
        removeAllLocalStorageItems();
        window.location.href = "/";
      },
    });
  }
}

function AccessTokenValid() {
  const accessToken = getCookie("accessToken");

  if (!accessToken) {
    CheckedAccessTokenValid();
    return false;
  }

  return true;
}



export {
  CheckedAccessTokenValid,
  AccessTokenValid,
  removeAllLocalStorageItems,
};
