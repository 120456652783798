import useLanguage from "../../../translation/TranslationEngToChinese";
import "./poster.css";
import logout from "../../../img/logout_copy.png";
import logo from "../../../img/wf-c.png";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import WSPGallery from "./WSPGallery/WSPGallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import LoadingSpinner from "./LoadingSpinner";
import Swal from "sweetalert2";
import { apiBaseUrls, apiKeys } from "../../../config";
import { encryptedaes  } from "../../../utils/encrypt/encryptUtils";
import { removeAllLocalStorageItems , CheckedAccessTokenValid , AccessTokenValid } from "../../../utils/LogAuth";
const API_ENDPOINT = apiBaseUrls.development;



export default function Poster() {
  // const { language, setLanguage, content } = useLanguage();
  const { language, setLanguage, content } = useOutletContext();

  const [selectedImages, setSelectedImages] = useState([]);

  // new changes 1 new
  const [checkedValues, setValue] = useState([]); // Add the checkedValues state
  // const [checkedValues, setValue] = useState([]);
  // const [selectedImages, setSelectedImages] = useState([]);
  const [isAnyImageSelected, setIsAnyImageSelected] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);

  // Add a loading state to manage the loading spinner
  const [isLoading, setIsLoading] = useState(false);

  // const [isPreviewLoading, setPreviewLoading] = useState(false);

  const [posterToDisplay, setPosterToDisplay] = useState([]);
  
  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };
  
  //  // Function to handle checkbox change single new 1
  const handleChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setValue((prev) => [...prev, value]);
    } else {
      setValue((prev) => prev.filter((imageid) => imageid !== value));
    }
  };

  console.log("new 1", checkedValues);

  // useEffect(() => {
  //   axios
  //     .get(API_ENDPOINT + "poster/getPosters", config)
  //     .then((response) => {
  //       const posters = response.data.images;
  //       console.log("response:-", response);
  //       const mappedImages = posters.map((poster) => ({
  //         id: poster._id,
  //         posterUrl: poster.posterUrl.replace(/^(https?:)?\/\/[^/]+/, ""),
  //         thumbnailUrl: poster.thumbnailUrl.replace(/^(https?:)?\/\/[^/]+/, ""),
  //       }));
  //       setGalleryImages(mappedImages); // Update the galleryImages state
  //       console.log("Image22", mappedImages);
  //     })
  //     .catch((error) => {
  //       console.error("Failed to fetch posters:", error);
  //       // Handle the error
  //     });
  // }, []);

  useEffect(() => {
    if(AccessTokenValid()){
              // Fetch posters and posterToDisplay when the component mounts
    Promise.all([
      axios.get(API_ENDPOINT + "poster/getPosters", config),
      axios.get(API_ENDPOINT + "poster/posterToDisplay", config),
    ])
      .then((responses) => {


        const postersResponse = responses[0];
        const posterToDisplayResponse = responses[1];
        const posters = postersResponse.data.images;
        const posterUrls = posterToDisplayResponse.data.images.map(
          (image) => image.posterUrl
        );
  
        const mappedImages = posters.map((poster) => ({
          id: poster._id,
          // posterUrl: poster.posterUrl.replace(/^(https?:)?\/\/[^/]+/, ""),
          // thumbnailUrl: poster.thumbnailUrl.replace(/^(https?:)?\/\/[^/]+/, ""),
          posterUrl: poster.posterUrl,
          thumbnailUrl: poster.thumbnailUrl,
        }));
  
        setGalleryImages(mappedImages); // Update the galleryImages state
        console.log("TestPictys",mappedImages)
        setPosterToDisplay(posterUrls); // Update the posterToDisplay state
      })
      .catch((error) => {
        console.error("Failed to fetch data:", error);
        // Handle the error
      });
    }else{
      CheckedAccessTokenValid()
    }



  }, []);
  
 

  const navigate = useNavigate();

  const handleLogout = () => {
    const accessToken = localStorage.getItem("accessToken");
    const userId = accessToken.split("#")[0];
    console.log("Test User_id", userId);

    axios
      .post(API_ENDPOINT + "user/logout", { accessToken, userId }, config)
      .then(() => {
        // localStorage.removeItem("accessToken");
          removeAllLocalStorageItems()
          document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  
  const ImageCollection = () => {
    const apiUrl = API_ENDPOINT + "poster/getPosters";
    if(AccessTokenValid()){
      axios
      .get(apiUrl, config)
      .then((response) => {
        const posters = response.data.images;
        const mappedImages = posters.map((poster) => ({
          id: poster._id,
          // posterUrl: poster.posterUrl.replace(/^(https?:)?\/\/[^/]+/, ""),
          // thumbnailUrl: poster.thumbnailUrl.replace(/^(https?:)?\/\/[^/]+/, ""),
          posterUrl: poster.posterUrl,
          thumbnailUrl: poster.thumbnailUrl,
        }));
        // isPreviewLoading(true);
        setGalleryImages(mappedImages); // Update the galleryImages state
      })
      .catch((error) => {
        console.log("Error fetching table data", error);
      });
    }else{
      CheckedAccessTokenValid()
    }




    // .finally(() => {
    //   isPreviewLoading(false); // Set loading to false after the upload completes
    // });
  };


    // Function to reset the file input after image upload
    const resetFileInput = () => {
      const fileInput = document.getElementById("file");
      if (fileInput) {
        fileInput.value = ""; // Reset the value to clear the selected file(s)
      }
    };



  // const handleImageUpload = (event) => {

  //   const file = event.target.files;
  //   const formData = new FormData();
  //   Array.from(file).forEach((item) => {
  //     formData.append("files", item);
  //   });

  //   //setGalleryImages

  //   axios
  //     .post(API_ENDPOINT + "multiPostersUpload", formData, config)
  //     .then((response) => {
  //       console.log(response.data); // handle the response from the server

  //       // Update galleryImages state with the newly uploaded image
  //       const newImage = {
  //         id: response.data._id,
  //         imageurl: response.data.posterUrl,
  //       };
  //       ImageCollection();
  //     })
  //     .catch((error) => {
  //       ImageCollection();
  //       console.error(error); // handle any errors
  //     });

  // };

  //Single Delete Poster
  // const handleImageDelete = (imageId) => {
  //   // Send the delete request to the API
  //   console.log("Deleting image with ID:", imageId);
  //   axios
  //     .post(API_ENDPOINT + "poster/deletePosters", { ids: [imageId] }, config)
  //     .then((response) => {
  //       console.log("Delete response:", response.data);
  //       // Refresh the images after successful deletion
  //       ImageCollection();
  //     })
  //     .catch((error) => {
  //       console.error("Error deleting image:", error);
  //     });
  // };



  // const handleImageDelete = (imageId) => {
  //   Swal.fire({
  //     text: content["Are you sure want to delete this poster"] + "?",
  //     showCancelButton: true,
  //     confirmButtonColor: "#5d649c",
  //     cancelButtonColor: "#CCCCCC",
  //     cancelButtonText: content["Cancel"],
  //     confirmButtonText: content["Delete"],
  //     backdrop: false,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       axios
  //         .post(
  //           API_ENDPOINT + "poster/deletePosters",
  //           { ids: [imageId] },
  //           config
  //         )
  //         .then((response) => {
  //           console.log("Delete response:", response.data);
  //           ImageCollection();
  //         })
  //         .catch((error) => {
  //           console.error("Error deleting image:", error);
  //           Swal.fire(content["Error"] + "!");
  //         });
  //     }
  //   });
  // };

  // const handleImageDelete = (imageId) => {
  //   // Check if the image's _id is in the posterToDisplay array
  //   const imageToDelete = galleryImages.find((image) => image.id === imageId);
  
  //   if (imageToDelete && posterToDisplay.includes(imageToDelete.posterUrl)) {
  //     // Image _id is used in posterToDisplay, show alert and prevent deletion
  //     Swal.fire({
  //       text: "Poster is being used in Banners.",
  //       icon: "error",
  //     });
  //   } else {
  //     // Image _id is not in posterToDisplay, proceed with deletion
  //     Swal.fire({
  //       text: content["Are you sure want to delete this poster"] + "?",
  //       showCancelButton: true,
  //       confirmButtonColor: "#5d649c",
  //       cancelButtonColor: "#CCCCCC",
  //       cancelButtonText: content["Cancel"],
  //       confirmButtonText: content["Delete"],
  //       backdrop: false,
  //     }).then((result) => {

  //       if (result.isConfirmed) {
  //         const requestBody = 
  //           { ids: [imageId] }
          
  //         const { encryptedData, SessionToken } = encryptedaes(requestBody);
  //         config.headers["Session-Token"] = SessionToken;
  //         const encryptbody = {data:encryptedData}
  //         axios
  //           .post(API_ENDPOINT + "poster/deletePosters", encryptbody , config)
  //           .then((response) => {
  //             console.log("Delete response:", response.data);
  //             // Refresh the images after successful deletion
  //             ImageCollection();
  //           })
  //           .catch((error) => {
  //             console.error("Error deleting image:", error);
  
  //             // Show an error message to the user
  //             Swal.fire(content["Error"] + "!");
  //           });
  //       }
  //     });
  //   }
  // };
  
  const handleImageDelete = (imageId) => {
    const imageToDelete = galleryImages.find((image) => image.id === imageId);
    const Activities = localStorage.getItem("Activities");
    const ActivitiesData = JSON.parse(Activities);
    const posterToActivity = ActivitiesData
    const isUsedInBanners = imageToDelete && posterToDisplay.includes(imageToDelete.posterUrl);
    const isUsedInActivities = imageToDelete && posterToActivity.some(activity => activity.image === imageToDelete.posterUrl);
  
    if (isUsedInBanners || isUsedInActivities) {
      // Image _id is used in either posterToDisplay or posterToActivity, show alert and prevent deletion
      Swal.fire({
        text: content["Poster is being used in Banners or Activities."],
        confirmButtonText:content["OK"],
        icon: "error",
      });
    } else {
      // Image _id is not used in either posterToDisplay or posterToActivity, proceed with deletion
      Swal.fire({
        text: content["Are you sure want to delete this poster"] + "?",
        showCancelButton: true,
        confirmButtonColor: "#5d649c",
        cancelButtonColor: "#CCCCCC",
        cancelButtonText: content["Cancel"],
        confirmButtonText: content["Delete"],
        backdrop: false,
      }).then((result) => {

        if (result.isConfirmed) {
          const requestBody = 
            { ids: [imageId] }
          
          const { encryptedData, SessionToken } = encryptedaes(requestBody);
          config.headers["Session-Token"] = SessionToken;
          const encryptbody = {data:encryptedData}
          if(AccessTokenValid()){
            axios
            .post(API_ENDPOINT + "poster/deletePosters", encryptbody, config)
            .then((response) => {
              const result = response.data.result;

              if (result === 420) {
                // Show Swal alert for unauthorized error
                Swal.fire({
                  icon: 'error',
                  title: content["Unauthorized"],
                  text: content["You are not authorized to perform this action!"],
                  confirmButtonText: content["OK"],
                });
                // Optionally, you can handle other logic for unauthorized case here
              } else {
                console.log("Delete response:", response.data);
                // Refresh the images after successful deletion
                ImageCollection();
              }
            })
            .catch((error) => {
              console.error("Error deleting image:", error);
  
              // Show an error message to the user
              Swal.fire(content["Error"] + "!");
            });
          }else{
            CheckedAccessTokenValid()
          }


        }
      });
    }
  };

  
  // Function to handle image upload
  const handleImageUpload = (event) => {
    setIsLoading(true); // Set loading to true while uploading

    const file = event.target.files;
    const formData = new FormData();
    Array.from(file).forEach((item) => {
      formData.append("files", item);
    });

    if(AccessTokenValid()){
      axios
      .post(API_ENDPOINT + "multiPostersUpload", formData, config)
      .then((response) => {
        const result = response.data.result;

        if (result === 420) {
          // Show Swal alert for unauthorized error
          Swal.fire({
            icon: 'error',
            title: content["Unauthorized"],
            text: content["You are not authorized to perform this action!"],
            confirmButtonText: content["OK"],
          });
          // Optionally, you can handle other logic for unauthorized case here
        } else {
          console.log("newImage--->", response.data.images[0].thumbnailUrl); // handle the response from the server

          // Update galleryImages state with the newly uploaded image
          const newImage = {
            id: response.data._id,
            imageurl: response.data.posterUrl,
          };

          ImageCollection();
          resetFileInput();
          // window.location.reload(false);
        }
      })
      .catch((error) => {
        ImageCollection();
        console.error(error); // handle any errors
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false after the upload completes
      });
    }else{
      CheckedAccessTokenValid()
    }



  };

  // const handleSelectAll = () => {
  //   const allImageIds = galleryImages.map((image) => image.id);
  //   setSelectedImages(allImageIds);
  //   setValue(allImageIds); // Set checkedValues to all image IDs when selecting all
  // };

  //select Images
  const handleImageSelect = (imageId) => {
    const isSelected = selectedImages.includes(imageId);
    if (isSelected) {
      setSelectedImages(selectedImages.filter((id) => id !== imageId));
    } else {
      setSelectedImages([...selectedImages, imageId]);
    }
  };

  const handleImagePreview = (imageUrl) => {
    // Implement image preview functionality here
    console.log("Preview image:", imageUrl);
  };

  // const handleImageDelete = (imageId) => {
  //   // Implement image delete functionality here
  //   console.log("Delete image:", imageId);
  // };

  // const handleUncheckAll = () => {
  //   setSelectedImages([]);
  // };

  // handing multiple function for unchecked and select all
  const handleUncheckAll = () => {
    setSelectedImages([]);
    setValue([]); // Clear the checkedValues state when unchecking all
  };

  // // Selection and unchecked
  const handleSelectAllold = () => {
    const allImageIds = galleryImages.map((image) => image.id);
    console.log("Multiple Select", allImageIds);
    setSelectedImages(Array.from(Array(galleryImages.length).keys()));
    setValue(allImageIds); // Capture all image IDs in the checkedValues array
    console.log(galleryImages.map((image) => image));
    console.log("remainingImageIds + setValue->",allImageIds,"+setSelectedImages->",Array.from(Array(galleryImages.length).keys()),"+galleryImages.map((image) => image)->",galleryImages.map((image) => image))
  };


  const handleSelectAll = () => {
    const allImageIds = galleryImages.map((image) => image.id);
    console.log("Multiple Select", allImageIds);
    // Create separate arrays to store images used in Banners or Activities
    const imagesUsedInBanners = [];
    const imagesUsedInActivities = [];
  
    // Loop through galleryImages to check if each image is used in Banners or Activities
    galleryImages.forEach((image, index) => {
      const imageToDelete = galleryImages.find((img) => img.id === image.id);
      const Activities = localStorage.getItem("Activities");
      const ActivitiesData = JSON.parse(Activities);
      const posterToActivity = ActivitiesData;
      const isUsedInBanners =
        imageToDelete && posterToDisplay.includes(imageToDelete.posterUrl);
      const isUsedInActivities =
        imageToDelete &&
        posterToActivity.some(
          (activity) => activity.image === imageToDelete.posterUrl
        );
  
      if (isUsedInBanners || isUsedInActivities) {
        if (isUsedInBanners) imagesUsedInBanners.push(index);
        if (isUsedInActivities) imagesUsedInActivities.push(index);
      }
    });
  
    // Filter out images used in Banners or Activities from allImageIds
    const remainingImageIds = allImageIds.filter(
      (id, index) =>
        !imagesUsedInBanners.includes(index) &&
        !imagesUsedInActivities.includes(index)
    );
  
    // Get the indices of specific IDs from remainingImageIds array
      const indicesArray = remainingImageIds.map((id) => allImageIds.indexOf(id));
      console.log("remainingImageIds",remainingImageIds)
      setValue(remainingImageIds);
      setSelectedImages(indicesArray); // Select only the images not used in Banners or Activities
  };

  
  // Selection and unchecked
  // const handleSelectAll2 = (event) => {
  //   if (event.target.checked) {
  //     console.log("123", event.target.checked);
  //     const allImageIds = galleryImages.map((image) => image.id);
  //     console.log("Multiple Select", allImageIds);
  //     setSelectedImages(allImageIds);
  //     setSelectedImages(Array.from(Array(galleryImages.length).keys()));
  //   } else {
  //     setSelectedImages([]);

  //   }
  // };

  // new 1
  // Function to handle deletion of multiple images

  // const handleDeleteMultipleImages = () => {
  //   if (checkedValues.length === 0) {
  //     // No images selected for deletion
  //     return;
  //   }

  //   axios
  //     .post(API_ENDPOINT + "poster/deletePosters", { ids: checkedValues }, config)
  //     .then((response) => {
  //       console.log("Delete response:", response.data);
  //       // After successful deletion, refresh the images
  //       ImageCollection();
  //       // Clear the selected images in the state
  //       setSelectedImages([]);
  //       // Clear the checkedValues array in the state
  //       setValue([]);
  //     })
  //     .catch((error) => {
  //       console.error("Error deleting images:", error);
  //     });
  // };

  // new 2
  const handleDeleteMultipleImages = () => {
    if (checkedValues.length === 0) {
      // No images selected for deletion
      return;
    }

    Swal.fire({
      text: content["Are you sure want to delete this poster"] + "?",
      showCancelButton: true,
      confirmButtonColor: "#5d649c",
      cancelButtonColor: "#CCCCCC",
      cancelButtonText: content["Cancel"],
      confirmButtonText: content["Delete"],
      backdrop: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const requestBody = 
          { ids: checkedValues }
        
        const { encryptedData, SessionToken } = encryptedaes(requestBody);
        config.headers["Session-Token"] = SessionToken;
        const encryptbody = {data:encryptedData}
        if(AccessTokenValid()){
          axios
          .post(
            API_ENDPOINT + "poster/deletePosters",
            encryptbody,
            config
          )
          .then((response) => {

            const result = response.data.result;

            if (result === 420) {
              // Show Swal alert for unauthorized error
              Swal.fire({
                icon: 'error',
                title: content["Unauthorized"],
                text: content["You are not authorized to perform this action!"],
                confirmButtonText: content["OK"],
              });
              // Optionally, you can handle other logic for unauthorized case here
            } else {
              console.log("Delete response:", response.data);
              // After successful deletion, refresh the images
              ImageCollection();
              // Clear the selected images in the state
              setSelectedImages([]);
              // Clear the checkedValues array in the state
              setValue([]);
            }

          })
          .catch((error) => {
            console.error("Error deleting images:", error);

            // Show an error message to the user
            Swal.fire(content["Error"] + "!");
          });
        }else{
          CheckedAccessTokenValid()
        }



      }
    });
  };

  const handleSelectedImages = (selectedImages) => {
    setSelectedImages(selectedImages);
  };

  useEffect(() => {
    setIsAnyImageSelected(selectedImages.length > null);
  }, [selectedImages]);

  return (
    <>
      <div
        className="mainpage"
        style={{
          textAlign: "right",
          marginLeft: "17px",
          marginRight: "31px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>
            <img
              style={{ width: "40px" }}
              src={logo}
              className="logoimage"
              alt=""
            />
            &nbsp;
          </div>
          <div style={{ fontSize: "large", fontWeight: 700 }}>
            {content["WhaleFit Content Management Platform"]}
          </div>
        </div>

        <div className="lang">
          <select
            value={language}
            onChange={(e) => {
              setLanguage(e.target.value);
            }}
          >
            <option>English</option>
            <option>中国人</option>
          </select>
        </div>

        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={handleLogout}
        >
          <div>
            <img
              style={{ width: "31px" }}
              src={logout}
              className="logoimage"
              alt=""
            />
            &nbsp;
          </div>
          <div style={{ fontSize: "large", fontWeight: 700 }}>
            {content["Logout"]}
          </div>
        </div>
      </div>

      <br />

      <div style={{ display: isLoading ? "block" : "none" }}>
        <LoadingSpinner />
        <span style={{ position: "absolute", top: "100px", left: "86px" }}>
          {content["Uploading"]}....
        </span>
      </div>

      <div className="mainpage">
        {isAnyImageSelected ? null : (
          <div style={{ fontSize: "3.3vh", fontWeight: 500 }}>
            {content["Posters"]}({galleryImages.length})
          </div>
        )}
        <div style={{ display: selectedImages.length > 0 ? "block" : "none" }}>
          <div>
            {/* <input
              type="checkbox"
              className="styledcheckbox"
              checked={selectedImages.length === galleryImages.length}
              onChange={handleSelectAll}
            /> */}
            <input
              type="checkbox"
              className="styledcheckbox"
              checked={selectedImages.length === galleryImages.length}
              onChange={
                selectedImages.length === galleryImages.length
                  ? handleUncheckAll
                  : handleSelectAll
              }
            />
            &nbsp;&nbsp;
            <span style={{ fontSize: "x-large" }}>{content["Select All"]}</span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontSize: "x-large", color: "gray" }}>
              {`${selectedImages.length}`}&nbsp;{content["Item Selected"]}
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span
              style={{
                fontSize: "x-large",
                color: "#6870af",
                cursor: "pointer",
              }}
              onClick={handleUncheckAll}
            >
              {content["Cancel Selection"]}
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span
              style={{
                fontSize: "x-large",
                color: "#6870af",
                cursor: "pointer",
              }}
              onClick={handleDeleteMultipleImages}
            >
              <FontAwesomeIcon icon={faTrashCan} style={{ color: "#000000" }} />
            </span>
          </div>
        </div>

        {/* <div style={{ display: "flex" }}>
          <input
            type="file"
            id="file"
            accept="image/jpeg"
            multiple
            onChange={handleImageUpload}
          />
          <label htmlFor="file">Upload</label>

          <label for="file">{content["Upload"]}</label>
        </div> */}

        <div style={{ display: "flex" }}>
          <input
            type="file"
            id="file"
            accept="image/jpeg"
            multiple
            onChange={handleImageUpload}
            // Disable the button when isLoading is true
            disabled={isLoading}
          />
          {/* Disable the label too */}
          <label
            htmlFor="file"
            className="labelimp"
            style={{
              pointerEvents: isLoading ? "none" : "auto",
              opacity: isLoading ? 0.5 : 1,
            }}
          >
            Upload
          </label>

          {/* You can also disable the other label if needed */}
          <label
          className="labelimp"
            htmlFor="file"
            style={{
              pointerEvents: isLoading ? "none" : "auto",
              opacity: isLoading ? 0.5 : 1,
            }}
          >
            {content["Upload"]}
          </label>
        </div>
      </div>

      <br />

      <WSPGallery
        galleryImages={galleryImages}
        selectedImages={selectedImages}
        onSelectImage={handleImageSelect}
        onPreviewImage={handleImagePreview}
        onDeleteImage={handleImageDelete}
        // new 1
        checkedValues={checkedValues}
        setValue={setValue}
        posterToDisplay={posterToDisplay}
      />
    </>
  );
}
