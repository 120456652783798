import React, { useState, useEffect, useRef } from "react";
import NavBar from "../other/navBar";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./coupon.css";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import currenciesJson from "../other/JsonData/currencies.json";
import { ImCross } from "react-icons/im";
import { encryptedaes } from "../../../utils/encrypt/encryptUtils";
import {
  CheckedAccessTokenValid,
  AccessTokenValid,
} from "../../../utils/LogAuth";
import { apiBaseUrls, apiKeys } from "../../../config";
const API_ENDPOINT = apiBaseUrls.development;


export default function Addcoupon() {
  const { content } = useOutletContext();
  const [selectedOption, setSelectedOption] = useState("cash");
  const [selectedUsageThreshold, setSelectedUsageThreshold] = useState("");
  const [selectedPromotionMethod, setSelectedPromotionMethod] = useState("");
  const [selectedvalidityPeriod, setSelectedvalidityPeriod] = useState("");
  const [selectedRecevingcondition, setSelectedRecevingcondition] =
    useState("");
  const [selectedLimited, setSelectedLimited] = useState("");
  const [selectedScope, setSelectedScope] = useState("");
  const [minimumOrderValue, setMinimumOrderValue] = useState("");
  const [lowestDiscount, setLowestDiscount] = useState("");
  const [low, setLow] = useState("");
  const [high, setHigh] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [daysFromActivation, setDaysFromActivation] = useState("");
  const [redeemPoints, setRedeemPoints] = useState("");
  const [cash, setCash] = useState("");
  const [quantity, setQuantity] = useState("");
  const [name, setName] = useState("");

  const [redemptionsLimit, setRedemptionsLimit] = useState("");

  const [selectedCurrency, setSelectedCurrency] = useState("GBP");
  const currencies = currenciesJson.currencies;
  const [allGift, setAllGift] = useState([]);


  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };

  const GetGiftAPI = () => {
    if (AccessTokenValid()) {
      axios
        .get(API_ENDPOINT + "gift/getGifts", config)
        .then((response) => {
          const gifts = response.data.gifts;
          console.log("Response", gifts);
          console.log("_____1____getGifts", gifts);
          setAllGift(gifts); // Update the galleryImages state
          localStorage.setItem("GetGift", JSON.stringify(gifts));
        })
        .catch((error) => {
          console.error("Failed to fetch Coupons:", error);
        });
    } else {
      CheckedAccessTokenValid();
    }
  };

  //new modified fixed bug
  const onKeyPress = (event) => {
    // const keyCode = event.keyCode || event.which;
    // const keyValue = String.fromCharCode(keyCode);
    // if (!/^\d$/.test(keyValue)) {
    //   event.preventDefault();
    // } else if (quantity === '' && keyValue === '0') {
    //   event.preventDefault();
    // }
  };

  const [selectedGiftIndex, setselectedGiftIndex] = useState(null);
  const [selectedGiftId, setselectedGiftId] = useState(null);
  const [selectedGiftData, setSelectedGiftData] = useState(null);

  const dialogControlGift = useRef();

  // coupon?
  const matchingObjectsarraygift = allGift.find(
    (gift) => gift._id === selectedGiftId
  );

  const matchingObjectsgift = {
    ...matchingObjectsarraygift,
  };
  // coupon?

  console.log("MatchingObjectGift________", matchingObjectsgift);

  const openModalGift = () => {
    if (dialogControlGift.current) {
      dialogControlGift.current.showModal();
    }
  };

  const handleDialogGiftClose = () => {
    dialogControlGift.current.close();
  };

  function handleClickCoupon() {
    GetGiftAPI();
    openModalGift();
  }

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const dateInput = document.getElementById("startDateInput");
    if (dateInput) {
      dateInput.min = today;
    }
  }, []);

  // Calculate today's date
  const today = new Date().toISOString().split("T")[0];
  // const today = new Date().toISOString().split("T")[0];
  console.log("today", today); // Check if the format is YYYY-MM-DD

  useEffect(() => {
    if (selectedOption === "voucher") {
      clearVoucherStates();
    } else if (selectedOption === "discount" || selectedOption === "cash") {
      clearAllGiftStates();
    }
  }, [selectedOption]);

  const clearVoucherStates = () => {
    setSelectedUsageThreshold("");
    setSelectedPromotionMethod("");
    setMinimumOrderValue("");
    setLowestDiscount("");
    setLow("");
    setHigh("");
  };

  const clearAllGiftStates = () => {
    setAllGift([]);
  };

  // const areAllFieldsFilled = () => {
  //   // List of required fields
  //   const requiredFields = [
  //     selectedOption,
  //     name,
  //     quantity,
  //     selectedScope,
  //     selectedUsageThreshold === "noThreshold" || !!minimumOrderValue,
  //     (selectedPromotionMethod === "fixedAmount" && !!lowestDiscount) ||
  //     (selectedPromotionMethod === "fixedDiscount" && !!lowestDiscount) ||
  //     (selectedPromotionMethod === "randomDiscount" && !!low && !!high),
  //     selectedvalidityPeriod !== "dateSelect" || !!startDate,
  //     selectedvalidityPeriod !== "dateSelect" || !!endDate,
  //     selectedvalidityPeriod !== "daysFromActivation" || !!daysFromActivation,
  //     selectedRecevingcondition !== "redeemPoints" || !!redeemPoints,
  //     selectedRecevingcondition !== "cash" || !!cash,
  //     selectedLimited !== "limit" || !!redemptionsLimit
  //   ];

  //   // Check if all required fields have a value
  //   const allFieldsFilled = requiredFields.every((field) => !!field);

  //   return allFieldsFilled;
  // };

  function dateToMilliseconds(date) {
    return date.getTime();
  }

  // const areAllFieldsFilled = () => {
  //   // List of required fields
  //   let requiredFields = [
  //     selectedOption,
  //     name,
  //     quantity,
  //     selectedScope,
  //     selectedvalidityPeriod !== "dateSelect" || !!startDate,
  //     selectedvalidityPeriod !== "dateSelect" || !!endDate,
  //     selectedvalidityPeriod !== "daysFromActivation" || !!daysFromActivation,
  //     selectedRecevingcondition !== "redeemPoints" || !!redeemPoints,
  //     selectedRecevingcondition !== "cash" || !!cash,
  //     selectedLimited !== "limit" || !!redemptionsLimit,
  //   ];

  //   // Check Promotion Method and Usage threshold only if selectedOption is not "voucher"
  //   if (selectedOption !== "voucher") {
  //     requiredFields = [
  //       ...requiredFields,
  //       selectedUsageThreshold === "noThreshold" || !!minimumOrderValue,
  //       (selectedPromotionMethod === "fixedAmount" && !!lowestDiscount) ||
  //         (selectedPromotionMethod === "fixedDiscount" && !!lowestDiscount) ||
  //         (selectedPromotionMethod === "randomDiscount" && !!low && !!high),
  //     ];
  //   }

  //   // Check if all required fields have a value
  //   const allFieldsFilled = requiredFields.every((field) => !!field);

  //   return allFieldsFilled;
  // };
  const areAllFieldsFilled = () => {
    let requiredFields = [
      selectedOption,
      name,
      quantity,
      selectedScope,
      (selectedvalidityPeriod !== "dateSelect" && !startDate) || !!startDate,
      (selectedvalidityPeriod !== "dateSelect" && !endDate) || !!endDate,
      selectedvalidityPeriod !== "daysFromActivation" || !!daysFromActivation,
      selectedRecevingcondition !== "redeemPoints" || !!redeemPoints,
      selectedRecevingcondition !== "cash" || !!cash,
      selectedLimited !== "limit" || !!redemptionsLimit,
    ];
    if (selectedOption !== "voucher") {
      requiredFields = [
        ...requiredFields,
        selectedUsageThreshold === "noThreshold" || !!minimumOrderValue,
        (selectedPromotionMethod === "fixedAmount" && !!lowestDiscount) ||
          (selectedPromotionMethod === "fixedDiscount" && !!lowestDiscount) ||
          (selectedPromotionMethod === "randomDiscount" && !!low && !!high),
      ];
    }
    const allFieldsFilled = requiredFields.every((field) => !!field);

    return allFieldsFilled;
  };

  const handleCurrencyChange = (event) => {
    setSelectedCurrency(event.target.value);
  };

  // Find the selected currency object
  const selectedCurrencyObj = currencies.find(
    (currency) => currency.code === selectedCurrency
  );

  console.log("Type:--", selectedOption);
  console.log("Scope:--", selectedScope);
  // Function to handle radio button for selection type

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  console.log("minimumOrderValue:->", minimumOrderValue);
  console.log("SelectedUsageThreshold", selectedUsageThreshold);

  // Function to handle radio button for selection Usage threshold
  const handleRadioChangeThreshold = (e) => {
    setSelectedUsageThreshold(e.target.value);
  };

  // const handleMinimumOrderValueChange = (value) => {
  //   // setMinimumOrderValue(value);
  //   const enteredValue = value;
  //   if (!enteredValue || (enteredValue >= 0 && enteredValue <= 1000000)) {
  //     setMinimumOrderValue(value);
  //   }
  // };

  const handleMinimumOrderValueChange = (value) => {
    const enteredValue = value;
    const regex = /^\d+(\.\d{0,2})?$/;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 1000000 && regex.test(enteredValue))
    ) {
      setMinimumOrderValue(value);
    }
  };

  // const handleMinimumOrderValueChange = (value) => {
  //   const enteredValue = value;
  //   if (enteredValue === '' || (enteredValue === '0') || (enteredValue > 0 && enteredValue <= 1000000)) {
  //     setMinimumOrderValue(value);
  //   }
  // };

  // Function to handle radio button for selection Usage Promotion Method
  const handleRadioChangePromotionMethod = (e) => {
    setSelectedPromotionMethod(e.target.value);
  };

  // Define your input change handlers for "fixedAmount" and "randomDiscount" values
  const handleLowestDiscountChange = (event) => {
    const enteredValue = event.target.value;
    // if (!enteredValue || (enteredValue >= 0 && enteredValue <= 1000000)) {
    //   setLowestDiscount(event.target.value);
    // }
    const regex = /^\d+(\.\d{0,2})?$/;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 1000000 && regex.test(enteredValue))
    ) {
      setLowestDiscount(event.target.value);
    }
  };

  // const handleLowChange = (event) => {
  //   setLow(event.target.value);
  // };

  // const handleHighChange = (event) => {
  //   setHigh(event.target.value);
  // };

  const handleLowChange = (e) => {
    // const newLow = parseInt(e.target.value, 10);
    const newLow = e.target.value.toString();
    // setLow(newLow);
    const enteredValue = newLow;
    const regex = /^\d+(\.\d{0,2})?$/;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 10000000 && regex.test(enteredValue))
    ) {
      setLow(newLow);
    }
  };

  const handleHighChange = (e) => {
    // const newHigh = parseInt(e.target.value, 10);
    const newHigh = e.target.value.toString();
    // setHigh(newHigh);
    const enteredValue = newHigh;
    const regex = /^\d+(\.\d{0,2})?$/;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 10000000 && regex.test(enteredValue))
    ) {
      setHigh(newHigh);
    }
  };
  // const handleLowChange = (e) => {
  //   const newLow = parseInt(e.target.value, 10);
  //   if (newLow <= high) {
  //     // If the newLow is less than or equal to the value in "high", update the state
  //     setLow(newLow);
  //   } else {
  //     // If the newLow is greater than the value in "high", show an error message using Swal
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Error',
  //       text: 'Minimum amount cannot be greater than Maximum amount',
  //     });

  //     setLow(""); // Clear the low input field
  //   }
  // };

  // const handleHighChange = (e) => {
  //   const newHigh = parseInt(e.target.value, 10);
  //   if (newHigh >= low) {
  //     // If the newHigh is greater than or equal to the value in "low", update the state
  //     setHigh(newHigh);
  //   } else {
  //     // If the newHigh is less than the value in "low", show an error message using Swal
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Error',
  //       text: 'Maximum amount cannot be less than Minimum amount',
  //     });

  //     setHigh(""); // Clear the high input field
  //   }
  // };

  // Function to handle radio button for selection Usage validity Period
  const handleRadioChangevalidityPeriod = (e) => {
    setSelectedvalidityPeriod(e.target.value);
  };

  // Define your input change handlers for start date and end date
  // const handleStartDateChange = (event) => {
  //   setStartDate(event.target.value);
  //   setEndDate("");
  // };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setEndDate("");
  };

  // const handleStartDateChange = (event) => {
  //   const selectedDate = event.target.value;
  //   const today = new Date().toISOString().split("T")[0];

  //   // Check if the selectedDate is before today
  //   if (selectedDate < today) {
  //     // If the selected date is in the past, set the start date to today
  //     setStartDate(today);
  //     setEndDate("");
  //     // You can also show a message or perform any other action to notify the user
  //     console.log("Please select a date starting from today.");
  //   } else {
  //     // If the selected date is valid, update the start date
  //     setStartDate(selectedDate);
  //     setEndDate("");
  //   }
  // };

  // const handleEndDateChange = (event) => {
  //   setEndDate(event.target.value);
  // };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  // const handleEndDateChange = (event) => {
  //   const selectedDate = event.target.value;
  //   // const today = startDate

  //   // Check if the selectedDate is before today
  //   if (selectedDate < startDate) {
  //     // If the selected date is in the past, set the start date to today
  //     setEndDate(startDate);
  //     // You can also show a message or perform any other action to notify the user
  //     console.log("Please select a date starting from today.");
  //   } else {
  //     // If the selected date is valid, update the start date
  //     // setStartDate(selectedDate);
  //     setEndDate(selectedDate);
  //     // setEndDate("");
  //   }
  // };

  //   const handleStartDateChange = (event) => {
  //   const selectedStartDate = new Date(event.target.value);
  //   setStartDate(selectedStartDate);
  // };

  // const handleEndDateChange = (event) => {
  //   const selectedEndDate = new Date(event.target.value);
  //   setEndDate(selectedEndDate);
  // };

  const handleDaysFromActivationChange = (event) => {
    const enteredValue = event.target.value;
    const regex = /^\d+(\\d{0,2})?$/;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 1000000 && regex.test(enteredValue))
    ) {
      setDaysFromActivation(event.target.value);
    }
  };

  // Function to handle radio button for selection Usage Receving condition
  const handleRadioChangeRecevingcondition = (e) => {
    setSelectedRecevingcondition(e.target.value);
  };

  // Define your input change handler for redeem points and cash
  const handleRedeemPointsChange = (event) => {
    // setRedeemPoints(event.target.value);
    // const enteredValue = event.target.value;
    // const regex = /^\d+(\\d{0,2})?$/;
    // if (
    //   enteredValue === "" ||
    //   enteredValue === 0 ||
    //   (enteredValue > 0 && enteredValue <= 10000000) && regex.test(enteredValue)
    // ) {
    //   setRedeemPoints(enteredValue);
    // }
    const regex = /^\d+(\.\d{0,2})?$/;
    const enteredValue = event.target.value;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 10000000 && regex.test(enteredValue))
    ) {
      setRedeemPoints(enteredValue);
    }
  };

  const handleCashChange = (event) => {
    // setCash(event.target.value);
    const regex = /^\d+(\.\d{0,2})?$/;
    const enteredValue = event.target.value;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 10000000 && regex.test(enteredValue))
    ) {
      setCash(enteredValue);
    }
  };

  // Function to handle radio button for selection Usage Limited
  const handleRadioLimited = (e) => {
    setSelectedLimited(e.target.value);
  };

  // Define your input change handler for redemptions limit
  const handleRedemptionsLimitChange = (event) => {
    // setRedemptionsLimit(event.target.value);
    const enteredValue = event.target.value;
    const regex = /^\d+(\\d{0,2})?$/;
    if (
      enteredValue === "" ||
      enteredValue === 0 ||
      (enteredValue > 0 && enteredValue <= 1000000 && regex.test(enteredValue))
    ) {
      setRedemptionsLimit(enteredValue);
    }
  };

  // Define your input change handler for quantity
  // const handleQuantityChange = (event) => {
  //   setQuantity(event.target.value);
  // };

  const handleQuantityChange = (event) => {
    // const enteredValue = event.target.value;
    // if (enteredValue === '' || enteredValue === 0 || (enteredValue > 0 && enteredValue <= 1000000)) {
    //   setQuantity(enteredValue);
    // }
    const enteredValue = event.target.value;
    const regex = /^\d+(\.\d{0,2})?$/; // Change the number inside {0,2} to set the allowed decimal places

    if (
      enteredValue === "" ||
      enteredValue === "0" ||
      (enteredValue > 0 && enteredValue <= 1000000 && regex.test(enteredValue))
    ) {
      setQuantity(enteredValue);
    }
  };

  // Define your input change handler for name
  // const handleNameChange = (event) => {
  //   setName(event.target.value);

  // };

  const MAX_WORD_LIMIT = 10; // Change this to your desired maximum word limit
  const MAX_CHARACTER_LIMIT = 35; // Change this to your desired maximum character limit

  const handleNameChange = (event) => {
    const inputValue = event.target.value;

    // Count words and characters of the input value
    const words = inputValue.trim().split(/\s+/);
    const characters = inputValue.trim().length;

    if (words.length <= MAX_WORD_LIMIT && characters <= MAX_CHARACTER_LIMIT) {
      setName(inputValue);
    } else {
      // Display an error message for exceeding limits
      console.error("Maximum 200 words and 2000 characters allowed.");
      // You might want to handle the exceeding limit case as per your requirements
      // For instance, prevent the input or take other appropriate actions
    }
  };

  // Function to handle radio button for selection Usage Limited
  const handleRadioScope = (e) => {
    setSelectedScope(e.target.value);
  };

  const handleSubmit = () => {
    // const parsedLow = parseFloat(low);
    // const parsedHigh = parseFloat(high);

    // // Perform the validation with parsed values
    // if (parsedLow > parsedHigh) {
    //   // Show an error message using Swal
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Error',
    //     text: 'Maximum amount cannot be less than Minimum amount',
    //   });
    //   return; // Exit the function to prevent form submission
    // }

    // Check if a validity period is selected and validate accordingly
    if (selectedvalidityPeriod === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please select a validity period.",
      });
      return; // Exit the function to prevent form submission
    }

    // if (selectedPromotionMethod === "randomDiscount") {
    //   // Ensure that low and high are parsed as numbers
    //   const parsedLow = parseFloat(low);
    //   const parsedHigh = parseFloat(high);

    //   // Perform the validation with parsed values
    //   if (parsedLow > parsedHigh || parsedLow === parsedHigh) {
    //     // Show an error message using Swal
    //     Swal.fire({
    //       icon: "error",
    //       title: "Error",
    //       text: "Maximum amount cannot be less than Minimum amount",
    //     });
    //     return; // Exit the function to prevent form submission
    //   }
    // }

    if (selectedPromotionMethod === "randomDiscount") {
      // Ensure that low and high are parsed as numbers
      const parsedLow = parseFloat(low);
      const parsedHigh = parseFloat(high);

      // Perform the validation with parsed values
      if (parsedLow > parsedHigh) {
        // Show an error message when parsedLow is greater than parsedHigh
        Swal.fire({
          icon: "error",
          title: content["Error"],
          text: content["Maximum amount cannot be less than Minimum amount"],
        });
        return; // Exit the function to prevent form submission
      } else if (parsedLow === parsedHigh) {
        // Show a different error message when parsedLow is equal to parsedHigh
        Swal.fire({
          icon: "error",
          title: content["Error"],
          text: content["Maximum amount cannot be the same as Minimum amount"],
        });
        return; // Exit the function to prevent form submission
      }
    }

    const promotionMethod = {};
    if (selectedPromotionMethod === "fixedAmount") {
      promotionMethod.fixedAmount = lowestDiscount;
    } else if (selectedPromotionMethod == "fixedDiscount") {
      promotionMethod.fixedDiscount = lowestDiscount;
    } else if (selectedPromotionMethod === "randomDiscount") {
      promotionMethod.randomDiscount = { low, high };
    }

    function dateToMilliseconds(date) {
      return date.getTime();
    }

    const state = "enable";
    // Convert startDate and endDate to milliseconds
    let startDateMillis, endDateMillis;
    if (selectedvalidityPeriod === "dateSelect") {
      startDateMillis = dateToMilliseconds(new Date(startDate));
      endDateMillis = dateToMilliseconds(new Date(endDate));
    }

    // Prepare the request body based on selections
    const expiryDate = {};
    if (selectedvalidityPeriod === "permanent") {
      expiryDate.permanent = "true";
    } else if (selectedvalidityPeriod === "dateSelect") {
      expiryDate.startDate = startDateMillis;
      expiryDate.endDate = endDateMillis;
    } else if (selectedvalidityPeriod === "daysFromActivation") {
      expiryDate.daysFromActivation = parseInt(daysFromActivation, 10);
    }

    // Prepare the request body based on selections
    // const expiryDate = {};
    // if (selectedvalidityPeriod === "permanent") {
    //   expiryDate.permanent = true;
    // } else if (selectedvalidityPeriod === "dateSelect") {
    //   expiryDate.startDate = startDate;
    //   expiryDate.endDate = endDate;
    // } else if (selectedvalidityPeriod === "daysFromActivation") {
    //   expiryDate.daysFromActivation = parseInt(daysFromActivation, 10);
    // }
    // const expiryDate = {};
    // if (selectedvalidityPeriod === "permanent") {
    //   expiryDate.permanent = true;
    // } else if (selectedvalidityPeriod === "dateSelect") {
    //   const startDateMillis = startDate.getTime();
    //   const endDateMillis = endDate.getTime();

    //   expiryDate.startDate = startDateMillis;
    //   expiryDate.endDate = endDateMillis;
    // } else if (selectedvalidityPeriod === "daysFromActivation") {
    //   expiryDate.daysFromActivation = parseInt(daysFromActivation, 10);
    // }

    // Prepare the request body based on selections
    const receivingCondition = {};
    if (selectedRecevingcondition === "free") {
      receivingCondition.free = "true";
    } else if (selectedRecevingcondition === "redeemPoints") {
      receivingCondition.redeemPoints = parseInt(redeemPoints, 10);
    } else if (selectedRecevingcondition === "cash") {
      receivingCondition.cash = cash;
    }

    // Prepare the request body based on selections
    const limitPerPerson = {};
    if (selectedLimited === "unlimited") {
      limitPerPerson.unlimited = "true";
    } else if (selectedLimited === "limit") {
      limitPerPerson.limit = parseInt(redemptionsLimit, 10);
    }

    // Prepare the scopeOfUse based on selectedOption
    // let scopeOfUse = {};

    // if (selectedOption === "cash" && selectedPromotionMethod) {
    //   // If selectedOption is "cash" and there's a discount
    //   scopeOfUse = {
    //     [selectedScope]: "true",
    //   };
    // }

    // if (
    //   (selectedOption === "cash" || selectedOption === "discount") &&
    //   selectedPromotionMethod
    // ) {
    //   // If selectedOption is "cash" or "discount" and there's a discount
    //   scopeOfUse = {
    //     [selectedScope]: "true",
    //   };
    // }

    let scopeOfUse = {};
    if (
      (selectedOption === "cash" ||
        selectedOption === "discount" ||
        selectedOption === "voucher") &&
      selectedPromotionMethod
    ) {
      // If selectedOption is "cash" or "discount" and there's a discount
      scopeOfUse = {
        [selectedScope]: "true",
      };
    }

    // Prepare the request body based on selections
    const requestBody = {
      coupon: {
        name: name, // Add the selected coupon name here
        type: selectedOption,
        quantity: quantity, // Add the selected quantity here
        expiryDate,
        // gift: selectedGiftData,
        promotionMethod,
        usageThreshold: {
          [selectedUsageThreshold]:
            selectedUsageThreshold === "minimumOrderValue"
              ? minimumOrderValue
              : "true",
        },
        receivedCondition: receivingCondition,
        limitPerPerson: limitPerPerson,

        // scopeOfUse: scopeOfUse,
        scopeOfUse: (scopeOfUse = {
          [selectedScope]: "true",
        }),
        // state: {
        //   state:"enable"
        // },

        // scopeOfUse: {
        //   [selectedScope]: "true",
        // },

        // scopeOfUse:
        //   selectedOption === "voucher" ? { [selectedScope]: "true" } : {},

        currency: selectedCurrency,
        state: "enable",
        //   scopeOfUse: {
        //     "singleMonthMembership": "true"
        // }
      },
    };

    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;

    const encryptbody = { data: encryptedData };

    if (AccessTokenValid()) {
      axios
      .post(API_ENDPOINT + "coupon/createCoupon", encryptbody, config)
      .then((response) => {
        const result = response.data.result;

        if (result === 420) {
          // Show Swal alert for unauthorized error
          Swal.fire({
            icon: 'error',
            title: content["Unauthorized"],
            text: content["You are not authorized to perform this action!"],
            confirmButtonText: content["OK"],
          });
          // Optionally, you can handle other logic for unauthorized case here
        } else {
          // Handle the API response here
          console.log(response.data); // You can access response data using response.data

          // Show a success message using Swal
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Coupon created successfully!",
          }).then((result) => {
            if (result.isConfirmed) {
              // Redirect to the desired location after the alert is closed
              // Make sure to import the necessary function for navigation
              navigate("/dashboard/coupon");
            }
          });
        }
      })
        .catch((error) => {
          // Handle errors here
          console.error(error);

          // Show an error message using Swal
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while creating the coupon.",
          });
        });
    } else {
      CheckedAccessTokenValid();
    }
  };

  const navigate = useNavigate();
  return (
    <>
      <NavBar />

      <br />
      <div>
        <span>
          <FontAwesomeIcon icon={faChevronLeft} size="xl" />
        </span>
        &nbsp;
        <span
          style={{ fontSize: "20px", cursor: "pointer" }}
          onClick={() => navigate("/dashboard/coupon")}
        >
          {content["Back to previous"]}
        </span>
      </div>
      <br />
      <div class="CouponContainer">
        <div class="div1">{content["Coupan Information"]}</div>
        <div class="div2">
          {selectedOption === "cash" && (
            /* UI for Cash selected */
            <div className="mainBoxCoupanSelect ">
              <div>
                <ul>
                  <li style={{ backgroundColor: "rgb(214 183 71)" }}>
                    <h1 style={{ fontSize: "37px", color: "#fff" }}>
                      {selectedCurrencyObj.symbol}0
                    </h1>
                  </li>
                  <li>
                    <div>
                      <div>
                        <h3 style={{ fontSize: "18px" }}>
                          {content["Coupon name"]}
                        </h3>
                      </div>
                      <div>
                        <p
                          style={{
                            fontSize: "larger",
                            fontWeight: "bold",
                            color: "gray",
                          }}
                        >
                          {content["Valid period of use"]}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}

          {selectedOption === "discount" && (
            /* UI for Discount selected */
            <div className="mainBoxCoupanSelect">
              <div>
                <ul>
                  <li style={{ backgroundColor: "rgb(255 87 120)" }}>
                    <h1 style={{ fontSize: "37px", color: "#fff" }}>0%</h1>
                  </li>
                  <li>
                    <div>
                      <div>
                        <h3 style={{ fontSize: "18px" }}>
                          {" "}
                          {content["Coupon name"]}
                        </h3>
                      </div>
                      <div>
                        <p
                          style={{
                            fontSize: "larger",
                            fontWeight: "bold",
                            color: "gray",
                          }}
                        >
                          {content["Valid period of use"]}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}

          {selectedOption === "voucher" && (
            /* UI for voucher selected */
            <div className="mainBoxGiftSelect ">
              <div>
                <ul>
                  <li
                    style={{
                      backgroundColor: "rgb(117 165 231)",
                      display: "block",
                    }}
                  >
                    <div>
                      <h2
                        style={{
                          color: "#fff",
                          marginLeft: "33px",
                        }}
                      >
                        {content["Voucher name"]}
                      </h2>
                    </div>
                    <div>
                      <p
                        style={{
                          fontSize: "larger",
                          fontWeight: "bold",
                          color: "rgb(255, 255, 255)",
                          marginLeft: "33px",
                        }}
                      >
                        {content["Valid period of use"]}
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div class="div3">
          <span style={{ color: "black" }}> &#x2022; </span>&nbsp;
          {content["Type"]}
        </div>
        <div class="div4">
          <span style={{ color: "black" }}> &#x2022; </span>&nbsp;
          {content["Coupon name"]}
        </div>

        <div class="div5">
          <span>
            <input
              type="radio"
              value="cash"
              checked={selectedOption === "cash"}
              onChange={handleRadioChange}
            />
            {content["Cash"]}
          </span>
        </div>
        <div class="div6">
          <span>
            <input
              type="radio"
              value="discount"
              checked={selectedOption === "discount"}
              onChange={handleRadioChange}
            />
            {content["Discount"]}
          </span>
        </div>
        <div class="div7">
          <span>
            <input
              type="radio"
              value="voucher"
              checked={selectedOption === "voucher"}
              onChange={handleRadioChange}
            />
            {content["Voucher"]}
          </span>
        </div>

        <div class="div8">
          <input
            type="text"
            className="coupon_input_name"
            value={name}
            onChange={handleNameChange}
          />
        </div>

        <div class="div9">
          <span style={{ color: "black" }}> &#x2022; </span>
          {content["Quantity"]}
        </div>
        <div class="div10">
          <input
            type="text"
            className="coupon_input_name"
            value={quantity}
            onChange={handleQuantityChange}
            onKeyPress={onKeyPress}
            placeholder={content["Quantity"]}
          />
        </div>

        <div class="div101">
          {content["Currency"]}&nbsp;&nbsp;
          <select
            id="currencyDropdown"
            onChange={handleCurrencyChange}
            value={selectedCurrency}
          >
            {/* <option value="">Select...</option> */}
            {currencies.map((currency) => (
              <option key={currency.code} value={currency.code}>
                {currency.code}
              </option>
            ))}
          </select>
        </div>

        {selectedOption === "cash" && (
          <>
            <div class="div11">
              <span style={{ color: "black" }}> &#x2022; </span>
              {content["Usage threshold"]}
            </div>
            <div class="div12">
              <span>
                <input
                  type="radio"
                  value="noThreshold"
                  checked={selectedUsageThreshold === "noThreshold"}
                  onChange={handleRadioChangeThreshold}
                />
                {content["No threshold"]}
              </span>
            </div>

            <div class="div13">
              <span>
                <input
                  type="radio"
                  value="minimumOrderValue"
                  checked={selectedUsageThreshold === "minimumOrderValue"}
                  onChange={handleRadioChangeThreshold}
                />
                {content["Minimum amount"]}&nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  placeholder={content["Minimum amount"]}
                  value={
                    selectedUsageThreshold === "minimumOrderValue"
                      ? minimumOrderValue
                      : ""
                  }
                  disabled={selectedUsageThreshold !== "minimumOrderValue"}
                  onChange={(e) =>
                    handleMinimumOrderValueChange(e.target.value)
                  }
                  // onKeyPress={onKeyPress}
                />
                &nbsp;
                {selectedCurrency}
              </span>
            </div>

            <div class="div14">
              <span style={{ color: "black" }}> &#x2022; </span>
              {content["Promotion method"]}
            </div>

            <div class="div15">
              <span>
                <input
                  type="radio"
                  value="fixedAmount"
                  checked={selectedPromotionMethod === "fixedAmount"}
                  onChange={handleRadioChangePromotionMethod}
                />
                {content["Fixed amount"]}&nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  placeholder={content["Fixed amount"]}
                  value={lowestDiscount}
                  onChange={handleLowestDiscountChange}
                  disabled={selectedPromotionMethod !== "fixedAmount"}
                  // onKeyPress={onKeyPress}
                />
                &nbsp;
                {selectedCurrency}
              </span>
            </div>

            <div class="div16">
              <span>
                <input
                  type="radio"
                  value="randomDiscount"
                  checked={selectedPromotionMethod === "randomDiscount"}
                  onChange={handleRadioChangePromotionMethod}
                />
                {content["Random amount"]}&nbsp;
                <input
                  type="text"
                  placeholder={content["Minimum amount"]}
                  className="coupon_input_quantity"
                  value={low}
                  onChange={handleLowChange}
                  disabled={selectedPromotionMethod !== "randomDiscount"}
                  // onKeyPress={onKeyPress}
                />
                &nbsp;
                {selectedCurrency}
              </span>
            </div>
            <div class="div17">
              <span>
                <input
                  type="text"
                  placeholder={content["Maximum amount"]}
                  className="coupon_input_quantity"
                  value={high}
                  onChange={handleHighChange}
                  disabled={selectedPromotionMethod !== "randomDiscount"}
                  // onKeyPress={onKeyPress}
                />
                &nbsp;
                {selectedCurrency}
              </span>
            </div>
            <div class="div18">
              <span style={{ color: "black" }}> &#x2022; </span>{" "}
              {content["Validity period"]}
            </div>
            <div class="div19">
              <span>
                <input
                  type="radio"
                  value="permanent"
                  checked={selectedvalidityPeriod === "permanent"}
                  onChange={handleRadioChangevalidityPeriod}
                />
                {content["Effective permanently"]}
              </span>
            </div>
            <div class="div20">
              <span>
                <input
                  type="radio"
                  value="dateSelect"
                  checked={selectedvalidityPeriod === "dateSelect"}
                  onChange={handleRadioChangevalidityPeriod}
                />
              </span>
              {/* <input
                type="date"
                id="startDateInput"
                className="coupon_input_quantity"
                placeholder="start date"
                value={startDate}
                onChange={handleStartDateChange}
                disabled={selectedvalidityPeriod !== "dateSelect"}
                max="2080-12-31"
              /> */}
              <DatePicker
                className="coupon_input_start"
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                minDate={new Date()} // Restrict to future dates
                maxDate={new Date("2080-12-1")} // Set maximum date
                dateFormat="dd-MM-yyyy"
                disabled={selectedvalidityPeriod !== "dateSelect"}
                placeholderText={content["Start Date"]}
              />
              &nbsp; - &nbsp;
              {/* <input
                type="date"
                className="coupon_input_quantity"
                placeholder="end date"
                value={endDate}
                onChange={handleEndDateChange}
                // disabled={selectedvalidityPeriod !== "dateSelect"}
                // disabled={!startDate}
                disabled={!startDate || selectedvalidityPeriod !== "dateSelect"}
                min={startDate}
              /> */}
              <DatePicker
                className="coupon_input_end"
                selected={endDate}
                onChange={handleEndDateChange}
                selectsStart
                minDate={startDate} // Restrict to future dates
                maxDate={new Date("2080-12-1")} // Set maximum date
                dateFormat="dd-MM-yyyy"
                disabled={!startDate || selectedvalidityPeriod !== "dateSelect"}
                placeholderText={content["End Date"]}
              />
            </div>
            <div class="div21">
              <span>
                <input
                  type="radio"
                  value="daysFromActivation"
                  checked={selectedvalidityPeriod === "daysFromActivation"}
                  onChange={handleRadioChangevalidityPeriod}
                />
                {content["From the day you receive the coupon"]} &nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  value={daysFromActivation}
                  onChange={handleDaysFromActivationChange}
                  disabled={selectedvalidityPeriod !== "daysFromActivation"}
                  onKeyPress={onKeyPress}
                />
                &nbsp;{content["Available within days"]}
              </span>
            </div>

            <div class="div22">
              <span style={{ color: "black" }}> &#x2022; </span>
              {content["Receving condition"]}
            </div>
            <div class="div23">
              <span>
                <input
                  type="radio"
                  value="free"
                  checked={selectedRecevingcondition === "free"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Get it for free"]}
              </span>
            </div>

            <div class="div24">
              <span>
                <input
                  type="radio"
                  value="redeemPoints"
                  checked={selectedRecevingcondition === "redeemPoints"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Redeem points"]}&nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  value={redeemPoints}
                  onChange={handleRedeemPointsChange}
                  disabled={selectedRecevingcondition !== "redeemPoints"}
                  onKeyPress={onKeyPress}
                />
                &nbsp;
                {content["Integral"]}
                {/* {selectedCurrency} */}
                {/* <select
                  id="currencyDropdown"
                  onChange={handleCurrencyChange}
                  value={selectedCurrency}
                >
                  <option value="">Select...</option>
                  {currencies.map((currency) => (
                    <option key={currency.code} value={currency.code}>
                      {currency.code}
                    </option>
                  ))}
                </select> */}
              </span>
            </div>
            <div class="div25">
              <span>
                <input
                  type="radio"
                  value="cash"
                  checked={selectedRecevingcondition === "cash"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Cash"]}&nbsp;
                <input
                  type="text"
                  value={cash}
                  onChange={handleCashChange}
                  className="coupon_input_quantity"
                  disabled={selectedRecevingcondition !== "cash"}
                  onKeyPress={onKeyPress}
                />
                &nbsp;
                {selectedCurrency}
              </span>
            </div>
          </>
        )}

        {selectedOption === "discount" && (
          <>
            <div class="div11">
              <span style={{ color: "black" }}> &#x2022; </span>
              {content["Usage threshold"]}
            </div>
            <div class="div12">
              <span>
                <input
                  type="radio"
                  value="noThreshold"
                  checked={selectedUsageThreshold === "noThreshold"}
                  onChange={handleRadioChangeThreshold}
                />
                {content["No threshold"]}
              </span>
            </div>

            <div class="div13">
              <span>
                <input
                  type="radio"
                  value="minimumOrderValue"
                  checked={selectedUsageThreshold === "minimumOrderValue"}
                  onChange={handleRadioChangeThreshold}
                />
                {content["Minimum amount"]}&nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  placeholder={content["Minimum amount"]}
                  value={
                    selectedUsageThreshold === "minimumOrderValue"
                      ? minimumOrderValue
                      : ""
                  }
                  disabled={selectedUsageThreshold !== "minimumOrderValue"}
                  onChange={(e) =>
                    handleMinimumOrderValueChange(e.target.value)
                  }
                  // onKeyPress={onKeyPress}
                />
                &nbsp; {selectedCurrency}
              </span>
            </div>

            <div class="div14">
              <span style={{ color: "black" }}> &#x2022; </span>
              {content["Promotion method"]}
            </div>

            <div class="div15">
              <span>
                <input
                  type="radio"
                  value="fixedDiscount"
                  checked={selectedPromotionMethod === "fixedDiscount"}
                  onChange={handleRadioChangePromotionMethod}
                />
                {content["Fixed discount"]}&nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  placeholder={content["Fixed discount"]}
                  value={lowestDiscount}
                  onChange={handleLowestDiscountChange}
                  disabled={selectedPromotionMethod !== "fixedDiscount"}
                  // onKeyPress={onKeyPress}
                />
                &nbsp; %
              </span>
            </div>

            <div class="div16">
              <span>
                <input
                  type="radio"
                  value="randomDiscount"
                  checked={selectedPromotionMethod === "randomDiscount"}
                  onChange={handleRadioChangePromotionMethod}
                />
                {content["Random discount"]}&nbsp;
                <input
                  type="text"
                  placeholder={content["Minimum discount"]}
                  className="coupon_input_quantity"
                  value={low}
                  onChange={handleLowChange}
                  disabled={selectedPromotionMethod !== "randomDiscount"}
                  onKeyPress={onKeyPress}
                />
                &nbsp; %
              </span>
            </div>
            <div class="div17">
              <span>
                <input
                  type="number"
                  placeholder={content["Maximum discount"]}
                  className="coupon_input_quantity"
                  value={high}
                  onChange={handleHighChange}
                  disabled={selectedPromotionMethod !== "randomDiscount"}
                  onKeyPress={onKeyPress}
                />
                &nbsp; %
              </span>
            </div>
            <div class="div18">
              <span style={{ color: "black" }}> &#x2022; </span>{" "}
              {content["Validity period"]}
            </div>
            <div class="div19">
              <span>
                <input
                  type="radio"
                  value="permanent"
                  checked={selectedvalidityPeriod === "permanent"}
                  onChange={handleRadioChangevalidityPeriod}
                />
                {content["Effective permanently"]}
              </span>
            </div>
            <div class="div20">
              <span>
                <input
                  type="radio"
                  value="dateSelect"
                  checked={selectedvalidityPeriod === "dateSelect"}
                  onChange={handleRadioChangevalidityPeriod}
                />
              </span>
              {/* <input
                type="date"
                id="startDateInput"
                className="coupon_input_quantity"
                placeholder="start date"
                value={startDate}
                onChange={handleStartDateChange}
                disabled={selectedvalidityPeriod !== "dateSelect"}
                min={today}
              /> */}
              <DatePicker
                className="coupon_input_start"
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                minDate={new Date()} // Restrict to future dates
                maxDate={new Date("2080-12-1")} // Set maximum date
                dateFormat="dd-MM-yyyy"
                disabled={selectedvalidityPeriod !== "dateSelect"}
                placeholderText={content["Start Date"]}
              />
              &nbsp; - &nbsp;
              <DatePicker
                className="coupon_input_end"
                selected={endDate}
                onChange={handleEndDateChange}
                selectsStart
                minDate={startDate} // Restrict to future dates
                maxDate={new Date("2080-12-1")} // Set maximum date
                dateFormat="dd-MM-yyyy"
                disabled={!startDate || selectedvalidityPeriod !== "dateSelect"}
                placeholderText={content["End Date"]}
              />
            </div>
            <div class="div21">
              <span>
                <input
                  type="radio"
                  value="daysFromActivation"
                  checked={selectedvalidityPeriod === "daysFromActivation"}
                  onChange={handleRadioChangevalidityPeriod}
                />
                {content["From the day you receive the coupon"]} &nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  value={daysFromActivation}
                  onChange={handleDaysFromActivationChange}
                  disabled={selectedvalidityPeriod !== "daysFromActivation"}
                  onKeyPress={onKeyPress}
                />
                &nbsp;{content["Available within days"]}
              </span>
            </div>

            <div class="div22">
              <span style={{ color: "black" }}> &#x2022; </span>
              {content["Receving condition"]}
            </div>
            <div class="div23">
              <span>
                <input
                  type="radio"
                  value="free"
                  checked={selectedRecevingcondition === "free"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Get it for free"]}
              </span>
            </div>

            <div class="div24">
              <span>
                <input
                  type="radio"
                  value="redeemPoints"
                  checked={selectedRecevingcondition === "redeemPoints"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Redeem points"]}&nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  value={redeemPoints}
                  onChange={handleRedeemPointsChange}
                  disabled={selectedRecevingcondition !== "redeemPoints"}
                  onKeyPress={onKeyPress}
                />
                &nbsp; {content["Integral"]}
              </span>
            </div>
            <div class="div25">
              <span>
                <input
                  type="radio"
                  value="cash"
                  checked={selectedRecevingcondition === "cash"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Cash"]}&nbsp;
                <input
                  type="number"
                  value={cash}
                  onChange={handleCashChange}
                  className="coupon_input_quantity"
                  disabled={selectedRecevingcondition !== "cash"}
                  onKeyPress={onKeyPress}
                />
                &nbsp; {selectedCurrency}
              </span>
            </div>
          </>
        )}

        {selectedOption === "voucher" && (
          <>
            <div class="div11">
              <span style={{ color: "black" }}> &#x2022; </span>{" "}
              {content["Redeem items"]}
            </div>
            <div class="div12">
              {/* <div
                style={{ textDecoration: "underline", color: "#4da5fe" }}
                onClick={() => handleClickCoupon()}
              >
                {content["Select Gift"]}
              </div> */}

              <div style={{ display: "flex" }}>
                {/* //preview */}

                {selectedGiftData ? (
                  <div className="mainBoxCoupanSelect">
                    <div className="mainBoxCoupanSelect">
                      <div>
                        <div className="Featured">
                          <div className="GiftImage">
                            <img
                              src={selectedGiftData.image}
                              alt="Featured img"
                              class="img-fluid"
                            />
                          </div>

                          <div className="Featured_text">
                            <h3>{selectedGiftData.name.en}</h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ margin: "14px" }}>
                      ({content["Stock"]}:{selectedGiftData.count})
                    </div>
                  </div>
                ) : null}

                <div>
                  {/* <button
                className="actbtn1"
                style={{ margin: "0px 0px 0px 21px" }}
                onClick={() => handleClickCoupon()}
              >
                + Gift
              </button> */}
                  <div
                    style={{
                      textDecoration: "underline",
                      color: "#4da5fe",
                      cursor: "pointer",
                    }}
                    onClick={() => handleClickCoupon()}
                    className="disabled-button"
                  >
                    {content["Select Gift"]}
                  </div>
                </div>
              </div>
            </div>

            <div class="div18">
              <span style={{ color: "black" }}> &#x2022; </span>{" "}
              {content["Validity period"]}
            </div>
            <div class="div19">
              <span>
                <input
                  type="radio"
                  value="permanent"
                  checked={selectedvalidityPeriod === "permanent"}
                  onChange={handleRadioChangevalidityPeriod}
                />
                {content["Effective permanently"]}
              </span>
            </div>
            <div class="div20">
              <span>
                <input
                  type="radio"
                  value="dateSelect"
                  checked={selectedvalidityPeriod === "dateSelect"}
                  onChange={handleRadioChangevalidityPeriod}
                />
              </span>
              <DatePicker
                className="coupon_input_start"
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                minDate={new Date()} // Restrict to future dates
                maxDate={new Date("2080-12-1")} // Set maximum date
                dateFormat="dd-MM-yyyy"
                disabled={selectedvalidityPeriod !== "dateSelect"}
                placeholderText={content["Start Date"]}
              />
              - &nbsp;
              <DatePicker
                className="coupon_input_end"
                selected={endDate}
                onChange={handleEndDateChange}
                selectsStart
                minDate={startDate} // Restrict to future dates
                maxDate={new Date("2080-12-1")} // Set maximum date
                dateFormat="dd-MM-yyyy"
                disabled={!startDate || selectedvalidityPeriod !== "dateSelect"}
                placeholderText={content["End Date"]}
              />
            </div>
            <div class="div21">
              <span>
                <input
                  type="radio"
                  value="daysFromActivation"
                  checked={selectedvalidityPeriod === "daysFromActivation"}
                  onChange={handleRadioChangevalidityPeriod}
                />
                {content["From the day you receive the coupon"]} &nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  value={daysFromActivation}
                  onChange={handleDaysFromActivationChange}
                  disabled={selectedvalidityPeriod !== "daysFromActivation"}
                  onKeyPress={onKeyPress}
                />
                &nbsp;{content["Available within days"]}
              </span>
            </div>

            <div class="div22">
              <span style={{ color: "black" }}> &#x2022; </span>
              {content["Receving condition"]}
            </div>
            <div class="div23">
              <span>
                <input
                  type="radio"
                  value="free"
                  checked={selectedRecevingcondition === "free"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Get it for free"]}
              </span>
            </div>

            <div class="div24">
              <span>
                <input
                  type="radio"
                  value="redeemPoints"
                  checked={selectedRecevingcondition === "redeemPoints"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Redeem points"]}&nbsp;
                <input
                  type="text"
                  className="coupon_input_quantity"
                  value={redeemPoints}
                  onChange={handleRedeemPointsChange}
                  disabled={selectedRecevingcondition !== "redeemPoints"}
                  onKeyPress={onKeyPress}
                />
                &nbsp;
                {content["Integral"]}
                {/* {selectedCurrency} */}
              </span>
            </div>
            <div class="div25">
              <span>
                <input
                  type="radio"
                  value="cash"
                  checked={selectedRecevingcondition === "cash"}
                  onChange={handleRadioChangeRecevingcondition}
                />
                {content["Cash"]}&nbsp;
                <input
                  type="number"
                  value={cash}
                  onChange={handleCashChange}
                  className="coupon_input_quantity"
                  disabled={selectedRecevingcondition !== "cash"}
                  onKeyPress={onKeyPress}
                />
                &nbsp;
                {selectedCurrency}
              </span>
            </div>
          </>
        )}

        <div class="div26">
          <span style={{ color: "black" }}> &#x2022; </span>
          {content["Limited to per person"]}
        </div>
        <div class="div27">
          <span>
            <input
              type="radio"
              value="unlimited"
              checked={selectedLimited === "unlimited"}
              onChange={handleRadioLimited}
            />
            {content["Unlimited"]}
          </span>
        </div>
        <div class="div28">
          <span>
            <input
              type="radio"
              value="limit"
              checked={selectedLimited === "limit"}
              onChange={handleRadioLimited}
            />
            {content["Limited"]}&nbsp;
            <input
              type="text"
              value={redemptionsLimit}
              onChange={handleRedemptionsLimitChange}
              disabled={selectedLimited !== "limit"}
              className="coupon_input_quantity"
              onKeyPress={onKeyPress}
            />
          </span>
        </div>
        <div class="div29">
          <span style={{ color: "black" }}> &#x2022; </span>
          {content["Scope of use"]}
        </div>
        <div class="div30">
          <span>
            <input
              type="radio"
              value="allAvailable"
              checked={selectedScope === "allAvailable"}
              onChange={handleRadioScope}
            />
            {content["All Available"]}
          </span>
        </div>
        <div class="div31">
          <span>
            <input
              type="radio"
              value="anualMembership"
              checked={selectedScope === "anualMembership"}
              onChange={handleRadioScope}
            />
            {content["Annual membership recharge"]}
          </span>
        </div>
        <div class="div32">
          <span>
            <input
              type="radio"
              value="semiAnnualMembership"
              checked={selectedScope === "semiAnnualMembership"}
              onChange={handleRadioScope}
            />
            {content["Semi-Annual membership recharge"]}
          </span>
        </div>
        <div class="div33">
          <span>
            <input
              type="radio"
              value="singleMonthMembership"
              checked={selectedScope === "singleMonthMembership"}
              onChange={handleRadioScope}
            />
            {content["Single-Month membership available"]}
          </span>
        </div>
        <div class="div34">
          <span>
            <input
              type="radio"
              value="notAnnualMembership"
              checked={selectedScope === "notAnnualMembership"}
              onChange={handleRadioScope}
            />
            {content["Not Annual membership"]}
          </span>
        </div>

        <div class="div35">
          <span>
            <input
              type="radio"
              value="notSemiAnnualMembership"
              checked={selectedScope === "notSemiAnnualMembership"}
              onChange={handleRadioScope}
            />
            {content["Not Semi-Annual membership"]}
          </span>
        </div>

        <div class="div36">
          <span>
            <input
              type="radio"
              value="notsingleMonthMembership"
              checked={selectedScope === "notsingleMonthMembership"}
              onChange={handleRadioScope}
            />
            {content["Not Single-Month membership"]}
          </span>
        </div>
        <div class="div37">
          <button
            class="btnCoupon"
            onClick={handleSubmit}
            // disabled={!areAllFieldsFilled() || selectedGiftData === null}
            disabled={
              selectedOption === "voucher"
                // ? !areAllFieldsFilled() || selectedGiftData === null
                ? !areAllFieldsFilled()
                : !areAllFieldsFilled()
            }
          >
            {content["Save"]}
          </button>
        </div>
      </div>

      {/* //Gift */}

      <dialog
        ref={dialogControlGift}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "73%",
          background: "#fff",
          zIndex: 9999,
          border: "none",
        }}
      >
        <div>
          <div className="header-app">
            <div>{content["Select Gift"]}</div>
            <div>
              <ImCross
                style={{ cursor: "pointer" }}
                className="btnClose"
                width={100}
                onClick={handleDialogGiftClose}
              />
            </div>
          </div>

          <div className="body-app">
            <div className="mainBox-card-container-app">
              {allGift.map((coupon, index) => (
                <div key={coupon._id}>
                  <label
                    htmlFor={`coupon${coupon._id}`}
                    className="label-container-act"
                  >
                    {/* <div>
                      test
                    </div> */}
                    <div className="Featured">
                      <div className="Featured_img">
                        <img
                          src={coupon.image}
                          alt="Featured img"
                          class="img-fluid"
                        />
                      </div>

                      <div className="Featured_text">
                        <h3>{coupon.name.en}</h3>
                        <div style={{ color: "darkgray" }}>
                          {content["Stock"]}:{coupon.count}
                        </div>
                      </div>
                    </div>

                    <input
                      type="checkbox"
                      checked={index === selectedGiftIndex}
                      onChange={() => {
                        setselectedGiftIndex(index);
                        setselectedGiftId(coupon._id);
                      }}
                      name="coupons"
                      id={`coupon${coupon._id}`}
                      className="hidden-checkbox-act"
                    />
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="footer-app" style={{ bottom: "8px" }}>
            <div
              className="btn-save"
              onClick={() => {
                handleDialogGiftClose();
                setSelectedGiftData(matchingObjectsgift);
                console.log("Selected Coupon ID:", selectedGiftId);
              }}
            >
              {content["OK"]}
            </div>
            <button className="btn-cancel" onClick={handleDialogGiftClose}>
              {content["Cancel"]}
            </button>
          </div>
        </div>
      </dialog>
    </>
  );
}
