import "./login.css";
import wfImage from "../../img/wf.png";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiBaseUrls, apiKeys } from "../../config";
import En from "../../translation/en.json";
import Ch from "../../translation/chinese.json";
import { encryptedaes, config } from "../../utils/encrypt/encryptUtils";

let OTP;
let apipath = apiBaseUrls.development;

export default function Login(props) {
  // console.log("re-render triggered");

  const storedLanguage = localStorage.getItem("language");
  const initialLanguage = storedLanguage ? storedLanguage : "English";

  const [language, setLanguage] = useState(initialLanguage);
  const [content, setContent] = useState({});

  useEffect(() => {
    if (language === "English") {
      setContent(En.english);
    } else if (language === "中国人") {
      setContent(Ch.chinese);
    }

    localStorage.setItem("language", language);
  }, [language]);

  const [currentCard, setCurrentCard] = useState({
    component: "MainLogin",
    data: "noData",
  });
  const handleCardChange = (card) => {
    setCurrentCard(card);
  };
  return (
    <div>
      <div className="bg">
        <div className="lang">
          <select
            value={language}
            onChange={(e) => {
              setLanguage(e.target.value);
            }}
          >
            <option>English</option>
            <option>中国人</option>
          </select>
        </div>
        <Logo content={content} />

        {currentCard.component === "MainLogin" ? (
          <MainLogin setParentState={handleCardChange} content={content} />
        ) : currentCard.component === "ResetEmail" ? (
          <ResetEmail setParentState={handleCardChange} content={content} />
        ) : currentCard.component === "EnterOtp" ? (
          <EnterOtp
            setParentState={handleCardChange}
            content={content}
            emailData={currentCard.data}
          />
        ) : (
          <UpdatePassword
            setParentState={handleCardChange}
            content={content}
            emailData={currentCard.data}
          />
        )}
      </div>
    </div>
  );
}

function Logo({ setParentState, content }) {
  return (
    <div>
      <div className="container-logo">
        <img src={wfImage} className="logoimage" alt="" />
        <h1>{content["WhaleFit Content Management Platform"]}</h1>
      </div>
    </div>
  );
}

function MainLogin({ setParentState, content }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isButtonDisabled, setIsButtonDisabled] = useState(true); // State for button disable/enable



  const navigate = useNavigate();

  useEffect(() => {
    // Enable the button only if both email and password fields are not empty
    setIsButtonDisabled(!(email.trim() !== '' && password.trim() !== ''));
  }, [email, password]);




  const [errorMessage, setErrorMessage] = useState(""); // New state for error message
  const handleLogin = () => {
    const apiUrl = apipath + "user/login"; // Replace with your API endpoint

    // Request body
    const requestBody = {
      userId: email,
      password: password,
    };

    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;
    const encryptbody = { data: encryptedData };
    axios
      .post(apiUrl, encryptbody, config)
      .then((response) => {
        const { result, accessToken , validityInSecs } = response.data;
        console.log("");
        if (result === 100) {
          localStorage.removeItem("getCourses"); //Remove "getCourses" from local storage
          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("PointOption", "redemption");
          const loginUser = accessToken;
          const dataArray = loginUser ? loginUser.split("#") : []; // Add a check for loginUser
          const Currentemail = dataArray[0] || null; // Set Currentemail to null if dataArray is empty
          localStorage.setItem("UserId", Currentemail);
          
          // Set the access token in a cookie with expiry time
          const expiryTime = validityInSecs - Date.now(); // Calculate expiry time
          const expiryInSeconds = Math.floor(expiryTime / 1000); // Convert to seconds
          document.cookie = 'accessToken=' + accessToken + '; max-age=' + expiryInSeconds + '; path=/';
          // Redirect to the dashboard
          navigate("/dashboard/home");
        } 
        else {
          // Failed login
          setErrorMessage("Invalid email or password"); // Update the error message state
        }
      })
      .catch((error) => {
        console.log("Error:", error);
        setErrorMessage("Server Not Running"); // Update the error message state
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <div>
      <div className="loginform">
        <div className="forms">
          <h1>{content["Log in"]}</h1>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyPress={handleKeyPress} // Add this line
            className="logininput"
            placeholder={content["Enter your email"]}
          />

          <div className="loginmain">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress} // Add this line
              className="logininput2"
              placeholder={content["Password"]}
            />
            <div
              className="forgetpasstext"
              type="submit"
              onClick={() => {
                setParentState({ component: "ResetEmail", data: "noData" });
              }}
            >
              {content["Reset"]}
            </div>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <span className="spanone">{content[""]}</span>
          <br />
          <button className="bgone" onClick={handleLogin} disabled={isButtonDisabled}>
            {content["Login"]}
          </button>
        </div>
      </div>
    </div>
  );
}

function ResetEmail({ setParentState, content }) {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // New state variable for error message
  const emailRef = useRef();

  const requestBody = {
    userId: email,
  };

  const { encryptedData, SessionToken } = encryptedaes(requestBody);
  config.headers["Session-Token"] = SessionToken;
  //encryption data
  const encryptbody = { data: encryptedData };

  const handleClick = async () => {
    try {
      // Make API request to fetch OTP
      const response = await axios.post(
        apipath + "user/otpVerification",
        encryptbody,
        config
      );

      const otp = response.data.otp;

      if (otp) {
        OTP = otp;

        setParentState({ component: "EnterOtp", data: email });
      } else {
        setErrorMessage("User does not exist");
      }
    } catch (error) {
      console.error("Error fetching OTP:", error);
      // Handle error condition
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrorMessage(""); // Reset error message when email changes
  };

  const isValidEmail = (email) => {
    // Email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div>
      <div className="loginform">
        <div className="formstwo">
          <h1>{content["Reset Password"]}</h1>
          <input
            ref={emailRef}
            type="text"
            className="logininput3"
            placeholder={content["Email"]}
            id="email-input"
            value={email}
            onChange={handleEmailChange}
          />
          <br />

          <button
            className="bgtwo"
            disabled={!isValidEmail(email)} // Disable the button if email is invalid
            onClick={handleClick}
          >
            {content["Next Step"]}
          </button>
          <div
            className="spantwo"
            onClick={() =>
              setParentState({ component: "MainLogin", data: "noData" })
            }
          >
            {content["Login"]}
          </div>
          {errorMessage && (
            <div className="error-message" style={{ marginTop: "23px" }}>
              {errorMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function EnterOtp({ setParentState, content, emailData }) {
  const inputRef = useRef();
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [timer, setTimer] = useState(60); // Timer state set to 30 seconds

  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setMessage(""); // Hide the message when the timer reaches 0
    }

    return () => {
      clearInterval(intervalId); // Clean up the interval
    };
  }, [timer]);

  const handleClick = async (email) => {
    // Request body
    const requestBody = {
      userId: email, // Pass the email as a parameter
    };

    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;
    const encryptbody = { data: encryptedData };
    try {
      // Make API request to fetch OTP
      const response = await axios.post(
        apipath + "user/otpVerification",
        // {
        //   userId: email, // Pass the email as a parameter
        // }
        encryptbody,
        config
      );

      const otp = response.data.otp; // Access the OTP value correctly
      OTP = otp;
      // Clear error message if OTP request was successful
      setErrorMessage("");
      setMessage("Verification code sent");
      setTimer(30); // Reset the timer to 30 seconds
    } catch (error) {
      console.error("Error fetching OTP:", error);
      // Handle error condition
    }
  };

  const handleVerifyClick = () => {
    const enteredOTP = inputRef.current.value;
    // const storedOTP = localStorage.getItem('otp');
    const storedOTP = OTP;
    console.log("after otp...", storedOTP);

    if (enteredOTP === storedOTP.toString()) {
      setParentState({ component: "UpdatePassword", data: emailData });
    } else {
      setErrorMessage("Incorrect verification code");
    }
  };

  return (
    <div>
      <div className="loginform">
        <div className="formsthree">
          <h1>{content["Reset Password"]}</h1>
          <span style={{ marginLeft: "8px" }}>
            {content["Send the verification code to"]}&nbsp;
            <span className="spanthree">
              <strong>{emailData}</strong>
            </span>
          </span>
          <div className="loginmain">
            <input
              ref={inputRef}
              type="password"
              className="logininput2"
              placeholder={content["Verification Code"]}
              maxLength={6}
            />
            {timer > 0 ? (
              <div className="forgetpasstext timer">{timer}s</div>
            ) : (
              <div className="forgetpasstext" type="submit">
                <a
                  onClick={() => handleClick(emailData)}
                  style={{ cursor: "pointer" }}
                >
                  {content["Resend"]}
                </a>
              </div>
            )}
          </div>
          {errorMessage && !message && (
            <div
              className="errorMessage error-message"
              style={{ marginTop: "23px" }}
            >
              {errorMessage}
            </div>
          )}
          {message && !errorMessage && (
            <div
              className="resendMessage success-message"
              style={{ marginTop: "23px" }}
            >
              {message}
            </div>
          )}
          <br />
          <button className="bgthree" onClick={handleVerifyClick}>
            {content["Verify"]}
          </button>
          <div
            className="spantwo"
            onClick={() =>
              setParentState({ component: "MainLogin", data: "noData" })
            }
          >
            {content["Back to Login"]}
          </div>
        </div>
      </div>
    </div>
  );
}

function UpdatePassword({ setParentState, content, emailData }) {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [inputColor, setInputColor] = useState("");

  useEffect(() => {
    if (password.length >= 6 && repeatPassword.length >= 6) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [password, repeatPassword]);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRepeatPasswordChange = (event) => {
    setRepeatPassword(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Request body
    const requestBody = {
      userId: emailData,
      password: password,
    };

    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;
    const encryptbody = { data: encryptedData };

    if (password !== repeatPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        apipath + "user/password/reset",
        encryptbody,
        config
      );

      if (response.data.result === 100) {
        setSuccessMessage("Password changed successfully");
        setErrorMessage("");
        setIsInputDisabled(true); // Disable input boxes
        setInputColor("#acacac"); // Change input box color
      } else {
        console.log("Error updating password");
      }
    } catch (error) {
      console.log("An error occurred", error);
    }
  };

  return (
    <div>
      <div className="loginform">
        <div className="forms">
          <h1>{content["Reset Password"]}</h1>
          <span className="success-message">
            {/* {content["Verification successful, please reset your password."]} */}
          </span>

          <input
            type="password"
            className="logininputfour"
            placeholder={content["Password"]}
            value={password}
            onChange={handlePasswordChange}
            disabled={isInputDisabled}
            style={{ backgroundColor: inputColor }}
          />
          <input
            type="password"
            className="logininputfour"
            placeholder={content["Repeat Password"]}
            value={repeatPassword}
            onChange={handleRepeatPasswordChange}
            disabled={isInputDisabled}
            style={{ backgroundColor: inputColor }}
          />
          {!successMessage && password.length < 6 && (
            <span style={{ color: "red" }}>
              {content["Password length at least 6 characters"]}
            </span>
          )}
          {!successMessage &&
            password &&
            repeatPassword &&
            password !== repeatPassword && (
              <div className="errorMessage error-message">
                {content["Passwords do not match"]}
              </div>
            )}
          {successMessage && (
            <div className="successMessage success-message">
              {successMessage}
            </div>
          )}

          <br />
          {successMessage ? null : (
            <button
              className="bgone"
              onClick={handleFormSubmit}
              disabled={isButtonDisabled}
            >
              {content["Finish"]}
            </button>
          )}
          <div
            className="forgetpassworddiv"
            onClick={() =>
              setParentState({ component: "MainLogin", data: "noData" })
            }
          >
            {content["Back to Login"]}
          </div>
        </div>
      </div>
    </div>
  );
}
