import React, { useRef, useEffect } from "react";
import "./MultiItemSlider.css";

function convertMillisecondsToMinutesAndSeconds(milliseconds) {
  // Calculate total seconds
  var totalSeconds = Math.floor(milliseconds / 1000);

  // Calculate minutes and remaining seconds
  var minutes = Math.floor(totalSeconds / 60);
  var seconds = totalSeconds % 60;

  return {
    minutes: minutes,
    seconds: seconds,
  };
}

const MultiItemSlider = ({ isFullscreen, galleryVideos }) => {
  const sliderRef = useRef(null);

  const leftScroll = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy(-200, 0);
    }
  };

  const rightScroll = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy(200, 0);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "ArrowRight") {
      rightScroll();
    } else if (event.key === "ArrowLeft") {
      leftScroll();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className={`dots-container ${isFullscreen ? "cover" : "not-cover"}`}>
      <div
        className={`scroll-images ${isFullscreen ? "cover" : "not-cover"}`}
        ref={sliderRef}
      >
        {galleryVideos.map((slide, index) => {
          let title = slide.title; // Initialize with the default title
          try {
            const titleObject = JSON.parse(slide.title);
            title = titleObject["en-US"] || title; // Use the localized title if available
          } catch (error) {
            // Parsing error, use default title
          }

          // Other calculations
          const duration = slide.duration;
          const time = convertMillisecondsToMinutesAndSeconds(duration);
          const totaltiming = time.minutes + time.seconds;

          const isVIPMember = slide.channel_ids.includes(9);

          return (
            <div key={slide.id} className="child">
              <img className="child-img" src={slide.snapshot_url} alt={title} />
              {isVIPMember && (
                <div
                  style={{
                    position: "absolute",
                    top: "14px",
                    marginLeft: "214px",
                    color: "black",
                    padding: "7px 10px 4px 10px",
                    borderRadius: "4px",
                    backgroundImage:
                      "linear-gradient(to right top, #ffffff, #f2e8f2, #efceda, #eab7b6, #daa48b)",
                  }}
                >
                  VIP
                </div>
              )}
              <div
                style={{
                  textAlign: "left",
                  marginLeft: "52px",
                  marginTop: "3px",
                  color: "white",
                }}
              >
                {title}
              </div>
              <div
                style={{
                  color: "white",
                  position: "absolute",
                  top: "115px",
                  marginLeft: "217px",
                }}
              >
                {time.minutes}:{time.seconds}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MultiItemSlider;
