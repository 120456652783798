import React, { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import NavBar from "../other/navBar";
import { useOutletContext } from "react-router-dom";
import { Switch } from "antd";
import { useNavigate } from "react-router-dom";
import { apiBaseUrls, apiKeys } from "../../../config";
import { encryptedaes  } from "../../../utils/encrypt/encryptUtils";
import { CheckedAccessTokenValid , AccessTokenValid } from "../../../utils/LogAuth";

const API_ENDPOINT = apiBaseUrls.development;

const tableHeaderStyle = {
  backgroundColor: "rgb(234 235 244)",
  fontWeight: "bold",
  padding: "8px",
};

export default function Coupon() {
  const { content } = useOutletContext();
  const [allCoupan, setAllCoupan] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [allActivities, setallActivities] = useState([])
  const [activities, setActivities] = useState([]);

console.log("act-->",activities);
  const navigate = useNavigate();

  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };
  

  useEffect(() => {
    // Check if GetCoupon data is in local storage
    const getCouponData = localStorage.getItem("GetCoupon");
    const Activities = localStorage.getItem("Activities");
    const ActivitiesData = JSON.parse(Activities);
    setallActivities(ActivitiesData)

    if(AccessTokenValid()){
      axios
      .get(API_ENDPOINT + "coupon/getCoupons", config)
      .then((response) => {
        const coupons = response.data.coupons;
        setAllCoupan(coupons); // Update the galleryImages state
        localStorage.setItem("GetCoupon", JSON.stringify(coupons));
      })
      .catch((error) => {
        console.error("Failed to fetch Coupons:", error);
      })
      // .finally(() => {
      //   setLoading(false);
      // });
      .finally(() => {
        // After the first API call completes, make the second API call
        axios
          .get(API_ENDPOINT + "activity/getActivities", config)
          .then((response) => {
            setActivities(response.data.activities);
          })
          .catch((error) => {
            console.error("Error fetching activities data:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      });
    }else{
      CheckedAccessTokenValid()
    }



  }, []);

  console.log("Activities",allActivities);

  // Function to handle the toggle state change and make API call
  // const handleCouponStateChange = (couponId, newState) => {
  //   // Determine the API endpoint based on the newState
  //   const apiEndpoint =
  //     newState === "enable"
  //       ? `${API_ENDPOINT}coupon/editCoupon`
  //       : `${API_ENDPOINT}coupon/editCoupon`;

  //   // Define the request body
  //   const requestBody = {
  //     coupon: {
  //       _id: couponId,
  //       state: newState,
  //     },
  //   };

  //   // Make the API call
  //   axios
  //     .post(apiEndpoint, requestBody, config)
  //     .then(() => {
  //       // Update the state of the coupon in your local data
  //       const updatedCoupons = allCoupan.map((coupon) => {
  //         if (coupon._id === couponId) {
  //           return { ...coupon, state: newState };
  //         }
  //         return coupon;
  //       });
  //       setAllCoupan(updatedCoupons);
  //       localStorage.setItem("GetCoupon", JSON.stringify(updatedCoupons));
  //     })
  //     .catch((error) => {
  //       console.error("Error updating coupon state:", error);
  //     });
  // };


  // Inside the handleCouponStateChange function
const handleCouponStateChange = (couponId, newState) => {
  // Check if the couponId is used in allActivities
  const isCouponUsedInActivities = allActivities.some(
    (activity) => activity.coupon && activity.coupon._id === couponId
  );

  // Determine if the toggle button should be disabled
  const isToggleButtonDisabled = isCouponUsedInActivities;

  if (isToggleButtonDisabled) {
    // Optionally, display a message to the user indicating that the coupon is in use
    Swal.fire({
      title: 'Coupon in Use',
      text: 'This coupon is associated with some activities. You cannot disable it.',
      icon: 'error',
    });
  } else {
    // Determine the API endpoint based on the newState
    const apiEndpoint =
      newState === 'enable'
        ? `${API_ENDPOINT}coupon/editCoupon`
        : `${API_ENDPOINT}coupon/editCoupon`;

    // Define the request body
    const requestBody = {
      coupon: {
        _id: couponId,
        state: newState,
      },
    };

    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;
     
    const encryptbody = {data:encryptedData}

    if(AccessTokenValid()){
    // Make the API call
    axios
      .post(apiEndpoint, encryptbody, config)
      .then((response) => {
        const result = response.data.result;

        if (result === 420) {
          // Show Swal alert for unauthorized error
          Swal.fire({
            icon: 'error',
            title: content["Unauthorized"],
            text: content["You are not authorized to perform this action!"],
            confirmButtonText: content["OK"],
          });
          // Optionally, you can handle other logic for unauthorized case here
        } else {
          // Update the state of the coupon in your local data
          const updatedCoupons = allCoupan.map((coupon) => {
            if (coupon._id === couponId) {
              return { ...coupon, state: newState };
            }
            return coupon;
          });
          setAllCoupan(updatedCoupons);
          localStorage.setItem('GetCoupon', JSON.stringify(updatedCoupons));
        }
      })
    .catch((error) => {
      console.error('Error updating coupon state:', error);
    });
    }else{
      CheckedAccessTokenValid()
    }



  }
};



  // const filteredActivities = allCoupan.filter((Coupan) =>
  //   Coupan.name.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  const filteredActivities = allCoupan.filter(
    (Coupan) =>
      Coupan.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      Coupan.type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  function handleEditCoupon(couponId) {
    navigate("/dashboard/coupon/editcoupon");
    localStorage.setItem("currentCouponId", couponId);
  }

  const handleDelete = (couponId) => {
    Swal.fire({
      title: "Are you sure you want to delete this coupon?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Send a POST request to your API route to delete the coupon
        const requestBody = { _id: couponId }
        const { encryptedData, SessionToken } = encryptedaes(requestBody);
        config.headers["Session-Token"] = SessionToken;
         
        const encryptbody = {data:encryptedData}


        if(AccessTokenValid()){
          axios
          .post(`${API_ENDPOINT}coupon/deleteCoupon`, encryptbody, config)
          .then((response) => {
            const result = response.data.result;
    
            if (result === 420) {
              // Show Swal alert for unauthorized error
              Swal.fire({
                icon: 'error',
                title: content["Unauthorized"],
                text: content["You are not authorized to perform this action!"],
                confirmButtonText: content["OK"],
              });
              // Optionally, you can handle other logic for unauthorized case here
            } else {
              // Remove the deleted coupon from the state
              const updatedCoupons = allCoupan.filter(
                (coupon) => coupon._id !== couponId
              );
              setAllCoupan(updatedCoupons);
              localStorage.setItem("GetCoupon", JSON.stringify(updatedCoupons));
              Swal.fire("Deleted!", "The coupon has been deleted.", "success");
            }
          })
          .catch((error) => {
            console.error("Error deleting coupon:", error);
            Swal.fire("Error", "Failed to delete the coupon.", "error");
          });
        }else{
          CheckedAccessTokenValid()
        }

  
      }
    });
  };

  function getCurrencySymbol(currencyCode) {
    // Define a map of currency codes to symbols
    const currencySymbols = {
      EUR: "€",
      GBP: "£",
      // Add more currency codes and symbols as needed
    };

    // Return the symbol if it exists in the map, or return the currency code itself
    return currencySymbols[currencyCode] || currencyCode;
  }

  function formatDate(milliseconds) {
    const date = new Date(milliseconds);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  return (
    <>
      <NavBar />
      <div
        className="mainpage"
        style={{
          textAlign: "right",
          marginBottom: "10px",
          marginLeft: "10px",
          marginRight: "-6px",
        }}
      >
        <div>
          <div style={{ fontSize: "3.3vh", fontWeight: 500 }}>
            {content["Coupons"]}({allCoupan.length})
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <button
            onClick={() => navigate("/dashboard/coupon/addcoupon")}
            className="btn_add"
          >
            {content["Add new coupon"]}
          </button>
          &nbsp;&nbsp;
          <div className="search-container">
            <form>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder={content["Enter Title/Keyword"]}
                style={{ padding: "8px", marginRight: "10px" }}
                name="search"
              />
              <button type="submit">
                <i class="fa fa-search"></i>
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* //list */}
      {loading ? (
        <div className="homeloader"></div>
      ) : (
        <div style={{ overflow: "auto", height: "82vh" }}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <br />
            <thead>
              <tr>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                ></th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Coupon name"]}
                  {/* Coupon name */}
                </th>

                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {/* Classification */}
                  {content["Classification"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Used"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Quantity"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["State"]}
                </th>

                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Operate"]}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredActivities.map((coupan) => (
                <tr key={coupan._id}>
                  <td className="tdvaluecoursetitle_new">
                    {coupan && coupan.expiryDate && (
                      <div className="mainBoxCoupan">
                        {coupan.type === "cash" && (
                          <div className="mainBoxCoupanSelect">
                            <div>
                              <ul>
                                <li
                                  style={{ backgroundColor: "rgb(214 183 71)" }}
                                >
                                  <h1
                                    style={{ fontSize: "37px", color: "#fff" }}
                                  >
                                    {/* ¥
                                    {coupan.promotionMethod &&
                                    coupan.promotionMethod.fixedAmount
                                      ? coupan.promotionMethod.fixedAmount
                                      : "N/A"} */}

                                    {/* {coupan.promotionMethod &&
                                      coupan.promotionMethod.fixedAmount !== undefined
                                      ? `¥ ${coupan.promotionMethod.fixedAmount}`
                                      : coupan.promotionMethod &&
                                        coupan.promotionMethod.randomDiscount !== undefined
                                      ? `¥${coupan.promotionMethod.randomDiscount.low}- ¥${coupan.promotionMethod.randomDiscount.high}`
                                      : "N/A"} */}

                                    {/* {coupan.promotionMethod &&
            coupan.promotionMethod.fixedDiscount !== undefined
            ? `%${coupan.promotionMethod.fixedDiscount}`
            : coupan.promotionMethod &&
              coupan.promotionMethod.fixedAmount !== undefined
            ? `${coupan.currency}${coupan.promotionMethod.fixedAmount}`
            : coupan.promotionMethod &&
              coupan.promotionMethod.randomDiscount !== undefined
            ? `${coupan.currency}${coupan.promotionMethod.randomDiscount.low}-${coupan.currency}${coupan.promotionMethod.randomDiscount.high}`
            : "N/A"} */}

                                    {coupan.promotionMethod &&
                                    coupan.promotionMethod.fixedDiscount !==
                                      undefined
                                      ? `%${coupan.promotionMethod.fixedDiscount}`
                                      : coupan.promotionMethod &&
                                        coupan.promotionMethod.fixedAmount !==
                                          undefined
                                      ? `${getCurrencySymbol(coupan.currency)}${
                                          coupan.promotionMethod.fixedAmount
                                        }`
                                      : coupan.promotionMethod &&
                                        coupan.promotionMethod
                                          .randomDiscount !== undefined
                                      ? `${getCurrencySymbol(coupan.currency)}${
                                          coupan.promotionMethod.randomDiscount
                                            .low
                                        }-${getCurrencySymbol(
                                          coupan.currency
                                        )}${
                                          coupan.promotionMethod.randomDiscount
                                            .high
                                        }`
                                      : "N/A"}
                                  </h1>
                                </li>
                                <li>
                                  <div>
                                    <div>
                                      <h4 style={{ fontSize: "18px" }}>
                                        {coupan.scopeOfUse.anualMembership ===
                                        "true"
                                          ? content[
                                              "Annual membership recharge"
                                            ]
                                          : coupan.scopeOfUse.allAvailable ===
                                            "true"
                                          ? content["All Available"]
                                          : coupan.scopeOfUse
                                              .semiAnnualMembership === "true"
                                          ? content[
                                              "Semi-Annual membership recharge"
                                            ]
                                          : coupan.scopeOfUse
                                              .notAnnualMembership === "true"
                                          ? content["Not Annual membership"]
                                          : coupan.scopeOfUse
                                              .notSemiAnnualMembership ===
                                            "true"
                                          ? content[
                                              "Not Semi-Annual membership"
                                            ]
                                          : coupan.scopeOfUse
                                              .notsingleMonthMembership ===
                                            "true"
                                          ? content[
                                              "Not Single-Month membership"
                                            ]
                                          : coupan.scopeOfUse
                                              .singleMonthMembership === "true"
                                          ? content[
                                              "Single-Month membership available"
                                            ]
                                          : content["N/A"]}
                                      </h4>
                                    </div>
                                    <div style={{ textAlign: "center" }}>
                                      <p
                                        style={{
                                          fontSize: "larger",
                                          fontWeight: "bold",
                                          color: "gray",
                                        }}
                                      >
                                        {/* {coupan.expiryDate.startDate} -
                                        {coupan.expiryDate.endDate} */}

                                        {/* {coupan.expiryDate.permanent
                                          ? "Permanent"
                                          : coupan.expiryDate.startDate &&
                                            coupan.expiryDate.endDate
                                          ? `${coupan.expiryDate.startDate} - ${coupan.expiryDate.endDate}`
                                          : coupan.expiryDate.daysFromActivation
                                          ? `Expires in ${coupan.expiryDate.daysFromActivation} days`
                                          : "N/A"} */}

                                        {coupan.expiryDate.permanent
                                          ? content["Permanent"]
                                          : coupan.expiryDate.startDate &&
                                            coupan.expiryDate.endDate
                                          ? `${formatDate(
                                              coupan.expiryDate.startDate
                                            )} - ${formatDate(
                                              coupan.expiryDate.endDate
                                            )}`
                                          : coupan.expiryDate.daysFromActivation
                                          ? `${content["Expires in"]} ${coupan.expiryDate.daysFromActivation} ${content["Days"]}`
                                          : content["N/A"]}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                        {coupan.type === "voucher" && (
                          <div className="mainBoxGiftSelect">
                            <div>
                              <ul>
                                <li
                                  style={{
                                    backgroundColor: "rgb(117 165 231)",
                                    display: "block",
                                  }}
                                >
                                  <div>
                                    <h2
                                      style={{
                                        color: "#fff",
                                        marginLeft: "33px",
                                      }}
                                    >
                                      {coupan.type}
                                    </h2>
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        fontSize: "larger",
                                        fontWeight: "bold",
                                        color: "rgb(255, 255, 255)",
                                        marginLeft: "33px",
                                      }}
                                    >
                                      {/* {coupan.expiryDate.startDate} -{" "}
                                      {coupan.expiryDate.endDate} */}

                                      {coupan.expiryDate.permanent
                                        ? content["Permanent"]
                                        : coupan.expiryDate.startDate &&
                                          coupan.expiryDate.endDate
                                        ? `${formatDate(
                                            coupan.expiryDate.startDate
                                          )} - ${formatDate(
                                            coupan.expiryDate.endDate
                                          )}`
                                        : coupan.expiryDate.daysFromActivation
                                        ? `${content["Expires in"]} ${coupan.expiryDate.daysFromActivation} ${content["Days"]}`
                                        : content["N/A"]}
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                        {coupan.type === "discount" && (
                          <div className="mainBoxCoupanSelect">
                            <div>
                              <ul>
                                <li
                                  style={{ backgroundColor: "rgb(255 87 120)" }}
                                >
                                  <h1
                                    style={{ fontSize: "37px", color: "#fff" }}
                                  >
                                    {/* {coupan.promotionMethod &&
                                    coupan.promotionMethod.fixedAmount
                                      ? coupan.promotionMethod.fixedAmount
                                      : content["N/A"]}
                                    % */}

                                    {/* {coupan.promotionMethod &&
                                    coupan.promotionMethod.fixedDiscount !==
                                      undefined
                                      ? `${coupan.promotionMethod.fixedDiscount}%`
                                      : coupan.promotionMethod &&
                                        coupan.promotionMethod.fixedAmount !==
                                          undefined
                                      ? `${coupan.promotionMethod.fixedAmount}`
                                      : coupan.promotionMethod &&
                                        coupan.promotionMethod
                                          .randomDiscount !== undefined
                                      ? `${coupan.promotionMethod.randomDiscount.low}%-${coupan.promotionMethod.randomDiscount.high}%`
                                      : "N/A"} */}

                                    {/* {coupan.promotionMethod &&
                                    coupan.promotionMethod.fixedDiscount !==
                                      undefined
                                      ? `${coupan.promotionMethod.fixedDiscount}%`
                                      : coupan.promotionMethod &&
                                        coupan.promotionMethod.fixedAmount !==
                                          undefined
                                      ? `${getCurrencySymbol(coupan.currency)}${
                                          coupan.promotionMethod.fixedAmount
                                        }`
                                      : coupan.promotionMethod &&
                                        coupan.promotionMethod
                                          .randomDiscount !== undefined
                                      ? `${getCurrencySymbol(coupan.currency)}${
                                          coupan.promotionMethod.randomDiscount
                                            .low
                                        }-${getCurrencySymbol(
                                          coupan.currency
                                        )}${
                                          coupan.promotionMethod.randomDiscount
                                            .high
                                        }`
                                      : content["N/A"]} */}

                                    {coupan.promotionMethod &&
                                    coupan.promotionMethod.fixedDiscount !==
                                      undefined
                                      ? `${coupan.promotionMethod.fixedDiscount}%`
                                      : coupan.promotionMethod &&
                                        coupan.promotionMethod.fixedAmount !==
                                          undefined
                                      ? `${coupan.promotionMethod.fixedAmount}%`
                                      : coupan.promotionMethod &&
                                        coupan.promotionMethod
                                          .randomDiscount !== undefined
                                      ? `${coupan.promotionMethod.randomDiscount.low}%-${coupan.promotionMethod.randomDiscount.high}%`
                                      : "N/A"}
                                  </h1>
                                </li>
                                <li>
                                  <div>
                                    <div>
                                      {/* <h3 style={{ fontSize: "18px" }}>
                                        {coupan.scopeOfUse.anualMembership ===
                                        "true"
                                          ? content[
                                              "Annual membership recharge"
                                            ]
                                          : coupan.scopeOfUse.allAvailable ===
                                            "true"
                                          ? content["All Available"]
                                          : coupan.scopeOfUse
                                              .semiAnnualMembership === "true"
                                          ? content[
                                              "Semi-Annual membership recharge"
                                            ]
                                          : coupan.scopeOfUse
                                              .notAnnualMembership === "true"
                                          ? content["Not Annual membership"]
                                          : coupan.scopeOfUse
                                              .notSemiAnnualMembership ===
                                            "true"
                                          ? content[
                                              "Not Semi-Annual membership"
                                            ]
                                          : coupan.scopeOfUse
                                              .notsingleMonthMembership ===
                                            "true"
                                          ? content[
                                              "Not Single-Month membership"
                                            ]
                                          : content["N/A"]}
                                      </h3> */}
                                      <h3 style={{ fontSize: "18px" }}>
                                        {coupan.scopeOfUse.anualMembership ===
                                        "true"
                                          ? content[
                                              "Annual membership recharge"
                                            ]
                                          : coupan.scopeOfUse.allAvailable ===
                                            "true"
                                          ? content["All Available"]
                                          : coupan.scopeOfUse
                                              .semiAnnualMembership === "true"
                                          ? content[
                                              "Semi-Annual membership recharge"
                                            ]
                                          : coupan.scopeOfUse
                                              .notAnnualMembership === "true"
                                          ? content["Not Annual membership"]
                                          : coupan.scopeOfUse
                                              .notSemiAnnualMembership ===
                                            "true"
                                          ? content[
                                              "Not Semi-Annual membership"
                                            ]
                                          : coupan.scopeOfUse
                                              .notsingleMonthMembership ===
                                            "true"
                                          ? content[
                                              "Not Single-Month membership"
                                            ]
                                          : coupan.scopeOfUse
                                              .singleMonthMembership === "true"
                                          ? "Single-Month membership"
                                          : content["N/A"]}
                                      </h3>
                                    </div>
                                    <div>
                                      <p
                                        style={{
                                          fontSize: "larger",
                                          fontWeight: "bold",
                                          color: "gray",
                                        }}
                                      >
                                        {/* {coupan.expiryDate.startDate} -{" "}
                                        {coupan.expiryDate.endDate} */}
                                        {/* {coupan.expiryDate.permanent
                                          ? "Permanent"
                                          : coupan.expiryDate.startDate &&
                                            coupan.expiryDate.endDate
                                          ? `${coupan.expiryDate.startDate} - ${coupan.expiryDate.endDate}`
                                          : coupan.expiryDate.daysFromActivation
                                          ? `Expires in ${coupan.expiryDate.daysFromActivation} days`
                                          : "N/A"} */}

                                        {coupan.expiryDate.permanent
                                          ? content["Permanent"]
                                          : coupan.expiryDate.startDate &&
                                            coupan.expiryDate.endDate
                                          ? `${formatDate(
                                              coupan.expiryDate.startDate
                                            )} - ${formatDate(
                                              coupan.expiryDate.endDate
                                            )}`
                                          : coupan.expiryDate.daysFromActivation
                                          ? `${content["Expires in"]} ${coupan.expiryDate.daysFromActivation} ${content["Days"]}`
                                          : content["N/A"]}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </td>
                  <td
                    className="tdimage"
                    style={{ textAlign: "center", color: "darkgray" }}
                  >
                    {coupan.name}
                  </td>

                  <td className="tdvaluecourse" style={{ color: "darkgray" }}>
                    {coupan.type}
                  </td>

                  <td className="tdvaluecourse">
                    {/* {coupan.usageThreshold.minimumOrderValue} */}
                  </td>
                  <td className="tdvaluecourse" style={{ color: "darkgray" }}>
                    {coupan.quantity}
                  </td>
                  <td className="tdvaluecourse">
                    {/* Toggle */}
                    {/* <Switch
                      checkedChildren={content["On the shelves"]}
                      unCheckedChildren={content["Off the shelves"]}
                    /> */}

                    <Switch
                      checked={coupan.state === "enable"} // Set initial state based on coupan.state
                      onChange={(checked) =>
                        handleCouponStateChange(
                          coupan._id,
                          checked ? "enable" : "disable"
                        )
                      }
                      checkedChildren={content["On the shelves"]}
                      unCheckedChildren={content["Off the shelves"]}
                    />

                    
                  </td>
                  <td className="tdvalueOperate">
                    {/* <a
                      className="EditAct"
                      onClick={() => handleEditCoupon(coupan._id)}
                    >
                      {content["Edit"]}
                    </a>
                    <a
                      className="DeleteAct"
                      onClick={() => handleDelete(coupan._id)}
                    >
                      {content["Delete"]}
                    </a> */}


{activities && (
                      <>
                        <a
                          className={`EditAct ${
                            activities.some(
                              (poster) =>
                                poster.coupon &&
                                poster.coupon._id === coupan._id
                            )
                              ? "disabled-button"
                              : ""
                          }`}
                          onClick={() => handleEditCoupon(coupan._id)}
                        >
                          {content["Edit"]}
                        </a>
                        <a
                          className={`DeleteAct ${
                            activities.some(
                              (poster) =>
                                poster.coupon &&
                                poster.coupon._id === coupan._id
                            )
                              ? "disabled-button"
                              : ""
                          }`}
                          onClick={() => handleDelete(coupan._id)}
                        >
                          {content["Delete"]}
                        </a>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
