import { useOutletContext } from "react-router-dom";
import "./MyCarousel.css";
import logo from "../../../../img/wf_splash_screen@2x.png";
import React, { useState, useRef, useEffect } from "react";
import { ImCross } from "react-icons/im";
import Swal from "sweetalert2";
import {
  AccessTokenValid,
  CheckedAccessTokenValid,
} from "../../../../utils/LogAuth";

import Carousel, {
  slidesToShowPlugin,
  arrowsPlugin,
} from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import MultiItemSlider from "./multi-item/MultiItemSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { apiBaseUrls, apiKeys } from "../../../../config";
import axios from "axios";
import { encryptedaes  } from "../../../../utils/encrypt/encryptUtils";

import blank from "../../../../img/blank.png";

const API_ENDPOINT = apiBaseUrls.development;


const imgStyleCenterfull = {
  width: "856px",
  height: "356px",
  objectFit: "cover",
};

const imgStyleCenter = {
  // width: "490px",
  width: "420px",
  height: "200px",
  objectFit: "cover",
};

const dotStyle = {
  display: "inline-block",
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  background: "black",
  margin: "0 5px",
  cursor: "pointer",
};

const activeDotStyle = {
  ...dotStyle,
  background: "white",
};

function getTitle(titleJson) {
  let title = titleJson; // Initialize with the default title
  try {
    const titleObject = JSON.parse(titleJson);
    title = titleObject["en-US"] || title; // Use the localized title if available
  } catch (error) {
    // Parsing error, use default title
  }
  return title;
}

export default function MyCarousel({
  onClose,
  isFullscreen,
  openModal,
  slides,
  setSlides,
  setGalleryVideos,
  galleryVideos,
  setGalleryImages,
  galleryImages,
  slidePosition,
}) {
  const { language, setLanguage, content } = useOutletContext();
  const [value, setValue] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(null);
  const [selectedVideoId, setSelectedVideoId] = useState(null);

  const [buttonDisabled, setbuttonDisabled] = useState(false);

  const [targetSelect, settargetSelect] = useState("none"); //// "none1",
  const [getActivities, SetgetActivities] = useState([]); //// "none1",

  const [selectedOption, setSelectedOption] = useState("course"); // 'course' or 'activity' // "none1",

  //start adding activity selection
  const [selectedActivityIndex, setselectedActivityIndex] = useState(null);
  const [selectedActivityId, setselectedActivityId] = useState(null);
  const [selectedActivityData, setselectedActivityData] = useState(null);

  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };

  
  const matchingObjectsarrayactivity = getActivities.find(
    (activity) => activity._id === selectedActivityId
  );

  const matchingObjectsactivity = {
    ...matchingObjectsarrayactivity,
  };

  console.log("MatchingObjectActivity________", matchingObjectsactivity);

  //end adding activity selection

  //cousreFilter Start
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTypeFilter, setSelectedTypeFilter] = useState("all"); // Default to "all"

  // Extract unique course types
  const uniqueCourseTypes = [
    ...new Set(galleryVideos.map((course) => course.class_type.name)),
  ];

  const filteredCourses = galleryVideos.filter((course) => {
    const isTitleMatch = getTitle(course.title)
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    const isTypeMatch =
      selectedTypeFilter === "all" ||
      course.class_type.name === selectedTypeFilter;

    // Return true if both title and type match (or if it's "all" type)
    return isTitleMatch && isTypeMatch;
  });
  //end

  // Function to handle the click on "Choose a course" option // "none1",
  const handleChooseCourse = () => {
    setSelectedOption("course");
  };

  // Function to handle the click on "Choose an activity" option // "none1",
  const handleChooseActivity = () => {
    setSelectedOption("activity");
  };

  // Define the text color based on the selected option // "none1",
  // const courseTextColor = selectedOption === "course" ? "black" : "blue";
  // const activityTextColor = selectedOption === "activity" ? "black" : "blue";

  const courseTextColor = selectedOption === "course" ? "black" : "blue";
  const activityTextColor = selectedOption === "activity" ? "black" : "blue";
  const courseCursorStyle = selectedOption === "course" ? "default" : "pointer";
  const activityCursorStyle =
    selectedOption === "activity" ? "default" : "pointer";

  const defaultSlide = {
    id: "11",
    posterUrl: blank,
    targetType: targetSelect, //"none",
    video: {},
  };

  const postSlidesToAPI = (slidesData) => {
    // Filter out objects with id containing "default#"
    const filteredSlidesData = slidesData.filter(
      (slide) => !slide.id.includes("default#")
    );

    // Modify the key "id" to "name" for each slide object
    const modifiedSlidesData = filteredSlidesData.map((slide) => ({
      ...slide,
      name: slide.id,
    }));

    const postData = {
      images: modifiedSlidesData,
    };
    const { encryptedData, SessionToken } = encryptedaes(postData);
    config.headers["Session-Token"] = SessionToken;
    const encryptbody = {data:encryptedData}
    if(AccessTokenValid()){
      axios
      .post(API_ENDPOINT + "poster/saveBanner", encryptbody, config)
      // .then((response) => {
      //   // Handle successful response, if needed
      //   console.log("Slides data posted successfully:", response.data);
      //   // handlePrevious()
      // })
      .then((response) => {
        // Handle successful response, if needed
        const result = response.data.result;

        if (result === 420) {
          // Show Swal alert for unauthorized error
          Swal.fire({
            icon: 'error',
            title: content["Unauthorized"],
            text: content["You are not authorized to perform this action!"],
            confirmButtonText: content["OK"],
          });
        } else if (result === 100) {
          // Show Swal alert for success
          Swal.fire({
            icon: 'success',
            title: content["Banner Save!"],
            confirmButtonText: content["OK"],
          });
        } else {
          // Handle other cases if needed
        }
      })
      .catch((error) => {
        // Handle error, if needed
        console.error("Error posting slides data:", error);
      });
    }else{
      CheckedAccessTokenValid()
    }


  };

  useEffect(() => {
    const currentPositionId = localStorage.getItem("position");
    const currentPositionInt = parseInt(currentPositionId);

    if (isNaN(currentPositionInt)) {
      // currentPositionId is not a valid integer or not present, set it to 2
      handleChange(0);
    } else {
      // currentPositionId is a valid integer, use its value
      handleChange(currentPositionInt);
    }

    const storedPosterData = JSON.parse(localStorage.getItem("getPosterToDisplay"));


    if(AccessTokenValid()){
      axios
      .get(API_ENDPOINT + "poster/posterToDisplay", config)
      .then((response) => {
        const posterData = response.data;
        setSlides(posterData.images);
        localStorage.setItem("getPosterToDisplay", JSON.stringify(posterData));
      })
      .catch((error) => {
        console.error("Error fetching getPosterToDisplay data:", error);
      });     
    }else{
      CheckedAccessTokenValid()
    }




    // if (!storedActivitiesData) {
    //   ActivityToAPI();
    // }

    ActivityToAPI();

    // if(!storedActivitiesData){
    //   const mapgetActivities = storedActivitiesData.data.activities;
    //   SetgetActivities(mapgetActivities);
    // }
    


  }, []);

//   const storedActivitiesData = JSON.parse(localStorage.getItem("Activities"));
//   useEffect(() => {
//     if (storedActivitiesData) {

// console.log("localactlocalact",storedActivitiesData)
//       if (storedActivitiesData) {
//         SetgetActivities(storedActivitiesData);
//       }
//     } else {
//       // If it's not in local storage, make the API request to fetch it data.activities
//       axios
//         .get(API_ENDPOINT + "activity/getActivities" , config)
//         .then((response) => {
//           const data = response.data;
//           const activitiesData = response.data.activities;
//           if (activitiesData) {
//             SetgetActivities(data.videos.data);
//             localStorage.setItem("Activities", JSON.stringify(activitiesData));
//           }
//         })
//         .catch((error) => {
//           console.error("Error fetching course data:", error);
//         })
//     }


//   }, []);



  console.log("Updated Slides:----", slides);

  const dialogControl = useRef();
  const dialogControlVideos = useRef();

  const postersToAPI = () => {
    if(AccessTokenValid()){
      axios
      .get(API_ENDPOINT + "poster/getPosters", config)
      .then((response) => {
        const posters = response.data.images;
        const mappedImages = posters.map((poster) => ({
          id: poster._id,
          // posterUrl: poster.posterUrl.replace(/^(https?:)?\/\/[^/]+/, ""),
          // thumbnailUrl: poster.thumbnailUrl.replace(/^(https?:)?\/\/[^/]+/, ""),
          posterUrl: poster.posterUrl,
          thumbnailUrl: poster.thumbnailUrl,
        }));
        setGalleryImages(mappedImages); // Update the galleryImages state
      })
      .catch((error) => {
        console.error("Failed to fetch posters:", error);
      });
    }else{
      CheckedAccessTokenValid()
    }

  };

  //activity-int
  const ActivityToAPI = () => {
    if(AccessTokenValid()){
      axios
      .get(API_ENDPOINT + "activity/getActivities", config)
      .then((response) => {
             
        const activitiesData = response.data.activities;
        localStorage.setItem("Activities", JSON.stringify(activitiesData));
        const mapgetActivities = response.data.activities;
        console.log("Get:----", mapgetActivities);
        SetgetActivities(mapgetActivities); // Update the galleryImages state
      })
      .catch((error) => {
        console.error("Failed to fetch posters:", error);
      });
    }else{
      CheckedAccessTokenValid()
    }

  };

  const handleCheckboxChange = (index, imageId) => {
    setSelectedImageIndex(index);
    setSelectedImageId(imageId);
  };

  // video?
  const matchingObjectsarrayvideo = galleryVideos.find(
    (image) => image.id === selectedVideoId
  );

  const matchingObjectsvideo = {
    ...matchingObjectsarrayvideo,
  };

  console.log("MatchingObject________", matchingObjectsvideo);
  // video?

  const matchingObjectsarray = galleryImages.find(
    (image) => image.id === selectedImageId
  );

  const matchingObjects = {
    ...matchingObjectsarray,
    targetType: targetSelect, // "none1",
    video: {},
  };
  const resetFileInput = () => {
    const fileInput = document.getElementById("file");
    if (fileInput) {
      fileInput.value = ""; // Reset the value to clear the selected file(s)
    }
  };
  // const handleImageUpload = (event) => {
  //   setIsLoading(true);
  //   let video = "none";

  //   const file = event.target.files;
  //   const formData = new FormData();
  //   Array.from(file).forEach((item) => {
  //     formData.append("files", item);
  //   });

  //   if(AccessTokenValid()){
  //     axios
  //     .post(API_ENDPOINT + "multiPostersUpload", formData, config)
  //     .then((response) => {
  //       const posters1 = response.data.images;

  //       const mappedImages1 = posters1.map((poster) => ({
  //         id: poster._id,
  //         // name: poster.name,
  //         // posterUrl: poster.posterUrl.replace(/^(https?:)?\/\/[^/]+/, ""),
  //         // thumbnailUrl: poster.thumbnailUrl.replace(/^(https?:)?\/\/[^/]+/, ""),
  //         posterUrl: poster.posterUrl,
  //         thumbnailUrl: poster.thumbnailUrl,
  //         targetType: targetSelect, //"none",
  //         video: {},
  //       }));

  //       const currentObjectId = localStorage.getItem("currentImageId");
  //       // const currentObjectId = '64d33c77b8ad7cb7f5cc3cb0'; // Replace with your logic
  //       const updatedSlides = slides.map((slide) => {
  //         if (slide.id === currentObjectId) {
  //           // Update the video property of the slide object
  //           return {
  //             id: mappedImages1[0].id, // Set the ID
  //             // name: mappedImages1[0].name,
  //             posterUrl: mappedImages1[0].posterUrl,
  //             thumbnailUrl: mappedImages1[0].thumbnailUrl,
  //             targetType: targetSelect, //"none",
  //             video: {},
  //             activity: {},
  //             // Include other properties if needed
  //           };
  //         }
  //         return slide;
  //       });

  //       setSlides(updatedSlides);
  //       localStorage.setItem("slides", JSON.stringify(updatedSlides));
  //       handleDialogvideoClose();
  //       resetFileInput();
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  //   }else{
  //     CheckedAccessTokenValid()
  //   }

  // };
const handleImageUpload = (event) => {
  setIsLoading(true);
  const files = event.target.files;
  const maxSizeInBytes = 200 * 1024 * 1024; // 4MB - adjust as needed
  let isOverSize = false;

  const formData = new FormData();
  Array.from(files).forEach((item) => {
    if (item.size > maxSizeInBytes) {
      isOverSize = true;
      Swal.fire({
        icon: 'error',
        title: content['File Size Limit Exceeded'],
        confirmButtonText: content["OK"],
      });
    } else {
      formData.append("files", item);
    }
  });

  if (!isOverSize) {
    if (AccessTokenValid()) {
      axios
        .post(API_ENDPOINT + "multiPostersUpload", formData, config)
        .then((response) => {
          const posters1 = response.data.images;

          const mappedImages1 = posters1.map((poster) => ({
            id: poster._id,
            posterUrl: poster.posterUrl,
            thumbnailUrl: poster.thumbnailUrl,
            targetType: targetSelect, //"none",
            video: {},
          }));

          const currentObjectId = localStorage.getItem("currentImageId");
          const updatedSlides = slides.map((slide) => {
            if (slide.id === currentObjectId) {
              return {
                id: mappedImages1[0].id,
                posterUrl: mappedImages1[0].posterUrl,
                thumbnailUrl: mappedImages1[0].thumbnailUrl,
                targetType: targetSelect, //"none",
                video: {},
                activity: {},
              };
            }
            return slide;
          });

          setSlides(updatedSlides);
          localStorage.setItem("slides", JSON.stringify(updatedSlides));
          handleDialogvideoClose();
          resetFileInput();
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          // setIsLoading(false);
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        });
    } else {
      CheckedAccessTokenValid();
    }
  }
};


  const currentImageId = localStorage.getItem("currentImageId");
  const indexToUpdate = slides.findIndex(
    (slide) => slide.id === currentImageId
  );

  const currentVideoId = localStorage.getItem("currentImageId");
  const indexToUpdateVideo = slides.findIndex(
    (slide) => slide.id === currentVideoId
  );

  let numberOfDefaultSlides = 0;

  if (slides.length === 0) {
    numberOfDefaultSlides = 3;
  } else if (slides.length === 1) {
    numberOfDefaultSlides = 2;
  } else if (slides.length === 2) {
    numberOfDefaultSlides = 1;
  }

  const defaultSlidesArray = Array(numberOfDefaultSlides).fill(defaultSlide);

  const modifiedSlides1 = slides.concat(defaultSlidesArray);

  const addDefaultObject = () => {
    handleImageClick1();
    console.log("Enter______");
    const nextDefaultIndex = slides.filter((slide) =>
      slide.id.includes("default#")
    ).length;

    const newDefaultSlide = {
      ...defaultSlide,
      id: `default#${nextDefaultIndex}`,
    };

    setSlides((prevSlides) => [...prevSlides, newDefaultSlide]);
    setValue(slides.length);

    // localStorage.setItem("slides", JSON.stringify([newDefaultSlide]));
  };

  const deleteSlide_old = () => {
    const id = localStorage.getItem("currentImageId");

    // Filter out the slide with the specified ID, but keep default slides

    setSlides((prevSlides) =>
      prevSlides.filter(
        // (slide) => slide.id !== id || slide.id.includes("default#")
        (slide) => slide.id !== id
      )
    );
    handleChange(value - 1);
  };

  const deleteSlide = () => {
    const index = value;
    setSlides((prevSlides) =>
      prevSlides.filter((slide, i) => i !== index)
    );
    if (value === 0) {
      handleChange(value + 1); 
    } else {
      handleChange(value - 1); 
    }
  };
  

  const handleChange = (newValue) => {
    setValue(newValue);
    localStorage.setItem("position", JSON.stringify(newValue));
  };

  const handleNext = () => {
    const nextIndex = (value + 1) % slides.length;
    setValue(nextIndex);
  };

  const handlePrevious = () => {
    const previousIndex = (value - 1 + slides.length) % slides.length;
    console.log("hi previousIndex",previousIndex)
    setValue(previousIndex);
  };

  const handleImageClick = (index) => {
    setActiveIndex(index); // Update active index when an image is clicked
  };

  const divRef = useRef();
  const imgRef = useRef(null);

  // Your logic to access the img value attribute
  const handleImageClick1 = () => {
    console.log("hii click 1")
    if (imgRef.current) {
      const imgElement = imgRef.current.querySelector(
        ".BrainhubCarouselItem.BrainhubCarouselItem--active img[value]"
      );
      if (imgElement) {
        const valueAttribute = imgElement.getAttribute("value");

        localStorage.setItem("currentImageId", valueAttribute);
      }
    }
  };

  // Step 2: Find the object based on the id
  const itemIdToUpdate = localStorage.getItem("currentImageId"); // Replace this with the id you want to update
  const updatedItemData = matchingObjects;

  const updatedItems_old = slides.map((item) => {

    console.log("item--------->",item)

    // console.log("Updated Slidesstart:----")
    if (item.id === itemIdToUpdate) {
      // Step 3: Replace the object with the updated object
      return updatedItemData;
    }
    return item;
  });

  // const indexToUpdate = value
  const updatedItems = slides.map((item, index) => {
    if (index === value) {
      // Step 3: Replace the object with the updated object
      return updatedItemData;
    }
    return item;
  });

  function handledeleteSlide() {
    handleImageClick1();
    deleteSlide();
  }

  const isAddButtonDisabled = slides.length >= 8;
  const isDeleteButtonDisabled = slides.length === 3;

  const openModalPoster = () => {
    if (dialogControl.current) {
      dialogControl.current.showModal();
    }
  };

  const handleDialogClose = () => {
    dialogControl.current.close();
  };

  function handleClick() {
    postersToAPI();
    openModalPoster();
    handleImageClick1();
    setbuttonDisabled(true);
  }

  const matchingVideoObject = {
    ...matchingObjectsarray,
    video: {
      id: selectedVideoId, // Set the selected video ID
    },
  };

  const handleVideoCheckboxChange = (index, videoId) => {
    setSelectedVideoIndex(index);
    setSelectedVideoId(videoId);
  };

  const openModalclass = () => {
    if (dialogControlVideos.current) {
      dialogControlVideos.current.showModal();
    }
  };

  const handleDialogvideoClose = () => {
    dialogControlVideos.current.close();
  };

  function handleClickvideo() {
    // fetchVideos();
    openModalclass();
    handleImageClick1();
  }

  function handleClicklocal() {
    handleImageClick1();
  }

  const plugins = [
    "centered",
    {
      resolve: arrowsPlugin,
      options: {
        arrowLeft: (
          <span
            style={{
              fontSize: "-webkit-xxx-large",
              cursor: "pointer",
              color: "#fff",
              position: "relative",
              left: "15px",
              zIndex: "1",
              
            }}
          >
            <FontAwesomeIcon
              icon={faCircleChevronLeft}
              style={{
                "--fa-primary-color": "#ffffff",
                "--fa-secondary-color": "#000000",
              }}
            />
          </span>
        ),
        arrowLeftDisabled: (
          <span
            style={{
              fontSize: "-webkit-xxx-large",
              cursor: "not-allowed",
              color: "rgb(114 114 114)",
              position: "relative",
              left: "15px",
              zIndex: "1",
            }}
          >
            <FontAwesomeIcon
              icon={faCircleChevronLeft}
              style={{
                "--fa-primary-color": "#ffffff",
                "--fa-secondary-color": "#000000",
              }}
            />
          </span>
        ),

        arrowRight: (
          <span
            style={{
              fontSize: "-webkit-xxx-large",
              cursor: "pointer",
              color: "#fff",
              position: "relative",
              right: "15px",
              zIndex: "1",
            }}
          >
            <FontAwesomeIcon
              icon={faCircleChevronRight}
              style={{
                "--fa-primary-color": "#000000",
                "--fa-secondary-color": "#ffffff",
              }}
            />
          </span>
        ),
        arrowRightDisabled: (
          <span
            style={{
              fontSize: "-webkit-xxx-large",
              cursor: "pointer",
              color: "rgb(114 114 114)",
              position: "relative",
              right: "15px",
              cursor: "not-allowed",
            }}
          >
            <FontAwesomeIcon
              icon={faCircleChevronRight}
              style={{
                "--fa-primary-color": "#000000",
                "--fa-secondary-color": "#ffffff",
              }}
            />
          </span>
        ),
        addArrowClickHandler: true,
      },
    },
    {
      resolve: slidesToShowPlugin,
      options: {
        numberOfSlides: 2,
      },
    },
  ];

  if (isFullscreen) {
    // plugins.push("infinite");
  }

  function convertMillisecondsToMinutesAndSeconds(milliseconds) {
    // Calculate total seconds
    var totalSeconds = Math.floor(milliseconds / 1000);

    // Calculate minutes and remaining seconds
    var minutes = Math.floor(totalSeconds / 60);
    var seconds = totalSeconds % 60;

    return {
      minutes: minutes,
      seconds: seconds,
    };
  }

  return (
    <>
      <section
        className={`${
          isFullscreen
            ? "content-area-wrapper clearfix"
            : "content-area-wrapper clearfix content-area-height"
        }`}
      >
        <main className={`${isFullscreen ? "" : "content-area"}`}>
          <div className="carosuelPage">
            <span style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "60px", padding: "19px" }}
                />
              </div>
              <div style={{ marginTop: "25px", marginRight: "18px" }}>
                {isFullscreen ? (
                  <div>
                    <button className="btn_one" onClick={onClose}>
                      {content["Exit Preview"]}
                    </button>{" "}
                    &nbsp;
                  </div>
                ) : (
                  <div>
                    <button className="btn_one" onClick={openModal}>
                      {content["Preview"]}
                    </button>
                    &nbsp;
                    <button
                      className="btn_two"
                      onClick={() => postSlidesToAPI(slides)}
                    >
                      {content["Save"]}
                    </button>
                    {/* <button
                      className="btn_two"
                      onClick={() => {
                        Swal.fire({
                          title: content["Banner Save!"],
                          // text: "Banner Save Successful",
                          icon: "success",
                          // showCancelButton: true,
                          confirmButtonText: content["OK"],
                          // cancelButtonText: "Cancel",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            // Call the function if the user confirms
                            postSlidesToAPI(slides);
                          }
                        });
                      }}
                    >
                      {content["Save"]}
                    </button> */}
                  </div>
                )}
              </div>
            </span>

            <div
              className={`${
                isFullscreen ? "Carouselstylefullscreen" : "Carouselstyle"
              }`}
            >
              <div
                className={`${
                  isFullscreen ? "fullscreen-Recommendation" : "Recommendation"
                }`}
              >
                {content["Recommendation"]}
              </div>

              <div ref={imgRef}>
                <Carousel
                  value={value}
                  onChange={handleChange}
                  plugins={plugins}
                  arrows
                  addArrowClickHandler
                >
                  {slides.map((slide, index) => (
                    
                    <div
                      onClick={handleImageClick1}
                      ref={divRef}
                      key={index}
                      className={`${
                        isFullscreen
                          ? "MyCarouselItem--active-fullscreen"
                          : "MyCarouselItem--active"
                      }`}
                    >

                      {/* <div className="div_center_home">
                      {isLoading && <span className="loader_home"></span>}
                      </div> */}
                      
                      <img
                        style={
                          isFullscreen ? imgStyleCenterfull : imgStyleCenter
                        }
                        value={slide.id}
                        src={slide.posterUrl}
                        alt={`Slide ${index + 1}`}
                      />

                      
                    </div>
                  ))}
                </Carousel>

                <div
                  className={`${
                    isFullscreen ? "Smallbannernone" : "Smallbanner"
                  } ${slides.length === 0 ? "hide-banner" : ""}`}
                >

                  {content["Banner"]} {value + 1}
                  <div className="div_center_home">
                      {/* <span className="loader_home"></span> */}
                      {isLoading ? (
                          <span class="loader_home"></span>
                        ) : null}
                      </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />

            <div
              className={`dots-container ${
                isFullscreen ? "fullscreen" : "not-fullscreen"
              }`}
            >
              {slides.map((_, index) => (
                <div
                  key={index}
                  style={index === value ? activeDotStyle : dotStyle}
                  onClick={() => handleChange(index)}
                />
              ))}
            </div>

            <MultiItemSlider
              isFullscreen={isFullscreen}
              galleryVideos={galleryVideos}
            />
          </div>
        </main>
        {isFullscreen ? (
          <></>
        ) : (
          <aside className="sidebar">
            <dialog
              ref={dialogControl}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "69%",
                background: "#fff",
                zIndex: 9999,
                border: "none",
                border: "none",
                height: "90%",
                overflowY: 'hidden'
                
              }}
            >
              <div>
                <div className="header-app">
                  <div>{content["Choose a poster"]}</div>
                  <div>
                    <ImCross
                      style={{ cursor: "pointer" }}
                      className="btnClose"
                      width={100}
                      onClick={handleDialogClose}
                    />
                  </div>
                </div>
                <div className="body-app">
                  <div className="card-container-app">
                    {galleryImages.map((image, index) => (
                      <div key={image.id} className="tile">
                        <label
                          htmlFor={`sport${image.id}`}
                          className="label-container"
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                            src={image.thumbnailUrl}
                            alt={`Image ${index + 1}`}
                          />
                          <input
                            type="checkbox"
                            checked={index === selectedImageIndex}
                            onChange={() =>
                              handleCheckboxChange(index, image.id)
                            }
                            name="sports"
                            id={`sport${image.id}`}
                            className="hidden-checkbox"
                          />
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="footer-app" style={{ bottom: "8px" }}>
                  <div
                    className="btn-save"
                    onClick={() => {
                      handleDialogClose();
                      setSlides(updatedItems);
                      console.log(
                        updatedItems,
                        "updatedItems+______________2_______"
                      );
                    }}
                  >
                    {content["OK"]}
                  </div>
                  <button
                    className="btn-cancel"
                    onClick={() => handleDialogClose()}
                  >
                    {content["Cancel"]}
                  </button>
                </div>
              </div>
            </dialog>

            <dialog
              ref={dialogControlVideos}
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "69%",
                background: "#fff",
                zIndex: 9999,
                border: "none",
                height: "90%",
                overflowY: 'hidden'
              }}
            >
              <div>
                <div className="header-app">
                  <div>
                    <span
                      style={{
                        color: courseTextColor,
                        cursor: courseCursorStyle,
                      }}
                      onClick={handleChooseCourse}
                    >
                      {content["Choose a course"]}
                    </span>
                    {" | "}
                    <span
                      style={{
                        color: activityTextColor,
                        cursor: activityCursorStyle,
                      }}
                      onClick={handleChooseActivity}
                    >
                      {content["Choose an activity"]}
                    </span>
                  </div>
                  <div>
                    <ImCross
                      style={{ cursor: "pointer" }}
                      className="btnClose"
                      width={100}
                      onClick={handleDialogvideoClose}
                    />
                  </div>
                </div>
                {selectedOption === "course" ? (
                  <div className="VideoClass">
                    <div className="body-app">
                      <div
                        style={{
                          textAlign: "initial",
                          marginLeft: "56px",
                          padding: "9px",
                        }}
                      >
                        <select
                          value={selectedTypeFilter}
                          onChange={(e) =>
                            setSelectedTypeFilter(e.target.value)
                          }
                        >
                          <option value="all">{content["All"]}</option>
                          {uniqueCourseTypes.map((type, index) => (
                            <option key={index} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="card-container-app-new">
                        {filteredCourses.map((video, index) => {
                          const time = convertMillisecondsToMinutesAndSeconds(
                            video.duration
                          );

                          return (
                            <div key={video.id}>
                              <label
                                htmlFor={`video${video.id}`}
                                className="label-container-act"
                              >
                                <div className="Featured">
                                  <div className="Featured_img">
                                    <img
                                      src={video.snapshot_url}
                                      alt="Featured img"
                                      class="img-fluid"
                                    />
                                  </div>
                                  <div
                                    style={{
                                      color: "white",
                                      position: "absolute",
                                      top: "124px",
                                      marginLeft: "178px",
                                    }}
                                  >
                                    {time.minutes}:{time.seconds}
                                  </div>
                                  <div className="Featured_text">
                                    <h3>{getTitle(video.title)}</h3>
                                    <div style={{ color: "darkgray" }}>
                                      {video.class_type.name}
                                    </div>
                                  </div>
                                </div>
                                <input
                                  type="checkbox"
                                  checked={index === selectedVideoIndex}
                                  onChange={() =>
                                    handleVideoCheckboxChange(index, video.id)
                                  }
                                  name="videos"
                                  id={`video${video.id}`}
                                  className="hidden-checkbox-act"
                                />
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="footer-app" style={{ bottom: "8px" }}>
                      <div
                        className="btn-save"
                        onClick={() => {
                          const updatedSlides = slides.map((slide , index) => {
                            const currentObejctId = value;
                            if (index === currentObejctId) {
                              return {
                                ...slide,
                                targetType: "video",
                                video: matchingObjectsvideo,
                                activity: {},
                              };
                            }
                            return slide;
                          });
                          setSlides(updatedSlides);

                          handleDialogvideoClose();
                        }}
                      >
                        {content["OK"]}
                      </div>
                      <button
                        className="btn-cancel"
                        onClick={handleDialogvideoClose}
                      >
                        {content["Cancel"]}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="ActivityCLass">
                    <div className="body-app">
                      <div className="card-container-app-new">
                        {getActivities.map((activity, index) => {
                          const discountInfo = activity.coupon.promotionMethod;
                          const isFixedDiscount =
                            discountInfo &&
                            discountInfo.fixedDiscount !== undefined;
                            const isFixedAmount =
                            discountInfo &&
                            discountInfo.fixedAmount !== undefined;
                          const isRandomDiscount =
                            discountInfo &&
                            discountInfo.randomDiscount !== undefined;

                          return (
                            <div key={activity.id}>
                              <label
                                htmlFor={`activity${activity._id}`}
                                className="label-container-act"
                              >
                                <div className="Featured">
                                  <div className="Featured_img">
                                    <img
                                      src={activity.image}
                                      alt="Featured img"
                                      className="img-fluid"
                                    />
                                  </div>

                                  <div className="Featured_text">
                                    <h3>{activity.name?.[0]?.text}</h3>
                                    <div style={{ color: "darkgray" }}>
                                      {isFixedDiscount &&
                                        `Fixed Discount: ${discountInfo.fixedDiscount}`}
                                  {isFixedAmount &&
                                        `Fixed Amount: ${discountInfo.fixedAmount}`}
                                      {isRandomDiscount &&
                                        `Discount: ${discountInfo.randomDiscount.low}Yuan - ${discountInfo.randomDiscount.high}Yuan`}
                                    </div>
                                  </div>
                                </div>

                                <input
                                  type="checkbox"
                                  checked={index === selectedActivityIndex}
                                  onChange={() => {
                                    setselectedActivityIndex(index);
                                    setselectedActivityId(activity._id);
                                  }}
                                  name="coupons"
                                  id={`activity${activity._id}`}
                                  className="hidden-checkbox-act"
                                />
                              </label>
                              <div
                                style={{
                                  color: "white",
                                  position: "absolute",
                                  top: "126px",
                                  marginLeft: "188px",
                                }}
                              ></div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="footer-app" style={{ bottom: "8px" }}>
                      <div
                        className="btn-save"
                        onClick={() => {
                          const updatedSlides = slides.map((slide , index) => {
                            const currentObejctId = value;
                            if (index === currentObejctId) {
                              return {
                                ...slide,
                                targetType: "activity",
                                activity: matchingObjectsactivity,
                                video: {},
                              };
                            }
                            return slide;
                          });
                          setSlides(updatedSlides);

                          handleDialogvideoClose();
                        }}
                      >
                        {content["OK"]}
                      </div>
                      <button
                        className="btn-cancel"
                        onClick={handleDialogvideoClose}
                      >
                        {content["Cancel"]}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </dialog>
            <div>

            <div style={{color: '#000', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <div className="card3434">
                <div style={{marginTop: '49px'}}>
                <h3> {content["Image can be dragged here"]}</h3>
                <span>
                    <input
                      type="file"
                      id="file"
                      accept="image/jpeg"
                      multiple
                      onChange={handleImageUpload}
                      disabled={isLoading}
                    />
                    <label
                      htmlFor="file"
                      style={{
                        pointerEvents: isLoading ? "none" : "auto",
                        opacity: isLoading ? 0.5 : 1,
                      }}
                    ></label>

                    <label
                      onClick={handleImageClick1()}
                      htmlFor="file"
                      style={{
                        pointerEvents: isLoading ? "none" : "auto",
                        color: "#59d3fc",
                        cursor: "pointer",
                        opacity: isLoading ? 0.5 : 1,
                      }}
                    >
                      {content["Local Upload"]}
                    </label>
                  </span>
                  &nbsp;|&nbsp;
                  <a
                    onClick={() => handleClick()}
                    style={{ pointerEvents: isLoading ? "none" : "auto",color: "#59d3fc", cursor: "pointer",opacity: isLoading ? 0.5 : 1, }}
                  >
                    {content["Select Poster"]}
                  </a>
                </div>
                </div>
              </div>


              <div className="linkclass">
                <h3> {content["Link to course/activity page"]}:</h3>
              </div>
              <div>
                {slides[value] &&
                slides[value].id &&
                slides[value].id.includes("default#") ? (
                  <button class="btnHome" disabled>
                    {content["Select course/activity"]}
                  </button>
                ) : (
                  <h1 style={{ color: "red" }}>
                    <button class="btnHome" onClick={() => handleClickvideo()}>
                      {content["Select course/activity"]}
                    </button>
                  </h1>
                )}
              </div>
              
              <div className="courseact">
                {slides[value] &&
                  (slides[value].video && slides[value].video.title ? (
                    <h1 style={{ color: "black" }}>
                      <h3> {content["Course"]}</h3>
                      <img
                        src={slides[value].video.snapshot_url}
                        alt="No Image"
                        style={{
                          width: "232px",
                          height: "121px",
                          objectFit: "cover",
                        }}
                      />
                      <br />
                      {getTitle(slides[value].video.title)}
                    </h1>
                  ) : (
                    <h1 style={{ color: "black" }}>
                      {/* {content["No Video/Title Available"]} */}
                    </h1>
                  ))}
                {slides[value] &&
                  (slides[value].activity &&
                  slides[value].activity.name &&
                  slides[value].activity.image ? (
                    <h1 style={{ color: "black" }}>
                      <h3> {content["Activity"]}</h3>
                      <img
                        src={slides[value].activity.image}
                        alt="No Image"
                        style={{
                          width: "232px",
                          height: "121px",
                          objectFit: "cover",
                        }}
                      />
                      <br />
                      {slides[value].activity.name.en}
                    </h1>
                  ) : (
                    <h1 style={{ color: "red" }}>
                      {/* {content["No Video/Title Available"]} */}
                    </h1>
                  ))}
              </div>
            </div>
          </aside>
        )}
      </section>

      {isFullscreen ? (
        <></>
      ) : (
        <>
          <button
            className="button-18"
            onClick={addDefaultObject}
            disabled={isAddButtonDisabled}
          >
            {content["Add Banner"]}
          </button>
          &nbsp;&nbsp;
          <button
            className="button-18"
            onClick={() => handledeleteSlide()}
            disabled={isDeleteButtonDisabled}
          >
            {content["Delete Banner"]}
          </button>
        </>
      )}
    </>
  );
}
