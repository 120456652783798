import React, { useEffect, useState } from "react";
import NavBar from "../other/navBar";
import "./refund.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useOutletContext } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { apiBaseUrls, apiKeys } from "../../../config";
import { encryptedaes } from "../../../utils/encrypt/encryptUtils";
import ReactPaginate from "react-paginate";

import {
  CheckedAccessTokenValid,
  AccessTokenValid,
} from "../../../utils/LogAuth";
import RefundModel from "./RefundModel";
const API_ENDPOINT = apiBaseUrls.development;

const tableHeaderStyle = {
  backgroundColor: "rgb(234 235 244)",
  fontWeight: "bold",
  padding: "8px",
};

export default function Refund() {
  const { content } = useOutletContext();
  const [searchType, setSearchType] = useState("orderId");
  const [loading, setLoading] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [email, setEmail] = useState("");
  const [orderData, setOrderData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [refundloading, setrefundLoading] = useState(false);
  const [refundData, setRefundData] = useState(null);

  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(20); 


  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  const pageCount = orderData ? Math.ceil(orderData.length / perPage) : 0;
  const currentData = orderData
    ? orderData.slice(currentPage * perPage, (currentPage + 1) * perPage)
    : [];

console.log("currentData2--->",currentData)
console.log("test orderData",orderData)
  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;

    return formattedDate;
  }

  const navigate = useNavigate();

  const handleOpenModal = async (e,refundId) => {
    setShowModal(true);

    const buttonRect = e.target.getBoundingClientRect();
    setModalPosition({ top: buttonRect.top, left: buttonRect.left });

    
    const requestBody = {
      refundId: refundId,
    };
    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;
    const encryptbody = { data: encryptedData };


    try {
      setrefundLoading(true);
      const response = await axios.post(`${API_ENDPOINT}refund/getRefund`, encryptbody, config);
      setrefundLoading(false);
      const responseData = response.data.refunds;
      console.log("responseData->",responseData)
      if (response.data.result === 403) {
        Swal.fire({
          icon: "warning",
          title: content["No data available"],
          confirmButtonText: content["OK"],
        });
      } else {
        setRefundData(responseData);
      }
    } catch (error) {
      console.error("Error fetching refund status:", error);
      setrefundLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

console.log("refundData",refundData)

  const fetchData = () => {
    const requestBody = {
      orderId: orderId,
      email: email,
    };
    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;
    const encryptbody = { data: encryptedData };

    setLoading(true);
    axios
      .post(`${API_ENDPOINT}refund/getOrders`, encryptbody, config)

      .then((response) => {
        setLoading(false);
        const responseData = response.data.refunds;
        console.log("test respppppp",response.data.result)
        if (response.data.result === 403) {
          setOrderData([]); 
          Swal.fire({
            icon: "warning",
            title:  content["No data available"],
            confirmButtonText: content["OK"],
          });
        } else {
          setOrderData(responseData);
        }
      })
      
      .catch((error) => {
        setLoading(false);
      });
  };


  const fetchDataUpdate = (orderIdNew) => {
    const requestBody = {
      orderId: orderIdNew,
      email: email,
    };
    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;
    const encryptbody = { data: encryptedData };

    setLoading(true);
    axios
      .post(`${API_ENDPOINT}refund/getOrders`, encryptbody, config)

      .then((response) => {
        setLoading(false);
        const responseData = response.data.refunds;
        console.log("test respppppp",response.data.result)
        if (response.data.result === 403) {
          setOrderData([]); 
          Swal.fire({
            icon: "warning",
            title:  content["No data available"],
            confirmButtonText: content["OK"],
          });
        } else {
          setOrderData(responseData);
        }
      })
      
      .catch((error) => {
        setLoading(false);
      });
  };

const dropdownOptions=[
  { value: 'part', label: 'Part' },
  { value: 'all', label: 'All' },
]


const RefundAmount = (orderId,amount,currency,qrCode,zeasnOrderId) => {
  Swal.fire({
    title: "",
    html: `
    <div className="dropdown-input-wrapper">

          <span class="textboxref">${subscriptionCountryMapper[currency]}</span>
  
      <input
        type="number"
        id="addamount"
        style="
          border: 1px solid rgba(0, 0, 0, 0.16);
          height: 25px;
          padding: 5px 10px 5px 5px;
          font-size: 15px;
          border-radius: 3px;
          width: 254px;
          outline: none;"
          placeholder="Initiate Amount"
          onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight','.'].includes(event.key) || (!isNaN(Number(event.key)) && event.key !== ' ')"
      />
    </div>
  `,
    showCancelButton: true,
    cancelButtonText: content["Cancel"],
    cancelButtonColor: "rgb(184 184 184)",
    confirmButtonText: content["Sure"],
    backdrop: false,
    preConfirm: () => {
      const addamount = Swal.getPopup().querySelector("#addamount").value;
      // const refundType = Swal.getPopup().querySelector('#refundType').value;


      if (parseFloat(addamount) > parseFloat(amount)) {
        Swal.showValidationMessage(
          // `Refund amount cannot be greater than the original amount (${amount}).`
          `Refund amount cannot be greater than the original amount`
        );
        return false;
      }

      // Request body
      const requestBody = {
        orderId,
        // refundType,
        refundType:"all",
        refundAmount: addamount,
        qrCode:qrCode,
        zeasnOrderId:zeasnOrderId
      };

      console.log("requestBody--->",requestBody)
      const { encryptedData, SessionToken } = encryptedaes(requestBody);
      config.headers["Session-Token"] = SessionToken;

      const encryptbody = { data: encryptedData };

      return axios
        .post(API_ENDPOINT + "refund/refundInitiate", encryptbody, config)
        .then((response) => {
          const result = response.data.result;

          if (result === 100) {
            // Handle the API response
            Swal.fire(content["Success"], content["Password updated successfully"], "success");

            //here i want to call api to get order
            fetchDataUpdate(orderId)

          } else if (result === 420) {
            Swal.fire({
              icon: 'error',
              title: content["Unauthorized"],
              text: content["You are not authorized to perform this action!"],
              confirmButtonText: content["OK"],
            });
          } 
          
          else if (result === 403) {
            Swal.fire({
              icon: 'error',
              title: "Order does not exist",
              text: result.refunds,
              confirmButtonText: content["OK"],
            });
          }
          else {
            console.error("Failed to update password:", response.data.error);
            Swal.fire(content["Error"], content["Failed to update password"], "error");
          }
        })
        .catch((error) => {
          // Handle API error
          Swal.fire(content["Error"], content["Failed to update password"], "error");
        });
    },
    didOpen: () => {
      const amountInput = Swal.getPopup().querySelector("#addamount");
      const confirmButton = Swal.getConfirmButton();

      confirmButton.disabled = true;

      const regex = /^(0(\.\d{0,2})?|[1-9]\d{0,8}(\.\d{0,2})?)$/;

      amountInput.addEventListener("input", () => {
        const inputValue = amountInput.value;
      
        if (regex.test(inputValue)) {
          confirmButton.disabled = false;
        } else {
          confirmButton.disabled = true;
        }
      });

    },
  });
};


const RefundReset = (orderId,refundId) => {
  const requestBody = {
    orderId,
    refundId
  };

  console.log("requestBody--->",requestBody)
  const { encryptedData, SessionToken } = encryptedaes(requestBody);
  config.headers["Session-Token"] = SessionToken;

  const encryptbody = { data: encryptedData };

  return axios
    .post(API_ENDPOINT + "refund/RefundReset", encryptbody, config)
    .then((response) => {
      const result = response.data.result;

      if (result === 100) {
        // Handle the API response
        Swal.fire(content["Success"], content["Password updated successfully"], "success");

        //here i want to call api to get order
        // fetchDataUpdate(orderId)

      } else if (result === 420) {
        Swal.fire({
          icon: 'error',
          title: content["Unauthorized"],
          text: content["You are not authorized to perform this action!"],
          confirmButtonText: content["OK"],
        });
      } else {
        console.error("Failed to update password:", response.data.error);
        Swal.fire(content["Error"], content["Failed to update password"], "error");
      }
    })
    .catch((error) => {
      // Handle API error
      Swal.fire(content["Error"], content["Failed to update password"], "error");
    });
};

  const subscriptionCountryMapper = {
    EUR: "€",
    GBP: "£",
  };


  const RefundStatusMapper = {
    refund_initiated: "Refund Initiated"
  };



  const isValidEmail = (email) => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  };

  const isValidOrderId = (orderId) => {
    return orderId.length === 18 && /^\d+$/.test(orderId);
  };

  const isButtonDisabled = () => {
    if (searchType === "orderId") {
      return !orderId || !isValidOrderId(orderId);
    } else if (searchType === "email") {
      return !email || !isValidEmail(email);
    }
    return true;
  };


  const handleOrderIdChange = (value) => {
    setEmail("");
    const enteredValue = value;
    let regex;
    regex = /^[1-9]\d{0,17}$/;
    if (enteredValue === "" || regex.test(enteredValue)) {
      setOrderId(enteredValue);
    }
  };

  const handleEmailIdChange = (value) => {
    setOrderId("");
    setEmail(value);
  };

  return (
    <>
      <NavBar />
      <div
        className="mainpage"
        style={{
          textAlign: "right",
          marginBottom: "10px",
          marginLeft: "10px",
          marginRight: "-6px",
        }}
      >
        <div>
          <div style={{ fontSize: "3.3vh", fontWeight: 500 }}>{content["View Order"]}</div>
        </div>

        <div style={{ display: "flex" }}>
        <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination_ref"}
              subContainerClassName={"pages pagination_ref"}
              activeClassName={"active"}
            />
          <select
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            className="box-dropdownforType"
          >
            <option value="orderId">{content["OrderId"]}</option>
            <option value="email">{content["Email"]}</option>
          </select>&nbsp;
          <div>
            {searchType === "orderId" ? (
              <>
                <input
                  type="text"
                  value={orderId}
                  className="order-input-class"
                  placeholder={content["Enter order id"]} 
                  onChange={(e) => handleOrderIdChange(e.target.value)}
                  maxLength={18}
                />
              </>
            ) : (
              <>
                <input
                  type="text"
                  value={email}
                  className="order-input-class"
                  placeholder={content["Enter email address"]}
                  onChange={(e) => handleEmailIdChange(e.target.value)}
                />
              </>
            )}
          </div>&nbsp;
          <button className="btn_order_search-refund" onClick={fetchData}  disabled={isButtonDisabled()}>{content["Search"]}</button>
          &nbsp;&nbsp;
        </div>
      </div>

      {loading ? (
        <div className="homeloader"></div>
      ) : (
        <div style={{ overflow: "auto", height: "78vh" }}>

          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <br />
            <thead>
              <tr>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Email"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["OrderId"]}
                </th>

                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Amount"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Date"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Refund Status"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Operation"]}
                </th>
              </tr>
              {orderData === null && (
                <tr>
                  <td
                    colSpan="6"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <h3>{content["Enter on Email and OrderId to search"]}</h3>
                  </td>
                </tr>
              )}
            </thead>
            <tbody>
              {orderData &&
                currentData.map((item) => (
                  <tr key={item.id}>
                    <td className="tdvaluesubtitle">{item.email}</td>
                    <td
                      className="tdimage"
                      style={{ textAlign: "center", color: "darkgray" }}
                    >
                      {item.zeasnOrderId}
                    </td>

                    <td className="tdvaluecourse" style={{ color: "darkgray" }}>
                      {subscriptionCountryMapper[item.currency]} {item.amount}
                    </td>

                    <td className="tdvaluecourse" style={{ color: "darkgray" }}>
                      {formatDate(item.t)}
                    </td>
                    <td
                      className="tdvalueOperaterefund"
                      style={{ color: "darkgray" }}
                    >
                      


            {item.refundId && item.refundId !== "null" ? (
            <>
              <a className="refundstatus_yes">{content["Yes"]}</a>
              &nbsp;&nbsp;&nbsp;
              <a className="refundprocess">
              {RefundStatusMapper[item.refundStatus]}
              </a>
            </>
          ) : (
            <a className="refundstatus_no">{content["No"]}</a>
          )}
                        
                    </td>
                    <td className="tdvaluecourse">

        {item.refundId && item.refundId !== "null" ? (
                <>
               {/* <a className="refundstatus_process" onClick={() => handleOpenModal(item.refundId)}refundloading={refundloading}>{content["Refund Status"]}</a>

                    <RefundModel show={showModal} handleClose={handleCloseModal}>

                        <div class="wrapper_home">
                          <div style={{textAlign:'left'}}><h4>Refund Process</h4></div>
                        <ul class="StepProgress">
                          <li class="StepProgress-item is-done"><strong>Submit a refund to Zeasn</strong><span style={{color:'gray'}}>12-08-77</span></li>
                          <li class="StepProgress-item is-done"><strong>Zeasn payment pending</strong>
                          </li>
                          <li class="StepProgress-item current"><strong>To be received</strong></li>
                        </ul>
                        </div>

                    </RefundModel> */}

              <a className="refundstatus_process" onClick={(e) => handleOpenModal(e,item.refundId)}>
                      {content["Refund Status"]}
                    </a>

                    <RefundModel show={showModal} handleClose={handleCloseModal} refundloading={refundloading} position={modalPosition}>

                      {refundData && (
                          <>
                      {/* <div className="wrapper_home">
                          <div style={{ textAlign: 'left' }}><h4>Refund Process</h4></div>
                          <ul className="StepProgress">
                          <li class="StepProgress-item is-done"><strong>Submit a refund to Zeasn</strong><span style={{color:'gray'}}>12-08-77</span></li>
                          <li class="StepProgress-item is-done"><strong>Zeasn payment pending</strong>
                          </li>
                          <li class="StepProgress-item current"><strong>To be received</strong></li>
                          </ul>
                        </div> */}


                              {refundData.refundstatus === "refund_initiated" && (
                              //   <div className="wrapper_home">
                              //   <div style={{ textAlign: 'left' }}><h4>Refund Process</h4></div>
                              //   <ul className="StepProgress">
                              //   <li class="StepProgress-item is-done"><strong>Submit a refund to Zeasn</strong><span style={{color:'gray'}}>12-08-77</span></li>
                              //   <li class="StepProgress-item"><strong>Zeasn payment pending</strong>
                              //   </li>
                              //   <li class="StepProgress-item"><strong>To be received</strong></li>
                              //   </ul>

                              //   {/* <a className="refundstatus_refund" style={{color:'red'}} onClick={() => RefundReset(item.zeasnOrderId,item.refundId)}>Fail</a> */}
                              // </div>


                              //failed
                              <div className="wrapper_home">
                              <div style={{ textAlign: 'left' }}><h4>Refund Process</h4></div>
                              <ul className="StepProgress">
                              <li class="StepProgress-item is-done"><strong>Submit a refund to Zeasn</strong><span style={{color:'gray'}}>12-08-77</span></li>
                              {/* <li class="StepProgress-item is-done"><strong>Zeasn payment pending</strong> */}
                              <li class="StepProgress-item current"><strong>Submit a refund to Zeasn</strong></li>
                              </ul>
                              <div style={{color:'gray'}}>12-08-77</div>
                              {/* <a className="refundstatus_refund" style={{color:'red'}} onClick={() => RefundReset(item.zeasnOrderId,item.refundId)}>Fail</a> */}
                            </div>
                              )}

                              {refundData.refundstatus === "Refund Initiated" && (
                                <div className="wrapper_home">
                                <div style={{ textAlign: 'left' }}><h4>Refund Process</h4></div>
                                <ul className="StepProgress">
                                <li class="StepProgress-item is-done"><strong>Submit a refund to Zeasn</strong><span style={{color:'gray'}}>12-08-77</span></li>
                                <li class="StepProgress-item"><strong>Zeasn payment pending</strong>
                                </li>
                                <li class="StepProgress-item"><strong>To be received</strong></li>
                                </ul>

                                {/* <a className="refundstatus_refund" style={{color:'red'}} onClick={() => RefundReset(item.zeasnOrderId,item.refundId)}>Fail</a> */}
                              </div>
                              )}

                              {refundData.refundstatus === "Refund_Pending" && (
                                <div className="wrapper_home">
                                <div style={{ textAlign: 'left' }}><h4>Refund Process</h4></div>
                                <ul className="StepProgress">
                                <li class="StepProgress-item is-done"><strong>Submit a refund to Zeasn</strong><span style={{color:'gray'}}>12-08-77</span></li>
                                <li class="StepProgress-item is-done"><strong>Zeasn payment pending</strong>
                                </li>
                                <li class="StepProgress-item"><strong>To be received</strong></li>
                                </ul>
                              </div>
                              )}

                              {refundData.refundstatus === "Refund_Completed" &&(
                                      <div className="wrapper_home">
                                      <div style={{ textAlign: 'left' }}><h4>Refund Process</h4></div>
                                      <ul className="StepProgress">
                                      <li class="StepProgress-item is-done"><strong>Submit a refund to Zeasn</strong><span style={{color:'gray'}}>12-08-77</span></li>
                                      <li class="StepProgress-item is-done"><strong>Zeasn payment pending</strong>
                                      </li>
                                      <li class="StepProgress-item current"><strong>To be received</strong></li>
                                      </ul>
                                    </div>
                              )}

                            


                          </>
                        )}
                    </RefundModel>
            </>
          ) : (
            <a className="refundstatus_refund" onClick={() => RefundAmount(item.zeasnOrderId, item.amount,item.currency,item.qrCode,item.zeasnOrderId)}>{content["Refund"]}</a>
          )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          
        </div>
      )}
    </>
  );
}