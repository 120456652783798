// import { useEffect, useState } from "react"

// import En from '../translation/en.json'
// import Ch from '../translation/chinese.json'

// function TranslationEngToChinese(){
//     const [language, setLanguage] = useState("English");
//     const [content, setContent] = useState({});

//     useEffect(() => {
//       if (language === "English") {
//         setContent(En.english);
//       } else if (language === "Chinese") {
//         setContent(Ch.chinese);
//       }
//     }, [language]);

//     return(
//         <div>
//      <div style={{position: 'absolute', right: '144px', top: '28px'}}  >
//         <select value={language} onChange={(e)=>{setLanguage(e.target.value)}}>
//                 <option>English</option>
//                 <option>Chinese</option>
//             </select>
//         </div>
//         </div>
//     );
// }
// export default TranslationEngToChinese;

import { useState, useEffect } from "react";
import En from "../translation/en.json";
import Ch from "../translation/chinese.json";

const useLanguage = () => {
  const storedLanguage = localStorage.getItem("language");
  const initialLanguage = storedLanguage ? storedLanguage : "English";
  const [language, setLanguage] = useState(initialLanguage);
  const [content, setContent] = useState({});

  useEffect(() => {
    if (language === "English") {
      setContent(En.english);
    } else if (language === "中国人") {
      setContent(Ch.chinese);
    }

    localStorage.setItem("language", language);
  }, [language]);

  return {
    language,
    setLanguage,
    content,
  };
};

export default useLanguage;

//new code

// import { useState, useEffect } from "react";
// import En from '../translation/en.json'
// import Ch from '../translation/chinese.json'

// const useLanguage = () => {
//   const storedLanguage = localStorage.getItem('language');
//   const initialLanguage = storedLanguage ? storedLanguage : 'English';
//   const [language, setLanguage] = useState(initialLanguage);
//   const [content, setContent] = useState({});

//   useEffect(() => {
//     if (language === "English") {
//       setContent(En.english);
//     } else if (language === "中国人") {
//       setContent(Ch.chinese);
//     }

//     localStorage.setItem('language', language);
//   }, [language]);

//   const updateLanguage = (newLanguage) => {
//     setLanguage(newLanguage);
//   };

//   return {
//     language,
//     updateLanguage,
//     content
//   };
// };

// export default useLanguage;

// import React, { createContext, useState, useEffect } from "react";
// import En from '../translation/en.json'
// import Ch from '../translation/chinese.json'

// const LanguageContext = createContext();

// const LanguageProvider = ({ children }) => {
//   const storedLanguage = localStorage.getItem('language');
//   const initialLanguage = storedLanguage ? storedLanguage : 'English';
//   const [language, setLanguage] = useState(initialLanguage);
//   const [content, setContent] = useState({});

//   useEffect(() => {
//     if (language === "English") {
//       setContent(En.english);
//     } else if (language === "中国人") {
//       setContent(Ch.chinese);
//     }

//     localStorage.setItem('language', language);
//   }, [language]);

//   const updateLanguage = (newLanguage) => {
//     setLanguage(newLanguage);
//   };

//   return (
//     <LanguageContext.Provider value={{ language, updateLanguage, content }}>
//       {children}
//     </LanguageContext.Provider>
//   );
// };

// export { LanguageContext, LanguageProvider };
