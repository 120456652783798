// import React, { useState } from 'react';
// import './Accordion.css';

// const Accordion = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleAccordion = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div className="accordion">
//       <div className="accordion-item">
//         <div className="accordion-header" onClick={toggleAccordion}>
//           <span>Monthly VIP</span>
//           <span>Actual payment: € 5.99</span>
//           <span className={`accordion-icon ${isOpen ? 'open' : ''}`}>&#9654;</span>
//         </div>
//         {isOpen && (
//           <div className="accordion-content">
//             <div>original price: € 6.99</div>
//             <div>Activity Discounts: 70% off</div>
//             <div>Points redeemable: € 1.00 off</div>
//             <div>2024-01-12 17:40</div>
//           </div>
//         )}
//       </div>

//     </div>
//   );
// };

// export default Accordion;



import React, { useState } from 'react';
import './Accordion.css';

const Accordion = ({ 
        content,
        subsData, 
        convertTimestampToDateTime,
      
      }) => {
const [openAccordions, setOpenAccordions] = useState({});

const subscriptionNameMapper = {
  monthly: "Monthly",
  annual: "Annual",
};

//   const toggleMonthlyAccordion = () => {
//     setIsMonthlyOpen(!isMonthlyOpen);
//   };
const toggleAccordion = (index) => {
    setOpenAccordions(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

console.log("jsdgfndshjvnd--accordion->",openAccordions)
  return (
    <div className="accordion">
    {subsData && subsData.map((item, index) => (
      <div className="accordion-item" key={index}>
        <div className="accordion-header" onClick={() => toggleAccordion(index)}>
          <span><h3>{subscriptionNameMapper[item.subscription]} VIP</h3></span>
          <div className="right-align">
            <div>
              <span className='accordion-text'>Actual payment:<span className='accordion-text-bold'>{item.symbol} {item.actualPrice}</span></span>
              <br />
              <span className="date"><h3>{convertTimestampToDateTime(item.subscriptionValidity)}</h3></span>
            </div>
            <span className={`accordion-icon ${openAccordions[index] ? 'open' : ''}`}>&#9660;</span>
          </div>
        </div>
        {openAccordions[index] && (
          <div className="accordion-content">
            <div className="right-align-content">
              <div><span className='accordion-text'>Original Price:</span> <span className='accordion-text-bold'>{item.symbol} {item.originalPrice}</span></div>
              <div><span className='accordion-text'>Discounts:</span> <span className='accordion-text-bold'>{item.discount} off</span></div>
              <div><span className='accordion-text'>Points Redemption:</span><span className='accordion-text-bold'>{item.symbol} {item.pointsRedeemable} off</span></div>
            </div>
          </div>
        )}
      </div>
      ))}
    </div>
  );
};

export default Accordion;
