import {
  decodeString,
  encodedString,
} from "../../utils/obfuscation/obfuscation";
import { apiBaseUrls, apiKeys } from "../../config";
import CryptoJS from "crypto-js";
var forge = require("node-forge");

const publicKey1 = decodeString(encodedString);



const loginUser = localStorage.getItem("accessToken");
const dataArray = loginUser ? loginUser.split("#") : []; // Add a check for loginUser
const Currentemail = dataArray[0] || null; // Set Currentemail to null if dataArray is empty

const config = {
  headers: {
    "X-API-KEY": apiKeys.development,
    "User-Id":Currentemail,
    "Session-Token": "",
  },
};

//algorthim old
// function encryption(dataToEncrypt) {
//   try {
//     const publicKey = forge.pki.publicKeyFromPem(publicKey1);

//     // Convert the data to a JSON string
//     const dataString = JSON.stringify(dataToEncrypt);

//     const encryptedData = publicKey.encrypt(dataString, "RSA-OAEP", {
//       md: forge.md.sha256.create(),
//     });

//     // Encode the encrypted data as base64
//     const encryptedDataBase64 = forge.util.encode64(encryptedData);

//     return encryptedDataBase64;
//   } catch (error) {
//     console.error("Encryption error:", error);
//     return "";
//   }
// }

//algorthim RSA
function encryptedrsa(dataToEncrypt) {
  try {
    const publicKey = forge.pki.publicKeyFromPem(publicKey1);

    // Convert the data to a JSON string
    const dataString = JSON.stringify(dataToEncrypt);

    const encryptedData = publicKey.encrypt(dataString, "RSA-OAEP", {
      md: forge.md.sha256.create(),
    });

    // Encode the encrypted data as base64
    const encryptedDataBase64 = forge.util.encode64(encryptedData);

    return encryptedDataBase64;
  } catch (error) {
    console.error("Encryption error:", error);
    return "";
  }
}

function encryptedaes(originalData) {
  // Function to generate a random password
  function generatePass() {
    let pass = "";
    let str =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "abcdefghijklmnopqrstuvwxyz0123456789@#$";

    for (let i = 1; i <= 8; i++) {
      let char = Math.floor(Math.random() * str.length);
      pass += str.charAt(char);
    }
    return pass;
  }

  // Generate a random secret key
  const secretKey = generatePass();

  const SessionToken = encryptedrsa(secretKey);

  // Convert the originalData object to a string
  const dataString = JSON.stringify(originalData);

  // Encrypt the data using the secretKey
  const encrypted = CryptoJS.AES.encrypt(dataString, secretKey);

  // Return both the encrypted data and the secret key
  return { encryptedData: encrypted.toString(), SessionToken: SessionToken };
}




//remove multiple items from local storage
const removeLocalStorageItems = (...keys) => {
  keys.forEach(key => {
    localStorage.removeItem(key);
  });
};

export { encryptedrsa, encryptedaes, config ,removeLocalStorageItems };
