import logo_old from "../../../img/wf-c.png";
import logout from "../../../img/logout_copy.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { apiBaseUrls, apiKeys } from "../../../config";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ImCross } from "react-icons/im";
import noposter from "../../../img/noposter.png";
import { useOutletContext } from "react-router-dom";
import NavBar from "../other/navBar";
import { encryptedaes } from "../../../utils/encrypt/encryptUtils";
import { CheckedAccessTokenValid , AccessTokenValid } from "../../../utils/LogAuth";


const MAX_NAME_LENGTH = 255;
const MAX_DESCRIPTION_LENGTH = 2000;


const API_ENDPOINT = apiBaseUrls.development;

  

function EditActivity() {
  const { language, setLanguage, content } = useOutletContext();
  const [inputBoxes, setInputBoxes] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [galleryImages, setGalleryImages] = useState([]);
  const [allCoupan, setAllCoupan] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState(null);
  const [selectedImageUrlpath, setSelectedImageUrlpath] = useState(null);
  const [selectedCouponIndex, setSelectedCouponIndex] = useState(null);
  const [selectedCouponId, setSelectedCouponId] = useState(null);
  const [selectedCouponData, setSelectedCouponData] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("all"); // Default to "all" initially

  //new start code for country
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [Allcountry, setAllcountry] = useState([]);
  const [selectedCountryForAdd, setSelectedCountryForAdd] = useState("");
  const [availableCountries, setAvailableCountries] = useState([]);
  const [matchedActivityData, setMatchedActivityData] = useState(null);
  const [isModified, setIsModified] = useState(false); //modified

  const [formData, setFormData] = useState({
    name: [
      //     {
      //     "lang": "en",
      //     "text": "Event English"
      // }
    ],
    description: [
      //     {
      //     "lang": "en",
      //     "text": "Event English"
      // }
    ],
    coupon: "",
  });

  // Create a state variable to store the filtered form data
  const [filteredFormData, setFilteredFormData] = useState({
    name: [],
    description: [],
  });


  const [selectedLangForAdd, setSelectedLangForAdd] = useState("");
  const [availableLangs, setAvailableLangs] = useState([]);
  const [selectedCheckboxeslang, setSelectedCheckboxeslang] = useState([]);
  // const [selectedSinglelanguges, setSelectedSinglelanguge] = useState("");
  const [selectedlanguges, setSelectedlanguges] = useState([
  ]);
  const [Alllanguge, setAlllanguge] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("en"); // Default to English

  
const [titleWordCount, setTitleWordCount] = useState(0);
const [titleCharacterCount, setTitleCharacterCount] = useState(0);

const config = {
  headers: {
    "X-API-KEY": apiKeys.development,
    "User-Id": localStorage.getItem("UserId"),
    "Session-Token": "",
  },
};

function calculateTitleCounts(text) {
  const words = text.trim().split(/\s+/);
  setTitleWordCount(MAX_WORD_LIMIT-words.length);
  setTitleCharacterCount(MAX_CHARACTER_LIMIT-text.trim().length);
}

  // Modify handleLanguageChange to update the selected language
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    setIsModified(true);
  };
  const MAX_WORD_LIMIT = 200; // Change this to your desired maximum word limit
  const MAX_CHARACTER_LIMIT = 2000; // Change this to your desired maximum character limit
  
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedFormData = { ...formData };
    const languageObject = updatedFormData[name].find(
      (item) => item.lang === selectedLanguage
    );
  
    // Count words and characters
    const words = value.trim().split(/\s+/);
    const characters = value.trim().length;
    const titleWords = value.trim().split(/\s+/);
    setTitleWordCount(MAX_WORD_LIMIT-titleWords.length);
    setTitleCharacterCount(MAX_CHARACTER_LIMIT-value.trim().length);


    if (languageObject) {
      // Check if the word count and character count exceed the limits
      if (words.length <= MAX_WORD_LIMIT && characters <= MAX_CHARACTER_LIMIT) {
        languageObject.text = value;
        calculateTitleCounts(value);
      } else {
        // Display a SweetAlert2 error message for exceeding limits
        Swal.fire({
          icon: "error",
          title: content["Limit Exceeded"],
          text: content["Maximum 200 words and 2000 characters allowed."],
          confirmButtonText: content["OK"]
        });
        return; // Exit the function without updating the state
      }
    } else {
      // Check the word count and character count for a new entry
      if (words.length <= MAX_WORD_LIMIT && characters <= MAX_CHARACTER_LIMIT) {
        updatedFormData[name].push({
          lang: selectedLanguage,
          text: value,
        });
        calculateTitleCounts(value);
      } else {
        // Display a SweetAlert2 error message for exceeding limits
        Swal.fire({
          icon: "error",
          title: content["Limit Exceeded"],
          text: content["Maximum 200 words and 2000 characters allowed."],
          confirmButtonText: content["OK"]
        });
      }
    }
  
    setFormData(updatedFormData);
    setIsModified(true);
  };

  useEffect(() => {
    let descriptionText = formData.description.find((item) => item.lang === selectedLanguage)?.text || "";
    calculateTitleCounts(descriptionText);
  }, [formData.description, selectedLanguage]);

  const handleCheckboxChange1 = (countryData) => {
    const isChecked = selectedCheckboxes.some(
      (country) => country._id === countryData._id
    );

    if (isChecked) {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((country) => country._id !== countryData._id)
        
      );
      setIsModified(true);
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, countryData]);
    }
    setIsModified(true);
  };

  const handleCheckboxChange2 = (langData) => {
    // Check if langData.code is in the selectedCheckboxeslang array
    const isChecked = selectedCheckboxeslang.includes(langData.code);

    if (isChecked) {
      // If it's checked, remove it from the array to uncheck
      setSelectedCheckboxeslang((prevCheckboxes) =>
        prevCheckboxes.filter((code) => code !== langData.code)
      );
      setIsModified(true);
    } else {
      // If it's not checked, add it to the array to check
      setSelectedCheckboxeslang([...selectedCheckboxeslang, langData.code]);
    }
    setIsModified(true);
  };

  // Create a function to filter formData based on selected languages
  const filterFormDataByLanguages = () => {
    const filteredData = {
      name: [],
      description: [],
    };

    // Filter 'name' field
    formData.name.forEach((item) => {
      if (selectedCheckboxeslang.includes(item.lang)) {
        filteredData.name.push(item);
      }
    });

    // Filter 'description' field
    formData.description.forEach((item) => {
      if (selectedCheckboxeslang.includes(item.lang)) {
        filteredData.description.push(item);
      }
    });
    console.log("filteredData:", filteredData);
    return filteredData;
    setIsModified(true);
  };

  useEffect(() => {
    const filteredData = filterFormDataByLanguages();
    setFilteredFormData(filteredData);
  }, [selectedCheckboxeslang, formData]);

  console.log("selectedCheckboxeslang:--", selectedCheckboxeslang);
  const handleAddDropdown = () => {
    if (selectedCountryForAdd) {
      const selectedCountryData = Allcountry.find(
        (country) => country.country === selectedCountryForAdd
      );
      if (selectedCountryData) {
        const newCountrySection = {
          ...selectedCountryData,
        };
        setSelectedCountries([...selectedCountries, newCountrySection]);
        setSelectedCountryForAdd(""); // Clear the selected country for the "Add Dropdown" option
      }
    }
    // setIsModified(true);
  };

  //for language

  const handleAddDropdownlang = () => {
    console.log("selectedLangForAdd-->", selectedLangForAdd);

    const TestValue = Alllanguge.find(
      (languageData) => languageData.language === selectedLangForAdd
    );

    console.log("languageData___1", TestValue);
    if (selectedLangForAdd) {
      const selectedCountryData = Alllanguge.find(
        (languageData) => languageData.language === selectedLangForAdd
      );

      console.log("selectedLangForAdd__1", selectedLangForAdd);
      console.log("selectedCountryData-->", selectedCountryData);
      if (selectedCountryData) {
        const newCountrySection = {
          ...selectedCountryData,
        };
        // console.log("newCountrySection-->",newCountrySection);
        setSelectedlanguges([...selectedlanguges, newCountrySection]);
        setSelectedLangForAdd(""); // Clear the selected country for the "Add Dropdown" option
      }
    }
    // setIsModified(true);
  };

  useEffect(() => {
    const currentActivityId = localStorage.getItem("currentActivityId");
    const responseDataString = localStorage.getItem("Activities");

    if (responseDataString) {
      try {
        const responseData = JSON.parse(responseDataString);
        console.log("______res1____", responseData);

        // Define the id you want to match
        const idToMatch = currentActivityId; // Replace this with the id you want to match

        const matchedActivity = responseData.find(
          (activity) => activity._id === idToMatch
        );

        // Set the matched activity in state
        setMatchedActivityData(matchedActivity);
        console.log("___Response", matchedActivityData);

        GetcountryAPI();
        GetlangugeAPI();
        addLangugeDropdown();
        // Populate formData with matchedActivityData
        if (matchedActivity) {
          setSelectedImageUrlpath(matchedActivity.image || "");
          setSelectedCouponData(matchedActivity.coupon || "");
          console.log("i want data->", matchedActivity);
          setSelectedCountries(matchedActivity.countries || []);
          setSelectedCheckboxes(matchedActivity.countries || []);
          const Activitieslang = localStorage.getItem("Languges");
          const ActivitieslangData = JSON.parse(Activitieslang);

          const formattedNameLanguages = matchedActivity.name.map(
            ({ lang }) => ({
              ...Alllanguge.find((language) => language.code === lang),
              lang,
            })
          );

          console.log("formattedNameLanguages", formattedNameLanguages);

          const selectedLanguage = formattedNameLanguages;

          const filteredData = ActivitieslangData.filter((item) =>
            selectedLanguage.some((selected) => selected.lang === item.code)
          );

          console.log("formattedNameLanguages1", filteredData);
          const langCodes = formattedNameLanguages.map((item) => item.lang);
          console.log("formattedNameLanguages2", langCodes);
          setSelectedCheckboxeslang(langCodes);
          setSelectedlanguges(filteredData);
          //name and description
          setFormData({
            ...formData, // Copy the existing formData
            name: matchedActivity.name,
            description: matchedActivity.description,
          });
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.error("Data with key 'Activities' not found in local storage.");
    }
  }, []);

  const GetcountryAPI = () => {
    if(AccessTokenValid()){
      axios
      .get(API_ENDPOINT + "activity/getCountries", config)
      .then((response) => {
        const country = response.data.countries;
        console.log("Response_______>", response);
        console.log("country", country);

        // Filter out countries that are already selected
        const filteredCountryData = country.filter(
          (country) =>
            !selectedCountries.find(
              (selectedCountry) => selectedCountry._id === country._id
            )
        );

        setAllcountry(filteredCountryData); // Update the galleryImages state
        setAvailableCountries(country);
        localStorage.setItem("countries", JSON.stringify(country));
      })
      .catch((error) => {
        console.error("Failed to fetch Coupons:", error);
      });
    }else{
      CheckedAccessTokenValid()
    }
  };

  useEffect(() => {
    // Whenever selectedCountries change, update availableCountries to exclude selected countries
    const newAvailableCountries = availableCountries.filter(
      (country) =>
        !selectedCountries.find(
          (selectedCountry) => selectedCountry._id === country._id
        )
    );
    // console.log("You--avail-->", newAvailableCountries);
    setAvailableCountries(newAvailableCountries);
  }, [selectedCountries]);

  useEffect(() => {
    const newAvailableLanguage = availableLangs.filter(
      (language) =>
        !selectedlanguges.find(
          (selectedlanguges) => selectedlanguges._id === language._id
        )
    );
    // console.log("You--avail-->", newAvailableCountries);
    setAvailableLangs(newAvailableLanguage);
  }, [selectedlanguges]);

  const addLangugeDropdown = () => {
    // setSelectedlanguge([...selectedlanguges]);
  };

  const GetlangugeAPI = () => {
    if(AccessTokenValid()){
      axios
      .get(API_ENDPOINT + "activity/getLanguages", config)
      .then((response) => {
        const languages = response.data.languages;
        // console.log("Response_______>", response);
        // console.log("Languages", languages);

        // Find the language with code "en" (English) in the API response
        const englishLanguage = languages.find(
          (language) => language.code === "en"
        );

        setAlllanguge(languages); // Update the languages state
        setAvailableLangs(languages);
        localStorage.setItem("Languges", JSON.stringify(languages));
      })
      .catch((error) => {
        console.error("Failed to fetch Languages:", error);
      });
    }else{
      CheckedAccessTokenValid()
    }
    

  };

  //new end code for language

  function getCurrencySymbol(currencyCode) {
    // Define a map of currency codes to symbols
    const currencySymbols = {
      EUR: "€",
      GBP: "£",
      // Add more currency codes and symbols as needed
    };

    // Return the symbol if it exists in the map, or return the currency code itself
    return currencySymbols[currencyCode] || currencyCode;
  }

  function formatDate(milliseconds) {
    const date = new Date(milliseconds);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const resetSelectedCouponData = () => {
    setSelectedCouponData(null);
  };

  const dialogControl = useRef();
  const dialogControlCoupon = useRef();

  console.log("new form______1", formData);

  const postersToAPI = () => {
    if(AccessTokenValid()){
      axios
      .get(API_ENDPOINT + "poster/getPosters", config)
      .then((response) => {
        const posters = response.data.images;
        console.log("Poster_", posters);
        const mappedImages = posters.map((poster) => ({
          id: poster._id,
          // posterUrl: poster.posterUrl.replace(/^(https?:)?\/\/[^/]+/, ""),
          // thumbnailUrl: poster.thumbnailUrl.replace(/^(https?:)?\/\/[^/]+/, ""),
          posterUrl: poster.posterUrl,
          thumbnailUrl: poster.thumbnailUrl,
        }));
        console.log("_____1____EditAci", mappedImages);
        setGalleryImages(mappedImages); // Update the galleryImages state
      })
      .catch((error) => {
        console.error("Failed to fetch posters:", error);
      });
    }else{
      CheckedAccessTokenValid()
    }

   
  };
  function getCurrencySymbol(currencyCode) {
    // Define a map of currency codes to symbols
    const currencySymbols = {
      EUR: "€",
      GBP: "£",
      // Add more currency codes and symbols as needed
    };

    // Return the symbol if it exists in the map, or return the currency code itself
    return currencySymbols[currencyCode] || currencyCode;
  }

  function formatDate(milliseconds) {
    const date = new Date(milliseconds);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const GetCouponAPI = () => {
    if(AccessTokenValid()){
      axios
      .get(API_ENDPOINT + "coupon/getCoupons", config)
      .then((response) => {
        const coupons = response.data.coupons;
        console.log("Response", coupons);
        console.log("_____1____getCoupons", coupons);
        setAllCoupan(coupons); // Update the galleryImages state
        localStorage.setItem("GetCoupon", JSON.stringify(coupons));
      })
      .catch((error) => {
        console.error("Failed to fetch Coupons:", error);
      });
    }else{
      CheckedAccessTokenValid()
    }


  };

  const resetFileInput = () => {
    const fileInput = document.getElementById("file");
    if (fileInput) {
      fileInput.value = ""; // Reset the value to clear the selected file(s)
    }
  };

  // const handleImageUpload = (event) => {
  //   let video = "none";

  //   const file = event.target.files;
  //   const formData = new FormData();
  //   Array.from(file).forEach((item) => {
  //     formData.append("files", item);
  //   });

  //     //encryption data
  //     // const encryptbody = {data:encryption(formData)}
  //     if(AccessTokenValid()){
  //       axios
  //       .post(API_ENDPOINT + "multiPostersUpload", formData , config)
  //       .then((response) => {
  //         const posters1 = response.data.images;
  //         const posters2 = posters1[0].posterUrl;
  //         console.log("Response:--->", posters2);
  //         setSelectedImageUrlpath(posters2);
  
  //         resetFileInput();
  //         handleDialogClose();
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       })
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //     }else{
  //       CheckedAccessTokenValid()
  //     }


  //     setIsModified(true);
  // };
  const handleImageUpload = (event) => {
    const files = event.target.files;
    const maxSizeInBytes = 200 * 1024 * 1024; // 4MB - adjust as needed
    let isOverSize = false;
  
    const formData = new FormData();
    Array.from(files).forEach((item) => {
      if (item.size > maxSizeInBytes) {
        isOverSize = true;
        Swal.fire({
          icon: 'error',
          title: content['File Size Limit Exceeded'],
          confirmButtonText: content["OK"],
          // text: `File ${item.name} exceeds the size limit (4MB). Please select a smaller file.`,
        });
      } else {
        formData.append("files", item);
      }
    });
  
    if (!isOverSize) {
      setIsLoading(true);
      if (AccessTokenValid()) {
        axios
        .post(API_ENDPOINT + "multiPostersUpload", formData, config)
        .then((response) => {
          const result = response.data.result;
    
          if (result === 420) {
            // Show Swal alert for unauthorized error
            Swal.fire({
              icon: "error",
              title: content["Unauthorized"],
              text: content["You are not authorized to perform this action!"],
              confirmButtonText: content["OK"],
            });
            // Optionally, you can handle other logic for unauthorized case here
          } else {
            // Proceed with handling the successful response
            const posters1 = response.data.images;
            const posters2 = posters1[0].posterUrl;
            console.log("Response:--->", posters2);
            setSelectedImageUrlpath(posters2);
            
            resetFileInput();
            handleDialogClose();
          }
        })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        CheckedAccessTokenValid();
        setIsLoading(true);
      }
    }
  };

  const openModalPoster = () => {
    if (dialogControl.current) {
      dialogControl.current.showModal();
    }
  };

  const handleDialogClose = () => {
    dialogControl.current.close();
  };

  const handleVideoCheckboxChange = (index, videoId) => {
    setSelectedCouponIndex(index);
    setSelectedCouponId(videoId);
  };

  // coupon?
  const matchingObjectsarraycoupon = allCoupan.find(
    (coupon) => coupon._id === selectedCouponId
  );

  const matchingObjectscoupon = {
    ...matchingObjectsarraycoupon,
  };
  // coupon?

  console.log("MatchingObject________", matchingObjectscoupon);

  const openModalCoupon = () => {
    if (dialogControlCoupon.current) {
      dialogControlCoupon.current.showModal();
    }
  };

  const handleDialogCouponClose = () => {
    dialogControlCoupon.current.close();
  };

  function handleClick() {
    postersToAPI();
    openModalPoster();
  }

  function handleClickCoupon() {
    GetCouponAPI();
    openModalCoupon();
  }

  const handleCheckboxChange = (index, imageId) => {
    setSelectedImageIndex(index);
    setSelectedImageUrl(imageId);
    setIsModified(true);
  };

  console.log("Image:---", selectedImageUrl);
  console.log("After New", selectedImageUrlpath);
  console.log("Coupon Index:---", selectedCouponIndex);
  console.log("Coupon Id:---", selectedCouponId);

  const handleInputChange_Area = (event) => {
    const { name, value } = event.target;

    const formattedValue = value.replace(/<br\s*\/?>/g, "\n");

    setFormData({ ...formData, [name]: formattedValue });
  };

  const navigate = useNavigate();
  const areAllFieldsFilled = () => {
    return (
      selectedImageUrlpath &&
      // formData.name.some((item) => item.text.trim() !== "") && // Check if at least one name is filled
      selectedCouponData &&
      // formData.description.some((item) => item.text.trim() !== "") && // Check if at least one description is filled
      selectedCheckboxes.length > 0 // Check if at least one checkbox is selected
    );
  };
  const currentActivityId = localStorage.getItem("currentActivityId");
  const handleSubmit = async () => {
    try {

      const requestBody = {
        activity: {
          _id: currentActivityId,
          image: selectedImageUrlpath,
          name: filteredFormData.name,
          coupon: selectedCouponData,
          description: filteredFormData.description,
          couponRule: {
            Rule: "none",
          },
          countries: selectedCheckboxes,
        },
        status: true,
      }

      console.log("Edit:---->",requestBody)
      const { encryptedData, SessionToken } = encryptedaes(requestBody);
      config.headers["Session-Token"] = SessionToken;
      const encryptbody = {data:encryptedData}


      const response = await axios.post(
        API_ENDPOINT + "activity/editActivity",
        // {
        //   activity: {
        //     _id: currentActivityId,
        //     image: selectedImageUrlpath,
        //     name: filteredFormData.name,
        //     coupon: selectedCouponData,
        //     description: filteredFormData.description,
        //     couponRule: {
        //       Rule: "none",
        //     },
        //     countries: selectedCheckboxes,
        //   },
        //   status: true,
        // }
        encryptbody
        ,
        config
      );

      if (response.data.result === 100) {
        // Activity created successfully, show SweetAlert2 success message and navigate
        Swal.fire({
          icon: "success",
          title: content["Success"],
          text: content["Activity created successfully"],
          confirmButtonText:content["OK"]
        }).then(() => {
          navigate("/dashboard/event"); // Redirect to /dashboard/event
        });
      } 
      
      else if (response.data.result === 420) {
        // Unauthorized, show SweetAlert2 unauthorized error message
        Swal.fire({
          icon: "error",
          title: content["Unauthorized"],
          text: content["You are not authorized to perform this action!"],
          confirmButtonText: content["OK"],
        });
      }
      
      else {
        // Handle any error from the API, show SweetAlert2 error message
        Swal.fire({
          icon: "error",
          title: content["Error"],
          text: content["Failed to create activity"],
          confirmButtonText:content["OK"]
        });
        console.error(response.data.activities);
      }
    } catch (error) {
      // Handle any network or other errors
      console.error("Error creating activity", error);
    }
    setIsModified(false);
  };

  const addInputBox = () => {
    if (inputBoxes.length < 4) {
      setInputBoxes([
        ...inputBoxes,
        {
          id: inputBoxes.length + 1,
          name: `Rule ${inputBoxes.length + 2}`,
        },
      ]);
    }
  };

  const filteredCoupons = allCoupan.filter((coupon) => {
    return (
      (selectedFilter === "all" && coupon.state === "enable") ||
      (coupon.type === selectedFilter && coupon.state === "enable")
    );
  });

  return (
    <>
      <NavBar />

      <div className="BodyActivities">
        <div>
          <span>
            <FontAwesomeIcon icon={faChevronLeft} size="xl" />
          </span>
          &nbsp;
          <span
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={() => navigate("/dashboard/event")}
          >
            {content["Back to previous"]}
          </span>
        </div>
        <br />
        <br />
        <div style={{ fontSize: "20px" }}>
          {content["Activities Edit Information"]}
        </div>
        <br />
        <div className="ActivitiesImages">
          <div>
            <span style={{ color: "red" }}>*</span>&nbsp;
            {content["Cover"]}&nbsp;&nbsp;
          </div>
          {/* <div class="container-act">
            {selectedImageUrlpath ? (
              <img
                src={selectedImageUrlpath}
                alt="No Image"
                width="400"
                height="249"
              />
            ) : (
              <img src={noposter} alt="IMG" width="400" height="249" />
            )}

            <div style={{ display: "flex" }}>
              <label class="btn">
                <span class="spanbtn1">
                  {" "}
                  <input
                    type="file"
                    id="file"
                    accept="image/jpeg"
                    onChange={handleImageUpload}
                  />
                  <label htmlFor="file"></label>
                  <label htmlFor="file">{content["Local Upload"]}</label>
                </span>
              </label>
              <a class="btn2" onClick={() => handleClick()}>
                <span class="spanbtn2">{content["Posters"]}</span>
              </a>
            </div>
          </div> */}

<div className="act-container">
                <div className="act-content">
                  <div className="act-wrapper">
                    <div className="act-box">
                      {isLoading ? (
                        <div className="centerspinner">
                          <div className="act-spinner"></div>
                          <p>{content["Uploading"]}</p>
                        </div>
                      ) : selectedImageUrlpath ? (
                        <img
                          src={selectedImageUrlpath}
                          alt="Selected Image"
                          width="400"
                          height="249"
                          style={{objectFit:"cover"}}
                        />
                      ) : null}
                    </div>
                    <div style={{ display: 'flex' }}>
                      <label className="uploadBtn1" disabled={isLoading}>
                      <input
                        type="file"
                        id="file"
                        accept="image/jpeg"
                        onChange={handleImageUpload}
                        disabled={isLoading}
                      />
                      {content["Local Upload"]}
                    </label>
                    <hr style={{ margin: '0 1px', height: '1px', backgroundColor: '#ccc' }} />
                      <button className="uploadBtn2" onClick={handleClick} disabled={isLoading}>
                        {content["Posters"]}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

        </div>
        <br />
        <br />

        <div>
          <label style={{ display: "flex" }}>
            <span style={{ color: "red" }}>*</span>&nbsp; {content["Country"]}
            &nbsp;&nbsp;
            <div style={{ display: "flex",flexWrap:'wrap' }}>
              {selectedCountries.map((country, index) => (
                <div key={country.id}>
                  <label className="custom-radio-button__container">
                    <div style={{ display: "flex", position: "relative" }}>
                      <span className="custom-radio-button designer">
                        {country.country}
                      </span>
                      <span className="checkbox-6">
                        <input
                          type="checkbox"
                          checked={selectedCheckboxes.some(
                            (c) => c._id === country._id
                          )}
                          onChange={() => handleCheckboxChange1(country)}
                          className="checkbox-7"
                        />
                      </span>
                    </div>
                    <br />
                    <br />
                  </label>
                </div>
              ))}
              &nbsp;&nbsp;&nbsp;
              <div>
                {/* <select
                  className="dropdownforCountry"
                  value={selectedCountryForAdd}
                  onChange={(e) => setSelectedCountryForAdd(e.target.value)}
                >
                  <option value="">{content["Select a Country to Add"]}</option>
                  {availableCountries.map((country) => (
                    <option key={country._id} value={country.country}>
                      {country.country}
                    </option>
                  ))}
                </select> */}
                <select
                  className="dropdownforCountry"
                  value={selectedCountryForAdd}
                  onChange={(e) => setSelectedCountryForAdd(e.target.value)}
                >
                  <option value="">{content["Select a Country to Add"]}</option>
                  {availableCountries
                    .filter(
                      (country) =>
                        !selectedCountries.some((sc) => sc._id === country._id)
                    )
                    .map((country) => (
                      <option key={country._id} value={country.country}>
                        {country.country}
                      </option>
                    ))}
                </select>
                <button className="button-5" disabled={selectedCountries.length >= 15} onClick={handleAddDropdown}>
                  {/* {content["Add Country"]} */}
                  {content["Add"]}

                  {/* Add */}
                </button>
              </div>
            </div>
          </label>
        </div>

        <br />
        <br />
        <div>
          <label style={{ display: "flex" }}>
            <span style={{ color: "red" }}>*</span>&nbsp; {content["Language"]} &nbsp;&nbsp;
            <div style={{ display: "flex",flexWrap:'wrap' }}>
              {selectedlanguges.map((language) => (
                <div key={language._id} className="box-6">
                  <label className="custom-radio-button__container">
                    <div style={{ display: "flex", position: "relative" }}>
                      <input
                        type="radio"
                        name="language"
                        value={language.code}
                        checked={selectedLanguage === language.code}
                        onChange={handleLanguageChange}
                      />
                      <span className="custom-radio-button designer">
                        {language.language}
                      </span>
                      <span className="checkbox-6">
                        <input
                          type="checkbox"
                          checked={selectedCheckboxeslang.includes(
                            language.code
                          )}
                          onChange={() => handleCheckboxChange2(language)}
                          className="checkbox-7"
                        />
                      </span>
                    </div>
                    <br />
                    <br />
                  </label>
                </div>
              ))}
              &nbsp;&nbsp;&nbsp;
              <div style={{ display: "flex" }}>
                <div>
                  {/* <select
                    className="dropdownforCountry"
                    value={selectedLangForAdd}
                    onChange={(e) => setSelectedLangForAdd(e.target.value)}
                  >
                    <option value="">Select a Language to Add</option>
                    {availableLangs.map((languageData) => (
                      <option
                        key={languageData._id}
                        value={languageData.language}
                      >
                        {languageData.language}
                      </option>
                    ))}
                  </select> */}

                  <select
                    className="dropdownforCountry"
                    value={selectedLangForAdd}
                    onChange={(e) => setSelectedLangForAdd(e.target.value)}
                  >
                    <option value="">Select a Language to Add</option>
                    {availableLangs
                      .filter(
                        (country) =>
                          !selectedlanguges.some((sc) => sc._id === country._id)
                      )
                      .map((country) => (
                        <option key={country._id} value={country.country}>
                          {country.language}
                        </option>
                      ))}
                  </select>
                </div>
                <div>
                  <a className="button-6" onClick={handleAddDropdownlang}>
                    Add
                  </a>
                </div>
              </div>
            </div>
          </label>
        </div>

        <br />
        <br />
        <div>
          <label>
            <span style={{ color: "red" }}>*</span>&nbsp; {content["Title"]}{" "}
            &nbsp;&nbsp;
            <input
              name="name"
              type="text"
              placeholder={content["Title"]}
              className="act_input1"
              value={
                formData.name.find((item) => item.lang === selectedLanguage)
                  ?.text || ""
              }
              onChange={handleInputChange}
              maxLength={MAX_NAME_LENGTH}
            />
          </label>
        </div>
        <br />
        <br />

        <div style={{ display: "flex" }}>
          <div>
            <span style={{ color: "red" }}>*</span>&nbsp;{content["Coupon"]}
          </div>
          &nbsp;&nbsp;
          <div style={{ display: "flex" }}>
            {/* //preview */}

            {selectedCouponData ? (
              <div className="mainBoxCoupanSelect">
                {selectedCouponData.type === "cash" && (
                  <div className="mainBoxCoupanSelect">
                    <div>
                      <ul>
                        <li style={{ backgroundColor: "rgb(214 183 71)" }}>
                          <h1 style={{ fontSize: "37px", color: "#fff" }}>
                            {selectedCouponData.promotionMethod &&
                            selectedCouponData.promotionMethod.fixedDiscount !==
                              undefined
                              ? `%${selectedCouponData.promotionMethod.fixedDiscount}`
                              : selectedCouponData.promotionMethod &&
                                selectedCouponData.promotionMethod
                                  .fixedAmount !== undefined
                              ? `${getCurrencySymbol(
                                  selectedCouponData.currency
                                )}${
                                  selectedCouponData.promotionMethod.fixedAmount
                                }`
                              : selectedCouponData.promotionMethod &&
                                selectedCouponData.promotionMethod
                                  .randomDiscount !== undefined
                              ? `${getCurrencySymbol(
                                  selectedCouponData.currency
                                )}${
                                  selectedCouponData.promotionMethod
                                    .randomDiscount.low
                                }-${getCurrencySymbol(
                                  selectedCouponData.currency
                                )}${
                                  selectedCouponData.promotionMethod
                                    .randomDiscount.high
                                }`
                              : "N/A"}
                          </h1>
                        </li>
                        <li>
                          <div>
                            <div>
                              <h3 style={{ fontSize: "18px" }}>
                                {selectedCouponData.scopeOfUse
                                  .anualMembership === "true"
                                  ? content["Annual membership recharge"]
                                  : selectedCouponData.scopeOfUse
                                      .allAvailable === "true"
                                  ? content["All Available"]
                                  : selectedCouponData.scopeOfUse
                                      .semiAnnualMembership === "true"
                                  ? content["Semi-Annual membership recharge"]
                                  : selectedCouponData.scopeOfUse
                                      .notAnnualMembership === "true"
                                  ? content["Not Annual membership"]
                                  : selectedCouponData.scopeOfUse
                                      .notSemiAnnualMembership === "true"
                                  ? content["Not Semi-Annual membership"]
                                  : selectedCouponData.scopeOfUse
                                      .notsingleMonthMembership === "true"
                                  ? content["Not Single-Month membership"]
                                  : selectedCouponData.scopeOfUse
                                      .singleMonthMembership === "true"
                                  ? content["Single-Month membership available"]
                                  : content["N/A"]}
                              </h3>
                            </div>
                            <div>
                              <p
                                style={{
                                  fontSize: "larger",
                                  fontWeight: "bold",
                                  color: "gray",
                                }}
                              >
                                {selectedCouponData.expiryDate.permanent
                                  ? content["Permanent"]
                                  : selectedCouponData.expiryDate.startDate &&
                                    selectedCouponData.expiryDate.endDate
                                  ? `${formatDate(
                                      selectedCouponData.expiryDate.startDate
                                    )} - ${formatDate(
                                      selectedCouponData.expiryDate.endDate
                                    )}`
                                  : selectedCouponData.expiryDate
                                      .daysFromActivation
                                  ? `${content["Expires in"]} ${selectedCouponData.expiryDate.daysFromActivation} ${content["Days"]}`
                                  : content["N/A"]}
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {selectedCouponData.type === "voucher" && (
                  <div className="mainBoxGiftSelect">
                    <div>
                      <ul>
                        <li
                          style={{
                            backgroundColor: "rgb(117 165 231)",
                            display: "block",
                          }}
                        >
                          <div>
                            <h2
                              style={{
                                color: "#fff",
                                marginLeft: "33px",
                              }}
                            >
                              {selectedCouponData.type}
                            </h2>
                          </div>
                          <div>
                            <p
                              style={{
                                fontSize: "larger",
                                fontWeight: "bold",
                                color: "rgb(255, 255, 255)",
                                marginLeft: "33px",
                              }}
                            >
                              {/* {selectedCouponData.expiryDate.startDate} -{" "}
                              {selectedCouponData.expiryDate.endDate} */}
                              {selectedCouponData.expiryDate.permanent
                                ? content["Permanent"]
                                : selectedCouponData.expiryDate.startDate &&
                                  selectedCouponData.expiryDate.endDate
                                ? `${formatDate(
                                    selectedCouponData.expiryDate.startDate
                                  )} - ${formatDate(
                                    selectedCouponData.expiryDate.endDate
                                  )}`
                                : selectedCouponData.expiryDate
                                    .daysFromActivation
                                ? `${content["Expires in"]} ${selectedCouponData.expiryDate.daysFromActivation} ${content["Days"]}`
                                : content["N/A"]}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {selectedCouponData.type === "discount" && (
                  <div className="mainBoxCoupanSelect">
                    <div>
                      <ul>
                        <li style={{ backgroundColor: "rgb(255 87 120)" }}>
                          <h1 style={{ fontSize: "37px", color: "#fff" }}>
                            {selectedCouponData.promotionMethod &&
                            selectedCouponData.promotionMethod.fixedDiscount !==
                              undefined
                              ? `${selectedCouponData.promotionMethod.fixedDiscount}%`
                              : selectedCouponData.promotionMethod &&
                                selectedCouponData.promotionMethod
                                  .fixedAmount !== undefined
                              ? `${selectedCouponData.promotionMethod.fixedAmount}%`
                              : selectedCouponData.promotionMethod &&
                                selectedCouponData.promotionMethod
                                  .randomDiscount !== undefined
                              ? `${selectedCouponData.promotionMethod.randomDiscount.low}%-${selectedCouponData.promotionMethod.randomDiscount.high}%`
                              : "N/A"}
                          </h1>
                        </li>
                        <li>
                          <div>
                            <div>
                              <h3 style={{ fontSize: "18px" }}>
                                {selectedCouponData.scopeOfUse
                                  .anualMembership === "true"
                                  ? content["Annual membership recharge"]
                                  : selectedCouponData.scopeOfUse
                                      .allAvailable === "true"
                                  ? content["All Available"]
                                  : selectedCouponData.scopeOfUse
                                      .semiAnnualMembership === "true"
                                  ? content["Semi-Annual membership recharge"]
                                  : selectedCouponData.scopeOfUse
                                      .notAnnualMembership === "true"
                                  ? content["Not Annual membership"]
                                  : selectedCouponData.scopeOfUse
                                      .notSemiAnnualMembership === "true"
                                  ? content["Not Semi-Annual membership"]
                                  : selectedCouponData.scopeOfUse
                                      .notsingleMonthMembership === "true"
                                  ? content["Not Single-Month membership"]
                                  : selectedCouponData.scopeOfUse
                                      .singleMonthMembership === "true"
                                  ? content["Single-Month membership available"]
                                  : content["N/A"]}
                              </h3>
                            </div>
                            <div>
                              <p
                                style={{
                                  fontSize: "larger",
                                  fontWeight: "bold",
                                  color: "gray",
                                }}
                              >
                                {selectedCouponData.expiryDate.permanent
                                  ? content["Permanent"]
                                  : selectedCouponData.expiryDate.startDate &&
                                    selectedCouponData.expiryDate.endDate
                                  ? `${formatDate(
                                      selectedCouponData.expiryDate.startDate
                                    )} - ${formatDate(
                                      selectedCouponData.expiryDate.endDate
                                    )}`
                                  : selectedCouponData.expiryDate
                                      .daysFromActivation
                                  ? `${content["Expires in"]} ${selectedCouponData.expiryDate.daysFromActivation} ${content["Days"]}`
                                  : content["N/A"]}
                              </p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                <div style={{ margin: "14px" }}>
                  ({content["Stock"]}:{selectedCouponData.quantity})
                </div>
              </div>
            ) : null}

            <div>
              <button
                className="actbtn1"
                style={{ margin: "0px 0px 0px 21px" }}
                onClick={() => handleClickCoupon()}
              >
                + {content["Coupon"]}
              </button>
            </div>
          </div>
        </div>

        <br />
        <br />
        <div className="wrapperact">
          <label>
            <span>
              <span style={{ color: "red" }}>*</span>
              &nbsp;{content["Description"]}&nbsp;&nbsp;
            </span>

            <textarea
              name="description"
              spellCheck="false"
              placeholder={content["Description(Max 200 words & 2000 Characters)"]}
              value={
                formData.description.find(
                  (item) => item.lang === selectedLanguage
                )?.text || ""
              }
              onChange={handleInputChange}
              maxLength={MAX_DESCRIPTION_LENGTH}
            />
            {/* <span className="countwordchar">
            {titleWordCount}/{titleCharacterCount}
            </span> */}
          </label>
        </div>
        <br />
        <br />
        <div style={{ padding: "0px 0px 0px 76px" }}>
          <button
            className="actbtn"
            // disabled={!areAllFieldsFilled()}
            disabled={!isModified || !areAllFieldsFilled()}
            onClick={handleSubmit}
          >
            {content["Save"]}
          </button>
        </div>
      </div>

      {/* //Poster Gallery */}
      <dialog
        ref={dialogControl}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "69%",
          background: "#fff",
          zIndex: 9999,
          border: "none",
          border: "none",
        }}
      >
        <div>
          <div className="header-app">
            <div>{content["Choose a poster"]}</div>
            <div>
              <ImCross
                style={{ cursor: "pointer" }}
                className="btnClose"
                width={100}
                onClick={handleDialogClose}
              />
            </div>
          </div>
          <div className="body-app">
            <div className="card-container-app">
              {galleryImages.map((image, index) => (
                <div key={image.id} className="tile">
                  <label
                    htmlFor={`sport${image.id}`}
                    className="label-container"
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={image.thumbnailUrl}
                      alt={`Image ${index + 1}`}
                    />
                    <input
                      type="checkbox"
                      checked={index === selectedImageIndex}
                      onChange={() =>
                        handleCheckboxChange(index, image.posterUrl)
                      }
                      name="sports"
                      id={`sport${image.id}`}
                      className="hidden-checkbox"
                    />
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="footer-app" style={{ bottom: "8px" }}>
            <div
              className="btn-save"
              onClick={() => {
                handleDialogClose();
                setSelectedImageUrlpath(selectedImageUrl);
                console.log(
                  selectedImageUrl,
                  "updatedItemUrl+______________4_______"
                );
                console.log(
                  selectedImageUrlpath,
                  "AfterupdatedItemUrl+______________5_______"
                );
              }}
            >
              {content["OK"]}
            </div>
            <button className="btn-cancel" onClick={() => handleDialogClose()}>
              {content["Cancel"]}
            </button>
          </div>
        </div>
      </dialog>

      {/* //Coupon */}
      <dialog
        ref={dialogControlCoupon}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "73%",
          background: "#fff",
          zIndex: 9999,
          border: "none",
        }}
      >
        <div>
          <div className="header-app">
            <div>
              <span style={{ color: "#3eccfd" }}>
                {content["Choose a Coupon"]}
              </span>
              <br />

              <select
                // className="select-element"
                className="dropdownforCountry"
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
              >
                <option value="all">{content["All"]}</option>
                <option value="cash">{content["Cash Coupons"]}</option>
                <option value="voucher">{content["Voucher Coupons"]}</option>
                <option value="discount">{content["Discount Coupons"]}</option>
              </select>
            </div>
            <div>
              <ImCross
                style={{ cursor: "pointer" }}
                className="btnClose"
                width={100}
                onClick={handleDialogCouponClose}
              />
            </div>
          </div>

          <div className="body-app">
            <div className="mainBox-card-container-app">
              {filteredCoupons.map((coupon, index) => (
                <div key={coupon._id}>
                  <label
                    htmlFor={`coupon${coupon._id}`}
                    className="label-container-act"
                  >
                    {coupon.type === "cash" && (
                      <div className="mainBoxCoupanSelect">
                        <div>
                          <ul>
                            <li style={{ backgroundColor: "rgb(214 183 71)" }}>
                              <h1 style={{ fontSize: "37px", color: "#fff" }}>
                                {coupon.promotionMethod &&
                                coupon.promotionMethod.fixedDiscount !==
                                  undefined
                                  ? `%${coupon.promotionMethod.fixedDiscount}`
                                  : coupon.promotionMethod &&
                                    coupon.promotionMethod.fixedAmount !==
                                      undefined
                                  ? `${getCurrencySymbol(coupon.currency)}${
                                      coupon.promotionMethod.fixedAmount
                                    }`
                                  : coupon.promotionMethod &&
                                    coupon.promotionMethod.randomDiscount !==
                                      undefined
                                  ? `${getCurrencySymbol(coupon.currency)}${
                                      coupon.promotionMethod.randomDiscount.low
                                    }-${getCurrencySymbol(coupon.currency)}${
                                      coupon.promotionMethod.randomDiscount.high
                                    }`
                                  : "N/A"}
                              </h1>
                            </li>
                            <li>
                              <div>
                                <div>
                                  <h3 style={{ fontSize: "18px" }}>
                                    {coupon.scopeOfUse.anualMembership ===
                                    "true"
                                      ? content["Annual membership recharge"]
                                      : coupon.scopeOfUse.allAvailable ===
                                        "true"
                                      ? content["All Available"]
                                      : coupon.scopeOfUse
                                          .semiAnnualMembership === "true"
                                      ? content[
                                          "Semi-Annual membership recharge"
                                        ]
                                      : coupon.scopeOfUse
                                          .notAnnualMembership === "true"
                                      ? content["Not Annual membership"]
                                      : coupon.scopeOfUse
                                          .notSemiAnnualMembership === "true"
                                      ? content["Not Semi-Annual membership"]
                                      : coupon.scopeOfUse
                                          .notsingleMonthMembership === "true"
                                      ? content["Not Single-Month membership"]
                                      : coupon.scopeOfUse
                                          .singleMonthMembership === "true"
                                      ? content[
                                          "Single-Month membership available"
                                        ]
                                      : content["N/A"]}
                                  </h3>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      fontSize: "larger",
                                      fontWeight: "bold",
                                      color: "gray",
                                    }}
                                  >
                                    {coupon.expiryDate.permanent
                                      ? content["Permanent"]
                                      : coupon.expiryDate.startDate &&
                                        coupon.expiryDate.endDate
                                      ? `${formatDate(
                                          coupon.expiryDate.startDate
                                        )} - ${formatDate(
                                          coupon.expiryDate.endDate
                                        )}`
                                      : coupon.expiryDate.daysFromActivation
                                      ? `${content["Expires in"]} ${coupon.expiryDate.daysFromActivation} ${content["Days"]}`
                                      : content["N/A"]}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div style={{ margin: "35px 0px 0px 10px" }}>
                          <span
                            style={{
                              fontSize: "larger",
                              fontWeight: "bold",
                              color: "gray",
                            }}
                          >
                            {content["Stock"]}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "larger",
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            {coupon.quantity}
                          </span>
                        </div>
                      </div>
                    )}
                    {coupon.type === "voucher" && (
                      <div className="mainBoxGiftSelect">
                        <div>
                          <ul>
                            <li
                              style={{
                                backgroundColor: "rgb(117 165 231)",
                                display: "block",
                              }}
                            >
                              <div>
                                <h2
                                  style={{
                                    color: "#fff",
                                    marginLeft: "33px",
                                  }}
                                >
                                  {coupon.type}
                                </h2>
                              </div>
                              <div>
                                <p
                                  style={{
                                    fontSize: "larger",
                                    fontWeight: "bold",
                                    color: "rgb(255, 255, 255)",
                                    marginLeft: "33px",
                                  }}
                                >
                                  {/* {coupon.expiryDate.startDate} -{" "}
                                  {coupon.expiryDate.endDate} */}
                                  {coupon.expiryDate.permanent
                                    ? content["Permanent"]
                                    : coupon.expiryDate.startDate &&
                                      coupon.expiryDate.endDate
                                    ? `${formatDate(
                                        coupon.expiryDate.startDate
                                      )} - ${formatDate(
                                        coupon.expiryDate.endDate
                                      )}`
                                    : coupon.expiryDate.daysFromActivation
                                    ? `${content["Expires in"]} ${coupon.expiryDate.daysFromActivation} ${content["Days"]}`
                                    : content["N/A"]}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div style={{ margin: "35px 0px 0px 10px" }}>
                          <span
                            style={{
                              fontSize: "larger",
                              fontWeight: "bold",
                              color: "gray",
                            }}
                          >
                            {content["Stock"]}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "larger",
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            {coupon.quantity}
                          </span>
                        </div>
                      </div>
                    )}
                    {coupon.type === "discount" && (
                      <div className="mainBoxCoupanSelect">
                        <div>
                          <ul>
                            <li style={{ backgroundColor: "rgb(255 87 120)" }}>
                              <h1 style={{ fontSize: "37px", color: "#fff" }}>
                                {coupon.promotionMethod &&
                                coupon.promotionMethod.fixedDiscount !==
                                  undefined
                                  ? `${coupon.promotionMethod.fixedDiscount}%`
                                  : coupon.promotionMethod &&
                                    coupon.promotionMethod.fixedAmount !==
                                      undefined
                                  ? `${coupon.promotionMethod.fixedAmount}%`
                                  : coupon.promotionMethod &&
                                    coupon.promotionMethod.randomDiscount !==
                                      undefined
                                  ? `${coupon.promotionMethod.randomDiscount.low}%-${coupon.promotionMethod.randomDiscount.high}%`
                                  : "N/A"}
                              </h1>
                            </li>
                            <li>
                              <div>
                                <div>
                                  <h3 style={{ fontSize: "18px" }}>
                                    {coupon.scopeOfUse.anualMembership ===
                                    "true"
                                      ? content["Annual membership recharge"]
                                      : coupon.scopeOfUse.allAvailable ===
                                        "true"
                                      ? content["All Available"]
                                      : coupon.scopeOfUse
                                          .semiAnnualMembership === "true"
                                      ? content[
                                          "Semi-Annual membership recharge"
                                        ]
                                      : coupon.scopeOfUse
                                          .notAnnualMembership === "true"
                                      ? content["Not Annual membership"]
                                      : coupon.scopeOfUse
                                          .notSemiAnnualMembership === "true"
                                      ? content["Not Semi-Annual membership"]
                                      : coupon.scopeOfUse
                                          .notsingleMonthMembership === "true"
                                      ? content["Not Single-Month membership"]
                                      : coupon.scopeOfUse
                                          .singleMonthMembership === "true"
                                      ? content[
                                          "Single-Month membership available"
                                        ]
                                      : content["N/A"]}
                                  </h3>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      fontSize: "larger",
                                      fontWeight: "bold",
                                      color: "gray",
                                    }}
                                  >
                                    {/* {coupon.expiryDate.startDate} -{" "}
                                    {coupon.expiryDate.endDate} */}
                                    {coupon.expiryDate.permanent
                                      ? content["Permanent"]
                                      : coupon.expiryDate.startDate &&
                                        coupon.expiryDate.endDate
                                      ? `${formatDate(
                                          coupon.expiryDate.startDate
                                        )} - ${formatDate(
                                          coupon.expiryDate.endDate
                                        )}`
                                      : coupon.expiryDate.daysFromActivation
                                      ? `${content["Expires in"]} ${coupon.expiryDate.daysFromActivation} ${content["Days"]}`
                                      : content["N/A"]}
                                  </p>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div style={{ margin: "35px 0px 0px 10px" }}>
                          <span
                            style={{
                              fontSize: "larger",
                              fontWeight: "bold",
                              color: "gray",
                            }}
                          >
                            {content["Stock"]}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "larger",
                              fontWeight: "bold",
                              color: "black",
                            }}
                          >
                            {coupon.quantity}
                          </span>
                        </div>
                      </div>
                    )}

                    <input
                      type="checkbox"
                      checked={index === selectedCouponIndex}
                      onChange={() => {
                        setSelectedCouponIndex(index);
                        setSelectedCouponId(coupon._id);
                      }}
                      name="coupons"
                      id={`coupon${coupon._id}`}
                      className="hidden-checkbox-act"
                    />
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="footer-app" style={{ bottom: "8px" }}>
            <div
              className="btn-save"
              onClick={() => {
                handleDialogCouponClose();
                setSelectedCouponData(matchingObjectscoupon);
                setIsModified(true);
                console.log("Selected Coupon ID:", selectedCouponId);
              }}
            >
              {content["OK"]}
            </div>
            <button className="btn-cancel" onClick={handleDialogCouponClose}>
              {content["Cancel"]}
            </button>
          </div>
        </div>
      </dialog>
    </>
  );
}

export default EditActivity;
