import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./account.css";
import logout from "../../../img/logout_copy.png";
import logo from "../../../img/wf-c.png";
import useLanguage from "../../../translation/TranslationEngToChinese";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { ImCross } from "react-icons/im";
import { apiBaseUrls, apiKeys } from "../../../config";
// import { encryption } from "../../../utils/encrypt/encryptUtils";
import {encryptedaes} from "../../../utils/encrypt/encryptUtils";
import { removeAllLocalStorageItems ,CheckedAccessTokenValid , AccessTokenValid } from "../../../utils/LogAuth";
const API_ENDPOINT = apiBaseUrls.development;

function Account() {
  const dialogControl = useRef();
  const { language, setLanguage, content } = useOutletContext();
  // new_code
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [userId, SetuserId] = useState("");

  const loginUser = localStorage.getItem("accessToken");
  const dataArray = loginUser ? loginUser.split("#") : []; // Add a check for loginUser
  const Currentemail = dataArray[0] || null; // Set Currentemail to null if dataArray is empty

  const [formData, setFormData] = useState({
    userId: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    registerUser();
  };


const config = {
  headers: {
    "X-API-KEY": apiKeys.development,
    "User-Id": localStorage.getItem("UserId"),
    "Session-Token": "",
  },
};



  // Calculate whether the button should be enabled or disabled
  const isButtonDisabled = formData.password.length < 6;


  const registerUser = () => {
    const apiUrl = API_ENDPOINT + "user/register";
    const requestData = {
      ...formData,
      name: "test", // Default name
      profileInfo: "test", // Default profile info
      role: "test", // Default role
    };


      const { encryptedData, SessionToken } = encryptedaes(requestData);
      config.headers["Session-Token"] = SessionToken;
      const encryptbody = {data:encryptedData}


      if(AccessTokenValid()){
        axios
        .post(apiUrl, encryptbody, config)
        .then((response) => {
          const result = response.data.result;
    
          if (result === 100) {
            // User registered successfully
            console.log("User registered successfully");
    
            // Fetch the updated table data after registration
            fetchTableData();
          } else if (result === 420) {
            dialogControl.current.close();
            // Show Swal alert for unauthorized error
            Swal.fire({
              icon: 'error',
              title: content["Unauthorized"],
              text: content["You are not authorized to perform this action!"],
              confirmButtonText: content["OK"],
            });
          } else {
            console.log("User registration failed");
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }else{
        CheckedAccessTokenValid()
      }

  };



  const fetchTableData = () => {
    const apiUrl = API_ENDPOINT + "user/users";
    if(AccessTokenValid()){
      axios
      .get(apiUrl, config)
      .then((response) => {
        // Update the users state with the fetched data
        setUsers(response.data.users);

        // Close the dialog after updating the data
        handleDialogClose();
      })
      .catch((error) => {
        console.log("Error fetching table data", error);
      });
    }else{
      CheckedAccessTokenValid()
    }


  };

  const handleDialogClose = () => {
    setFormData({ userId: "", password: "" });
    dialogControl.current.close();
  };

  ////close-register

  const apiurl = API_ENDPOINT + "user/users";

  useEffect(() => {

    if(AccessTokenValid()){
      axios
      .get(apiurl, config) // Modify the API endpoint URL if needed
      .then((response) => {
        setUsers(response.data.users);
        console.log("api", response.data.users);
      })
      .catch((error) => {
        console.log(error);
      }); 
    }else{
      CheckedAccessTokenValid()
    }



  }, []);



  // const handleEdit = (userId,password) => {
  //   console.log("This is a current password",password)
  //   Swal.fire({
  //     title: "",
  //     html: `
  //       <input type="password" id="password" class="swal2-input" style="width: 82%;" placeholder="${content["New Password"]}" maxlength="10">
  //             <span style="float: left;margin-left: 41px;">
  //               ${content["Password length at least 6 characters"]}
  //              </span>
  //     `,
  //     showCancelButton: true,
  //     cancelButtonText: content["Cancel"],
  //     // cancelButtonColor: "rgb(242 242 242)",
  //     cancelButtonColor: "rgb(184 184 184)",
  //     confirmButtonText: content["Sure"],
  //     backdrop: false,
  //     preConfirm: () => {
  //       const password = Swal.getPopup().querySelector("#password").value;

  //       if (password.length < 6) {
  //         Swal.showValidationMessage(
  //           content["Password should be at least 6 characters"]
  //         );
  //         return false;
  //       }

  //               // Request body
  //               const requestBody = 
  //               {
  //                 userId,
  //                 password,
  //               }

  //                 //encryption data
  //   // const encryptbody = {data:encryption(requestBody)}
  //   const { encryptedData, SessionToken } = encryptedaes(requestBody);
  //   config.headers["Session-Token"] = SessionToken;
     
  //   const encryptbody = {data:encryptedData}


  //       // Call your API to update the password using the provided `userId` and `password`
  //       return axios
  //       .post(
  //         API_ENDPOINT + "user/password/update",
  //         encryptbody,
  //         config
  //       )
  //       .then((response) => {
  //         const result = response.data.result;

  //         if (result === 100) {
  //           // Handle the API response
  //           Swal.fire(
  //             content["Success"],
  //             content["Password updated successfully"],
  //             "success"
  //           );

  //           // Update the users state with the updated user data
  //           const updatedUsers = users.map((user) => {
  //             if (user.userId === userId) {
  //               return {
  //                 ...user,
  //                 password: password, // Update the password field in the user object
  //               };
  //             }
  //             return user;
  //           });
  //           setUsers(updatedUsers);
  //         } else if (result === 420) {
  //           // Show Swal alert for unauthorized error
  //           Swal.fire({
  //             icon: 'error',
  //             title: content["Unauthorized"],
  //             text: content["You are not authorized to perform this action!"],
  //             confirmButtonText: content["OK"],
  //           });
  //           // Optionally, you can handle other logic for unauthorized case here
  //         } else {
  //           // Handle any other error from the API, log the error
  //           console.error("Failed to update password:", response.data.error);
  //           Swal.fire(
  //             content["Error"],
  //             content["Failed to update password"],
  //             "error"
  //           );
  //         }
  //       })
  //         .catch((error) => {
  //           // Handle API error
  //           Swal.fire(
  //             content["Error"],
  //             content["Failed to update password"],
  //             "error"
  //           );
  //         });
  //     },
  //     didOpen: () => {
  //       const passwordInput = Swal.getPopup().querySelector("#password");
  //       const confirmButton = Swal.getConfirmButton();

  //       // Disable the button initially
  //       confirmButton.disabled = true;

  //       // Add an event listener to the password input
  //       passwordInput.addEventListener("input", () => {
  //         // Enable the button if the password length is at least 6 characters
  //         confirmButton.disabled = passwordInput.value.length < 6;
  //       });
  //     },
  //   });
  // };

  const handleEdit = (userId, password) => {
    Swal.fire({
      title: "",
      html: `
        <input type="password" id="password" class="swal2-input" style="width: 82%;" placeholder="${content["New Password"]}" maxlength="10">
        <span style="float: left;margin-left: 41px;">
          ${content["Password length at least 6 characters"]}
        </span>
      `,
      showCancelButton: true,
      cancelButtonText: content["Cancel"],
      cancelButtonColor: "rgb(184 184 184)",
      confirmButtonText: content["Sure"],
      backdrop: false,
      preConfirm: () => {
        const newPassword = Swal.getPopup().querySelector("#password").value;
  
        if (newPassword === password) {
          Swal.showValidationMessage(content["New password cannot be the same as the old password."]);
          return false;
        }
  
        if (newPassword.length < 6) {
          Swal.showValidationMessage(content["Password should be at least 6 characters."]);
          return false;
        }
  
        // Request body
        const requestBody = {
          userId,
          password: newPassword,
        };
  
        const { encryptedData, SessionToken } = encryptedaes(requestBody);
        config.headers["Session-Token"] = SessionToken;
  
        const encryptbody = { data: encryptedData };
  
        return axios
          .post(API_ENDPOINT + "user/password/update", encryptbody, config)
          .then((response) => {
            const result = response.data.result;
  
            if (result === 100) {
              // Handle the API response
              Swal.fire(content["Success"], content["Password updated successfully"], "success");
  
              // Update the users state with the updated user data
              const updatedUsers = users.map((user) => {
                if (user.userId === userId) {
                  return {
                    ...user,
                    password: newPassword, 
                  };
                }
                return user;
              });
              setUsers(updatedUsers);
            } else if (result === 420) {
              Swal.fire({
                icon: 'error',
                title: content["Unauthorized"],
                text: content["You are not authorized to perform this action!"],
                confirmButtonText: content["OK"],
              });
            } else {
              console.error("Failed to update password:", response.data.error);
              Swal.fire(content["Error"], content["Failed to update password"], "error");
            }
          })
          .catch((error) => {
            // Handle API error
            Swal.fire(content["Error"], content["Failed to update password"], "error");
          });
      },
      didOpen: () => {
        const passwordInput = Swal.getPopup().querySelector("#password");
        const confirmButton = Swal.getConfirmButton();
  
        confirmButton.disabled = true;
  
        passwordInput.addEventListener("input", () => {
          confirmButton.disabled = passwordInput.value.length < 6;
        });
      },
    });
  };
  

  const handleCheck = (userId) => {
    // const Currentemail = "ujjwalbxr114@gmail.com"; // Set the current logged-in user's email
  
    // Check if the user is trying to change their own state
    if (userId === Currentemail) {
      Swal.fire({
        title: content["You cannot disable your own account"],
        // text: "You cannot change your own state.",
        icon: "warning",
        confirmButtonText:content["OK"]
      });
    } else {
      // Handle the logic for changing state for other users here
      // For example, you can call the `handleState` function here
      handleState(userId);
    }
  };
  

  const handleState = (userId) => {
    // Check the current state of the user
    const userIndex = users.findIndex((user) => user.userId === userId);
    if (userIndex === -1) {
      console.log("User not found");
      return;
    }
    const currentUser = users[userIndex];
    const currentState = currentUser.state;

    // Determine the new state based on the current state
    const newState = currentState === "Available" ? "Disabled" : "Available";

    // Make the API request to update the user state
    const apiUrl = API_ENDPOINT + "user/state/update";
    const requestData = {
      userId: userId,
      state: newState,
    };

      //encryption data
      // const encryptbody = {data:encryption(requestData)}
      const { encryptedData, SessionToken } = encryptedaes(requestData);
      config.headers["Session-Token"] = SessionToken;
       
      const encryptbody = {data:encryptedData}
      if(AccessTokenValid()){
        axios
        .post(apiUrl, encryptbody, config)
        .then((response) => {
          const result = response.data.result;
    
          if (result === 100) {
            // Handle the API response if needed
            console.log("State updated successfully:", response.data);
    
            // Update the state in the users array
            const updatedUser = { ...currentUser, state: newState };
            const updatedUsers = [...users];
            updatedUsers[userIndex] = updatedUser;
            setUsers(updatedUsers);
          } else if (result === 420) {
            // Show Swal alert for unauthorized error
            Swal.fire({
              icon: 'error',
              title: content["Unauthorized"],
              text: content["You are not authorized to perform this action!"],
              confirmButtonText: content["OK"],
            });
            // Optionally, you can handle other logic for unauthorized case here
          } else {
            // Handle any other error from the API, log the error
            console.error("Error updating state:", response.data.error);
          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the API request
          console.log("Error updating state:", error);
        });
      }else{
        CheckedAccessTokenValid()
      }



  };



  const handleDelete = (userId) => {
    Swal.fire({
      title: content["Are you sure?"],
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: content["Delete"] ,
      cancelButtonText: content["Cancel"] ,
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed the deletion, send the request to delete the user
        deleteUser(userId);
      }
    });
  };


  const deleteUser = (userId) => {
    const apiUrl = API_ENDPOINT + "user/user/delete";
    const requestBody = { userId }
      //encryption data
      // const encryptbody = {data:encryption(requestBody)}
      const { encryptedData, SessionToken } = encryptedaes(requestBody);
      config.headers["Session-Token"] = SessionToken;
       
      const encryptbody = {data:encryptedData}
      if(AccessTokenValid()){
        axios
      .post(apiUrl, encryptbody, config)
      .then((response) => {
        const result = response.data.result;

        if (result === 100) {
          // Handle the response after successful deletion
          console.log("User deleted successfully");

          // Update the users state by removing the deleted user
          setUsers((prevUsers) =>
            prevUsers.filter((user) => user.userId !== userId)
          );
        } else if (result === 420) {
          // Show Swal alert for unauthorized error
          Swal.fire({
            icon: "error",
            title: content["Unauthorized"],
            text: content["You are not authorized to perform this action!"],
            confirmButtonText: content["OK"],
          });
          // Optionally, you can handle other logic for unauthorized case here
        } else {
          // Handle any other error from the API, log the error
          console.error("Error deleting user:", response.data.error);
        }
      })
        .catch((error) => {
          // Handle the error if deletion fails
          console.log("Error deleting user", error);
        });
      }else{
        CheckedAccessTokenValid()
      }


  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };



  const handleCountUsers = () => {
    // alert(`Existing accounts: ${users.length}`);
  };

  //old_Search
  // const filteredUsers = users
  //   .filter((user) => {
  //     return user.userId.toLowerCase().includes(searchValue.toLowerCase());
  //   })
  //   .map((user) => {
  //     return {
  //       ...user,
  //       createtime: new Date(user.createtime).toLocaleString(),
  //       lastlogin: new Date(user.lastlogin).toLocaleString(),
  //     };
  //   });

  const filteredUsers = users
  .filter((user) => {
      const searchLowerCase = searchValue.toLowerCase();
      const userLastLogin = new Date(user.lastLogin);
      const searchDate = new Date(searchValue);

      return (
          user.userId.toLowerCase().includes(searchLowerCase) ||
          user.role.toLowerCase().includes(searchLowerCase) ||
          user.createtime.includes(searchValue) || // Assuming `createtime` is a string
          (userLastLogin instanceof Date && userLastLogin.getTime() === searchDate.getTime()) ||
          user.state.toLowerCase().includes(searchLowerCase)
      );
  })
  .map((user) => {
      return {
          ...user,
          createtime: new Date(user.createtime).toLocaleString(),
          lastLogin: new Date(user.lastLogin).toLocaleString(), // Corrected property name to match your map transformation
      };
  });



  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();



  const handleLogout = () => {
    const accessToken = localStorage.getItem("accessToken");
    const userId = accessToken.split("#")[0];
    console.log("Test User_id", userId);
    const requestBody = { accessToken, userId }
      //encryption data
      // const encryptbody = {data:encryption(requestBody)}
      const { encryptedData, SessionToken } = encryptedaes(requestBody);
      config.headers["Session-Token"] = SessionToken;
       
      const encryptbody = {data:encryptedData}

      
    axios
      .post(API_ENDPOINT + "user/logout", encryptbody ,config)
      .then(() => {
        // localStorage.removeItem("accessToken");
        removeAllLocalStorageItems()
        document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log("apiurl", apiurl + "user/logout");

  return (
    <>
      <span style={{ position: "fixed", top: "4%", right: "297px" , color:"gray" }}>
        {Currentemail}
      </span>
      <div
        className="mainpage"
        style={{
          textAlign: "right",
          marginBottom: "10px",
          marginLeft: "-3px",
          marginRight: "6px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <span><img src={logo} className="logoimage" alt="" /><span style={{verticalAlign: 'middle'}}>WhaleFit Content Management Platform</span></span> */}
          <div>
            <img
              style={{ width: "40px" }}
              src={logo}
              className="logoimage"
              alt=""
            />
            &nbsp;
          </div>
          <div style={{ fontSize: "large", fontWeight: 700 }}>
            {content["WhaleFit Content Management Platform"]}
          </div>
        </div>

        <div className="lang">
          <select
            value={language}
            onChange={(e) => {
              setLanguage(e.target.value);
            }}
          >
            <option>English</option>
            <option>中国人</option>
          </select>
        </div>



        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={handleLogout}
        >
          {/* <span><img src={logo} className="logoimage" alt="" /><span style={{verticalAlign: 'middle'}}>WhaleFit Content Management Platform</span></span> */}

          <div>
            <img
              style={{ width: "31px" }}
              src={logout}
              className="logoimage"
              alt=""
            />
            &nbsp;
          </div>
          <div style={{ fontSize: "large", fontWeight: 700 }}>
            {content["Logout"]}
          </div>
        </div>

      </div>

      <br />

      {/* new_code */}
      <div
        className="mainpage"
        style={{
          textAlign: "right",
          marginBottom: "10px",
          marginLeft: "10px",
          marginRight: "-6px",
        }}
      >
        <div>

          <div style={{ fontSize: "3.3vh", fontWeight: 500 }}>
            {content["Accounts"]}({users.length})
          </div>
        </div>

   

        <div style={{ display: "flex" }}>

          <button
            onClick={() => dialogControl.current.showModal()}
            className="btn_add"
          >
            {content["Add Account"]}
          </button>
          &nbsp;&nbsp;
  
          <div className="search-container">
            <form>
              <input
                type="text"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder={content["Enter Title/Keyword"]}
                style={{ padding: "8px", marginRight: "10px" }}
                name="search"
              />
              <button type="submit">
                <i class="fa fa-search"></i>
              </button>
            </form>
          </div>
        </div>
      </div>

 

      <dialog
        ref={dialogControl}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "gray" /* adjust the opacity as needed */,
          zIndex: 9999,
          border: "none",
          border: "2px solid gray",
        }}
      >
        <div className="loginform">
          <form onSubmit={handleSubmit}>
            <div
              className="forms"
              style={{
                width: "26%",
                height: "329px",
                padding: "81px 30px 80px 30px",
                margin: "154px 0px 0px 610px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  <h1>{content["Add Account"]}</h1>
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dialogControl.current.close();
                  }}
                >
                  <ImCross />
                </span>
              </div>

              <input
                type="email"
                name="userId"
                value={formData.userId}
                onChange={handleChange}
                className="logininput"
                placeholder={content["Account"]}
                maxLength={50}
                required
              />

              <div className="loginmain">

                <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="logininput2"
                placeholder={content["Password"]}
                maxLength={10}
                required
              />
              </div>
              <span className="spanone">
                {content["Password length at least 6 characters"]}
              </span>
              <span className="spanone">{content[""]}</span>
              <br />
              {/* <button className="bgonethree">{content["Finish"]}</button> */}
              <button className="bgonethree" disabled={isButtonDisabled}>
      {content["Finish"]}
    </button>
            </div>
          </form>
        </div>
      </dialog>

      <div style={{ overflow: "auto", height: "82vh" }}>
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>


            <tr>
              <th
                style={{
                  ...tableHeaderStyle,
                  position: "sticky",
                  top: "0",
                  color: "#a9afc3",
                  textAlign: "left",
                }}
              >
                {content["Account/Password"]}
              </th>

              <th
                style={{
                  ...tableHeaderStyle,
                  position: "sticky",
                  top: "0",
                  color: "#a9afc3",
                  textAlign: "left",
                }}
              >
                {content["Role"]}
              </th>


              <th
                style={{
                  ...tableHeaderStyle,
                  position: "sticky",
                  top: "0",
                  color: "#a9afc3",
                  textAlign: "left",
                }}
              >
                {content["Creation Date"]}
              </th>
              <th
                style={{
                  ...tableHeaderStyle,
                  position: "sticky",
                  top: "0",
                  color: "#a9afc3",
                  textAlign: "left",
                }}
              >
                {content["Last Login"]}
              </th>
              <th
                style={{
                  ...tableHeaderStyle,
                  position: "sticky",
                  top: "0",
                  color: "#a9afc3",
                  textAlign: "left",
                }}
              >
                {content["State"]}
              </th>
              <th
                style={{
                  ...tableHeaderStyle,
                  position: "sticky",
                  top: "0",
                  color: "#a9afc3",
                }}
              >
                {content["Operate"]}
              </th>
            </tr>
          </thead>
          <tbody>
           

            {filteredUsers.map((user) => (
              <tr key={user.userId}>
                <td className="tdData">
                  <span className="usertext">{user._id}</span>
                  <br />
                  {/* <p className="ptext">{user.password}</p> */}
                  <p className="ptext">
                    {user.password.charAt(0)}{'*'.repeat(user.password.length - 2)}{user.password.charAt(user.password.length - 1)}
                  </p>
                </td>
                <td className="tdData">
                  <span className="usertext">
                    {user.role}
                  </span>
                </td>
                <td className="tdData">
                  <span className="usertext">
                    {moment(user.createtime).format("YYYY/M/DD")}
                  </span>
                </td>
                <td className="tdData">
                  <span className="usertext">
                    {moment(user.lastLogin).format("YYYY/M/DD h:mm:ss A")}
                  </span>
                </td>
                <td className="tdvalue">
                  <span
                    className={`usertext ${
                      user.state === "Disabled" ? "gray-text" : ""
                    }`}
                  >
                    {user.state === "Available"
                      ? content["Available"]
                      : content["Disabled"]}
                  </span>
                </td>

                <td className="tdvaluetwo">
                  <a
                    // onClick={() => handleEdit(user.userId)}
                    onClick={() => handleEdit(user.userId,user.password)}
                    className="Change_Password"
                  >
                    {content["Change Password"]}
                  </a>
                  <a
                    onClick={() =>
                      user.userId === Currentemail
                        ? handleCheck(user.userId)
                        : handleState(user.userId)
                    }
                    className={
                      user.state === "Available"
                        ? "disable-button"
                        : "enable-button"
                    }
                  >
                    {user.state === "Available"
                      ? content["Disable"]
                      : content["Enabled"]}
                  </a>

                  <a
                    onClick={() => handleDelete(user.userId)}
                    className={`Delete_User ${
                      user.userId === Currentemail ? "disabled-button" : ""
                    }`}
                    disabled={user.userId === Currentemail}
                  >
                    {content["Delete"]}
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

const tableHeaderStyle = {
  backgroundColor: "rgb(234 235 244)",
  fontWeight: "bold",
  padding: "8px",
};

const tableCellStyle = {
  // border: "1px solid #ddd",
  // padding: "24px",
};
export default Account;
