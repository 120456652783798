import React, { useEffect, useState, useRef } from "react";
import NavBar from "../other/navBar";
import calendarImage from "../../../img/calendar.png";
import "./users.css";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useOutletContext } from "react-router-dom";
import { FaCircleInfo } from "react-icons/fa6";
import { SlCalender } from "react-icons/sl";
import { RiCloseFill } from "react-icons/ri";
import { apiBaseUrls, apiKeys } from "../../../config";
import { encryptedaes } from "../../../utils/encrypt/encryptUtils";
import Accordion from './Accordion';

const API_ENDPOINT = apiBaseUrls.development;

const tableHeaderStyle = {
  backgroundColor: "rgb(234 235 244)",
  fontWeight: "bold",
  padding: "8px",
};

const createCustomLocale = (days, months) => {
  return {
    localize: {
      day: (n) => days[n],
      month: (n) => months[n],
    },
    formatLong: {
      date: () => "MM/dd/yyyy",
    },
  };
};

const ModalPoint = ({
  show,
  handleClose,
  modalLoading,
  pointsData,
  refundData,
  convertTimestampToDateTime,
  showDatePicker,
  startDate,
  handleDateChange,
  getDatePickerLocale,
  opencalender,
  calendarRef,
  content,
}) => {
  if (!show) return null;

  const filterDataByDate = (data, selectedDate) => {
    if (!selectedDate) return data;
    return data.filter((entry) => {
      const entryDate = new Date(entry.time);
      return entryDate.toDateString() === selectedDate.toDateString();
    });
  };

  const filteredData = filterDataByDate(pointsData?.data || [], startDate);

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <div>
            <div>
              <b>{refundData?.email}</b>
            </div>
            <div style={{ textAlign: "left" }}>
              <b>{content["Total Point"]}:</b>
              <span style={{ color: "#22905a", fontWeight: "800" }}>
                {pointsData ? pointsData.Total_Points : "Loading..."}
              </span>
            </div>
          </div>
          <div>
            {showDatePicker && (
              <div ref={calendarRef}>
                <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  inline
                  locale={getDatePickerLocale()}
                />
              </div>
            )}
            <img
            src={calendarImage}
            alt="Calendar"
            style={{ cursor: "pointer", width:"32px",marginRight: "38px" }}
            onClick={opencalender}
          />
            <RiCloseFill
              style={{
                color: "#000",
                cursor: "pointer",
                fontSize: "34px",
              }}
              onClick={handleClose}
            />
          </div>
        </div>
        <div className="modal-body">
          {modalLoading ? (
            <div className="loader"></div>
          ) : (
            <div className="points-info">
              <table>
                <tbody>
                  {filteredData.map((entry, index) => (
                    <tr key={index} className="entry">
                      <td>
                        {entry.title}
                        <div className="dateinfo">
                          {convertTimestampToDateTime(entry.time)}
                        </div>
                      </td>
                      <td className="points">+ {entry.points}</td>
                      <td>{entry.Total_Points}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {filteredData.length === 0 && (
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  {content["No data available for selected date"]}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ModalSub = ({ 
  show, 
  handleClose, 
  content,
  refundData,
  subsData, 
  modalLoading,
  convertTimestampToDateTime,

}) => {
  if (!show) return null;
console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&--->",refundData)
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <div>
            <div>
              <b>{refundData?.email}</b>
            </div>
            <br />
            <div style={{ textAlign: "left" }}>
              <b>Identity:</b>
              {subsData ? 
              
              <>
              <span style={{ color: "#22905a", fontWeight: "800" }}>
                <span
                  style={{
                    color: "black",
                    padding: "0px 8px",
                    borderRadius: "4px",
                    backgroundImage:
                      "linear-gradient(to right top, #ffffff, #f2e8f2, #efceda, #eab7b6, #daa48b)",
                  }}
                >
                  <span style={{ color: "#6a4633" }}>
                    <b>{content["VIP"]}</b>
                  </span>
                </span>
              </span>
              </>
              
              : "Loading..."}
            </div>
          </div>
          <div>
            <RiCloseFill
              style={{
                color: "#000",
                cursor: "pointer",
                fontSize: "34px",
              }}
              onClick={handleClose}
            />
          </div>
        </div>
  

        <div className="modal-body">
          {subsData ? 
              <>
                <Accordion 
                subsData={subsData}
                content={content}
                convertTimestampToDateTime={convertTimestampToDateTime}
                />      
              </>
              : <>
                <span className="loader"></span>
              </>}


        </div>
      </div>
    </div>
  );
};

export default function Users() {
  const { content } = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [refundData, setRefundData] = useState(null);
  const [modalVisiblePoint, setModalVisiblePoint] = useState(false);
  const [modalVisibleSub, setModalVisibleSub] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [pointsData, setPointsData] = useState(null);
  const [subsData, setSubsData] = useState(null);
  const [modalLoading, setModalLoading] = useState(false);

  const calendarRef = useRef(null);

  const opencalender = () => {
    setShowDatePicker(true);
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    setShowDatePicker(false);
  };

  const config = {
    headers: {
      "X-API-KEY": apiKeys.development,
      "User-Id": localStorage.getItem("UserId"),
      "Session-Token": "",
    },
  };

  const getDatePickerLocale = () => {
    return createCustomLocale(content.days, content.months);
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;

    return formattedDate;
  }

  function convertTimestampToDateTime(timestamp) {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return `${year}-${month}-${day}`;
  }

  function isSubscriptionExpired(subscriptionTimestamp) {
    const currentDate = new Date();
    const subscriptionDate = new Date(subscriptionTimestamp);
    return subscriptionDate < currentDate;
  }

  const fetchData = () => {
    const requestBody = {
      email: email,
    };
    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;
    const encryptbody = { data: encryptedData };

    setLoading(true);
    axios
      .post(`${API_ENDPOINT}userdetails/getUsers`, encryptbody, config)

      .then((response) => {
        setLoading(false);
        const responseData = response.data.data;
        console.log("UserInfo", response.data.result);
        if (response.data.result === 403) {
          setRefundData([]);
          Swal.fire({
            icon: "warning",
            title: content["No data available"],
            confirmButtonText: content["OK"],
          });
        } else {
          setRefundData(responseData);
        }
      })

      .catch((error) => {
        setLoading(false);
      });
  };

  const handleEmailIdChange = (value) => {
    setEmail(value);
  };

  const handleClickPoint = (email) => {
    setModalVisiblePoint(true);
    setModalLoading(true);

    const requestBody = {
      email: email,
    };
    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;
    const encryptbody = { data: encryptedData };

    axios
      .post(`${API_ENDPOINT}userdetails/getPoints`, encryptbody, config)
      .then((response) => {
        setModalLoading(false);
        if (response.data.result === 100) {
          setPointsData(response.data.points);
        } else {
          // Handle error
          console.error("Error fetching points data");
        }
      })
      .catch((error) => {
        setModalLoading(false);
        console.error("Error fetching points data", error);
      });
  };

  const handleCloseModal = () => {
    setModalVisiblePoint(false);
    setShowDatePicker(false);
    setPointsData(null);
    setStartDate(null);
  };

  const handleClickSub = (email) => {
    setModalVisibleSub(true);
    const requestBody = {
      email: email,
    };
    const { encryptedData, SessionToken } = encryptedaes(requestBody);
    config.headers["Session-Token"] = SessionToken;
    const encryptbody = { data: encryptedData };

    axios
      .post(`${API_ENDPOINT}userdetails/getSubscription`, encryptbody, config)
      .then((response) => {
        setModalLoading(false);
        if (response.data.result === 100) {
          setSubsData(response.data.data);
        } else {
          console.error("Error fetching subs data");
        }
      })
      .catch((error) => {
        setModalLoading(false);
        console.error("Error fetching points data", error);
      });


  };

  const handleCloseModalSub = () => {
    setModalVisibleSub(false);
    setSubsData(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    };

    if (showDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDatePicker]);

  const isValidEmail = (email) => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  };

  const isButtonDisabled = () => {
    return !email || !isValidEmail(email);
  };

  return (
    <>
      <NavBar />
      <div
        className="mainpage"
        style={{
          textAlign: "right",
          marginBottom: "10px",
          marginLeft: "10px",
          marginRight: "-6px",
        }}
      >
        <div>
          <div style={{ fontSize: "3.3vh", fontWeight: 500 }}>
            {content["TV App User Record"]}
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div>
            <input
              type="text"
              value={email}
              className="order-input-class"
              placeholder={content["Enter email address"]}
              onChange={(e) => handleEmailIdChange(e.target.value)}
            />
          </div>
          &nbsp;
          <button
            className="btn_order_search"
            onClick={fetchData}
            disabled={isButtonDisabled()}
          >
            {content["Search"]}
          </button>
          &nbsp;&nbsp;
        </div>
      </div>

      {loading ? (
        <div className="homeloader"></div>
      ) : (
        <div style={{ overflow: "auto", height: "82vh" }}>
          <table style={{ borderCollapse: "collapse", width: "100%" }}>
            <br />
            <thead>
              <tr>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Email"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Current Date"]}
                </th>

                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Last Login"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Current Points"]}
                </th>
                <th
                  style={{
                    ...tableHeaderStyle,
                    position: "sticky",
                    top: "0",
                    color: "#a9afc3",
                    textAlign: "center",
                    zIndex: "1",
                  }}
                >
                  {content["Current Identity"]}
                </th>
              </tr>
              {refundData === null && (
                <tr>
                  <td
                    colSpan="6"
                    style={{ textAlign: "center", padding: "20px" }}
                  >
                    <h3> {content["Enter on Email to search"]}</h3>
                  </td>
                </tr>
              )}
            </thead>
            <tbody>
              {refundData && (
                <tr>
                  <td className="tdvaluesubtitle">{refundData.email}</td>
                  <td className="tdimage" style={{ textAlign: "center" }}>
                    {convertTimestampToDateTime(refundData.currentTime)}
                  </td>
                  <td className="tdvaluecourse">
                    {convertTimestampToDateTime(refundData.lastLogin)}
                  </td>
                  <td className="tdvaluecourse">
                    <span style={{ color: "#22905a", fontWeight: "800" }}>
                      {refundData.totalPoints}
                    </span>
                    &nbsp;
                    <FaCircleInfo
                      style={{ color: "#000", cursor: "pointer" }}
                      onClick={() => handleClickPoint(refundData.email)}
                    />
                  </td>
                  <td
                    className="tdvalueOperaterefund"
                    style={{ color: "darkgray" }}
                  >
                    {isSubscriptionExpired(refundData.subscriptionValidity) ? (
                      <span
                        style={{
                          color: "red",
                          padding: "7px 28px",
                          borderRadius: "4px",
                          backgroundImage:
                            "linear-gradient(to right top, #ffffff, #f2e8f2, #efceda, #eab7b6, #daa48b)",
                        }}
                      >
                        <span style={{ color: "#6a4633" }}>
                          <b> {content["Expired"]}</b>
                        </span>
                      </span>
                    ) : (
                      <>
                        <span
                          style={{
                            color: "black",
                            padding: "7px 28px",
                            borderRadius: "4px",
                            backgroundImage:
                              "linear-gradient(to right top, #ffffff, #f2e8f2, #efceda, #eab7b6, #daa48b)",
                          }}
                        >
                          <span style={{ color: "#6a4633" }}>
                            <b>{content["VIP"]}</b>
                          </span>
                        </span>
                        &nbsp;
                        <FaCircleInfo
                          style={{ color: "#000", cursor: "pointer" }}
                          onClick={() => handleClickSub(refundData.email)}
                        />
                        <div style={{ marginTop: "10px" }}>
                          {content["Valid until"]}:
                          {convertTimestampToDateTime(
                            refundData.subscriptionValidity
                          )}
                        </div>
                      </>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      <ModalPoint
        show={modalVisiblePoint}
        handleClose={handleCloseModal}
        modalLoading={modalLoading}
        pointsData={pointsData}
        refundData={refundData}
        convertTimestampToDateTime={convertTimestampToDateTime}
        showDatePicker={showDatePicker}
        handleDateChange={handleDateChange}
        startDate={startDate}
        getDatePickerLocale={getDatePickerLocale}
        opencalender={opencalender}
        calendarRef={calendarRef}
        content={content}
      />

      <ModalSub
        show={modalVisibleSub}
        handleClose={handleCloseModalSub}
        modalLoading={modalLoading}
        convertTimestampToDateTime={convertTimestampToDateTime}
        content={content}
        subsData={subsData}
        refundData={refundData}
      />
    </>
  );
}
