import useLanguage from "../../../translation/TranslationEngToChinese";


export default function Gift() {
  const { content } = useLanguage();

    return (
      <>
        <h1>{content['Coming soon']}</h1>
      </>
    )
}
